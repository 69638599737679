import React, { useMemo } from "react";
import { useRouteMatch } from "react-router-dom";
import { useUrlParams } from "../../../state/hooks";
import { EXECUTION_DETAILS } from "../../routes";
import { useSelector } from "react-redux";
import useExecution from "../../../state/executions/useExecution";

const selectOrchestration = (state, id) => state.orchestrations.entities[id];

export default function useOrchestrationExecutionViewer(overrides = {}) {
  const params = useUrlParams();
  const match = useRouteMatch(EXECUTION_DETAILS);
  const { execution, loading } = useExecution({ id: overrides.executionId });

  const props = useMemo(
    () => ({
      ...params,
      ...overrides,
      id: overrides.executionId,
      execution,
    }),
    [params, overrides, execution]
  );

  const getEnv = () => {
    if (window.self !== window.top) {
      return "iframe";
    }
    if (match) {
      return "url";
    }
    return "web";
  };

  const orchestration = useSelector((state) =>
    selectOrchestration(state, props.orchestrationId)
  );

  const [error, setError] = React.useState(null);

  React.useEffect(() => {
    setError(null);
  }, []);

  return {
    orchestration,
    env: getEnv(),
    error,
    loading,
    ...props,
  };
}
