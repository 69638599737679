export const socketEvents = {
  UNAUTHENTICATED: "unauthenticated",
};

export const socketApiEvents = {
  ABORT_ORCHESTRATION: "abort-orchestration",
  AUTHENTICATED: "authenticated",
  COLLECTION_CHANGED: "collection:changed",
  STORE_SECRETS: "store-secrets",
  CONNECTION_READING: "reading",
  CONNECTION_STATUS_UPDATED: "connection:status-updated",
  MANUAL_TRIGGER: "manual-trigger",
  USER_READING: "user-reading",
  STORE_FILE: "store-file",
  IO_COMPLETED: "interactive-orchestration-completed",
  STEP_ACTION_COMPLETED: "step-action-completed",
};

export const types = {
  WS_ERROR: "WS_ERROR",
  WS_STORE_SECRETS: "WS_STORE_SECRETS",
  WS_SEND_MESSAGE: "WS_SEND_MESSAGE",
  WS_STORE_FILE: "WS_STORE_FILE",
  WS_CONNECTION_STATUS_UPDATED: "WS_CONNECTION_STATUS_UPDATED",
  WS_CONNECTION_READING: "WS_CONNECTION_READING",
};

export const CLOSE_SOCKET = "CLOSE_SOCKET";
export const OPEN_SOCKET = "OPEN_SOCKET";
export const SOCKET_MESSAGE = "SOCKET_MESSAGE";
