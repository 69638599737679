import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import cloneDeep from "lodash/cloneDeep";
import Navigation from "@dlx/atoms/Navigation";
import styles from "./OrchestrationEditor.module.scss";
import OrchestrationFlow from "./OrchestrationFlow";
import OrchestrationEditorName from "./OrchestrationEditorName";
import selectors from "../../state/selectors";
import { alertError } from "../../state/alerts/actions";
import { selectOrchestrationEditor } from "./state/selectors";
import { closeEditor, isEditing, loadOrchestrationsPage } from "./state/actions";
import FrameDrawer from "../FrameDrawer/FrameDrawer";
import { ORCHESTRATION_EXECUTION_UPDATE } from "../../state/executions/constants";
import useSocketEvent from "../../state/hooks/useSocketEvent";
import api from "../../services/api";
import {
  ORCHESTRATIONS_PATH,
  UPDATE_EDITED_EXECUTION,
  ERR_VALIDATION,
} from "./state/constants";
import { DiscardPromptProvider } from "./DiscardDialog";
import { useLocation } from "react-router-dom";
import { useCurrentUserContext } from "../../contexts/current-user/Provider";
import OrchestrationUpdateBanner from "./OrchestrationUpdateBanner";
import { Settings } from "./settings/Settings";
import { reMapStepData } from "@dlx/state/executions/useExecution";

const NAVIGATION_DEFAULT = {
  label: "Orchestration",
  error: false,
};

const NAVIGATION_SETTINGS = {
  label: "Settings",
  error: false,
};

const defaultExecutionInfo = {
  execution: {},
  statusOfStep: () => null,
  getStepInputs: () => null,
  getStepOutput: () => null,
  getTriggerOutput: () => null,
};

export const OrchestrationEditorContext = React.createContext(null);

export function useOrchestrationEditorContext() {
  return useContext(OrchestrationEditorContext);
}

const NAVIGATION_OPTIONS = [NAVIGATION_DEFAULT, NAVIGATION_SETTINGS];

/**
 * @param {{
 * disabled: boolean,
 * executionId?: String,
 * onSubmit: Function,
 * orchestration: Orchestration,
 * validationSchema?: any,
 * title: String,
 * upgradeOrchestration: Function,
 * editorType: String,
 * selectedNavigation: Object,
 * }} props
 */

export default function BaseEditor({
  disabled,
  executionInfo = defaultExecutionInfo,
  onSubmit,
  orchestration,
  validationSchema,
  title,
  upgradeOrchestration,
  editorType,
  selectedNavigation = NAVIGATION_OPTIONS[0],
}) {
  const dispatch = useDispatch();
  const [hideBanner, setHideBanner] = useState(false);
  const actors = useSelector(selectors.getActors);
  const [shouldHideSubmit, setShouldHideSubmit] = React.useState(true);
  const location = useLocation();
  const editor = useSelector(selectOrchestrationEditor);
  const [navigations, setNavigations] = useState(NAVIGATION_OPTIONS);
  const [selected, setSelected] = useState(selectedNavigation);
  const {
    currentUser: { permissions },
  } = useCurrentUserContext();
  const showUpdateBanner =
    orchestration?.orchestrationVersion === 1 && title === "Update" && !hideBanner;
  const permissionRequired = React.useCallback(
    (step) =>
      actors[step.kind]?.permissionRequired &&
      !permissions.includes(actors[step.kind]?.permissionRequired),
    [actors, permissions]
  );

  const validateStep = React.useCallback(
    (step) => {
      if (permissionRequired(step)) {
        const message = `You do not have the necessary permissions to create or modify an orchestration with a ${
          actors[step.kind]?.name
        } step`;
        throw new Error(message);
      }
      return true;
    },
    [actors, permissionRequired]
  );

  const validateStepPermissions = (values) => {
    for (const step of values.steps || []) {
      validateStep(step);
    }
    return true;
  };

  const handleOnSubmit = async (values) => {
    try {
      validateStepPermissions(values);
      onSubmit(values);
      resetValidationErrors(values);
    } catch (error) {
      console.log("error submitting values", error);
      dispatch(alertError(error.message));
    }
  };

  const formik = useFormik({
    initialValues: orchestration,
    validationSchema: validationSchema,
    onSubmit: handleOnSubmit,
  });

  useEffect(() => {
    if (orchestration.orchestrationVersion !== formik.values.orchestrationVersion) {
      formik.resetForm({ values: orchestration });
    }
  }, [orchestration]);

  useEffect(() => {
    if (formik.dirty) {
      setFieldValue("orchestrationVersion", orchestration.orchestrationVersion);
      setShouldHideSubmit(false);
    } else {
      if (orchestration.name) {
        setShouldHideSubmit(false);
      } else {
        setShouldHideSubmit(true);
      }
    }
  }, [formik.dirty, orchestration, dispatch]);

  const setFieldValue = formik.setFieldValue;

  const resetValidationErrors = React.useCallback(
    (values) => {
      delete values?.trigger?.validationError;
      if (values.steps) {
        values.steps.forEach((s) => delete s?.validationError);
      }
      formik.resetForm({ values });
    },
    [formik]
  );

  const setFieldError = formik.setFieldError;

  const handleUpdateOrchestration = React.useCallback(
    /** @param {Orchestration} update */
    (update, error) => {
      if (error) {
        setFieldError(error.field, error.message);
      } else {
        update.trigger && setFieldValue("trigger", update.trigger);
        update.steps && setFieldValue("steps", update.steps);
      }
    },
    [setFieldError, setFieldValue]
  );

  const isDisabled = React.useMemo(
    () => disabled || !formik.isValid || !formik.dirty,
    [disabled, formik.isValid, formik.dirty]
  );

  useEffect(() => {
    dispatch(isEditing({ dirty: formik.dirty }));
  }, [formik.dirty, dispatch]);

  const setFailedVaildations = React.useCallback(
    (steps = []) => {
      const stepsCopy = cloneDeep(steps);
      stepsCopy.forEach((s) => delete s.validationError);
      editor.error.save.error.failedValidations.document.forEach((err) => {
        if (err.path.includes("trigger.")) {
          setFieldValue("trigger.validationError", err);
        }
        if (err.path.includes("steps.")) {
          const stepNum = Number(err.path.match(/steps\.(.*?)\./)?.[1]);
          if (stepsCopy[stepNum]) {
            stepsCopy[stepNum].validationError = err;
          }
        }
      });
      setFieldValue("steps", stepsCopy);
    },
    [setFieldValue, editor.error]
  );

  useEffect(() => {
    if (editor.error?.save?.error?.failedValidations) {
      setFailedVaildations(formik.values.steps);
    }
    // eslint-disable-next-line
  }, [setFailedVaildations, editor.error]);

  useSocketEvent({
    event: ORCHESTRATION_EXECUTION_UPDATE,
    onCompleted: async (message) => {
      if (message.executionId !== editor.executionId) {
        const exec = await api.executions.get({ id: message.executionId });
        if (exec.orchestration.id === editor.orchestrationId) {
          dispatch({
            type: UPDATE_EDITED_EXECUTION,
            payload: { executionId: exec?.id },
          });
        }
      }
    },
    skip: () => editor.mode === "view",
  });

  useEffect(() => {
    dispatch(loadOrchestrationsPage());
    return () => {
      dispatch(
        closeEditor({
          prompt: editor.isEditing,
          redirectUrl: location.state?.from | ORCHESTRATIONS_PATH,
        })
      );
    };
  }, []);

  const errorIndicator = (label) => {
    let error = editor.error?.save?.error;
    if (label === NAVIGATION_DEFAULT.label) {
      error = error?.failedValidations || error?.code === ERR_VALIDATION;
    }
    if (label === NAVIGATION_SETTINGS.label) {
      const { description, labelExpressions } = formik.errors;
      error = (description || labelExpressions) && error?.code === ERR_VALIDATION;
    }
    return error;
  };

  useEffect(() => {
    const update = [...navigations].map((n) => ({
      ...n,
      error: errorIndicator(n.label),
    }));
    setNavigations(update);
  }, [editor.error, formik.errors]);

  const getExecutionOutput = async () => {
    const output = await api.executions.mappingSources({
      id: executionInfo?.execution?.id,
    });
    const steps = reMapStepData(output);
    return { ...output, steps };
  };

  return (
    <OrchestrationEditorContext.Provider value={{ executionInfo, orchestration }}>
      {showUpdateBanner && (
        <OrchestrationUpdateBanner
          orchestration={orchestration}
          disabled={!isDisabled || disabled}
          upgradeOrchestration={upgradeOrchestration}
          setHideBanner={setHideBanner}
        />
      )}
      <DiscardPromptProvider
        shouldPrompt={editor.isEditing && editor.mode !== "view"}
        fallback="/orchestrations"
      >
        <div
          className={styles.dialogContent}
          data-testid="dtid_orchestration_editor_dialog_content"
        >
          <OrchestrationEditorName
            formik={formik}
            editor={editor}
            disabled={isDisabled}
            hideSubmit={shouldHideSubmit}
          />
          <Navigation
            navigations={navigations}
            selected={selected}
            onSelect={setSelected}
            dataTestId="dtid_orchestration_navigation"
          />

          {selected.label === NAVIGATION_DEFAULT.label && (
            <OrchestrationFlow
              orchestration={formik.values}
              updateOrchestration={handleUpdateOrchestration}
              editorType={editorType}
            />
          )}
          {selected.label === NAVIGATION_SETTINGS.label && (
            <Settings
              value={formik.values.description}
              onTextChange={(e) => {
                formik.setFieldValue("description", e.target.value);
              }}
              list={formik.values.labelExpressions}
              onChange={(tags) => {
                formik.setFieldValue("labelExpressions", tags);
              }}
              errors={formik.errors}
              getExecutionOutput={getExecutionOutput}
              onBlur={() => {
                formik.setFieldValue(
                  "description",
                  formik.values?.description?.trim()
                );
              }}
            />
          )}
          <FrameDrawer />
        </div>
      </DiscardPromptProvider>
    </OrchestrationEditorContext.Provider>
  );
}
