import { createSlice, createEntityAdapter } from "@reduxjs/toolkit";
import { initialState, reducer } from "../reducers/dbAdapter";
import { types as socketTypes, socketApiEvents } from "../socket/socket.actions";
const tableName = "users";

export const createUserReading = (connectionId, payload, metadata) => ({
  type: socketTypes.WS_SEND_MESSAGE,
  payload: {
    type: socketApiEvents.USER_READING,
    message: {
      connectionId,
      payload,
      metadata,
      recordedAt: new Date().toISOString(),
    },
  },
});

export const userAdapter = createEntityAdapter({
  selectId: (entity) => entity.id || entity._id,
});

const slice = createSlice({
  name: tableName,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    reducer(tableName)(builder);
  },
});

export default slice.reducer;
