import { createSelector } from "@reduxjs/toolkit";

export const getConnectorSelector = (state, id) => state.connectors.entities[id];

export const getConnectorByConnectionSelector = (state, id) =>
  state.connectors.entities[state.connections.entities[id]?.connector];

export const getConnectorsIdsByStatus = (status) => (state) => {
  return state.connectors.ids.filter(
    (id) => state.connectors.entities[id].state === status
  );
};

export const getConnectors = createSelector(
  (state) => state.connectors.ids,
  (state) => state.connectors.entities,
  (ids, entities) => ids.map((id) => entities[id]).filter(Boolean)
);

export const getConnectorsList = (state) => state.connectors.entities;

export const getConnectorsLoadingStatus = (state) => state.connectors.loading;
