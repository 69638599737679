import React, { useState, useEffect } from "react";
import isEmpty from "lodash/isEmpty";
import { DeleteIcon } from "../../../../icons";
import { useOrchestrationEditorContext } from "../../BaseEditor";
import {
  AstEditor,
  astToExpression,
  expressionToAst,
} from "@dlx/components/ExpressionEditor";

/**
 * @param {{
 *  condition: string,
 *  stepNum: number,
 *  onRemoveCondition: Function,
 *  onUpdateCondition: Function,
 * }} props
 */
const ConditionalParameter = ({
  condition,
  stepNum,
  onRemoveCondition,
  onUpdateCondition,
}) => {
  const { executionInfo = {} } = useOrchestrationEditorContext() || {};
  const [context, setContext] = useState(null);
  const [draft, setDraft] = useState(expressionToAst(condition));

  const { getStepInputs } = executionInfo;

  useEffect(() => {
    const expression = astToExpression(draft);
    if (condition && expression !== condition) {
      setDraft(expressionToAst(condition));
    }
  }, [condition]);

  useEffect(() => {
    getStepInputs(stepNum - 1)?.then((data) => {
      if (stepNum === 0) {
        const { steps, ...ctx } = data;
        return setContext(ctx);
      }
      return setContext(data);
    });
  }, [stepNum, getStepInputs]);

  useEffect(() => {
    if (!isEmpty(draft)) {
      handleUpdateCondition();
    }
  }, [draft]);

  const handleUpdateCondition = async () => {
    const exp = astToExpression(draft);
    if (exp) {
      onUpdateCondition(exp);
    }
  };

  return (
    <div className="flex">
      <div className="flex-auto">
        <AstEditor
          element={draft}
          setElement={setDraft}
          // typesAllowed={["boolean"]}
          context={context}
        />
      </div>
      <div className="flex items-center pl2 pointer" onClick={onRemoveCondition}>
        <DeleteIcon name={"Clear"} size={24} color="inherit" />
      </div>
    </div>
  );
};

export default ConditionalParameter;
