import { all, fork } from "redux-saga/effects";
import authSaga from "../auth/auth.saga";
import wsSaga from "./ws.saga";
import recordSaga from "./record.saga";
import tablesSaga from "./tables.saga";
import apiSaga from "./api.saga";
import importSaga from "./import.saga";
import exportSaga from "./export.saga";
import leaseSaga from "../connections/lease/lease.saga";
import orchestrationEditorSaga from "../../components/orchestrationeditor/state/saga";
import urlTriggeredOrchestrationSaga from "../orchestrations/triggerOrchestration.saga";

export default function* rootSaga() {
  try {
    yield all([
      fork(authSaga),
      fork(wsSaga),
      fork(tablesSaga),
      fork(recordSaga),
      fork(apiSaga),
      fork(orchestrationEditorSaga),
      fork(importSaga),
      fork(exportSaga),
      fork(leaseSaga),
      fork(urlTriggeredOrchestrationSaga),
    ]);
  } catch (e) {
    console.log("error in root saga", e);
    localStorage.clear();
    window.location.reload();
  }
}
