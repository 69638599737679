import React from "react";
import { ScitaraLogo } from "../../icons";
import styles from "./Header.module.scss";

export default function Logo({ onClick }) {
  return (
    <ScitaraLogo
      className={styles.logo}
      onClick={onClick}
      onDragStart={(e) => e.preventDefault()}
    />
  );
}

Logo.defaultProps = {
  onClick: () => null,
};
