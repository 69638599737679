import { createEntityAdapter } from "@reduxjs/toolkit";
import { types as socketTypes } from "../socket/socket.actions";

export const reading = (message) => ({
  type: socketTypes.WS_CONNECTION_READING,
  payload: message,
});

const readingAdapter = createEntityAdapter({
  selectId: (connection) => connection.id,
});

export default function reducer(state = readingAdapter.getInitialState(), action) {
  if (action.type === socketTypes.WS_CONNECTION_READING) {
    return readingAdapter.upsertOne(state, {
      ...action.payload,
      reading: [...action.payload],
    });
  }
  return state;
}
