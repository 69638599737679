import {
  broadcastLogout,
  broadcastSessionAuthenticated,
  broadcastTokenRefreshFailed,
  channel as authChannel,
} from "./services/authChannel";
import { discoverProvider, getIamToken } from "./services/sso";
import {
  storeCurrentUser,
  storeIamToken,
  validateCurrentUser,
} from "@dlx/services/tokens";
import ReactDOM from "react-dom";
import React from "react";
import { clearStorage, isInIframe } from "@dlx/utils/index";
import RenderIframe, { IFRAME_SCREEN } from "./components/IFrame/RenderIFrame";
const channel = new BroadcastChannel("dlx");
const currentUrl = new URL(window.location.href);

async function processSignOnWithoutCode() {
  broadcastLogout();
  clearStorage();
  await discoverProvider({ action: "sign-on", redirectUrl: "/" });
}

async function processSignOnWithCode() {
  const stateString = currentUrl.searchParams.get("state");
  const state = JSON.parse(stateString);
  if (state.action === "popup") {
    try {
      const token = await getIamToken(stateString);
      validateCurrentUser(token);
      storeIamToken(token);
      storeCurrentUser(token);
      broadcastSessionAuthenticated(token);
    } catch (error) {
      broadcastTokenRefreshFailed(error);
    }
    window.close();
  }
  if (state.action === "esign") {
    try {
      const token = await getIamToken(stateString);
      validateCurrentUser(token);
      channel.postMessage({
        requestId: state.requestId,
        signature: token.id_token,
        action: "esign",
      });
    } catch (error) {
      console.log(error);
      channel.postMessage({
        requestId: state.requestId,
        error,
        action: "esign",
      });
    }
    window.close();
  }
  if (state.action === "sign-on") {
    try {
      if (currentUrl.searchParams.has("error")) {
        throw new Error(currentUrl.searchParams.get("error_description"));
      }
      const token = await getIamToken(stateString);
      validateCurrentUser(token, true);
      storeIamToken(token);
      storeCurrentUser(token);
      window.history.replaceState({}, "", state.redirectUrl);
    } catch (error) {
      console.log(error);
      window.history.replaceState(
        {
          error,
        },
        "",
        "/error"
      );
    }
  }
  return require("./main");
}

(async () => {
  if (isInIframe()) {
    return require("./dlx-iframe");
  }
  if (currentUrl.pathname === "/sign-on" && !currentUrl.searchParams.has("code")) {
    await processSignOnWithoutCode();
  }
  if (currentUrl.pathname === "/sign-on" && currentUrl.searchParams.has("code")) {
    return processSignOnWithCode();
  }

  const isSigninIframe = currentUrl.searchParams.get("signinAction") === "iframe";
  const signinAction = isSigninIframe ? "popup" : "sign-on";
  if (isSigninIframe) {
    ReactDOM.render(
      <RenderIframe screen={IFRAME_SCREEN.AUTHENTICATING} />,
      document.getElementById("app")
    );
    authChannel.addEventListener("message", ({ data }) => {
      const allowedOrigin = `${window.location.protocol}//${window.location.host}`;
      window.opener?.postMessage(data?.token, allowedOrigin);
      window.close();
    });
  }

  return discoverProvider({
    action: signinAction,
    redirectUrl: currentUrl.href,
  });
})();
