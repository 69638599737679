import { createAction, createEntityAdapter } from "@reduxjs/toolkit";
import { kebabCase } from "lodash";
import { sortByField } from "../../utils/commons";

export const dbPreLoad = createAction("db:preload");
export const dbPreLoadError = createAction("db:preload:error");

export const dbLoad = createAction("db:load");
export const dbLoadSuccess = createAction("db:load:success");
export const dbLoadError = createAction("db:load:error");
export const dbRecordCreated = createAction("CREATE_RECORD_SUCCESS");
export const dbRecordRetrieved = createAction("GET_RECORD_SUCCESS");
export const dbRecordUpdated = createAction("UPDATE_RECORD_SUCCESS");
export const dbRecordDeleted = createAction("DELETE_RECORD_SUCCESS");
export const dbCreateRecord = createAction("CREATE_RECORD");
export const dbRetrieveRecord = createAction("GET_RECORD");
export const dbUpdateRecord = createAction("UPDATE_RECORD");
export const dbDeleteRecord = createAction("DELETE_RECORD");
export const recordError = createAction("RECORD_ERROR");

export const dbPostUpdateProcess = createAction("db:load:postprocess");
export const dbLoading = createAction("db:loading");
export const dbReissueTemporaryPassword = createAction(
  "db:reissue:temporarypassword"
);

export const actions = {
  preload: dbPreLoad,
  preloadError: dbPreLoadError,
  load: dbLoad,
  loadSuccess: dbLoadSuccess,
  loadError: dbLoadError,
  recordCreated: dbRecordCreated,
  recordRetrieved: dbRecordRetrieved,
  recordUpdated: dbRecordUpdated,
  recordDeleted: dbRecordDeleted,
  createRecord: dbCreateRecord,
  getRecord: dbRetrieveRecord,
  updateRecord: dbUpdateRecord,
  deleteRecord: dbDeleteRecord,
  postUpdateProcess: dbPostUpdateProcess,
  loading: dbLoading,
  ReissueTemporaryPassword: dbReissueTemporaryPassword,
};

export const constants = {
  DB_PRELOAD: dbPreLoad.toString(),
  DB_LOAD: dbLoad.toString(),
  DB_LOAD_SUCCESS: dbLoadSuccess.toString(),
  DB_LOAD_ERROR: dbLoadError.toString(),
  DB_RECORD_CREATED: dbRecordCreated.toString(),
  DB_RECORD_RETRIEVED: dbRecordRetrieved.toString(),
  DB_RECORD_UPDATED: dbRecordUpdated.toString(),
  DB_RECORD_DELETED: dbRecordDeleted.toString(),
  DB_CREATE_RECORD: dbCreateRecord.toString(),
  DB_RETRIEVE_RECORD: dbRetrieveRecord.toString(),
  DB_UPDATE_RECORD: dbUpdateRecord.toString(),
  DB_DELETE_RECORD: dbDeleteRecord.toString(),
  DB_LOADING: dbLoading.toString(),
  DB_REISSUE_TEMPORARY_PASSWORD: dbReissueTemporaryPassword.toString(),
};

const sortByName = sortByField("name");

export const initialState = {
  loading: false,
  fetched: {},
  error: {},
  loaded: false,
  entities: {},
  ids: [],
  $links: [],
};

const addDbLoading = (builder, _adapter, tableName) => {
  builder.addCase(dbLoading, (state, action) => {
    if (action.payload.tableName === tableName) {
      state.loading = true;
    }
  });
};
const addRecordError = (builder, _adapter, tableName) => {
  builder.addCase(recordError, (state, action) => {
    if (action.payload.tableName === tableName) {
      const { id } = action.payload;
      state.error[id] = action.payload.message;
    }
  });
};
const addDbRetrieveRecord = (builder, _adapter, tableName) => {
  builder.addCase(dbRetrieveRecord, (state, action) => {
    if (action.payload.tableName === tableName) {
      const { id } = action.payload;
      state.error[id] = null;
      state.fetched[id] = state.fetched[id] || false;
    }
  });
};

const addDbLoadSuccess = (builder, adapter, tableName) => {
  builder.addCase(dbLoadSuccess, (state, action) => {
    let isReasonsForChange = false;
    if (tableName === "reasons-for-change") {
      const newTableName = action.payload.tableName.split("/");
      if (newTableName[newTableName.length - 1] === tableName) {
        isReasonsForChange = true;
      }
    }

    if (kebabCase(action.payload.tableName) === tableName || isReasonsForChange) {
      // setAll would replace the entire list and
      // momentarily unmount any components that rely on a record in state.
      // and upsertMany won't remove entities not in the payload
      // so we handle in two steps.
      const { items, $links = [], ...data } = action.payload.data;
      try {
        if (action.payload.clearTable) {
          const removeIds = state.ids.filter(
            (id) => !items.map((r) => r.id).includes(id)
          );
          adapter.removeMany(state, removeIds);
        }
        adapter.upsertMany(state, items);
      } catch (e) {
        console.log(e.message, state, action);
      }

      state.$links = $links;
      // Add any other keys
      for (const key in data) {
        state[key] = data[key];
      }

      state.loaded = true;
      state.loading = false;
    }
  });
};
const addDbRecordRetrieved = (builder, adapter, tableName) => {
  builder.addCase(dbRecordRetrieved, (state, action) => {
    const match = kebabCase(action.payload.tableName) === tableName;
    if (match) {
      if (tableName === "executions") {
        adapter.setOne(state, action.payload.record);
      } else {
        adapter.upsertOne(state, action.payload.record);
      }
      state.fetched[action.payload.record.id] = true;
      state.error[action.payload.record.id] = null;
    }
  });
};
const addDbRecordCreated = (builder, adapter, tableName) => {
  builder.addCase(dbRecordCreated, (state, action) => {
    if (action.payload.tableName === tableName) {
      adapter.addOne(state, action.payload.record);
    }
  });
};
const addDbRecordUpdated = (builder, adapter, tableName) => {
  builder.addCase(dbRecordUpdated, (state, action) => {
    const { id, changes = [] } = action.payload;
    if (action.payload.tableName === tableName) {
      adapter.updateOne(state, { id, changes });
      state.error[id] = null;
    }
  });
};

const addDbRecordDeleted = (builder, adapter, tableName) => {
  builder.addCase(dbRecordDeleted, (state, action) => {
    if (action.payload.tableName === tableName) {
      adapter.removeOne(state, action.payload.id);
      state.error[action.payload.id] = null;
    }
  });
};

export const reducer =
  (tableName, options = { entityAdapter: {} }) =>
  (builder) => {
    const adapter = createEntityAdapter({
      selectId: (entity) => {
        if (entity._id) {
          throw new Error(`id found for ${entity}`);
        }
        return entity.id || entity.uuid || entity.correlation || entity.type;
      },
      sortComparer: sortByName,
      ...options.entityAdapter,
    });
    addDbLoading(builder, adapter, tableName);
    addRecordError(builder, adapter, tableName);
    addDbRetrieveRecord(builder, adapter, tableName);
    addDbLoadSuccess(builder, adapter, tableName);
    addDbRecordRetrieved(builder, adapter, tableName);
    addDbRecordCreated(builder, adapter, tableName);
    addDbRecordUpdated(builder, adapter, tableName);
    addDbRecordDeleted(builder, adapter, tableName);
    return builder;
  };

const dbAdapter = {
  actions,
  reducer,
  initialState,
  constants,
};

export default dbAdapter;
