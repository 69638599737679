import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getFullConnection } from "./selectors";
import useGetConnector from "../connectors/useGetConnector";
import useGetRecord from "../hooks/useGetRecord";
import useGetCollection from "../hooks/useGetCollection";
import { actions } from "../hooks/useApiResource";

const slice = "connections";

export default function useGetConnection(id) {
  const [fetched, connection] = useGetRecord(slice, id);
  return { fetched, connection };
}

export function useGetFullConnection(id) {
  const { fetched: connectionFetched, connection } = useGetConnection(id);
  const { fetched: connectorFetched } = useGetConnector(connection?.connector?.id);
  const fullConnection = useSelector(getFullConnection(id));
  return {
    fullConnection,
    fetched: connectionFetched && connectorFetched,
  };
}

export function useGetConnectionsCollection(filter) {
  const [loaded, connections] = useGetCollection(slice, filter);
  return { loaded, connections };
}

export const useGetConnectionInstallerCreds = (connection, setDetails) => {
  const dispatch = useDispatch();
  const [data, setData] = useState();
  const [error, setError] = useState();
  useEffect(() => {
    const getUrl = async () => {
      const response = await dispatch(
        actions.apiTransaction({
          route: `/connections/${connection.id}/installer-credentials`,
          method: "POST",
        })
      );
      setError(response.error);
      setData(response.error ? null : response);
    };
    if (setDetails) {
      getUrl();
    }
    // eslint-disable-next-line
  }, [connection.id, setDetails]);
  return { notAvailable: error, installer: data };
};
