export const ENQUEUE_SNACKBAR = "alerts:enque-snackbar";

export function enqueueSnackbar(message, options) {
  return {
    type: ENQUEUE_SNACKBAR,
    message,
    options,
  };
}

export function alertSuccess(message) {
  return enqueueSnackbar(message, {
    variant: "success",
  });
}

export function alertWarning(message) {
  return enqueueSnackbar(message, {
    variant: "warning",
  });
}

export function alertInfo(message) {
  return enqueueSnackbar(message, {
    variant: "info",
  });
}

export function alertError(message) {
  return enqueueSnackbar(message, {
    variant: "error",
  });
}
