import { useSystemEnvContext } from "../contexts/system/Provider";

const PreviewFeature = ({ children }) => {
  const {
    config: { enablePreviewFeatures },
  } = useSystemEnvContext();
  return enablePreviewFeatures ? children : null;
};

export default PreviewFeature;
