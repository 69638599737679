import { USER_MISMATCH } from "@dlx/state/constants";
import { logger } from "@dlx/utils";

const log = logger("auth");

export const channel = new BroadcastChannel("auth");

export const broadcastTokenRefreshCompleted = (token) => {
  log("broadcasting: TokenRefreshCompleted");
  channel.postMessage({
    type: "TokenRefreshCompleted",
    token,
    timestamp: Date.now(),
  });
};

export const broadcastTokenRefreshFailed = (error) => {
  log("broadcasting: TokenRefreshFailed");
  channel.postMessage({
    type: "TokenRefreshFailed",
    error,
    timestamp: Date.now(),
  });
};

export const broadcastTokenRefreshCanceled = (reason) => {
  log("broadcasting: TokenRefreshCanceled");
  channel.postMessage({
    type: "TokenRefreshCanceled",
    reason,
    timestamp: Date.now(),
  });
};
export const broadcastSessionAuthenticated = (token) => {
  log("broadcasting: SessionAuthenticated");
  channel.postMessage({
    type: "SessionAuthenticated",
    token,
    timestamp: Date.now(),
  });
};
export const broadcastSessionExpired = (error) => {
  log("broadcasting: SessionExpired");
  channel.postMessage({
    type: "SessionExpired",
    timestamp: Date.now(),

    error,
  });
};

export const broadcastTokenExpired = (token) => {
  log("broadcasting: TokenExpired");
  channel.postMessage({ type: "TokenExpired", timestamp: Date.now(), token });
};

export const broadcastLogout = (token) => {
  log("broadcasting: Logout");
  channel.postMessage({ type: "Logout", timestamp: Date.now(), token });
};

export const waitForSessionAuthenticated = () => {
  const chan = new BroadcastChannel("auth");
  return new Promise((resolve, reject) => {
    chan.onmessage = ({ data }) => {
      if (data.type === "SessionAuthenticated") {
        log("SessionAuthenticated");
        resolve(data);
        chan.close();
      }
      if (data.type === "SessionExpired") {
        log("SessionExpired");
        reject(data);
        chan.close();
      }
      if (
        data.type === "TokenRefreshFailed" &&
        data.error.message === USER_MISMATCH
      ) {
        window.location.reload(true);
      }
    };
  });
};

export const waitForTokenRefreshCompleted = () => {
  const chan = new BroadcastChannel("auth");
  return new Promise((resolve, reject) => {
    chan.onmessage = ({ data }) => {
      if (data.type === "TokenRefreshCompleted") {
        log("TokenRefreshCompleted");
        resolve(data);
        chan.close();
      }
      if (data.type === "TokenRefreshFailed") {
        log("TokenRefreshFailed");
        reject(data);
        chan.close();
      }
      if (data.type === "TokenRefreshCanceled") {
        log("TokenRefreshCanceled");
        reject(data);
        chan.close();
      }
    };
  });
};
