const DOMAIN = "scitara";
const APP = "dlx";
const DEBUG = "scitara:*";

class AppStorage {
  constructor(namespace) {
    this.namespace = namespace;
  }

  remove(key) {
    localStorage.removeItem(this.setKey(key));
  }

  set(key, val) {
    try {
      localStorage.setItem(this.setKey(key), JSON.stringify(val));
    } catch (error) {
      console.log(error.message);
      if (error.name === "QuotaExceededError") {
        this.clear();
        localStorage.setItem(this.setKey(key), JSON.stringify(val));
      }
    }
  }

  get(key) {
    try {
      const data = localStorage.getItem(this.setKey(key));
      if (data) {
        return JSON.parse(data);
      }
      return null;
    } catch (error) {
      console.log(error);
      return null;
    }
  }

  clear() {
    console.log("Purging keys for space");
    Object.keys(localStorage).forEach((k) => {
      if (k.includes(`${DOMAIN}:${APP}`)) {
        localStorage.removeItem(k);
      }
    });
  }

  setKey(key) {
    return `${DOMAIN}:${APP}:${this.namespace}:${key}`;
  }

  static setDebug(levels = "") {
    localStorage.setItem(
      "debug",
      levels
        .split(",")
        .map((l) => l.trim())
        .map((l) => `scitara:${l}`)
        .join(",")
    );
    if (!localStorage.debug) {
      console.log(
        `No debug level is set. Use 'localStorage.setItem("debug", "${DEBUG}") to view logs.`
      );
    }
  }
}

export default AppStorage;
