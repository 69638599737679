import { getConnectionSelector } from "../connections/selectors";
import { getConnectorSelector } from "../connectors/selectors";
import { getOrchestrationSelector } from "../orchestrations/selectors";
import { getUserSelector } from "../users/selectors";
import { createSelector } from "@reduxjs/toolkit";

export const getEventlogSelector = (state, id) => state.eventlogs.entities[id];

export const getEventlogList = createSelector(
  (state) => state.eventlogs.entities,
  (state) => state.eventlogs.loading,
  (eventlogs, loading) => {
    const events = Object.values(eventlogs).sort(
      (a, b) => new Date(a.timestamp) - new Date(b.timestamp)
    );
    return {
      events,
      loading,
    };
  }
);

export const getFullEventlog = (id) => (state) => {
  const eventLog = getEventlogSelector(state, id);
  const connection = getConnectionSelector(state, eventLog.connection);
  const connector = getConnectorSelector(state, eventLog.connector);
  const orchestration = getOrchestrationSelector(state, eventLog.orchestration);
  const user = getUserSelector(state, eventLog.user);

  return {
    ...eventLog,
    connection: connection?.name,
    connector: connector?.name,
    orchestration: orchestration?.name,
    user: user?.name,
  };
};

export const getEventLogsList = (state) => state.eventlogs.entities;

export const getEventLogsLoadingStatus = (state) => state.eventlogs.loading;
