import {
  REQUEST_LEASE,
  START_LEASE,
  ABANDON_LEASE,
  UPDATE_LEASES,
} from "./lease.constants";

export const initialState = {
  connectionId: null,
};

export default function leaseReducer(state = initialState, action) {
  switch (action.type) {
    case REQUEST_LEASE:
      return {
        ...state,
        connectionId: action.payload.connectionId,
        [action.payload.connectionId]: {
          status: "queued",
        },
      };
    case START_LEASE:
      return { ...state, [action.payload.connectionId]: { status: "leased" } };
    case ABANDON_LEASE:
      return initialState;
    case UPDATE_LEASES:
      return {
        ...state,
        [action.payload.id]: { ...action.payload },
      };
    default:
      return state;
  }
}
