import React, { useState } from "react";
import styles from "../OrchestrationFlow.module.scss";
import { ChevronDownIcon } from "../../../icons";

import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import { snakeCase } from "lodash";

const TriggerEventSelector = ({ triggerEvent, setTriggerEvent, triggerEvents }) => {
  const [open, setOpen] = useState(false);
  if (!triggerEvents) {
    return null;
  }

  const triggerKeys = Object.keys(triggerEvents || {});

  const handleOptionChange = (selection) => {
    if (selection !== triggerEvent) {
      setTriggerEvent({ event: selection });
      setOpen(false);
    }
  };

  let triggerStyle = "";
  if (open) {
    triggerStyle = styles.active;
  }
  if (triggerEvent) {
    triggerStyle = styles.selected;
  }
  const handleOnClick = () => {
    if (triggerKeys.length) {
      setOpen(!open);
    }
  };
  return (
    <ClickAwayListener onClickAway={() => setOpen(false)}>
      <div className={styles.connectionStep} style={{ marginTop: "20px" }}>
        <div
          className={`${styles.connectionInner} ${triggerStyle}`}
          onClick={handleOnClick}
        >
          <TriggerTitle
            trigger={triggerEvents[triggerEvent]}
            enabled={triggerEvent}
          />
          {!!triggerKeys.length && (
            <ChevronDownIcon
              style={{ transform: !open ? "none" : "rotate(180deg)" }}
              className={styles.dropdownIcon}
              data-testid="dtid_orchestration_flow_dropdown_event_icon"
            />
          )}
        </div>
        <ul className={open ? styles.activeDropdown : ""}>
          {Object.entries(triggerEvents || {}).map(([name, option]) => {
            return (
              <TriggerEvent
                key={name}
                active={name === triggerEvent}
                description={option.description}
                enabled={name}
                name={name}
                onClick={handleOptionChange}
                title={option.title}
              />
            );
          })}
        </ul>
      </div>
    </ClickAwayListener>
  );
};

function TriggerTitle({ trigger, enabled }) {
  if (!trigger) {
    return (
      <div
        className={`${styles.menuLabel} ${styles.boldText}`}
        data-testid="dtid_orchestration_flow_bold_event_text"
      >
        Select an event
      </div>
    );
  }
  return (
    <div className={`${styles.menuLabel}`}>
      <div className={styles.boldText}>
        {trigger.title} {!enabled && " (not enabled)"}
      </div>
      <div className={styles.subTitle} style={{ paddingTop: "5px" }}>
        {trigger.description}
      </div>
    </div>
  );
}
function TriggerEvent({ onClick, title, description, name, active, enabled }) {
  return (
    <li
      onClick={() => onClick(name)}
      className={active ? styles.activeItem : ""}
      data-testid={snakeCase(`dtid_${title}`)}
    >
      <h2>
        {title}
        {!enabled && " (not enabled)"}
      </h2>
      <p>{description}</p>
    </li>
  );
}
export default TriggerEventSelector;
