import React, { useState } from "react";
import Popover from "@dlx/atoms/Popover";
import Button, { BUTTON_VARIANTS } from "@dlx/atoms/Button";
import ConfirmInline from "@dlx/atoms/ConfirmInline";

import { OPERATORS } from "../constants";
import { snakeCase } from "lodash";
import clsx from "clsx";

function OperatorSelector({ onSelect, onRemove = null, onInit = null, children }) {
  const [confirm, setConfirm] = useState(false);
  let popoverHandler = null;

  const handleOnRemove = () => {
    onRemove();
    popoverHandler.close();
  };

  const handleOnSelect = (group, operator) => {
    onSelect(group, operator);
    popoverHandler.close();
  };

  return (
    <Popover
      onInit={(handler) => {
        popoverHandler = handler;
        onInit && onInit(popoverHandler);
      }}
      dataTestId="dtid_edit_operator"
    >
      {children}
      <div className="font-medium">
        <div className="flex justify-between items-center pa3 bb border-secondary">
          {!confirm && <span className="b">Select Operator</span>}
          {onRemove && !confirm && (
            <Button
              variant={BUTTON_VARIANTS.PRIMARY}
              className="pv2 ph2"
              onClick={() => setConfirm(true)}
            >
              Remove Operation
            </Button>
          )}
          {confirm && (
            <ConfirmInline
              onClose={() => setConfirm(false)}
              className={"flex items-center"}
            >
              <span>Are you sure? The right side value will also be removed.</span>
              <Button
                variant={BUTTON_VARIANTS.BORDER}
                className="pa2 ml4"
                onClick={handleOnRemove}
              >
                Yes
              </Button>
              <Button variant={BUTTON_VARIANTS.PRIMARY} className="pa2 ml2">
                No
              </Button>
            </ConfirmInline>
          )}
        </div>
        <section className="flex items-start">
          {Object.values(OPERATORS).map((group) => {
            return (
              <div
                key={group.type}
                className="grid"
                style={{ gridTemplateColumns: "auto auto" }}
              >
                {group.list.map((operator) => {
                  const { symbol, icon, label } = operator;
                  const OperatorIcon = icon;
                  return (
                    <div
                      key={symbol}
                      className={clsx(
                        "flex items-center pv1 ph3 mh2 pointer bb border-secondary-light bg-hover-secondary-light",
                        snakeCase(`dtid_operator_select_${label}`)
                      )}
                      onClick={() => handleOnSelect(group, operator)}
                    >
                      <span className="b tc w5 mr3 mt1 text-primary">
                        <OperatorIcon size={24} />
                      </span>

                      {label}
                    </div>
                  );
                })}
              </div>
            );
          })}
        </section>
      </div>
    </Popover>
  );
}

export default OperatorSelector;
