import isObject from "lodash/isObject";

/* recursively parse a JSON string */
export default function parseJson(input) {
  if (Array.isArray(input)) {
    return input.map((v) => parseJson(v));
  }
  if (isObject(input)) {
    const entries = Object.entries(input).map(([k, v]) => {
      return [k, parseJson(v)];
    });
    return Object.fromEntries(entries);
  }
  let value = null;
  try {
    value = JSON.parse(input);
    if (isObject(value)) {
      return parseJson(value);
    }
    if (typeof input === "string" && typeof value !== "string") {
      return input;
    }
    return value;
  } catch (err) {
    return input;
  }
}
