// @ts-nocheck
import * as oauth2 from "oauth4webapi";
import config from "config";
import { SIGN_ON } from "../views/routes";
import { v4 as uuid } from "uuid";
import { USER_MISMATCH } from "@dlx/state/constants";

export const client = {
  client_id: config.oidcClientId,
  token_endpoint_auth_method: "none",
};

let savedAuthConfig = null;
export const getAuthConfig = async () => {
  if (savedAuthConfig) {
    return savedAuthConfig;
  }
  const storedConfig = JSON.parse(localStorage.getItem("authConfig"));
  if (storedConfig) {
    savedAuthConfig = storedConfig;
    return savedAuthConfig;
  }
  const issuer = new URL("/oidc", config.iamUrl);
  savedAuthConfig = await oauth2
    .discoveryRequest(issuer, { algorithm: "oidc" })
    .then((response) => oauth2.processDiscoveryResponse(issuer, response))
    .then((result) => {
      localStorage.setItem("authConfig", JSON.stringify(result));
      return result;
    });
  return savedAuthConfig;
};

export async function discoverProvider(state = {}) {
  const authorizationUrl = await getAuthorizationUrl(JSON.stringify(state));
  authorizationUrl.searchParams.set("scope", "openid email profile dlx");
  if (state.action === "popup" || state.action === "esign") {
    window.open(authorizationUrl.href);
  } else {
    window.location.href = authorizationUrl.href;
  }
}

export const getIamToken = async (stateString) => {
  const authConfig = await getAuthConfig();
  const currentUrl = new URL(window.location.href);
  const params = oauth2.validateAuthResponse(
    authConfig,
    client,
    currentUrl,
    stateString
  );

  if (oauth2.isOAuth2Error(params)) {
    throw new Error(params.error_description);
  }

  const codeVerifier = localStorage.getItem("codeVerifier");

  const redirectUrl = new URL(SIGN_ON, window.location.href);

  const response = await oauth2.authorizationCodeGrantRequest(
    authConfig,
    client,
    params,
    redirectUrl.href,
    codeVerifier
  );

  const challenges = oauth2.parseWwwAuthenticateChallenges(response);
  if (challenges) {
    for (const challenge of challenges) {
      console.log("challenge", challenge);
    }
    throw new Error("Invalid challenges"); // Handle www-authenticate challenges as needed
  }

  let iamToken = null;

  if (stateString.includes("esign")) {
    iamToken = await oauth2.processAuthorizationCodeOpenIDResponse(
      authConfig,
      client,
      response,
      localStorage.getItem("nonce")
    );
  } else {
    iamToken = await oauth2.processAuthorizationCodeOpenIDResponse(
      authConfig,
      client,
      response
    );
  }

  if (oauth2.isOAuth2Error(iamToken)) {
    throw new Error(iamToken.error_description); // Handle OAuth 2.0 response body error
  }
  return iamToken;
};

export const refreshToken = async (token) => {
  const authConfig = await getAuthConfig();
  const apiAccessTokenResponse = await oauth2.refreshTokenGrantRequest(
    authConfig,
    client,
    token.refresh_token,
    {
      additionalParameters: {},
    }
  );

  const freshToken = await oauth2.processRefreshTokenResponse(
    authConfig,
    client,
    apiAccessTokenResponse
  );
  if (oauth2.isOAuth2Error(freshToken)) {
    throw new Error(freshToken.error_description); // Handle OAuth 2.0 response body error
  }
  return freshToken;
};

export const getElectronicSignature = async (email, nonce) => {
  let resolve = null;
  let reject = null;
  const promise = new Promise((res, rej) => {
    [resolve, reject] = [res, rej];
  });
  let requestId = uuid();
  const channel = new BroadcastChannel("dlx");
  channel.addEventListener("message", ({ data }) => {
    if (data.requestId) {
      if (data.requestId === requestId) {
        if (data.error) {
          if (data.error.message === USER_MISMATCH) {
            window.location.reload(true);
          }
          reject(data.error);
        } else {
          resolve(data.signature);
        }
      } else {
        reject("Sign not allowed");
        channel.postMessage({ action: "esign-cancelled" });
      }
      channel.close();
    }
    if (data.action === "cancel-esign") {
      requestId = "";
    }
  });
  const authorizationUrl = await getAuthorizationUrl(
    JSON.stringify({
      action: "esign",
      requestId,
    })
  );
  authorizationUrl.searchParams.set("login_hint", email);
  authorizationUrl.searchParams.set("prompt", "login");
  authorizationUrl.searchParams.set("scope", "openid dlx:signature");
  authorizationUrl.searchParams.set("nonce", nonce);
  localStorage.setItem("nonce", nonce);
  window.open(authorizationUrl);
  return promise;
};

const getAuthorizationUrl = async (stateString) => {
  const authConfig = await getAuthConfig();
  const codeVerifier = oauth2.generateRandomCodeVerifier();
  const codeChallenge = await oauth2.calculatePKCECodeChallenge(codeVerifier);
  const codeChallengeMethod = "S256";
  localStorage.setItem("codeVerifier", codeVerifier);

  const redirectUrl = new URL(SIGN_ON, window.location.href);

  const authorizationUrl = new URL(authConfig.authorization_endpoint);
  authorizationUrl.searchParams.set("client_id", client.client_id);
  authorizationUrl.searchParams.set("code_challenge_method", codeChallengeMethod);
  authorizationUrl.searchParams.set("code_challenge", codeChallenge);
  authorizationUrl.searchParams.set("redirect_uri", redirectUrl.href);
  authorizationUrl.searchParams.set("response_type", "code");
  authorizationUrl.searchParams.set("state", stateString);
  return authorizationUrl;
};
