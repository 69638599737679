import React, { useEffect, useState } from "react";
import clsx from "clsx";
import { TreeIcon } from "../../../icons";

import Popover, { POPOVER_POSITIONS } from "@dlx/atoms/Popover";
import JsonViewer from "@dlx/components/jsonviewer";

import { IconButton } from "../Wrappers";
import { TYPE_REFERENCE } from "../constants";
import { useEditorContext } from "../context";

function ReferenceEditor({ element, setElement }) {
  const [draft, setDraft] = useState(null);
  const [showInput, setShowInput] = useState(false);
  const { context } = useEditorContext();

  useEffect(() => {
    if (element.value !== draft) {
      setDraft(element.value);
    }
  }, [element.value]);

  const handleUpdate = (value) => {
    setElement({
      type: TYPE_REFERENCE.TYPE,
      value: value || draft,
    });
    setShowInput(false);
  };

  const isBlank = draft === TYPE_REFERENCE.DEFAULT_VALUE;
  let popoverPathSelector = null;

  return showInput ? (
    <div className="flex">
      <input
        type="text"
        className={clsx(
          "p0 bn bgPrimaryDark rounded-pill ph2 pv1 textContrast ml1",
          "dtid_reference_edit_input"
        )}
        autoFocus
        value={isBlank ? "" : draft}
        onChange={(e) => {
          setDraft(e.target.value);
        }}
        onBlur={() => {
          handleUpdate();
        }}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            handleUpdate();
          }
        }}
      />
    </div>
  ) : (
    <div
      className={clsx("flex items-center ml1", {
        "text-primary-light": isBlank,
      })}
    >
      <div onClick={() => setShowInput(true)} className="dtid_reference_edit">
        {draft === TYPE_REFERENCE.DEFAULT_VALUE ? "Click to edit reference" : draft}
      </div>
      <Popover
        position={POPOVER_POSITIONS.BOTTOM_CENTER}
        onInit={(handlers) => (popoverPathSelector = handlers)}
        dataTestId="dtid_reference_select"
      >
        <IconButton className="pointer ml2 " height={32} width="auto">
          <TreeIcon size={28} color="inherit" />
        </IconButton>

        <div className="font-medium">
          <JsonViewer
            json={context || {}}
            trail={true}
            allowSelect={true}
            onCancel={() => popoverPathSelector.close()}
            onSelect={(path) => {
              handleUpdate(path);
            }}
            title="Select a reference value"
            copyAll={true}
          />
        </div>
      </Popover>
    </div>
  );
}

export default ReferenceEditor;
