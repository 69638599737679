import { getCacheKey } from "./saga";
import { sortBy } from "../../../utils/commons";
import * as constants from "./constants";

export const closeEditor = (payload) => ({
  type: constants.CLOSE_EDITOR,
  payload,
});

export const isEditing = (payload) => ({
  type: constants.IS_EDITING,
  payload,
});

/**
 * @param {{
 *  executionId?: Execution["id"],
 *  mode: "edit"| "update"| "view"| "new"| "import"| "copy",
 *  orchestration?: Orchestration,
 *  orchestrationId?: Orchestration["id"],
 * }} props
 */
export const openEditor = ({
  executionId,
  mode,
  orchestration,
  orchestrationId,
}) => ({
  type: constants.OPEN_EDITOR,
  payload: { orchestrationId, executionId, mode, orchestration },
});

export const setFrameUrl = ({ frameUrl }) => ({
  type: constants.SET_FRAME_URL,
  payload: { frameUrl },
});

export const prepareOrch = (orchestration) => {
  return {
    ...orchestration,
    steps: orchestration.steps.map((step) => {
      if (step.kind === "user-input" && typeof step.inputsSchema === "string") {
        try {
          return { ...step, inputsSchema: JSON.parse(step.inputsSchema) };
        } catch (error) {
          return step;
        }
      }
      return step;
    }),
  };
};

export const saveOrchestration = ({ orchestration, mode }) => {
  orchestration = prepareOrch(orchestration);
  return {
    type: constants.SAVE_ORCHESTRATION,
    payload: {
      orchestration,
      mode,
    },
  };
};

export const setTriggerEvents = (triggerEvents) => ({
  type: constants.SET_TRIGGER_EVENTS,
  payload: { triggerEvents },
});

export const setCommandOptions = ({ commandOptions, key }) => ({
  type: constants.SET_COMMAND_OPTIONS,
  payload: { commandOptions: getSortedOptions(commandOptions), key },
});

export const setStepData = (stepData) => ({
  type: constants.SET_STEP_DATA,
  payload: { stepData },
});

export const editorError = ({ key, error }) => {
  const { message, stack } = error;
  return {
    type: constants.EDITOR_ERROR,
    payload: { error: { key, message, stack } },
  };
};

export const setOrchestration = (orchestration, executionId) => ({
  type: constants.SET_ORCHESTRATION,
  payload: { orchestration, executionId },
});

export const saveOrchestrationFailure = ({ mode, error }) => {
  return {
    type: constants.SAVE_ORCHESTRATION_FAILURE,
    payload: { mode, error },
  };
};

export const saveOrchestrationSuccess = (orchestration) => ({
  type: constants.SAVE_ORCHESTRATION_SUCCESS,
  payload: { orchestration },
});

/** @param {Option[]} items */
const getSortedOptions = (items = []) => {
  /** @type {Option[]} */
  const newOptions = [];
  try {
    items.forEach((item) => {
      if (item.options) {
        const newOptionsSort = sortBy(item.options, "label");
        newOptionsSort.forEach((groupItem) =>
          newOptions.push({ ...groupItem, group: item.label })
        );
      } else {
        newOptions.push(item);
      }
    });
  } catch (error) {
    console.log("error sorting options", error, items);
  }
  return newOptions;
};

/** @param {OptionSchema} option */
const parseApiLookup = (option) => option.lookup;

/**
 * @param {OptionSchema} option
 */
const apiCommand = (option) => {
  return { type: "api", command: parseApiLookup(option) };
};

/**
 * @param {{
 *   title: string,
 *   description: string,
 *   type: string,
 *   lookup: string,
 *  }} option
 */
const socketCommand = (option) => {
  return {
    type: "socket",
    command: parseSocketLookup(option),
  };
};

/**
 * @param {{
 *  title: string,
 *  description: string,
 *  type: string,
 *  lookup: string,
 * }} option
 */
const parseSocketLookup = (option) => {
  return option.lookup.split("/").reverse()[0];
};

const isApiLookup = (option) => {
  return (
    Array.isArray(option.lookup) &&
    option.lookup.every((o) => o.kind === "api-resource")
  );
};

const getLookupCommand = (option) => {
  return isApiLookup(option) ? apiCommand(option) : socketCommand(option);
};

/**
 * @param {{
 *  optionSchema: {
 *    title: string,
 *    description: string,
 *    type: string | string[],
 *    lookup: string | {kind: string, href: string, groupName: string}[],
 *  },
 *  connectionId: string
 * }} props
 */
export const getCommandOptions = ({ optionSchema, connectionId }) => {
  const payload = {
    ...getLookupCommand(optionSchema),
    connectionId,
  };
  return {
    type: constants.GET_COMMAND_OPTIONS,
    payload,
    key: getCacheKey(payload),
  };
};

export const loadOrchestrationsPage = () => ({
  type: constants.LOAD_ORCHESTRATIONS_PAGE,
});

export const getTriggerEvents = ({ connectionId }) => ({
  type: constants.GET_TRIGGER_EVENTS,
  payload: { connectionId },
});

export const toggleOrchestrationState = (orchestration) => ({
  type: constants.TOGGLE_ORCHESTRATION_STATE,
  payload: {
    orchestration,
  },
});
