import React, { useState } from "react";
import ConnectorSelector from "./ConnectorSelector";
import ConnectionReference from "./ConnectionReference";
import UserSelectionSelector from "./UserSelectionSelector";
import ConnectionSelector from "./ConnectionSelector";
import StepCommandSelector from "./StepCommandSelector";
import { expressionToAst } from "@dlx/components/ExpressionEditor";

/**
 * @param {{
 * step: Step,
 * userSelection: boolean,
 * setUserSelection: Function,
 * actions?: Object,
 * connection?: Connection,
 * connector?: Connector,
 * stepNum: number,
 * onUpdate: Function,
 * actor: Object,
 * }} props
 */
const ConnectionConfigurationSelector = ({
  step,
  userSelection,
  setUserSelection,
  actions,
  connector,
  stepNum,
  onUpdate,
  actor,
}) => {
  const isCommandSelector = actor?.inputs?.includes("command-selector");
  const isReadingSelector = actor?.inputs?.includes("reading-action-selector");

  return (
    <>
      <section className="pb5">
        <ConnectorSelector
          id={step.connector}
          key={step.connector}
          connector={connector}
          setConnector={onUpdate}
          uuid={step.uuid}
          version={step.version}
        />
      </section>

      {(connector || step.connection) && (
        <section className="pb5">
          <ConnectionDataSelector
            step={step}
            setUserSelection={setUserSelection}
            userSelection={userSelection}
            onUpdate={onUpdate}
            stepNum={stepNum}
          />
        </section>
      )}

      {(isCommandSelector || isReadingSelector) && (
        <StepCommandSelector
          command={step.command}
          commands={actions}
          setCommand={onUpdate}
        />
      )}
    </>
  );
};

export default ConnectionConfigurationSelector;

const connectionId = (step) => expressionToAst(step.connection)?.value;

/**
 * @param {{
 * step: Step,
 * userSelection: boolean,
 * setUserSelection: Function,
 * onUpdate: Function,
 * stepNum: number,
 * }} props
 */
const ConnectionDataSelector = ({
  step,
  userSelection,
  setUserSelection,
  onUpdate,
  stepNum,
}) => {
  const [open, setOpen] = useState(false);

  return (
    <div style={{ display: "flex", alignItems: "stretch" }}>
      {(step.connector || step.connection) && (
        <div style={{ flexGrow: 1 }}>
          {userSelection ? (
            <ConnectionReference step={step} onUpdate={onUpdate} stepNum={stepNum} />
          ) : (
            <ConnectionSelector
              id={connectionId(step)}
              key={connectionId(step)}
              setConnection={onUpdate}
              uuid={step.uuid}
              connector={step.connector}
              openList={open}
              setOpenList={setOpen}
            />
          )}
        </div>
      )}

      <UserSelectionSelector
        userSelection={userSelection}
        setUserSelection={setUserSelection}
        disabled={!step.connector}
        onUpdate={onUpdate}
      />
    </div>
  );
};
