import {
  CLOSE_EDITOR_CONFIRM,
  EDITOR_ERROR,
  GET_STEP_DATA,
  GET_TRIGGER_EVENTS,
  IS_EDITING,
  OPEN_EDITOR,
  SAVE_ORCHESTRATION_FAILURE,
  SAVE_ORCHESTRATION_SUCCESS,
  SAVE_ORCHESTRATION,
  SET_COMMAND_OPTIONS,
  SET_ORCHESTRATION,
  SET_STEP_DATA,
  SET_TRIGGER_EVENTS,
  SET_FRAME_URL,
  UPDATE_EDITED_EXECUTION,
} from "./constants";

const initialState = {
  commandOptions: {},
  draft: null,
  error: null,
  isEditing: false,
  loading: false,
  triggerEvents: null,
  stepData: null,
  frameUrl: null,
  orchestrationId: null,
  execution: null,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case OPEN_EDITOR: {
      return {
        ...state,
        orchestrationId: action.payload.orchestrationId,
        mode: action.payload.mode,
        loading: true,
      };
    }
    case SET_FRAME_URL: {
      return {
        ...state,
        frameUrl: action.payload.frameUrl,
      };
    }
    case SET_ORCHESTRATION: {
      return {
        ...state,
        error: null,
        draft: action.payload.orchestration,
        open: true,
        loading: false,
        executionId: action.payload.executionId,
      };
    }
    case CLOSE_EDITOR_CONFIRM: {
      return initialState;
    }
    case GET_STEP_DATA: {
      return { ...state, stepData: null };
    }
    case GET_TRIGGER_EVENTS: {
      return { ...state, triggerEvents: null };
    }
    case SET_STEP_DATA: {
      return { ...state, stepData: action.payload.stepData };
    }
    case SET_TRIGGER_EVENTS: {
      return { ...state, error: null, triggerEvents: action.payload.triggerEvents };
    }
    case SET_COMMAND_OPTIONS: {
      return {
        ...state,
        error: null,
        commandOptions: {
          ...state.commandOptions,
          [action.payload.key]: action.payload.commandOptions,
        },
      };
    }

    case SAVE_ORCHESTRATION: {
      return { ...state, draft: action.payload.orchestration, error: null };
    }
    case SAVE_ORCHESTRATION_FAILURE: {
      return {
        ...state,
        error: { ...state.error, save: action.payload },
      };
    }
    case SAVE_ORCHESTRATION_SUCCESS: {
      return {
        ...state,
        draft: action.payload.orchestration,
        error: null,
        isEditing: false,
        loading: false,
        open: state.open,
        orchestrationId: state.orchestrationId,
        prompt: false,
        saved: true,
      };
    }
    case EDITOR_ERROR: {
      return {
        ...state,
        error: { ...state.error, [action.payload.key]: action.payload },
      };
    }
    case IS_EDITING: {
      return { ...state, saved: false, isEditing: action.payload.dirty };
    }
    case UPDATE_EDITED_EXECUTION: {
      return { ...state, executionId: action.payload.executionId };
    }
    default: {
      return state;
    }
  }
}
