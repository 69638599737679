import { createSelector } from "@reduxjs/toolkit";

export const getUserSelector = (state, id) => state.users.entities[id];

export const getFullUser = (id) =>
  createSelector(
    (state) => state.users.entities,
    (users) => {
      const user = users[id];
      const roles = user.roles?.map((role) => role.id);
      const groups = user.groups?.map((group) => group.id);
      return {
        id: user.id,
        name: user.name,
        email: user.email,
        roles: roles,
        groups: groups,
        state: user.state,
      };
    }
  );

export const getUserPreferences = (state) => state.users.entities.undefined;

export const getUsers = createSelector(
  (state) => state.users.ids,
  (state) => state.users.entities,
  (state) => state.auth.user,
  (ids, entities, currentUser) =>
    ids.length ? ids.map((id) => entities[id]).filter(Boolean) : [currentUser]
);
