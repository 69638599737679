import { useCallback, useEffect } from "react";
import socket from "../../services/socket";

/**
 * @param {{event: string, onCompleted: function, variables?: any, skip?: function}} props
 */
export default function useSocketEvent({
  event,
  onCompleted,
  variables = {},
  skip = () => false,
}) {
  const vars = JSON.stringify(variables);
  const callback = useCallback(
    (message) => {
      if (!skip(message)) {
        if (onCompleted) {
          onCompleted(message);
        }
      }
    },
    [onCompleted, skip]
  );
  useEffect(() => {
    socket.on(event, callback);
    return () => {
      socket.off(event, callback);
    };
  }, [event, skip, vars, onCompleted, callback]);
}
