import React from "react";
import clsx from "clsx";
import styles from "./StepChip.module.scss";
import { TriggerIcon, ActorChipIcon } from "../../icons";

/**
 * @param {{
 * isTrigger: boolean,
 * actors: Array,
 * stepKind: string,
 * }} props
 */
const ChipIcon = ({ isTrigger, actors, stepKind }) => {
  return isTrigger ? (
    <TriggerIcon trigger={stepKind} className={clsx(styles.triggerChipIcon)} />
  ) : (
    <ActorChipIcon
      className={clsx(styles.chipIcon)}
      actors={actors}
      iconType={stepKind}
    />
  );
};

export default ChipIcon;
