import React, { useEffect, useState } from "react";
import clsx from "clsx";
import { cloneDeep } from "lodash";

import { EditIcon, ErrorIcon, TransformIcon } from "../../../icons";
import Popover from "@dlx/atoms/Popover";
import Button, { BUTTON_VARIANTS } from "@dlx/atoms/Button";

import { ElementActions, ExpressionNode } from "../Wrappers";
import { AstElement } from "../Helpers";
import { FunctionArgumentsEditor } from "../Editors";
import { AST_ELEMENT_TYPES, TYPE_ARRAY } from "../constants";
import { useEditorContext } from "../context";
import { astToExpression, filterTransforms } from "../util";

function FunctionCallElement({ schema, element, setElement, hasFunction }) {
  const [draft, setDraft] = useState();
  const [transform, setTransform] = useState(null);
  const [inputType, setInputType] = useState(null);
  const [outputType, setOutputType] = useState(null);
  const { transforms } = useEditorContext();

  const handleSetElement = (newElement, isDeleting = false) => {
    if (isDeleting) {
      setElement(newElement, true);
      return;
    }
    if (element.args) {
      element.args[0] = newElement;
      setElement(element);
    }
  };

  const handleSetFunctionArgs = (updates) => {
    // Convert any options named "expression" back into a Literal string ast
    const functionArguments = updates.args[1];
    const expression = functionArguments.value.expression;
    if (expression) {
      const value = astToExpression(expression);
      if (value) {
        functionArguments.value.expression = {
          type: AST_ELEMENT_TYPES.LITERAL,
          value,
        };
      }
    }

    setDraft({ ...updates });
  };

  const handleDeleteElement = () => {
    setElement(element.args[0], true);
  };

  useEffect(() => {
    setDraft(cloneDeep(element));
    const [item] = filterTransforms(transforms, { name: element.name });
    if (item) {
      setTransform(item);
      setInputType(item.inputSchema.type);
      setOutputType(item.outputSchema.type);
    }
  }, [element, element.error, transforms]);

  const optionsSchema = transform?.optionsSchema;
  const isArgumentsRequired = optionsSchema?.type;
  const isIteratorFunction =
    inputType === TYPE_ARRAY.VALUE_TYPE && optionsSchema?.properties?.expression;
  let popoverCloseHandler = null;
  return (
    <>
      {/* Assume args[0] as child element of FunctionCall in the ast tree*/}
      <AstElement
        schema={schema}
        element={element.args[0]}
        setElement={handleSetElement}
        hasFunction={true}
      />
      <ExpressionNode
        className={clsx(
          {
            "rounded-tl0 rounded-bl0": !hasFunction,
            "rounded-tl0 rounded-bl0 rounded-tr0 rounded-br0": hasFunction,
          },
          "dtid_function_call_expression_element"
        )}
      >
        <div className="ml2 mv1 grid items-center justify-center rounded-pill bg-primary-light text-primary">
          {element.error ? (
            <ErrorIcon size={24} color="red" />
          ) : (
            <TransformIcon size={24} color="inherit" />
          )}
        </div>
        {/*
          ElementActions provides:
          1. Clear and add transform menu and functions.
        */}
        <ElementActions
          element={element}
          setElement={setElement}
          deleteElement={handleDeleteElement}
          functionInputType={outputType}
          hasFunction={hasFunction}
        >
          {!isArgumentsRequired ? (
            <ElementLabel element={element}></ElementLabel>
          ) : (
            <Popover
              onInit={({ close }) => (popoverCloseHandler = close)}
              dataTestId="dtid_edit_function_args"
            >
              <ElementLabel element={element}>
                <EditIcon size={12} color="inherit" className="ml2" />
              </ElementLabel>
              <div className="font-medium" style={{ minWidth: "400px" }}>
                <div className="flex items-center bb border-secondary ph3 pv3 sticky top0 bg-default">
                  <div className="flex-auto b">
                    {optionsSchema?.title || "Array Editor"}
                  </div>
                  <Button
                    variant={BUTTON_VARIANTS.TEXT}
                    onClick={() => {
                      setDraft(element);
                      popoverCloseHandler();
                    }}
                    dataTestId="dtid_cancel_changes"
                  >
                    Cancel
                  </Button>
                  <Button
                    variant={BUTTON_VARIANTS.PRIMARY}
                    className="pv2 ph2 ml2"
                    onClick={() => {
                      setElement(draft);
                      popoverCloseHandler();
                    }}
                    dataTestId="dtid_apply_changes"
                  >
                    Apply
                  </Button>
                </div>
              </div>
              <FunctionArgumentsEditor
                transform={transform}
                isIterator={isIteratorFunction}
                schema={schema}
                element={draft}
                setElement={handleSetFunctionArgs}
              />
            </Popover>
          )}
        </ElementActions>
      </ExpressionNode>
    </>
  );
}

function ElementLabel({ element, children = null }) {
  return (
    <div className="pv2 ml2 flex items-center noselection pointer">
      <span>{element.name}</span>
      {children}
    </div>
  );
}

export default FunctionCallElement;
