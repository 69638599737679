import React, { createContext, useContext } from "react";
import { AuthHeaderInterceptor } from "./AuthHeaderInterceptor";
import { AuthRefreshInterceptor } from "./AuthRefreshInterceptor";
import { ESignRequestInterceptor } from "./ESignRequestInterceptor";

const ApiContext = createContext(null);

export const useApiClient = () => useContext(ApiContext);

/**
 * @param {{
 *   client: import("axios").AxiosInstance,
 *   children: import("react").ReactNode
 * }} props
 */
export const ApiProvider = ({ client, children }) => {
  return (
    <ApiContext.Provider value={{ client }}>
      <AuthHeaderInterceptor client={client} />
      <AuthRefreshInterceptor client={client} />
      <ESignRequestInterceptor client={client} />
      {children}
    </ApiContext.Provider>
  );
};
