import React from "react";
import styles from "./OrchestrationFlow.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowAltCircleRight,
  faStopCircle,
  faCircle,
  faCheckCircle,
} from "@fortawesome/free-regular-svg-icons";
import { useCurrentUserContext } from "../../contexts/current-user/Provider";
import { STEP_STATUS } from "./constants";

export default function StatusIcon({ statusOfStep, ...props }) {
  const { currentUser } = useCurrentUserContext();

  const requiresUserInput =
    statusOfStep?.waitingFor === "input" &&
    statusOfStep?.targetUsers &&
    statusOfStep.targetUsers.some((u) => u.id === currentUser.id);

  switch (statusOfStep.status) {
    case STEP_STATUS.WAITING:
    case STEP_STATUS.EXECUTING:
      return requiresUserInput ? (
        <FontAwesomeIcon
          icon={faArrowAltCircleRight}
          className={styles.active}
          {...props}
        />
      ) : (
        <FontAwesomeIcon icon="spinner" className={styles.active} spin {...props} />
      );
    case STEP_STATUS.FUTURE:
      return (
        <FontAwesomeIcon className={styles.future} icon={faCircle} {...props} />
      );
    case STEP_STATUS.COMPLETED:
      return (
        <FontAwesomeIcon
          className={styles.complete}
          icon={faCheckCircle}
          {...props}
        />
      );
    case STEP_STATUS.SKIPPED:
      return (
        <div className={styles.skipped}>
          <div className={styles.halfCircle} />
          <div className={styles.dotCircle} />
        </div>
      );
    case STEP_STATUS.ABORTED:
      return (
        <FontAwesomeIcon className={styles.error} icon={faStopCircle} {...props} />
      );
    case STEP_STATUS.FAILED:
    case STEP_STATUS.CONTINUED:
      return (
        <FontAwesomeIcon
          className={styles.error}
          icon="exclamation-circle"
          {...props}
        />
      );
    default: {
      console.log(`unknown step status`, statusOfStep);
      return (
        <FontAwesomeIcon
          icon="question-circle"
          className={styles.disabled}
          {...props}
        />
      );
    }
  }
}
