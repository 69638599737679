import React from "react";
import styles from "./AuditTrailView.module.scss";
import { formatDate } from "../../utils";
import ReactDiffViewer, { DiffMethod } from "react-diff-viewer";
import clsx from "clsx";
import colors from "../../assets/styles/variables.scss";

const overrideStyles = {
  variables: {
    light: {
      addedBackground: colors.guttergreenbackground,
      wordAddedBackground: colors.addgreenbackground,
      removedBackground: colors.gutterredbackground,
      wordRemovedBackground: colors.addredbackground,
      addedGutterBackground: colors.lightgreenbackground,
      removedGutterBackground: colors.lightredbackground,
    },
  },
};

const DetailedView = ({ details, capitalize, replaceDashes, print = false }) => {
  const otherOptions = ["session", "clientIp", "userAgent"];

  return (
    <div className={styles.detailView}>
      {!print && (
        <div className={styles.detailViewHolder}>
          <div className={styles.title}>Timestamp</div>
          <div className={styles.detail}>{formatDate(details.timestamp)}</div>
          <div className={styles.title}>Operation</div>
          <div className={styles.detail}>
            {capitalize(replaceDashes(details.action))}
            <div className={styles.subDetail}>
              {["kind", "documentType", "action"].map((i) => {
                return (
                  <span key={i}>
                    {i}: {details[i]}
                  </span>
                );
              })}
            </div>
          </div>
          {details.kind === "connection" && (
            <>
              <div className={styles.title}>Connection name</div>
              <div className={styles.detail}>
                {details.connectionName}
                <div className={styles.subDetail}>Id: {details.connectionId}</div>
              </div>
            </>
          )}
          {details.documentName && (
            <>
              <div className={styles.title}>Entity</div>
              <div className={styles.detail}>
                {details.documentName}
                <div className={styles.subDetail}>
                  entitiy id: {details.documentId}
                </div>
              </div>
            </>
          )}
          {details.identity && (
            <>
              <div className={styles.title}>Operator</div>
              <div className={styles.detail}>
                {details.identity.name} | {details.identity.email}
                <div className={styles.subDetail}>
                  {["kind", "scope", "authenticated"].map((i) => {
                    return (
                      <span key={i}>
                        {i}: {details.identity[i]}
                      </span>
                    );
                  })}
                </div>
              </div>
            </>
          )}
          {details.controls?.signature && (
            <>
              <div className={styles.title}>Esign</div>
              <div className={styles.detail}>
                {details.controls.signature ? "Yes" : "No"}
              </div>
            </>
          )}
          {details.controls?.reasonForChange && (
            <>
              <div className={styles.title}>Reason</div>
              <div className={styles.detail}>{details.controls.reasonForChange}</div>
            </>
          )}
        </div>
      )}
      {details.changes?.length > 0 && (
        <div className={clsx(styles.detailViewHolder, styles.changes)}>
          <div className={styles.detail}>
            <table className={styles.tableData}>
              <thead>
                <tr>
                  <th>Path</th>
                  <th>Changes</th>
                </tr>
              </thead>
              <tbody>
                {details.changes.map((i, key) => {
                  return (
                    <tr key={key}>
                      <td>
                        <div className={styles.pathData}>{i.path}</div>
                        <div className={styles.kind}>({i.kind})</div>
                      </td>
                      <td>
                        <RenderChanges detail={i} />
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      )}
      {Object.keys(details).some((r) => otherOptions.includes(r)) && (
        <div className={clsx(styles.detailViewHolder, styles.changes)}>
          <div className={styles.detail}>
            <table className={styles.tableData}>
              <thead>
                <tr>
                  <th>Key</th>
                  <th>Value</th>
                </tr>
              </thead>
              <tbody>
                {otherOptions.map((i) => {
                  return (
                    details[i] && (
                      <tr key={i}>
                        <td>{i}</td>
                        <td>{details[i]}</td>
                      </tr>
                    )
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </div>
  );
};

const RenderChanges = ({ detail }) => {
  const oldText = getText(detail.oldValue);
  const newText = getText(detail.newValue);
  let renderData = null;

  renderData = (
    <div className={styles.diffViewer}>
      <ReactDiffViewer
        styles={overrideStyles}
        oldValue={oldText}
        newValue={newText}
        splitView={false}
        compareMethod={DiffMethod.WORDS}
      />
    </div>
  );

  return renderData;
};

const getText = (data) => {
  let displayContent = data;
  // If data is type: object and value is function transform content
  if (data && typeof data === "object" && data.kind === "function-transform") {
    displayContent = data.content;
  }

  if (typeof displayContent !== "string") {
    displayContent = JSON.stringify(displayContent, undefined, 4);
  }

  return displayContent;
};

export default DetailedView;
