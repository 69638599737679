import React, { useEffect, useState } from "react";
import clsx from "clsx";
import { OperatorIcon } from "../operators";

import { OperatorSelector } from ".";
import { IconButton } from "../Wrappers";
import { AST_ELEMENT_TYPES, VALUE_TYPES } from "../constants";
import { useEditorContext } from "../context";
import {
  comparePrecedence,
  filterTransforms,
  getAstElementValueType,
  getOperatorExpressionElement,
} from "../util";

function AddElementOptions({ element, setElement, className = "" }) {
  const [outputType, setOutputType] = useState(null);
  const { transforms } = useEditorContext();
  const isBinaryELement = element.type === AST_ELEMENT_TYPES.BINARY_EXPRESSION;
  useEffect(() => {
    if (element.type === AST_ELEMENT_TYPES.FUNCTION_CALL) {
      const [item] = filterTransforms(transforms, { name: element.name });
      if (item) {
        setOutputType(item.outputSchema.type);
      }
    } else if (isBinaryELement) {
      setOutputType(null);
    } else {
      setOutputType(getAstElementValueType(element));
    }
  }, [element.name, element.value, transforms]);

  const isBinaryApplicable =
    Array.isArray(outputType) ||
    [VALUE_TYPES.STRING, VALUE_TYPES.NUMBER, VALUE_TYPES.BOOLEAN].includes(
      outputType
    ) ||
    [
      AST_ELEMENT_TYPES.BINARY_EXPRESSION,
      AST_ELEMENT_TYPES.IDENTIFIER,
      AST_ELEMENT_TYPES.FILTER_EXPRESSION,
    ].includes(element.type);

  const onSelectOperator = (group, operator) => {
    if (isBinaryELement && comparePrecedence(operator.symbol, element.operator)) {
      element.right = getOperatorExpressionElement(
        element.right,
        group,
        operator,
        outputType
      );
      setElement({ ...element });
    } else {
      const newElement = getOperatorExpressionElement(
        element,
        group,
        operator,
        outputType
      );
      setElement(newElement);
    }
  };

  let popoverOperatorSelector = null;
  return (
    <>
      {isBinaryApplicable && (
        <OperatorSelector
          onSelect={onSelectOperator}
          onInit={(args) => (popoverOperatorSelector = args)}
        >
          <IconButton
            onClick={() => popoverOperatorSelector.open()}
            className={clsx(
              "ml2 bg-primary-light bg-hover-primary text-primary text-hover-contrast",
              "dtid_operator_select",
              className
            )}
            size={32}
            tooltip="Add operator"
          >
            <OperatorIcon size={25} />
          </IconButton>
        </OperatorSelector>
      )}
    </>
  );
}

export default AddElementOptions;
