import React, { useCallback } from "react";

import {
  Divider,
  IconButton,
  ListItemText,
  Menu,
  MenuItem,
} from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useApiCollection } from "@dlx/state/hooks/useApiCollection";
import LoadingButton from "@dlx/components/DLXButton/LoadingButton";

import DLXTooltip from "../../../DLXTooltip/DLXTooltip";
import styles from "./SchemaInput.module.scss";
import { useDispatch } from "react-redux";
import { alertSuccess } from "../../../../state/alerts/actions";

const dlxResourceLookups = [
  {
    type: "DlxUserSelector",
    label: "Select a user",
    scope: "#/properties/userId",
    options: {
      showGroups: true,
      multi: true,
      nameContains:
        "*** This will filter list based on a partial user name - remove if not needed ***",
    },
  },
  {
    type: "DlxConnectionSelector",
    label: "Select a connection",
    scope: "#/properties/connectionId",
    options: {
      connector:
        "*** Edit this to specify a connector id to limit selections to ***",
      showOffline: false,
      nameContains:
        "*** This will filter list based on a partial connection name - remove if not needed ***",
    },
  },
  {
    type: "DlxOrchestrationSelector",
    label: "Select an orchestration",
    scope: "#/properties/orchestrationId",
    options: {
      nameContains:
        "*** This will filter list based on a partial orchestration name - remove if not needed ***",
    },
  },
  {
    type: "DlxFileSelector",
    label: "Select a local file",
    scope: "#/properties/fileIds",
    options: {
      multi: true,
      accept: ["application/pdf"],
    },
  },
  {
    type: "DlxConnectionAction",
    label: "Execute a connection action",
    scope: "#/properties/actionResponse",
    options: {
      connection: "*** (REQUIRED) Edit this to specify a connection id here ***",
      action: "*** (REQUIRED) Edit this to specify a connection action to execute",
      actionOptions:
        "*** Edit this to provide all options needed to execute the action ***",
      actionCredentials:
        "*** Edit this to provide any credentials needed to execute the action ***",
      showOptionsDialog:
        "*** set to true if you want a dialog box for the user to enter action options ***",
      path: "*** (OPTIONAL) enter the data path you want to use (i.e. trigger.sampleId) ***",
    },
  },
  {
    type: "DlxTriggerOrchestration",
    label: "Trigger an orchestration",
    scope: "#/properties/orchestrationData",
    options: {
      orchestration: "*** (REQUIRED) Edit this to specify the orchestrtion id ***",
      triggerData:
        '*** Edit this to provide a trigger data object, if used in an array, a "row" field (i.e. an object containing the specific row data) will be added to the triggerData ***',
      showExecution: false,
      path: "*** (OPTIONAL) enter the data path you want to use (i.e. trigger.sampleId) ***",
    },
  },
  {
    type: "DlxCustomSelector",
    label: "Custom lookup selector",
    scope: "#/properties/itemId",
    options: {
      items: [
        {
          label: "One",
          value: 1,
        },
        {
          label: "Two",
          value: 3,
        },
        {
          label: "Three",
          value: 3,
        },
      ],
    },
  },
];

const LookupSelectorsMenu = () => {
  const {
    entities: connectors,
    loading,
    loadMore,
    lastPageRetrieved,
  } = useApiCollection({
    collectionName: "connectors",
    queryString: "state=current",
  });

  const connectorsWithLookup = connectors?.filter(
    (c) =>
      c.actions &&
      c.state === "current" &&
      Object.values(c.actions).some((a) => a.usage.includes("lookup"))
  );
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selected, setSelected] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <DLXTooltip placement="top-start" text="Browse lookup selectors">
        <IconButton className={styles.iconButton} onClick={handleClick}>
          <FontAwesomeIcon icon="list" />
        </IconButton>
      </DLXTooltip>
      <Lookups selected={selected} onClose={setSelected} />
      <Menu
        transformOrigin={{
          vertical: "center",
          horizontal: "left",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <MenuItem
          onClick={(e) =>
            setSelected({
              anchor: e.currentTarget,
              dlxLookups: true,
              onClose: handleClose,
            })
          }
          selected={selected?.dlxLookups}
        >
          <ListItemText>DLX resource selectors</ListItemText>
          <FontAwesomeIcon className={styles.selectorIcon} icon="caret-right" />
        </MenuItem>
        <Divider />
        {connectorsWithLookup?.map((con) => (
          <MenuItem
            key={con.id}
            onClick={(e) =>
              setSelected({
                anchor: e.currentTarget,
                connector: con,
                onClose: handleClose,
              })
            }
            selected={selected?.connector?.id === con.id}
          >
            <ListItemText>{con.name}</ListItemText>
            <FontAwesomeIcon className={styles.selectorIcon} icon="caret-right" />
          </MenuItem>
        ))}

        <div
          className="tc bg-secondary-light"
          data-testid="dtid_browse_connectors_load_more_holder"
        >
          <LoadingButton
            onClick={loadMore}
            loading={loading}
            totalRecords={connectors.length}
            hasMoreRecords={!lastPageRetrieved && connectors.length > 0}
            dataTestIdSuffix="lookupSelectorsMenu"
            style={{ fontSize: "14px" }}
          >
            Load more
          </LoadingButton>
        </div>
      </Menu>
    </>
  );
};

const Lookups = ({ selected, onClose }) => {
  const dispatch = useDispatch();
  const open = Boolean(selected);
  let lookups = [];

  if (selected?.connector?.actions) {
    lookups = Object.entries(selected.connector.actions)
      .filter(([_k, v]) => v.usage.includes("lookup"))
      .map(([k, v]) => ({
        ...v,
        label: v.title,
        name: k,
        type: "DlxActionLookupSelector",
      }));
  }
  if (selected?.dlxLookups) {
    lookups = dlxResourceLookups;
  }

  const handleClose = () => {
    onClose(null);
  };

  const handleCopy = useCallback(
    (lookup) => {
      if (lookup.type === "DlxActionLookupSelector") {
        navigator.clipboard.writeText(createActionLookupSelector(lookup));
      } else {
        navigator.clipboard.writeText(createDlxResourceSelector(lookup));
      }
      dispatch(alertSuccess(`${lookup.label} lookup copied to clipboard`));
      onClose(null);
      if (selected.onClose) {
        selected.onClose();
      }
    },
    [dispatch, onClose, selected]
  );

  return (
    <Menu
      getContentAnchorEl={null}
      anchorOrigin={{
        vertical: "center",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "center",
        horizontal: "left",
      }}
      anchorEl={selected?.anchor}
      open={open}
      onClose={handleClose}
    >
      {lookups.map((lookup, i) => (
        <MenuItem key={i} onClick={() => handleCopy(lookup)}>
          <ListItemText>{lookup.label}</ListItemText>
          <FontAwesomeIcon className={styles.selectorIcon} icon="copy" />
        </MenuItem>
      ))}
    </Menu>
  );
};

const createDlxResourceSelector = (lookup) => {
  return JSON.stringify(lookup, null, 2);
};

const createActionLookupSelector = (action) => {
  const options = {};
  const credentials = action?.credentialsSchema?.properties && {};
  const requiredCredentials = action?.credentialsSchema?.required || [];
  const requiredOptions = action?.optionsSchema?.required || [];
  if (action?.optionsSchema?.properties) {
    Object.keys(action?.optionsSchema?.properties).forEach((k) => {
      const req = requiredOptions.includes(k) ? "(REQUIRED) " : "";
      options[k] = `${req}*** Edit this to specify a ${k} ***`;
    });
  }

  if (action?.credentialsSchema?.properties) {
    Object.keys(action?.credentialsSchema?.properties).forEach((k) => {
      const req = requiredCredentials.includes(k) ? "(REQUIRED) " : "";
      credentials[k] = `${req} *** Edit this to specify a ${k} ***`;
    });
  }

  const element = {
    type: "DlxActionLookupSelector",
    label: action.title,
    scope: "#/properties/*** Edit this to specify schema property here ***",
    options: {
      connection: "*** (REQUIRED) Edit this to specify a connection id here ***",
      action: action.name,
      actionOptions: options,
      actionCredentials: credentials,
      multi: false,
    },
  };
  return JSON.stringify(element, null, 2);
};

export default LookupSelectorsMenu;
