import React from "react";

const PRIMARY = "bg-primary bg-hover-primary-dark text-contrast";
const SECONDARY = "bg-secondary bg-hover-secondary-dark text-hover-contrast";
const BORDER = "ba border-primary bg-hover-primary text-hover-contrast";
const ERROR = "bg-error bg-hover-primary-dark text-contrast";
const TEXT = "text-hover-primary";

const BUTTON_VARIANTS = Object.freeze({
  PRIMARY,
  SECONDARY,
  BORDER,
  ERROR,
  TEXT,
});

function Button({
  children,
  onClick = () => {},
  variant = "",
  className = "",
  dataTestId = "",
  disabled = false,
}) {
  let css = `rounded2 bn bg-none ${className} ${dataTestId}`;
  if (disabled) {
    css = `${css} bg-secondary bg-hover-secondary text-secondary`;
  } else {
    css = `${css} ${variant} pointer`;
  }

  return (
    <button
      onClick={onClick}
      className={css}
      style={{ font: "inherit" }}
      data-testid={dataTestId}
    >
      {children}
    </button>
  );
}

export default Button;
export { BUTTON_VARIANTS };
