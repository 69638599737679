import React from "react";
import StepChip from "../StepChip/StepChip";
import AddCircleTwoToneIcon from "@material-ui/icons/AddCircleTwoTone";
import IconButton from "@material-ui/core/IconButton";
import styles from "./OrchestrationFlow.module.scss";
import StepMenu from "./StepMenu/StepMenu";

export const AddStep = ({ onSelect, index, end = false }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div
      className={styles.addStepContainer}
      data-testid="dtid_orchestration_flow_add_step_container"
    >
      <div className={`${end && styles.lastStep} ${styles.addStepBtn}`}>
        <div className={styles.line} />
        <IconButton onClick={handleClick} className={styles.addIcon}>
          <AddCircleTwoToneIcon fontSize="large" />
        </IconButton>
        {!end && <div className={styles.line} />}
      </div>
      <StepMenu
        onClose={handleClose}
        onSelect={onSelect}
        anchorEl={anchorEl}
        setAnchorEl={setAnchorEl}
        index={index}
      />
    </div>
  );
};

const validateString = (str) => typeof str === "string" && str.trim() !== "";

const validateEntity = (entity) => {
  const value = typeof entity === "object" ? entity?.value : entity;
  return validateString(value);
};

const fullyConfigured = "fullyConfigured";
const noConfiguration = "noConfiguration";
const unconfigured = "unconfigured";
const validationError = "validationError";

export const getStepState = (step) => {
  const state = {
    timer: step.interval && step.units ? fullyConfigured : unconfigured,
    manual: noConfiguration,
    event: validateEntity(step.event) ? fullyConfigured : unconfigured,
    reading: validateEntity(step.connection) ? fullyConfigured : unconfigured,
    "connection-command":
      validateEntity(step.connection) && validateString(step.command)
        ? fullyConfigured
        : unconfigured,
    notification: noConfiguration,
    "function-transform": noConfiguration,
    "library-transform": validateString(step.libraryTransformId)
      ? fullyConfigured
      : unconfigured,
    "user-input": step.inputsSchema ? fullyConfigured : unconfigured,
    "user-reading": validateEntity(step.connection) ? fullyConfigured : unconfigured,
    "trigger-orchestration": validateEntity(step.orchestration)
      ? fullyConfigured
      : unconfigured,
    "event-query": validateEntity(step.connection) ? fullyConfigured : unconfigured,
    interactive: noConfiguration,
  };
  return step.validationError ? validationError : state[step.kind];
};

/**
 * @param {{
 * activeLink: number,
 * addStep: Function,
 * deleteStep: Function,
 * onStepClick: Function,
 * setActiveStep: Function,
 * activeStep: number,
 * steps: Step[],
 * trigger: Trigger,
 * updateStep: Function,
 * updateTrigger: Function,
 * }} props
 */
function OrchestrationEditorStepSelector({
  activeLink,
  addStep,
  deleteStep,
  onStepClick,
  setActiveStep,
  activeStep,
  steps,
  trigger,
  updateStep,
  updateTrigger,
}) {
  return [
    {
      kind: trigger?.kind,
      name: "Select Trigger",
      type: "trigger",
      id: "trigger",
    },
    ...steps,
  ].map((step, index) => (
    <React.Fragment key={step.id}>
      <StepChip
        stepState={getStepState(index ? step : trigger)}
        active={index === activeLink}
        step={index ? step : trigger}
        stepNum={index - 1}
        stepId={step.id}
        deleteStep={deleteStep}
        setActiveStep={setActiveStep}
        activeStep={activeStep}
        onClick={onStepClick}
        index={index}
        updateStep={updateStep}
        updateTrigger={updateTrigger}
      />
      <AddStep index={index} onSelect={addStep} end={index === steps.length} />
    </React.Fragment>
  ));
}

export default OrchestrationEditorStepSelector;
