import React, { useEffect, useState } from "react";
import styles from "./StepContent.module.scss";
import JsonViewer from "../jsonviewer/JsonViewer";
import UserInputInterface from "./InputInterface";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Button, { BUTTON_VARIANTS } from "@dlx/atoms/Button";
import { SyncIcon, RunIcon, ClearIcon } from "../../icons";
import { useCurrentUserContext } from "../../contexts/current-user/Provider";
import { useDispatch } from "react-redux";
import { errorActionCompleted } from "@dlx/state/orchestrations/orchestration.reducer";
import { STEP_STATUS } from "./constants";

const ERROR_RESPONSES = {
  FAIL: "fail",
  CONTINUE: "continue",
  RETRY: "retry",
};

/**
 * @param {{
 * stepOutput: StepOutput,
 * stepStatus: Object,
 * step: Object,
 * stepNum: Number,
 * submitUserInput: Function,
 * getStepInputs: Function,
 * active: Boolean,
 * requiresUserInput: boolean,
 * stepRetryRequired: boolean,
 * execution: Execution,
 * }} props
 */
const StepContent = ({
  stepOutput,
  stepStatus,
  step,
  stepNum,
  submitUserInput,
  getStepInputs,
  active,
  requiresUserInput,
  stepRetryRequired,
  execution,
}) => {
  const { currentUser } = useCurrentUserContext();
  const dispatch = useDispatch();
  const [actionClicked, setActionClicked] = useState(false);

  useEffect(() => {
    setActionClicked(false);
  }, [execution]);

  if (!active) {
    return null;
  }

  function completeCurrentStep(action) {
    setActionClicked(true);
    dispatch(errorActionCompleted(currentUser, execution, action));
  }

  if (
    stepStatus.status === STEP_STATUS.EXECUTING ||
    stepStatus.status === STEP_STATUS.WAITING
  ) {
    if (requiresUserInput) {
      return (
        <div className={styles.stepContentContainer}>
          <UserInputInterface
            step={step}
            stepNum={stepNum}
            submitUserInput={submitUserInput}
            getStepInputs={getStepInputs}
            execution={execution}
          />
        </div>
      );
    }
    if (stepRetryRequired) {
      return (
        <div>
          <div className={styles.stepContentContainer}>
            <StepOutput stepOutput={stepOutput} stepStatus={stepStatus} />
          </div>
          <div className="flex items-center mt3">
            <Button
              className="flex items-center ph3"
              dataTestId="dtid_error_handling_retry_button"
              variant={BUTTON_VARIANTS.PRIMARY}
              disabled={actionClicked}
              onClick={() => completeCurrentStep(ERROR_RESPONSES.RETRY)}
            >
              <SyncIcon spin={false} size={12} />
              <span className="pv2 ml3">Retry</span>
            </Button>
            <Button
              className="flex items-center ml3 pr3"
              dataTestId="dtid_error_handling_continue_button"
              variant={BUTTON_VARIANTS.PRIMARY}
              disabled={actionClicked}
              onClick={() => completeCurrentStep(ERROR_RESPONSES.CONTINUE)}
            >
              <RunIcon size={24} />
              <span className="pv2 ml1">Continue</span>
            </Button>
            <Button
              className="flex items-center ml3 pr3"
              dataTestId="dtid_error_handling_fail_button"
              variant={BUTTON_VARIANTS.ERROR}
              disabled={actionClicked}
              onClick={() => completeCurrentStep(ERROR_RESPONSES.FAIL)}
            >
              <ClearIcon size={24} />
              <span className="pv2 ml1">Fail</span>
            </Button>
          </div>
        </div>
      );
    }
  }

  if (
    [STEP_STATUS.COMPLETED, STEP_STATUS.FAILED, STEP_STATUS.CONTINUED].includes(
      stepStatus.status
    )
  ) {
    return (
      <div className={styles.stepContentContainer}>
        <StepOutput stepOutput={stepOutput} stepStatus={stepStatus} />
      </div>
    );
  }

  return null;
};

/**
 * @param {{stepStatus: stepStatus, stepOutput: StepOutput}} props
 */
const StepOutput = ({ stepStatus, stepOutput }) => {
  if (!stepOutput) {
    return (
      <div className={styles.loader}>
        <FontAwesomeIcon icon="spinner" spin />
      </div>
    );
  }
  return (
    <div>
      <div className={styles.stepOutput}>
        <JsonViewer
          json={stepOutput}
          allowCopy={false}
          allowSelect={false}
          onSelect={console.log}
          replaceRoot={`steps[${stepStatus.stepNumber}]`}
          trail={true}
          title="Step output"
          copyAll={true}
        />
      </div>
    </div>
  );
};

export default StepContent;
