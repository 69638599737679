import React from "react";
import "./DLXButton.scss";

import { colors } from "@dlx/assets/styles";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DLXTooltip from "../DLXTooltip/DLXTooltip";

function getIconColor(color, variant) {
  let iconColor = null;
  if (["primary", "secondary"].includes(color) && variant === "contained") {
    iconColor = "white";
  } else if (color === "primary") {
    iconColor = colors.textPrimary;
  } else if (color === "") {
    iconColor = colors.textDefault;
  } else {
    iconColor = colors.textSecondary;
  }
  return iconColor;
}

function getIconSize(size) {
  const sizes = {
    "small-xs": 14,
    small: 16,
    medium: 18,
    large: 20,
  };
  return sizes[size] || size;
}

const DLXButton = ({
  color = "",
  disabled,
  handleClick,
  icon,
  iconOnRight,
  label,
  size = 16,
  spin,
  variant = "default",
  dataTestId,
  tooltip,
  ...rest
}) => {
  const variantColor = variant && color ? `${variant}${color}` : "";

  const disabledVariant = disabled && variant ? `disabled${variant}` : "";

  const isDisabled = disabled === true ? "disabled" : "";
  const buttonSize = size;

  const iconColor = getIconColor(color, variant);
  const iconSize = getIconSize(size);

  const inlineFlex = "inline-flex";

  return (
    <button
      className={`button ${color} ${variant} ${variantColor} ${disabledVariant} ${buttonSize} ${isDisabled}`}
      onClick={handleClick}
      type="submit"
      style={{ fontSize: `${buttonSize}px`, display: inlineFlex }}
      data-testid={dataTestId}
      disabled={disabled}
      {...rest}
    >
      <DLXTooltip text={tooltip}>
        <div style={{ display: inlineFlex, color: iconColor }}>
          {!iconOnRight && !icon !== true && variant !== "textButton" && (
            <div
              style={{
                display: inlineFlex,
                alignItems: "center",
              }}
            >
              <FontAwesomeIcon
                color={iconColor}
                style={{
                  marginLeft: "5px",
                  marginRight: "5px",
                  fontSize: iconSize,
                }}
                icon={icon}
                spin={spin}
              />
            </div>
          )}
          {label && <div className="labelContainer">{label}</div>}
          {iconOnRight && !icon !== true && variant !== "textButton" && (
            <div
              style={{
                display: inlineFlex,
                alignItems: "center",
              }}
            >
              <FontAwesomeIcon
                color={iconColor}
                style={{
                  marginLeft: "5px",
                  marginRight: "5px",
                  fontSize: iconSize,
                }}
                icon={icon}
                spin={spin}
              />
            </div>
          )}
        </div>
      </DLXTooltip>
    </button>
  );
};

export default DLXButton;
