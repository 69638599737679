import React from "react";
import styles from "./StepContent.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import JsonViewer from "../jsonviewer/JsonViewer";

/**
 * @param {{triggerOuput: TriggerOutput}} props
 */
const TriggerContent = ({ triggerOutput }) => {
  return <TriggerOutput triggerOutput={triggerOutput} />;
};

/**
 * @param {{triggerOutput: TriggerOutput}} props
 */
const TriggerOutput = ({ triggerOutput }) => {
  if (!triggerOutput) {
    return (
      <div className={styles.loader}>
        <FontAwesomeIcon icon="spinner" spin />
      </div>
    );
  }
  return (
    <div className={styles.stepContentContainer}>
      <div className={styles.stepOutput}>
        <JsonViewer
          json={triggerOutput}
          allowCopy={false}
          allowSelect={false}
          onSelect={console.log}
          replaceRoot={"trigger"}
          trail={true}
          title="Trigger output"
          copyAll={true}
        />
      </div>
    </div>
  );
};

export default TriggerContent;
