/**
 * @typedef {object} Reading
 * @prop {`permanent|transient`} type
 */

/**
 * @returns {Reading}
 */
export const selectTransientReading = (connectionId) => (state) =>
  state.liveReadings.transientReadings?.[connectionId];

/**
 * @returns {Reading[]}
 */
export const selectPermanentReadings = (connectionId) => (state) =>
  state.liveReadings.permanentReadings.filter(
    (r) => r.connectionId === connectionId
  );
