import React from "react";
import clsx from "clsx";
import styles from "./OrchestrationFlow.module.scss";
import StatusIcon from "./StatusIcon";

export function ConnectorLine({ stepStatus, length }) {
  const last = stepStatus.stepNumber + 1 === length;
  const first = stepStatus.stepNumber === "trigger";
  const status = stepStatus.status;

  return (
    <div
      className={clsx(styles.connector, {
        [styles.last]: last,
        [styles.first]: first,
      })}
    >
      <div
        className={clsx(styles.connectorInner, {
          [styles.last]: last,
          [styles.first]: first,
        })}
      >
        <div
          className={clsx(styles.statusLine, {
            [styles.last]: last,
            [styles.first]: first,
            [styles.futureStatus]: status === "future",
          })}
        >
          <div
            title={status}
            className={clsx(styles.connectorIcon, {
              [styles.first]: first,
            })}
          >
            <StatusIcon statusOfStep={stepStatus} />
          </div>
          <div
            className={clsx(styles.connectorLine, {
              [styles.last]: last,
              [styles.first]: first,
            })}
          >
            <div className={styles.line}></div>
          </div>
        </div>
      </div>
    </div>
  );
}
