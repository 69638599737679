import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import variables from "../../assets/styles/variables.scss";
import clsx from "clsx";

const DLXTooltip = ({
  children,
  placement = "bottom-start",
  size = "1em",
  text = "",
  className = "",
  ...props
}) => {
  const classes = useStyles({ size });
  return (
    <Tooltip
      classes={{
        tooltip: clsx(classes.customTooltip, className),
      }}
      title={text}
      placement={placement}
      {...props}
    >
      {children}
    </Tooltip>
  );
};

const useStyles = makeStyles({
  customTooltip: {
    backgroundColor: variables.lightgraybackground,
    border: `1px solid ${variables.lightgrayborder}`,
    boxShadow: variables.boxshadow,
    color: variables.darkgraytext,
    fontSize: (props) => props.size,
    zIndex: 10000,
  },
});

export default DLXTooltip;
