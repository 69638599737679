import axios from "axios";
import config from "config";
import React, { useContext, useState, useLayoutEffect } from "react";
import { useDispatch } from "react-redux";
import { LoadingIndicator } from "../../components/LoadingScreen";
import { dbLoadSuccess } from "../../state/reducers/dbAdapter";

const SystemEnvContext = React.createContext({
  constants: {},
  enums: {},
  permissions: {},
  operations: {},
  config: {},
});

const addKeysForResource = (resource) => ({
  items: Object.keys(resource).map((id) => ({ id, value: resource[id] })),
});

const httpClient = axios.create({
  baseURL: config.apiUrl,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
  },
});

export const useSystemEnvContext = () => useContext(SystemEnvContext);

export const SystemEnvProvider = ({ children }) => {
  const [system, setSystem] = useState(null);
  const [error, setError] = useState(null);
  const dispatch = useDispatch();

  useLayoutEffect(() => {
    (async () => {
      let mounted = true;
      try {
        const { data } = await httpClient.get("/system");
        if (mounted) {
          setSystem(data);
          Object.entries(data).forEach(([key, val]) => {
            dispatch(
              dbLoadSuccess({ tableName: key, data: addKeysForResource(val) })
            );
          });
        }
      } catch (e) {
        setError(e);
      }
      return () => {
        mounted = false;
      };
    })();
  }, [dispatch]);

  if (error) {
    return <h1>{error.message}</h1>;
  }
  if (!system) {
    return <LoadingIndicator />;
  }
  return (
    <SystemEnvContext.Provider value={system}>{children}</SystemEnvContext.Provider>
  );
};
