import { useEffect, useState } from "react";
import { fetchTransforms } from "./api";

export function useTransforms() {
  const [transforms, setTransforms] = useState([]);
  useEffect(() => {
    fetchTransforms().then((items) => setTransforms(items));
  }, []);
  return { transforms };
}
