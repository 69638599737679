import React, { useState, Fragment, useEffect } from "react";
import { useDispatch } from "react-redux";
import styles from "../OrchestrationFlow.module.scss";
import useLookup from "../../../state/hooks/useLookup";
import { ChevronDownIcon, SearchThroughIcon } from "../../../icons";

import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import Grid from "@material-ui/core/Grid";
import clsx from "clsx";
import { actions } from "../../../state/hooks/useApiResource";

/**
 * @param {{
 * id: string,
 * setConnector: Function,
 * connector?: Connector,
 * uuid: string,
 * }} props
 */
const ConnectorSelector = ({ id, setConnector, connector, uuid }) => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [selectedConnector, setSelectedConnector] = useState({});
  const { options: connectorsList } = useLookup({
    path: "/lookups/connectors",
  });

  const allConnectors = React.useMemo(() => {
    return connectorsList
      .filter(Boolean)
      .filter((con) => con?.state === "current")
      .filter(
        (con) =>
          !searchText || con?.label?.toLowerCase().includes(searchText.toLowerCase())
      );
  }, [connectorsList, searchText]);

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      const loadRequests = async () => {
        try {
          const response = await dispatch(
            actions.apiTransaction({
              route: `/connectors?state=current&uuid=${uuid}`,
            })
          );
          if (response.items) {
            const defaultConnector = response.items.filter(
              (con) => con.state === "current"
            );
            setSelectedConnector(defaultConnector);
          }
        } catch (e) {
          console.log(e);
        }
      };
      if (connector) {
        setSelectedConnector(connector);
      }
      if (!connector) {
        loadRequests();
      }
    }
    return () => {
      mounted = false;
    };
  }, [connector, dispatch, uuid]);

  const handleConnectorSelection = React.useCallback(
    (selection) => {
      setConnector({ connector: selection.value });
      setSelectedConnector(selection);
      setOpen(false);
    },
    [setConnector, setSelectedConnector]
  );

  return (
    <Fragment>
      <ClickAwayListener onClickAway={() => setOpen(false)}>
        <div
          className={styles.connectionStep}
          data-testid="dtid_orchestration_flow_connection_step"
        >
          <div
            className={clsx(
              styles.connectionInner,
              open ? styles.active : id && styles.selected
            )}
            onClick={() => (!open ? setOpen(true) : setOpen(false))}
          >
            <section className={`${styles.menuLabel} `}>
              <div
                className={`${styles.boldText} `}
                data-testid="dtid_orchestration_flow_bold_connector_text"
              >
                {selectedConnector?.name ||
                  selectedConnector?.label ||
                  "Select a connector"}
              </div>
              {selectedConnector?.type && (
                <ConnectorVersion
                  setConnector={setConnector}
                  selected={selectedConnector}
                  setSelected={setSelectedConnector}
                />
              )}
            </section>

            <ChevronDownIcon
              style={{
                display: connectorsList.length > 0 ? "inline-block" : "none",
                transform: !open ? "none" : "rotate(180deg)",
              }}
              className={styles.dropdownIcon}
            />
          </div>
          <ul className={!open ? "" : styles.activeDropdown}>
            <li key="search" className={styles.search}>
              <TextField
                variant="outlined"
                fullWidth
                size="small"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <SearchThroughIcon className={styles.searchIcon} />
                    </InputAdornment>
                  ),
                }}
                onChange={(e) => {
                  setSearchText(e.target.value);
                }}
              />
            </li>
            {allConnectors.map((option, i) => {
              return (
                <li key={i} onClick={() => handleConnectorSelection(option)}>
                  <Grid container spacing={1}>
                    <Grid item xs={10}>
                      <h2>{option.label}</h2>
                      <p>v{option.version} (current)</p>
                    </Grid>
                  </Grid>
                </li>
              );
            })}
          </ul>
        </div>
      </ClickAwayListener>
    </Fragment>
  );
};

const ConnectorVersion = ({ setConnector, selected, setSelected }) => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [connectors, setConnectors] = useState([]);

  useEffect(() => {
    let mounted = true;
    if (mounted && selected) {
      const fetchConnectors = async () => {
        const response = await dispatch(
          actions.apiTransaction({
            route: `/connectors?uuid=${selected.uuid}`,
          })
        );
        response.items && setConnectors(response.items);
      };
      fetchConnectors();
    }
    return () => {
      mounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = React.useCallback(
    (conn) => {
      setSelected(conn);
      setConnector({ connector: conn.id, version: conn.version });
      setOpen(false);
    },
    [setConnector, setSelected]
  );

  const handleViewVersion = (e) => {
    e.stopPropagation();
    setOpen((o) => !o);
  };

  return (
    <div className={styles.connectorVersionContainer}>
      <ClickAwayListener onClickAway={() => setOpen(false)}>
        {selected && (
          <div className={styles.connectorVersionHolder} onClick={handleViewVersion}>
            <div className={clsx(styles.connectorVersion)}>
              {selected.version && (
                <Grid container spacing={1}>
                  <Grid item xs={11} container>
                    <Grid item xs={12} className={styles.selectedVersion}>
                      v {selected.version}({selected.state})
                    </Grid>
                  </Grid>
                  <Grid item xs={1}>
                    <ChevronDownIcon
                      className={clsx(
                        styles.dropdownIcon,
                        connectors.length > 0 && styles.displayInline,
                        open && styles.rotate
                      )}
                    />
                  </Grid>
                </Grid>
              )}
            </div>
            {connectors && (
              <ul
                className={clsx(
                  styles.versionOptions,
                  open && styles.activeDropdown
                )}
              >
                {connectors
                  .filter((conn) => conn.version !== selected.version)
                  .map((option, i) => {
                    return (
                      <li key={i} onClick={() => handleChange(option)}>
                        <span>
                          v {option.version}({option.state})
                        </span>
                      </li>
                    );
                  })}
              </ul>
            )}
          </div>
        )}
      </ClickAwayListener>
    </div>
  );
};

export default ConnectorSelector;
