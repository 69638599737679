import { types } from "./auth.actions";
import { NO_AUTH } from "../../state/constants";

const initialState = {
  user: null,
  status: NO_AUTH,
  loading: false,
  error: null,
  loginAttempts: 3,
  lastAuthenticatedAt: null,
  idle: false,
  idleSessionTimeoutMinutes: 0,
};
export default function authReducer(state = initialState, action) {
  switch (action.type) {
    case types.AUTH_SUCCESS:
      return {
        ...state,
        user: action.payload?.user,
        status: action.payload?.status,
        idleSessionTimeoutMinutes: action.payload?.idleSessionTimeoutMinutes,
        loading: false,
        error: null,
        lastAuthenticatedAt: action.payload?.timestamp,
      };
    case types.AUTH_FAILURE:
      return {
        ...state,
        user: null,
        loading: false,
        ...action.payload,
      };
    case types.AUTH_REACTIVATE_FAILURE:
      return {
        ...state,
        loginAttempts: state.loginAttempts - 1,
        error: action.error,
      };
    case types.AUTH_LOGGED_OUT:
      return initialState;
    case types.PWD_RESET_RQD:
      return { ...state, user: null, loading: false, status: action.status };
    case types.AUTH_LOADING:
      return { ...state, loading: true };
    case types.USER_IDLE:
      return { ...state, idle: true };
    case types.SET_USER: {
      return { ...state, user: action.payload?.user };
    }
    default:
      return state;
  }
}
