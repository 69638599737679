import { useDispatch } from "react-redux";
import { alertError } from "../../state/alerts/actions";
import { sendConnectionActionRequest } from "../../state/sagas/connectionAction.saga";
import { actions } from "../../state/hooks/useApiResource";
import { logger } from "../../utils";
const log = logger("useLookup");

export const useLookup = () => {
  const dispatch = useDispatch();

  const executeConnectionLookup = async (lookup) => {
    try {
      const { success, message, result } = await dispatch(
        sendConnectionActionRequest(lookup)
      );
      log({ lookup, success, message, result });
      if (!success) {
        throw new Error(`An error occurred looking up list items: ${message}`);
      }
      return { result };
    } catch (error) {
      dispatch(alertError(error.message || error));
      return { result: [] };
    }
  };

  const executeRouteLookup = async (lookup) => {
    const query = lookup.options
      ? "?" + new URLSearchParams(lookup.options).toString()
      : "";
    const route = `${lookup.route}${query}`;
    const response = await dispatch(
      actions.apiTransaction({
        route,
      })
    );
    log({ lookup, response });
    if (!response.error && Array.isArray(response.items)) {
      if (response.items.length === 1) {
        return { result: response.items[0].options };
      }
      return {
        result: response.items.map((item) => ({
          ...item,
          items: item.items || item.options,
          selectable: false,
        })),
      };
    }
    dispatch(alertError("An error occurred looking up selections"));
    return { result: [] };
  };

  const executeApiLookup = async (lookup) => {
    const requests = Object.values(lookup)
      .filter(Boolean)
      .map((c) =>
        dispatch(
          actions.apiTransaction({
            route: c.href,
          })
        )
      );
    const lists = await Promise.allSettled(requests);
    log({ lists });
    return {
      result: lists
        .filter((i) => i.status === "fulfilled")
        .map((j) => j.value.items[0])
        .map((item) => ({
          label: item.label,
          value: item.label,
          items: item.options,
          selectable: false,
        })),
    };
  };

  const executeLookup = (lookup) => {
    if (lookup.command) {
      return executeConnectionLookup(lookup);
    }
    if (lookup?.[0]?.kind === "api-resource") {
      return executeApiLookup(lookup);
    }
    if (lookup.route) {
      return executeRouteLookup(lookup);
    }
    dispatch(alertError("Unknown lookup used"));
    return null;
  };

  return { executeLookup };
};
