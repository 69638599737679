export const orchestrationsPath = `/orchestrations`;
export const executionPath = `/executions/:executionId`;
export const executeOrchestrationPath = "/orchestrations/:orchestrationId/execute";
export const orchestrationExecutionPath =
  "/orchestrations/:orchestrationId/executions/:executionId";
export const ROOT = "/";
export const SIGN_ON = `/sign-on`;
export const ORCHESTRATIONS = `/orchestrations`;
export const EXECUTIONS = "/executions";
export const EXECUTION_DETAILS = `${EXECUTIONS}/:executionId`;
export const EXECUTE_ORCHESTRATION = `${ORCHESTRATIONS}/:orchestrationId/execute`;
export const ORCHESTRATION_EXECUTION_DETAILS = `${ORCHESTRATIONS}/:orchestrationId/executions/:executionId`;

export const USER_ROLES = "/user-roles";
export const USERS = "/users";
export const USER_GROUPS = "/user-groups";
export const REASONS_FOR_CHANGE = `/customers/:id/reasons-for-change`;
export const SIGN_OFF_SUCCESS = "/signed-off";
