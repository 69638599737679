/*  FontAwesome icons  */
import { library } from "@fortawesome/fontawesome-svg-core";

import {
  faFileImport,
  faFileExport,
  faMailBulk,
  faEnvelopeOpen,
  faQuestion,
  faBars,
  faMinus,
  faCircle,
  faCrown,
  faShieldAlt,
  faCog,
  faUserShield,
  faFlask,
  faUserCircle,
  faUserAlt,
  faSortDown,
  faSignOutAlt,
  faPlug,
  faRetweet,
  faQuestionCircle,
  faComments,
  faEye,
  faEyeSlash,
  faSignal,
  faWifi,
  faThLarge,
  faList,
  faAddressBook,
  faCode,
  faUsers,
  faPlus,
  faSync,
  faChevronLeft,
  faChevronRight,
  faChevronDown,
  faChevronUp,
  faEllipsisV,
  faEllipsisH,
  faCheck,
  faTimes,
  faHeartbeat,
  faCogs,
  faDotCircle,
  faCircleNotch,
  faArrowAltCircleDown,
  faArrowAltCircleRight,
  faCloudUploadAlt,
  faFileUpload,
  faFileMedical,
  faStopwatch,
  faPen,
  faTrash,
  faExclamation,
  faClock,
  faExclamationCircle,
  faStopCircle,
  faInfoCircle,
  faInfo,
  faEdit,
  faStream,
  faLevelDownAlt,
  faSignInAlt,
  faHandPointUp,
  faHandPointRight,
  faDatabase,
  faPlay,
  faUpload,
  faCamera,
  faAsterisk,
  faUndo,
  faCheckCircle,
  faCompressAlt,
  faCaretRight,
  faCaretLeft,
  faSpinner,
  faPaperclip,
  faReply,
  faEnvelope,
  faDownload,
  faSave,
  faSitemap,
  faCopy,
  faStop,
  faSlidersH,
  faTimesCircle,
  faSearch,
  faChevronCircleLeft,
  faChevronCircleRight,
  faChevronCircleDown,
  faThumbsUp,
  faKey,
  faArchive,
  faUnlink,
  faLink,
  faCloud,
  faLock,
  faUnlock,
  faFileContract,
  faQrcode,
  faBarcode,
  faRunning,
  faDesktop,
  faHistory,
  faFileDownload,
  faFileMedicalAlt,
  faGripHorizontal,
  faBan,
  faPowerOff,
  faTachometerAlt,
  faUserEdit,
  faBolt,
  faPrint,
  faUserGraduate,
} from "@fortawesome/free-solid-svg-icons";
import { faStar } from "@fortawesome/free-regular-svg-icons";

library.add(
  faMailBulk,
  faBars,
  faCircle,
  faPlug,
  faRetweet,
  faUserCircle,
  faQuestionCircle,
  faComments,
  faShieldAlt,
  faCrown,
  faCog,
  faFlask,
  faUserAlt,
  faSortDown,
  faSignOutAlt,
  faEye,
  faEyeSlash,
  faSignal,
  faWifi,
  faThLarge,
  faList,
  faAddressBook,
  faCode,
  faUsers,
  faPlus,
  faSync,
  faChevronLeft,
  faChevronRight,
  faChevronDown,
  faChevronUp,
  faEllipsisV,
  faCheck,
  faTimes,
  faHeartbeat,
  faCogs,
  faDotCircle,
  faCloudUploadAlt,
  faFileUpload,
  faFileMedical,
  faStopwatch,
  faPen,
  faTrash,
  faExclamation,
  faStar,
  faClock,
  faExclamationCircle,
  faStopCircle,
  faInfoCircle,
  faInfo,
  faEdit,
  faStream,
  faLevelDownAlt,
  faSignInAlt,
  faHandPointUp,
  faHandPointRight,
  faDatabase,
  faPlay,
  faUpload,
  faCamera,
  faUserShield,
  faAsterisk,
  faEnvelopeOpen,
  faUndo,
  faCheckCircle,
  faArrowAltCircleRight,
  faCompressAlt,
  faCaretRight,
  faCaretLeft,
  faSpinner,
  faPaperclip,
  faReply,
  faQuestion,
  faEnvelope,
  faDownload,
  faSave,
  faSitemap,
  faCopy,
  faStop,
  faSlidersH,
  faRunning,
  faTimesCircle,
  faSearch,
  faChevronCircleLeft,
  faChevronCircleRight,
  faThumbsUp,
  faKey,
  faArchive,
  faUnlink,
  faLink,
  faCloud,
  faEllipsisH,
  faLock,
  faUnlock,
  faFileContract,
  faQrcode,
  faBarcode,
  faRunning,
  faDesktop,
  faHistory,
  faFileExport,
  faFileImport,
  faFileDownload,
  faFileMedicalAlt,
  faGripHorizontal,
  faFileMedicalAlt,
  faBan,
  faCircleNotch,
  faPowerOff,
  faRunning,
  faMinus,
  faArrowAltCircleDown,
  faChevronCircleDown,
  faTachometerAlt,
  faUserEdit,
  faBolt,
  faPrint,
  faUserGraduate
);

export default library;
