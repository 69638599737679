import { createSelector } from "@reduxjs/toolkit";

export const getConnectionSelector = (state, id) => state.connections.entities[id];
export const getReadingSelector = (state, id) => state.reading.entities[id];

export const getConnectorsSelector = createSelector(
  (state) => state.connectors.ids,
  (state) => state.connectors.entities,
  (ids, entities) => {
    return ids.map((id) => entities[id]);
  }
);
/**
 * @returns {getFullConnection}
 */
const emptyConnection = {};

export const getFullConnection = (id) =>
  createSelector(
    (state) => state.connections.entities,
    (state) => state.connectors.entities,
    (connections, connectors) => {
      const connection = connections[id];
      const connector =
        connectors[connection?.connector?.id || connection?.connector];
      if (connection) {
        const status = connection.status;
        return { ...connection, connector, status };
      }
      return emptyConnection;
    }
  );

export const getConnectionConnector = (state, id) => {
  const connection = state.connections.entities[id];
  return state.connectors.entities[connection?.connector];
};

export const getConnections = createSelector(
  (state) => state.connections.ids,
  (state) => state.connections.entities,
  (state) => state.connectors.entities,
  (ids, connections, connectors) =>
    ids
      .map((id) => ({
        ...connections[id],
        connector:
          connectors[connections[id]?.connector?.id] || connections[id]?.connector,
        status: connections[id].status,
      }))
      .filter(Boolean)
);

export const getConnectionsList = (state) => state.connections.entities;

export const getConnectionsLoadingStatus = (state) => state.connections.loading;
