import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import config from "config";
import { useLocalStorage } from "react-use";
import { useDispatch } from "react-redux";
import { alertError } from "@dlx/state/alerts/actions";
import { SIGN_OFF_SUCCESS } from "@dlx/views/routes";
import { useHistory } from "react-router-dom";
import { getStoredIamToken, storeIamToken } from "@dlx/services/tokens";
import {
  channel as authChannel,
  broadcastLogout,
  broadcastSessionExpired,
  broadcastTokenExpired,
  broadcastTokenRefreshCanceled,
  broadcastTokenRefreshCompleted,
  waitForSessionAuthenticated,
  waitForTokenRefreshCompleted,
} from "../../services/authChannel";
import { refreshToken } from "@dlx/services/sso";
import { logger } from "@dlx/utils";
import { logoutWithIdentityProvider } from "@dlx/services/logoutWithIdentityProvider";

const log = logger("session");

export const SessionContext = React.createContext(null);

export const useSessionContext = () => useContext(SessionContext);

const useSessionWorker = () => {
  useEffect(() => {
    const worker = new SharedWorker("../../workers/session.shared-worker.js", {
      name: "session",
      type: "module",
    });
    worker.port.addEventListener("message", ({ data }) => {
      if (data.type === "config/get") {
        worker.port.postMessage({ type: "config/set", value: { config } });
      }
    });
    worker.port.start();
    return () => {
      worker.port.close();
    };
  }, []);
  return null;
};

const requestLock = (callback) => {
  return navigator.locks.request(
    "token",
    { mode: "exclusive", ifAvailable: true },
    callback
  );
};

export const SessionProvider = ({ children }) => {
  const dispatch = useDispatch();
  const [iamToken, setIamToken] = useLocalStorage("iamToken");
  const [currentUser, setCurrentUser] = useLocalStorage("currentUser");
  const [isAuthenticated, setIsAuthenticated] = useState(true);
  const [isIdle, setIsIdle] = useState(false);
  const [error, setError] = useState(null);
  const history = useHistory();

  useSessionWorker();

  const channel = useMemo(() => new BroadcastChannel("dlx"), []);

  const handleLogout = useCallback(() => {
    broadcastLogout();
    logoutWithIdentityProvider(iamToken);
  }, [iamToken]);

  const handleExpiredSession = useCallback(async () => {
    log("session expired");
    setIsIdle(true);
    await requestLock(async (lock) => {
      if (lock) {
        log("lock acquired");
        broadcastSessionExpired();
      } else {
        log("lock in use");
      }
      log("waiting for SessionAuthenticated");
      return waitForSessionAuthenticated();
    });
    log("lock released");
    setIsIdle(false);
  }, []);

  const handleExpiredToken = useCallback(async () => {
    try {
      await requestLock(async (lock) => {
        if (!lock) {
          log("lock in use");
          log("waiting for TokenRefreshCompleted");
          return waitForTokenRefreshCompleted();
        }
        broadcastTokenExpired();
        log("lock acquired");
        const handleBeforeUnload = () => broadcastTokenRefreshCanceled("ClosedTab");
        window.addEventListener("beforeunload", handleBeforeUnload);
        const newToken = await refreshToken(getStoredIamToken());
        window.removeEventListener("beforeunload", handleBeforeUnload);
        storeIamToken(newToken);
        log("token refreshed");
        broadcastTokenRefreshCompleted(newToken);
        return newToken;
      });
    } catch (err) {
      log(err.message);
      if (err.type === "TokenRefreshCanceled") {
        return handleExpiredToken();
      }
      if (err.message === "grant request is invalid") {
        return handleExpiredSession();
      }
      throw err;
    }
    const token = getStoredIamToken();
    setIamToken(token);
    setIsAuthenticated(true);
    return token;
  }, []);

  useEffect(() => {
    authChannel.addEventListener("message", ({ data }) => {
      if (data.type === "Logout") {
        history.replace(SIGN_OFF_SUCCESS);
        setIsAuthenticated(false);
        setIamToken("");
      }
      if (data.type === "TokenRefreshCompleted") {
        setIsAuthenticated(true);
        setIamToken(data.token);
      }
      if (data.type === "SessionAuthenticated") {
        setIsIdle(false);
        setIsAuthenticated(true);
      }
      if (data.type === "SessionExpired") {
        setIsIdle(true);
      }
      if (data.error) {
        setError(data.error);
      }
    });
  }, []);

  useEffect(() => {
    channel.addEventListener("message", ({ data }) => {
      log("message received:", data);
      if (data.action === "esign-cancelled") {
        dispatch(alertError(`Esign was cancelled`));
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (iamToken) {
      setIsAuthenticated(true);
    }
  }, [iamToken]);

  const value = useMemo(() => {
    return {
      currentUser,
      error,
      handleExpiredSession,
      handleExpiredToken,
      handleLogout,
      iamToken,
      isAuthenticated,
      isIdle,
      setCurrentUser,
      setIamToken,
      setIsAuthenticated,
      setIsIdle,
    };
  }, [currentUser, iamToken, isAuthenticated, isIdle]);
  return <SessionContext.Provider value={value}>{children}</SessionContext.Provider>;
};
