import { call, takeEvery } from "redux-saga/effects";
import AuthService from "../../services/Auth";
import { actions } from "../hooks/useApiResource";
import { implementPromiseAction } from "@adobe/redux-saga-promise";
import { client } from "../../services/api/client";

import {
  createRecordSaga,
  hasRecordBeenFetched,
  fetchRecord,
  selectRecord,
} from "./record.saga";

const authService = new AuthService();

export default function* apiSaga() {
  yield takeEvery(actions.apiRequest, handleApiRequest);
  yield takeEvery(actions.apiTransaction, handleApiTransaction);
  yield takeEvery(actions.getLink, handleApiGetLink);
  yield takeEvery(actions.createRecord, handleCreateRecord);
}

function* handleCreateRecord(action) {
  yield call(implementPromiseAction, action, function* () {
    return yield createRecordSaga(action);
  });
}
function* handleApiGetLink(action) {
  yield call(implementPromiseAction, action, function* () {
    const { linkName = "self", record } = action.payload;
    const link = record?.$links.find((l) => l.rel === linkName);
    if (!link) {
      return { error: "Invalid link" };
    }
    try {
      const request = { url: link.href, method: "GET" };
      return yield call(authService.fetch, request);
    } catch (error) {
      return { error };
    }
  });
}

function* handleApiRequest(action) {
  yield call(implementPromiseAction, action, function* () {
    const { payload } = action;
    try {
      if (yield hasRecordBeenFetched(payload)) {
        return yield selectRecord(payload);
      }
      return yield fetchRecord(action.payload);
    } catch (error) {
      return { error };
    }
  });
}

function* handleApiTransaction(action) {
  yield call(implementPromiseAction, action, function* () {
    const { route, method = "GET", body, headers } = action.payload;
    try {
      const { data } = yield call(client, route, { method, data: body, headers });
      return data;
    } catch (error) {
      return { error: error?.response?.data };
    }
  });
}
