export const CLOSE_EDITOR = "CLOSE_EDITOR";
export const CLOSE_EDITOR_CONFIRM = "CLOSE_EDITOR_CONFIRM";
export const CLOSE_EDITOR_CANCEL = "CLOSE_EDITOR_CANCEL";
export const EDITOR_ERROR = "EDITOR_ERROR";
export const GET_COMMAND_OPTIONS = "GET_COMMAND_OPTIONS";
export const GET_STEP_DATA = "GET_STEP_DATA";
export const GET_TRIGGER_EVENTS = "GET_TRIGGER_EVENTS";
export const IS_EDITING = "IS_EDITING";
export const LOAD_ORCHESTRATIONS_PAGE = "LOAD_ORCHESTRATIONS_PAGE";
export const OPEN_EDITOR = "OPEN_EDITOR";
export const ORCHESTRATIONS_PATH = "/orchestrations";
export const SAVE_ORCHESTRATION = "SAVE_ORCHESTRATION";
export const SAVE_ORCHESTRATION_FAILURE = "SAVE_ORCHESTRATION_FAILURE";
export const SAVE_ORCHESTRATION_SUCCESS = "SAVE_ORCHESTRATION_SUCCESS";
export const SET_COMMAND_OPTIONS = "SET_COMMAND_OPTIONS";
export const SET_ORCHESTRATION = "SET_ORCHESTRATION";
export const SET_STEP_DATA = "SET_STEP_DATA";
export const SET_TRIGGER_EVENTS = "SET_TRIGGER_EVENTS";
export const TOGGLE_ORCHESTRATION_STATE = "TOGGLE_ORCHESTRATION_STATE";
export const SET_FRAME_URL = "SET_FRAME_URL";
export const UPDATE_EDITED_EXECUTION = "UPDATE_EDITED_EXECUTION";
export const ERR_VALIDATION = "FST_ERR_VALIDATION";

export const ERROR_HANDLING_SCHEMA = Object.freeze({
  type: "object",
  properties: {
    errorResponse: {
      type: "string",
      title: "Error Response",
      description:
        "Provides options to choose an action in the event of a step error",
      enum: ["Continue on Error", "Fail on Error", "Wait on Error"],
      default: "Fail on Error",
    },
    message: {
      type: "string",
      title: "Message",
      description: "Error message to be displayed in step output",
    },
    to: {
      title: "To",
      description:
        "Provide one or more users or user groups to send the notification to",
      type: ["string", "array"],
      pattern: "^[0-9a-fA-F]{24}$",
      minItems: 1,
      items: { type: "string", pattern: "^[0-9a-fA-F]{24}$" },
      lookup: [
        {
          kind: "api-resource",
          href: "/lookups/users",
          groupName: "Users",
        },
        {
          kind: "api-resource",
          href: "/lookups/user-groups",
          groupName: "User Groups",
        },
      ],
    },
    subject: {
      title: "Subject",
      description: "The subject of the message",
      type: "string",
    },
    text: {
      title: "Text",
      description: "The text contents of the message",
      type: "string",
    },
    tabularData: {
      title: "Tabular data",
      description:
        "An object that contains key-value data to display in the notification",
      examples: [
        {
          Clorine: "50mg/mL",
          Bromine: "20mg/mL",
          Florine: "15mg/mL",
        },
      ],
      type: "object",
    },
    attachments: {
      title: "Attachment",
      description: "Add the filePackage or uuid of the file to be attached",
      type: ["array", "object", "string"],
      items: {
        type: ["object", "string"],
        properties: {
          uuid: {
            type: "string",
            format: "uuid",
          },
        },
        format: "uuid",
      },
      properties: {
        uuid: {
          type: "string",
          format: "uuid",
        },
      },
      format: "uuid",
    },
  },
});
