import React from "react";
import DLXTooltip from "../../DLXTooltip/DLXTooltip";
import Switch from "@material-ui/core/Switch";
import styles from "../OrchestrationFlow.module.scss";

/**
 * @param {{
 *  userSelection: boolean,
 *  setUserSelection: Function,
 *  disabled: boolean,
 * onUpdate: Function,
 * }} props
 */
const UserSelectionSelector = ({
  userSelection,
  setUserSelection,
  disabled,
  onUpdate,
}) => {
  const tooltipMsg = !userSelection
    ? "Enable to allow user to select a connection during orchestration execution."
    : "Disable to select the connection for orchestration execution.";

  return (
    <div
      className={styles.inputBorder}
      style={{ display: "flex", alignItems: "center", marginLeft: "20px" }}
    >
      <div className={styles.subTitle} style={{ padding: "0px 0px 0px 15px" }}>
        Runtime Selection
      </div>
      <DLXTooltip text={tooltipMsg}>
        <Switch
          classes={{
            switchBase: styles.switchBase,
            checked: styles.checked,
            track: styles.track,
          }}
          color="primary"
          name="state"
          checked={userSelection}
          value={userSelection}
          onChange={(e) => {
            setUserSelection(e.target.checked);
            onUpdate({ runtime: e.target.checked });
          }}
          disabled={disabled}
        />
      </DLXTooltip>
    </div>
  );
};

export default UserSelectionSelector;
