import useGetCollection from "../hooks/useGetCollection";
import useGetRecord from "../hooks/useGetRecord";

const slice = "userGroups";
export default function useGetUserGroup(id) {
  const [fetched, userGroup] = useGetRecord(slice, id);
  return { fetched, userGroup };
}

export function useGetUserGroupsCollection() {
  const [loaded, userGroups] = useGetCollection(slice);
  return { loaded, userGroups };
}
