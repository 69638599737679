import React, { useEffect, useState } from "react";
import styles from "./ConnectionInterfaceTable.module.scss";
import UserReadingsDialog from "./UserReadingsDialog";
import { formatDate } from "../../../utils";
import { useGetFullConnection } from "../../../state/connections/useGetConnection";
import useLiveReadings from "../../../state/connections/live-readings/useLiveReadings";
import DLXChip from "../../DLXChip/DLXChip";
import DLXTooltip from "../../DLXTooltip/DLXTooltip";
import { useDispatch } from "react-redux";
import { createUserReading } from "../../../state/users/users.reducer";
import DLXButton from "../../DLXButton/DLXButton";
import { TimesIcon, FlaskIcon, SyncIcon } from "../../../icons";
import { useCurrentUserContext } from "../../../contexts/current-user/Provider";

const formatReading = (reading) => reading.readingId;

const formattedDate = (timestamp) => {
  try {
    return formatDate(new Date(timestamp));
  } catch (error) {
    return "Invalid date";
  }
};

export const TransientReading = ({ connection }) => {
  const { transientReading } = useLiveReadings(connection?.id);
  const dataState = transientReading?.status?.valid ? "valid" : "invalid";

  if (!connection?.connector?.readings?.supportsReadingStreaming) {
    return null;
  }

  return transientReading ? (
    <tr>
      <td>Live reading</td>
      <td>
        <div className={styles.rightCell}>
          {transientReading?.payload && (
            <div className={styles.readingsContainer}>
              {Object.entries(transientReading?.payload).map(([k, v]) => {
                return (
                  <DLXTooltip key={k} text={transientReading?.status?.message}>
                    <span className={styles.reading}>
                      <span className={styles.readingType}>{v.label}</span>
                      <span
                        className={`${styles.readingValue} ${styles[dataState]}`}
                      >
                        {v.value}
                      </span>
                      <span
                        className={`${styles.readingUnits} ${styles[dataState]}`}
                      >
                        {v.units}
                      </span>
                    </span>
                  </DLXTooltip>
                );
              })}
            </div>
          )}
        </div>
      </td>
    </tr>
  ) : null;
};

export const MultipleReadings = ({
  connection,
  updateInterfaceData,
  waitingForReading,
  waitingForSeries,
}) => {
  const { permanentReadings } = useLiveReadings(connection?.id);

  const { currentUser } = useCurrentUserContext();
  const [rejected, setRejected] = useState([]);

  const acceptedReadings = permanentReadings.filter(
    (r) => !rejected.includes(r.actionRequestId)
  );
  const rejectedReadings = permanentReadings.filter((r) =>
    rejected.includes(r.actionRequestId)
  );

  useEffect(() => {
    updateInterfaceData({
      connection: { name: connection.name, id: connection.id },
      user: { name: currentUser.name, id: currentUser.id },
      accepted: acceptedReadings.map(formatReading),
      rejected: rejectedReadings.map(formatReading),
    });
    // eslint-disable-next-line
  }, [permanentReadings.length]);

  if (!acceptedReadings.length) {
    return null;
  }

  const unRejectReading = (reading) => {
    setRejected([...rejected.filter((r) => r !== reading.actionRequestId)]);
  };

  return (
    <>
      {permanentReadings.length || waitingForReading ? (
        <tr>
          <td>Captured readings</td>
          <td>
            <div className={styles.rightCell}>
              <div className={styles.chipHolder}>
                {acceptedReadings.map((reading, i) => {
                  return (
                    <ReadingChip
                      key={`${reading.recordedAt}-${i}`}
                      reading={reading}
                    />
                  );
                })}
                <WaitingForReadingChip
                  permanentReadings={permanentReadings}
                  waitingForReading={waitingForReading}
                  waitingForSeries={waitingForSeries}
                />
              </div>
            </div>
          </td>
        </tr>
      ) : null}
      {rejectedReadings.length ? (
        <tr>
          <td>Rejected readings</td>
          <td>
            <div className={styles.rightCell}>
              <div className={styles.chipHolder}>
                {rejectedReadings.map((reading, i) => {
                  return (
                    <ReadingChip
                      key={`${reading.recordedAt}-${i}`}
                      reading={reading}
                      rejected={true}
                      rejectReading={() => unRejectReading(reading)}
                    />
                  );
                })}
              </div>
            </div>
          </td>
        </tr>
      ) : null}
    </>
  );
};

export const SingleReading = ({
  connection,
  updateInterfaceData,
  waitingForReading,
}) => {
  const { permanentReadings } = useLiveReadings(connection?.id);
  const { currentUser } = useCurrentUserContext();
  const [rejectionReasons, setRejectionReasons] = useState([]);
  const acceptedReading = permanentReadings.length
    ? permanentReadings[permanentReadings.length - 1]
    : undefined;
  const rejectedReadings =
    permanentReadings.length > 1
      ? permanentReadings.slice(0, permanentReadings.length - 1)
      : [];

  useEffect(() => {
    if (acceptedReading && permanentReadings.length > 1) {
      //Show dialog to capture reason for rejecting reading here
      setRejectionReasons([...rejectionReasons, "No reason given"]);
    }
    // eslint-disable-next-line
  }, [permanentReadings.length]);

  useEffect(() => {
    if (acceptedReading) {
      updateInterfaceData({
        connection: { name: connection.name, id: connection.id },
        user: { name: currentUser.name, id: currentUser.id },
        accepted: [formatReading(acceptedReading)],
        rejected: rejectedReadings.map(formatReading),
      });
    }
    // eslint-disable-next-line
  }, [permanentReadings.length]);

  return (
    <>
      {permanentReadings.length || waitingForReading ? (
        <tr>
          <td>Captured reading</td>
          <td>
            <div className={styles.rightCell}>
              <div className={styles.chipHolder}>
                {acceptedReading && (
                  <ReadingChip
                    key={acceptedReading.recordedAt}
                    reading={acceptedReading}
                  />
                )}
                <WaitingForReadingChip waitingForReading={waitingForReading} />
              </div>
            </div>
          </td>
        </tr>
      ) : null}
      {rejectedReadings.length ? (
        <tr>
          <td>Rejected readings</td>
          <td>
            <div className={styles.rightCell}>
              <div className={styles.chipHolder}>
                {rejectedReadings.map((reading, i) => {
                  return (
                    <ReadingChip
                      key={`${reading.recordedAt}-${i}`}
                      reading={reading}
                      rejected={true}
                      rejectReading={null}
                    />
                  );
                })}
              </div>
            </div>
          </td>
        </tr>
      ) : null}
    </>
  );
};

const WaitingForReadingChip = ({ waitingForReading, waitingForSeries }) => {
  const label = waitingForSeries ? "Waiting for next in series..." : "Working...";
  return waitingForReading || waitingForSeries ? (
    <DLXChip leftIcon={<SyncIcon spin />} label={label} />
  ) : null;
};

const ReadingChip = ({ reading, index, rejectReading, rejected }) => {
  const ReadingElement = ({ rdg, attr }) => (
    <div className={styles.readingKey}>
      <b>{rdg.label || attr}:</b>
      {` ${rdg.value} ${rdg.units ? rdg.units : ""}`}
    </div>
  );
  return (
    <DLXChip
      key={index}
      leftIcon={<FlaskIcon />}
      disabled={rejected}
      label={`${formattedDate(reading.recordedAt)}${rejected ? " (REJECTED)" : ""}`}
      rightIcon={rejectReading ? <TimesIcon /> : ""}
      invalid={!reading.status.valid}
      tooltip={
        <div style={{ padding: "5px" }}>
          <div>
            <b>Recorded at:</b>
            {` ${
              reading.recordedAt ? formattedDate(reading.recordedAt) : "No timestamp"
            }`}
          </div>
          <div>
            <b>Message:</b>
            {` ${reading.status?.message}`}
          </div>
          {Object.values(reading.payload).map((r, i) => (
            <ReadingElement
              key={`element-${r.label}-${i}`}
              rdg={r}
              attr={Object.keys(reading.payload)[i]}
            />
          ))}
        </div>
      }
      onRightIconClick={() => rejectReading(reading)}
    />
  );
};

export const UserReadings = ({
  connection,
  interfaceData,
  options,
  setWaitingForReading,
}) => {
  const dispatch = useDispatch();
  const { fullConnection } = useGetFullConnection(connection.id);
  const [showUserReadingsDialog, setShowUserReadingsDialog] = useState(false);
  const readingTypes = fullConnection?.settings?.readingTypes;
  if (!readingTypes) {
    return null;
  }

  const submitReading = (reading) => {
    dispatch(createUserReading(connection.id, reading, options.attributes));
  };

  const handleClick = () => {
    setShowUserReadingsDialog(true);
    setWaitingForReading(true);
  };

  return (
    <>
      <tr>
        <td>User entered readings</td>
        <td>
          <div className={styles.rightCell}>
            {showUserReadingsDialog && (
              <UserReadingsDialog
                readingTypes={readingTypes}
                submitReading={submitReading}
                onCancel={() => setShowUserReadingsDialog(false)}
              />
            )}
            <div className={styles.buttonContainer}>
              <DLXButton
                icon={"dot-circle"}
                size="small-xs"
                label="Create reading"
                variant="contained"
                color="primary"
                handleClick={handleClick}
              >
                Create reading
              </DLXButton>
            </div>
          </div>
        </td>
      </tr>
      {interfaceData.recordedAt && (
        <tr>
          <td>Timestamp</td>
          <td>{formatDate(interfaceData.recordedAt)}</td>
        </tr>
      )}
    </>
  );
};
