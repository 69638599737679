import React from "react";
import { Route, Switch } from "react-router-dom";
import Dashboard from "../views/dashboard/Dashboard";
import UrlTriggeredOrchestration from "../views/OrchestrationsView/OrchestrationExecutionViewer/UrlTriggeredOrchestration";
import { Page as OrchestrationExecutionViewerPage } from "../views/OrchestrationsView/OrchestrationExecutionViewer";
import { EXECUTION_DETAILS, EXECUTE_ORCHESTRATION } from "../views/routes";
import "@dlx/assets/styles";

import AuditTrailExportView from "@dlx/views/AuditTrail/AuditTrailExportView";

const LiveView = React.lazy(() =>
  import("../components/Connection/LiveView/LiveView")
);

export const App = () => {
  return (
    <Switch>
      <Route
        exact
        path={EXECUTE_ORCHESTRATION}
        component={UrlTriggeredOrchestration}
      />
      <Route
        exact
        path={EXECUTION_DETAILS}
        component={OrchestrationExecutionViewerPage}
      />
      <Route path="/connections/:id/live" component={LiveView} />
      <Route
        path={[
          "/audittrail/print/:columns/:filters/:filterLabels",
          "/audittrail/print/:columns",
          "/audittrail/print/",
        ]}
        component={AuditTrailExportView}
      />
      <Route
        path={["/", "/connections", "/authsuccess", "/attachments/:uuid"]}
        component={Dashboard}
      />
    </Switch>
  );
};
