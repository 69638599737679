import { createSelector } from "@reduxjs/toolkit";

export const getFullUserGroup = (id) =>
  createSelector(
    (state) => state.userGroups.entities,
    (usergroups) => {
      const usergroup = usergroups[id];
      return {
        ...usergroup,
        roles: usergroup.roles?.map((role) => role.id),
      };
    }
  );

export const getUserGroupSelector = (state, id) => state.userGroups.entities[id];
