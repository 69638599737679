import React, { useContext, useEffect, useMemo, useState } from "react";
import { useMountedState } from "react-use";
import { useApiClient } from "../api/Provider";
import { useSessionContext } from "../session/Provider";
import { decodeUser } from "@dlx/services/tokens";
import { LoadingIndicator } from "@dlx/components/LoadingScreen";

export const CurrentUserContext = React.createContext(null);

export const useCurrentUserContext = () => useContext(CurrentUserContext);

export const usePermission = (action) => {
  const {
    currentUser: { permissions },
  } = useCurrentUserContext();
  if (action.length === 0) {
    return true;
  }
  if (Array.isArray(action)) {
    return action.some((p) => permissions.includes(p));
  }
  return permissions.includes(action);
};

export const CurrentUserProvider = ({ children }) => {
  const { client } = useApiClient();
  const { iamToken } = useSessionContext();
  const [customer, setCustomer] = useState(null);
  const isMounted = useMountedState();

  const currentUser = useMemo(() => decodeUser(iamToken), [iamToken]);

  useEffect(() => {
    (async () => {
      if (currentUser) {
        const { data } = await client.get(`/customers/${currentUser.customer}`);
        if (isMounted()) {
          setCustomer(data);
        }
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [iamToken, currentUser]);

  const value = useMemo(
    () => ({
      currentUser,
      customer,
    }),
    [currentUser, customer]
  );

  if (!customer) {
    return <LoadingIndicator />;
  }
  return (
    <CurrentUserContext.Provider value={value}>
      {children}
    </CurrentUserContext.Provider>
  );
};
