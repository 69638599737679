import { kebabCase } from "lodash";
import { useCallback, useEffect, useRef, useState } from "react";
import { useMountedState } from "react-use";
import { client } from "../../services/api/client";
import socket from "../../services/socket";

const COLLECTION_CHANGED = "collection:changed";

const deleteRecord = (message, args) => {
  args.setEntities((list) => list.filter((item) => item.id !== message.documentId));
};

const updateRecord = async (message, args) => {
  const { documentId, collection } = message;
  const { data: record } = await client.get(
    `/${kebabCase(collection)}/${documentId}`
  );
  if (record.archived) {
    args.setEntities((list) => list.filter((item) => record.id !== item.id));
  } else {
    args.setEntities((list) =>
      list.map((item) => (item.id === record.id ? record : item))
    );
  }
};

export const collectionChanged = (message, args) => {
  if (args.collectionName !== message.collection) {
    return;
  }
  switch (message.action) {
    case "updated":
      updateRecord(message, args);
      break;
    case "deleted":
      deleteRecord(message, args);
      break;
    default:
      console.log("unhandled action", message.action);
  }
};

const defaultSubscriptions = {
  event: COLLECTION_CHANGED,
  callback: collectionChanged,
};

export const useApiCollection = ({
  collectionName = "",
  queryString = "",
  reload = false,
  options = { subscriptions: [{ ...defaultSubscriptions }] },
  url,
  paginate = true,
  refetchOnCollectionChange = false,
}) => {
  const [entities, setEntities] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [debouncedQueryString, setDebouncedQueryString] = useState("init");
  const [next, setNext] = useState("");
  const [recordCount, setRecordCount] = useState(0);
  const lastPageRetrieved = useRef(false);
  const timer = useRef(0);
  const subscriptions = useRef(options.subscriptions);
  const isMounted = useMountedState();

  url = url || `/${kebabCase(collectionName)}?${debouncedQueryString}`;

  const getAll = useCallback(async (path) => {
    let nxt = path;
    let responses = [];
    while (nxt) {
      const {
        data: { items, $links },
      } = await client.get(nxt);
      setRecordCount((count) => count + items.length);
      responses = responses.concat(items);
      nxt = $links?.find((l) => l.rel === "next")?.href;
    }
    return responses;
  }, []);

  const getItems = useCallback(
    async (path) => {
      const {
        data: { items, $links },
      } = await client.get(path);
      const nxt = $links?.find((l) => l.rel === "next")?.href;
      lastPageRetrieved.current = !nxt;
      isMounted() && setNext(nxt);
      return items;
    },
    [isMounted]
  );

  useEffect(() => {
    if (timer.current) {
      clearTimeout(timer.current);
    }
    timer.current = setTimeout(() => {
      isMounted() && setDebouncedQueryString(queryString);
    }, 100);
    return () => clearTimeout(timer.current);
  }, [isMounted, queryString]);

  useEffect(() => {
    (async () => {
      if (paginate && debouncedQueryString !== "init") {
        setLoading(true);
        setEntities([]);
        getItems(url)
          .then((items) => {
            isMounted() && setEntities(items);
          })
          .catch((e) => {
            isMounted() && setError(e.message);
          })
          .finally(() => {
            isMounted() && setLoading(false);
          });
      }
    })();
  }, [
    debouncedQueryString,
    collectionName,
    getItems,
    reload,
    url,
    paginate,
    isMounted,
  ]);

  useEffect(() => {
    (async () => {
      if (!paginate) {
        setLoading(true);
        try {
          const list = await getAll(url);
          isMounted() && setEntities(list);
        } catch (e) {
          isMounted() && setError(e.message);
        }
        isMounted() && setLoading(false);
      }
    })();
  }, [getAll, isMounted, paginate, url]);

  const refetch = useCallback(async () => {
    try {
      if (paginate) {
        const items = await getItems(url);
        isMounted() && setEntities(items);
      } else {
        const items = await getAll(url);
        isMounted() && setEntities(items);
      }
    } catch (e) {
      isMounted() && setError(e.message);
    }
  }, [getAll, getItems, isMounted, paginate, url]);

  const loadMore = useCallback(async () => {
    if (!lastPageRetrieved.current) {
      setLoading(true);
      try {
        const moreItems = await getItems(next);
        isMounted() && setEntities((ent) => [...ent, ...moreItems]);
      } catch (e) {
        isMounted() && setError(e.message);
      }
      isMounted() && setLoading(false);
    }
  }, [getItems, isMounted, next]);

  useEffect(() => {
    const subs = subscriptions.current;
    subs.forEach(({ event, callback }) => {
      socket.on(event, (message) =>
        callback(message, { collectionName, setEntities, refetch })
      );
    });
    return () => {
      subs.forEach(({ event }) => {
        socket.off(event);
      });
    };
  }, [collectionName]);

  useEffect(() => {
    const handleChange = (message) => {
      if (message.collection === collectionName) {
        refetch();
      }
    };
    if (refetchOnCollectionChange) {
      socket.on(COLLECTION_CHANGED, handleChange);
    }
    return () => {
      socket.off(COLLECTION_CHANGED, handleChange);
    };
  }, [collectionName, refetch, refetchOnCollectionChange]);

  return {
    loadMore,
    entities,
    setEntities,
    loading,
    error,
    refetch,
    recordCount,
    lastPageRetrieved: lastPageRetrieved.current,
  };
};
