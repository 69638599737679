import React from "react";
import {
  BinaryExpressionElement,
  FunctionCallElement,
  LiteralElement,
} from "../Elements";
import { astToExpression } from "../util";
import { AST_ELEMENT_TYPES, TYPE_REFERENCE } from "../constants";

const ElementEditors = {
  Literal: LiteralElement,
  Identifier: LiteralElement,
  BinaryExpression: BinaryExpressionElement,
  ArrayLiteral: LiteralElement,
  ObjectLiteral: LiteralElement,
  FunctionCall: FunctionCallElement,
};

function AstElement({
  schema = {},
  element,
  setElement,
  hasFunction = false,
  isBlank = false,
}) {
  if (
    !element ||
    !(element.type in ElementEditors) ||
    element.type === AST_ELEMENT_TYPES.IDENTIFIER
  ) {
    /*Any element type that does not have a visual editor is converted into an Identifier
    element. The value of the Identifier is the rendered expression for that element.
    This allows all expressions to be supported. Those expressions with element types
    that do not have visual editors will be manually editable within the Identifier editor.*/
    element = {
      type: "Identifier",
      value: astToExpression(element) ?? TYPE_REFERENCE.DEFAULT_VALUE,
    };
  }

  switch (element.type) {
    case AST_ELEMENT_TYPES.FUNCTION_CALL:
      return (
        <FunctionCallElement
          schema={schema}
          element={element}
          setElement={setElement}
          hasFunction={hasFunction}
        />
      );
    case AST_ELEMENT_TYPES.BINARY_EXPRESSION:
      return <BinaryExpressionElement element={element} setElement={setElement} />;
    default:
      return (
        <LiteralElement
          schema={schema}
          element={element}
          setElement={setElement}
          hasFunction={hasFunction}
          isBlank={isBlank}
        />
      );
  }
}

export default AstElement;
