import React from "react";
import clsx from "clsx";
import Tooltip from "@dlx/atoms/Tooltip";

function IconButton({
  onClick = () => {},
  size = 24,
  height = null,
  width = null,
  className = "",
  children,
  dataTestId = "",
  tooltip = "",
  disabled = false,
}) {
  const h = `${height || size}px`;
  const w = `${width || size}px`;
  return (
    <Tooltip text={tooltip}>
      <div
        onClick={onClick}
        className={clsx(
          "grid items-center justify-center rounded-pill",
          !disabled && "pointer",
          dataTestId,
          className
        )}
        style={{ height: h, width: w }}
        data-testid={dataTestId}
      >
        {children}
      </div>
    </Tooltip>
  );
}

export default IconButton;
