import createAuthRefreshInterceptor from "axios-auth-refresh";
import { useEffect } from "react";
import { getStoredAccessToken } from "@dlx/services/tokens";
import { useSessionContext } from "../session/Provider";
import { broadcastTokenExpired } from "../../services/authChannel";

export const AuthRefreshInterceptor = ({ client }) => {
  const { handleExpiredToken, setIsAuthenticated } = useSessionContext();
  useEffect(() => {
    const interceptor = createAuthRefreshInterceptor(
      client,
      /** @param {import("axios").AxiosError} failedRequest */
      async (failedRequest) => {
        setIsAuthenticated(false);
        broadcastTokenExpired();
        await handleExpiredToken();
        const accessToken = getStoredAccessToken();
        failedRequest.response.config.headers["Authorization"] =
          "Bearer " + accessToken;
        return failedRequest.response;
      }
    );
    return () => {
      client.interceptors.response.eject(interceptor);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return null;
};
