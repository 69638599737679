// https://reactjs.org/docs/error-boundaries.html
import React from "react";
import { connect } from "react-redux";
import DLXModal from "../DLXModal/DLXModal";
import styles from "./ErrorBoundary.module.scss";
const COMPONENT_ERROR = "COMPONENT_ERROR";

export class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, errorInfo: "", open: true };
    this.resetApplication = this.resetApplication.bind(this);
  }

  static getDerivedStateFromError(error) {
    return { hasError: true, error };
  }

  componentDidCatch(error, errorInfo) {
    this.props.reportError({ error: error.message, errorInfo });
  }

  resetApplication() {
    if (this.props.noReset) {
      this.setState({ open: false });
    } else {
      window.location = "/";
    }
  }

  render() {
    if (this.state.hasError) {
      return (
        <DLXModal
          open={this.state.open}
          submitButtonLabel={this.props.noReset ? "Close" : "Reset application"}
          size="small"
          onSubmit={this.resetApplication}
          hideCancelButton={true}
          backdropFilter
          title={this.props.title || "Application error"}
        >
          <div className={styles.container}>
            <h1>
              {this.props.message
                ? this.props.message
                : "An unrecoverable error has occured in the Scitara DLX application"}
            </h1>
            <div className={styles.error_message}>
              <b>Location: </b>
              {this.props.component}

              <br />
              <br />
              <b>Error: </b>
              {this.state.error.message}
            </div>
            {!this.props.noReset && (
              <p>
                Please click below or refresh your browser to restart the application
              </p>
            )}
          </div>
        </DLXModal>
      );
    }
    return this.props.children;
  }
}

const mapDispatchToProps = (dispatch) => ({
  reportError: (payload) => dispatch({ type: COMPONENT_ERROR, payload }),
});

export default connect(null, mapDispatchToProps)(ErrorBoundary);
