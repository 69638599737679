export const types = {
  AUTH_FAILURE: "AUTH_FAILURE",
  AUTH_LOADING: "AUTH_LOADING",
  AUTH_LOGOUT: "AUTH_LOGOUT",
  AUTH_LOGOUT_LEADER: "AUTH_LOGOUT_LEADER",
  AUTH_LOGOUT_ALL_SESSIONS: "AUTH_LOGOUT_ALL_SESSIONS",
  AUTH_REACTIVATE_FAILURE: "AUTH_REACTIVATE_FAILURE",
  AUTH_REACTIVATE_SUCCESS: "AUTH_REACTIVATE_SUCCESS",
  AUTH_REACTIVATE: "AUTH_REACTIVATE",
  AUTH_REQUEST: "AUTH_REQUEST",
  AUTH_SUCCESS: "AUTH_SUCCESS",
  USER_IDLE: "USER_IDLE",
  SESSION_EXPIRED: "SESSION_EXPIRED",
  TOKEN_EXPIRED: "TOKEN_EXPIRED",
  AUTHENTICATE: "AUTHENTICATE",
  SET_USER: "SET_USER",
  PWD_RESET_RQD: "PWD_RESET_RQD",
  AUTH_LOGGED_OUT: "AUTH_LOGGED_OUT",
  PASSWORD_RESET_REQUIRED: "PASSWORD_RESET_REQUIRED",
  RESET_PASSWORD_REQUEST: "RESET_PASSWORD_REQUEST",
  PASSWORD_RESET_SUCCESS: "PASSWORD_RESET_SUCCESS",
  PASSWORD_RESET_FAILURE: "PASSWORD_RESET_FAILURE",
};

export const accountRecoveryTypes = {
  REQUEST_VERIFICATION_CODE: "REQUEST_VERIFICATION_CODE",
  REQUEST_VERIFICATION_CODE_SUCCESS: "REQUEST_VERIFICATION_CODE_SUCCESS",
  RESET_PASSWORD: "RESET_PASSWORD",
  RESET_PASSWORD_SUCCESS: "RESET_PASSWORD_SUCCESS",
  RECOVER_ACCOUNT: "RECOVER_ACCOUNT",
};

export const authSuccess = ({ user, status }) => {
  return {
    type: types.AUTH_SUCCESS,
    payload: { user, status, timestamp: Date.now() },
  };
};

export const userIdle = () => ({ type: types.USER_IDLE });
