import React, { useEffect, useState } from "react";
import clsx from "clsx";
import isEmpty from "lodash/isEmpty";

import {
  AstEditor,
  astToExpression,
  expressionToAst,
} from "@dlx/components/ExpressionEditor";
import styles from "../OrchestrationFlow.module.scss";
import { useOrchestrationEditorContext } from "../BaseEditor";

/**
 * @param {{
 * onUpdate: Function,
 * step: Step,
 * stepNum: number
 * }} props
 */
const ConnectionReference = ({ onUpdate, step, stepNum }) => {
  const { executionInfo = {} } = useOrchestrationEditorContext() || {};
  const [context, setContext] = useState(null);
  const [draft, setDraft] = useState(expressionToAst(step?.connection || null));
  const { getStepInputs } = executionInfo;

  useEffect(() => {
    const { connection } = step;
    const expression = astToExpression(draft);
    if (connection && expression !== connection) {
      setDraft(expressionToAst(step.connection || null));
    }
  }, [step]);

  useEffect(() => {
    getStepInputs(stepNum - 1)?.then((data) => {
      if (stepNum === 0) {
        const { steps, ...ctx } = data;
        return setContext(ctx);
      }
      return setContext(data);
    });
  }, [stepNum, getStepInputs]);

  useEffect(() => {
    if (!isEmpty(draft)) {
      handlePath();
    }
  }, [draft]);

  const handlePath = () => {
    const exp = astToExpression(draft);
    if (exp) {
      onUpdate({ connection: exp });
    }
  };

  return (
    <div className={clsx(styles.connectionReferenceHolder)}>
      <div className="flex-auto">
        <AstEditor element={draft} setElement={setDraft} context={context} />
      </div>
    </div>
  );
};

export default ConnectionReference;
