import React from "react";
import clsx from "clsx";
import { ErrorIcon } from "../../icons";
import { colors } from "@dlx/assets/styles";

function Navigation({ navigations, selected, onSelect = null, dataTestId = "" }) {
  return (
    <div
      className="flex items-center bg-secondary-light bb bt border-secondary"
      data-testid={`${dataTestId}_navigation_menu`}
    >
      {navigations?.map((nav, i) => {
        return (
          <div
            className={clsx(
              "flex items-center ml5 pointer bb",
              selected?.label === nav.label
                ? "border-primary text-primary"
                : "border-none text-default"
            )}
            onClick={() => onSelect(nav)}
            key={i}
            data-testid={`${dataTestId}_navigation_${nav.label}`}
          >
            <span className="mr1 pv3 font-default">{nav.label}</span>
            {nav.error && <ErrorIcon size={18} color={colors.bgError} />}
          </div>
        );
      })}
    </div>
  );
}

export default Navigation;
