import React from "react";
import { rankWith, schemaMatches } from "@jsonforms/core";
import { areEqual, withJsonFormsEnumProps } from "@jsonforms/react";
import MuiSelectorControl from "./MuiSelectorControl";

const ConnectionOptionLookupTester = rankWith(
  103, //increase rank as needed
  schemaMatches((schema) => schema.lookup)
);

const ConnectionOptionLookupControl = (props) => {
  props.uischema.options = {
    lookup: {
      command: props.schema.lookup.split("/").reverse()[0],
    },
  };
  return MuiSelectorControl(props);
};

const ConnectionOptionLookup = {
  renderer: withJsonFormsEnumProps(
    React.memo(ConnectionOptionLookupControl, areEqual)
  ),
  tester: ConnectionOptionLookupTester,
};

export default ConnectionOptionLookup;
