export const CANCEL = "CANCEL";
export const CANCELED = "CANCELED";
export const CONFIRM = "CONFIRM";
export const DISABLE_ORCHESTRATION = "DISABLE_ORCHESTRATION";
export const ENABLE_ORCHESTRATION = "ENABLE_ORCHESTRATION";
export const GENERATE_SIGNATURE = "GENERATE_SIGNATURE";
export const LOAD_TABLE_ERROR = "LOAD_TABLE_ERROR";
export const NO_AUTH = "no auth";
export const RESET_ERROR = "RESET_ERROR";
export const SOURCE_ICONS = { orchestration: "sync" };
export const USER_LOGGED_IN = "USER_LOGGED_IN";
export const USER_MISMATCH = "USER_MISMATCH";
