import { useDispatch } from "react-redux";
import { actions } from "../hooks/useApiResource";

export const useGetConnectionsList = () => {
  const dispatch = useDispatch();

  const getConnectionsList = async (connector) => {
    const query = connector
      ? new URLSearchParams({
          compatibleWithConnectorId: connector,
        }).toString()
      : "";
    const response = await dispatch(
      actions.apiTransaction({
        route: `/lookups/connections?${query}`,
      })
    );
    return response?.items?.[0]?.options ? response.items[0].options : [];
  };

  return { getConnectionsList };
};
