import { createSlice } from "@reduxjs/toolkit";
import { initialState, reducer } from "../reducers/dbAdapter";
import { types as socketTypes } from "../socket/socket.actions";
import { createPromiseAction } from "@adobe/redux-saga-promise";
import { useDispatch } from "react-redux";
import { useCurrentUserContext } from "../../contexts/current-user/Provider";
import { useCallback } from "react";

export const storeFile = createPromiseAction(
  socketTypes.WS_STORE_FILE,
  (payload) => payload
);

const tableName = "filestore";

const slice = createSlice({
  name: tableName,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    reducer(tableName)(builder);
  },
});

export const useStoreFile = () => {
  const dispatch = useDispatch();
  const { currentUser } = useCurrentUserContext();
  return useCallback(
    (filePackage) => {
      return dispatch(
        storeFile({
          filePackage,
          context: {
            user: currentUser.id,
          },
        })
      );
    },
    [currentUser, dispatch]
  );
};
export default slice.reducer;
