import React, { useState } from "react";
import { RunIcon } from "../../../icons";

import Popover, { POPOVER_POSITIONS } from "@dlx/atoms/Popover";
import JsonViewer from "@dlx/components/jsonviewer";

import { useEditorContext } from "../context";
import { IconButton } from "../Wrappers";
import { getDataType } from "../util";
import { VALUE_TYPES, TYPE_REFERENCE } from "../constants";
import clsx from "clsx";

function ExecuteElement({ element, tooltip }) {
  const { output, outputError, isEvaluating, evaluateExpression } = useHelpers({
    element,
  });
  const disabled = !element.type || element.value === TYPE_REFERENCE.DEFAULT_VALUE;
  const dataType = getDataType(output);
  let outputDisplay = output;

  //This code block is needed to cover the cases which can't be displayed by JsonViewer
  if (output !== null) {
    if (dataType === VALUE_TYPES.OBJECT && Object.keys(output).length === 0) {
      //Display empty Object
      outputDisplay = "{ }";
    } else if (dataType === VALUE_TYPES.ARRAY && output.length === 0) {
      //Display empty Array
      outputDisplay = "[ ]";
    } else if (dataType === VALUE_TYPES.BOOLEAN) {
      //Display Boolean
      outputDisplay = String(output);
    } else if (dataType === VALUE_TYPES.STRING) {
      //Add quotes if dataType is string
      outputDisplay = `"${output}"`;
    }
  } else {
    outputDisplay = "null";
  }

  if (disabled) {
    return (
      <IconButton
        className="bg-secondary text-contrast"
        size={32}
        dataTestId="dtid_test_expression"
        tooltip={tooltip}
        disabled={true}
      >
        <RunIcon size={28} />
      </IconButton>
    );
  }

  return (
    <Popover position={POPOVER_POSITIONS.BOTTOM_RIGHT}>
      <IconButton
        onClick={evaluateExpression}
        className="bg-primary bg-hover-primary-dark text-contrast"
        size={32}
        dataTestId="dtid_test_expression"
        tooltip={tooltip}
      >
        <RunIcon size={28} />
      </IconButton>

      <div className="bb border-secondary pa3 capitalize b">Output</div>
      <OutputDisplay
        isEvaluating={isEvaluating}
        outputError={outputError}
        outputDisplay={outputDisplay}
      />
    </Popover>
  );
}

function OutputDisplay({ isEvaluating, outputError, outputDisplay }) {
  if (isEvaluating) {
    return (
      <div className="pa3 text-secondary mb1">Evaluating the expression...</div>
    );
  }
  if (outputError) {
    return (
      <div className="text-error pa3">
        {Array.isArray(outputError.messages)
          ? outputError.messages.map((msg, index) => {
              const isFirst = index === 0;
              return (
                <div
                  key={index}
                  className={clsx("mb1", {
                    b: isFirst,
                  })}
                >
                  {!isFirst && "- "}
                  {msg}
                </div>
              );
            })
          : outputError}
      </div>
    );
  }
  if (typeof outputDisplay === VALUE_TYPES.OBJECT) {
    return (
      <div className="ph3 pb2">
        <JsonViewer json={outputDisplay} trail={true} />
      </div>
    );
  }
  return (
    <div className="pa3 mb1" style={{ color: "#40af6c" }}>
      {outputDisplay}
    </div>
  );
}

function useHelpers({ element }) {
  const [output, setOutput] = useState(null);
  const [outputError, setOutputError] = useState("");
  const [isEvaluating, setIsEvaluating] = useState(false);

  const { context, executeAst } = useEditorContext();

  const evaluateExpression = async () => {
    setIsEvaluating(true);
    setOutputError("");
    const response = await executeAst(element, context);
    setIsEvaluating(false);
    setOutputError(response.error);
    setOutput(response.data);
  };

  return {
    output,
    outputError,
    isEvaluating,
    evaluateExpression,
  };
}

export default ExecuteElement;
