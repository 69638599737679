import camelCase from "camelcase";
import ConfirmationDialog from "../../components/ConfirmDialog/ConfirmationDialog";
import { Modal as OrchestrationExecutionViewerModal } from "../../views/OrchestrationsView/OrchestrationExecutionViewer";

export const modals = {};
export const actions = {};

export const SHOW_MODAL = "SHOW_MODAL";
export const HIDE_MODAL = "HIDE_MODAL";

export function show(name, props) {
  return {
    type: SHOW_MODAL,
    payload: {
      name,
      props,
    },
  };
}

export function hide(name, props) {
  return {
    type: HIDE_MODAL,
    payload: {
      name,
      props,
    },
  };
}

function register(component, componentName) {
  const name = camelCase(componentName);
  modals[name] = component;
  return {
    show: show.bind(undefined, name),
    hide: hide.bind(undefined, name),
  };
}

const modalActions = {
  confirmationDialog: register(ConfirmationDialog, "ConfirmationDialog"),
  orchestrationExecutionViewerModal: register(
    OrchestrationExecutionViewerModal,
    "OrchestrationExecutionViewerModal"
  ),
};

export default modalActions;
