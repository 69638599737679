import React from "react";
import { useDispatch } from "react-redux";
import useUrlParams from "../../../state/hooks/useUrlParams";
import LoadingScreen from "../../../components/LoadingScreen";
import { useCurrentUserContext } from "../../../contexts/current-user/Provider";

export default function UrlTriggeredOrchestration() {
  const params = useUrlParams();
  const dispatch = useDispatch();
  const {
    currentUser: { permissions },
  } = useCurrentUserContext();

  React.useEffect(() => {
    const { orchestrationId, queryParams } = params;

    Object.entries(queryParams).forEach(([k, v]) => {
      try {
        queryParams[k] = JSON.parse(v);
      } catch (error) {
        //Not a parseable object
      }
    });

    dispatch({
      type: "TRIGGER_ORCHESTRATION",
      payload: {
        id: orchestrationId,
        data: queryParams,
        source: "url",
        permissions,
      },
    });
  }, [dispatch, params, permissions]);

  return <LoadingScreen />;
}
