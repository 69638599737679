import React, { useEffect, useRef } from "react";

function ConfirmInline({ children, onClose, className = "" }) {
  const timer = useRef();
  useEffect(() => {
    timer.current = setTimeout(onClose, 5000);
    return () => {
      clearTimeout(timer.current);
    };
  });
  return (
    <div className={className} onClick={onClose}>
      {children}
    </div>
  );
}

export default ConfirmInline;
