import React, { useState, useEffect } from "react";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { useGetConnectionsList } from "../../../state/connections/useGetConnectionsList";
import { TextField } from "@material-ui/core";
import { useGetConnector } from "../../../state/hooks";

function ConnectionSelector({ connectorId, setConnectionId }) {
  const [connectionsList, setConnectionsList] = useState(null);
  const { getConnectionsList } = useGetConnectionsList();
  const { connector, fetched: connectorFetched } = useGetConnector(connectorId);

  useEffect(() => {
    let mounted = true;
    if (mounted && connectorFetched && !connectionsList) {
      getConnectionsList(connectorId).then((list) => {
        setConnectionsList(list);
      });
    }
    return () => {
      mounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [connectorId, connectionsList, connectorFetched]);

  const label =
    connectorFetched && connectionsList && connector
      ? `Select a ${connector.name}`
      : "Loading...";

  const handleOnChange = (_event, selected) => setConnectionId(selected?.value);

  return (
    <Autocomplete
      id="connection-selector"
      options={connectionsList || []}
      renderInput={(params) => <TextField {...params} label={label} />}
      getOptionLabel={(opt) => opt.label}
      onChange={handleOnChange}
    />
  );
}

export default ConnectionSelector;
