import { put, call, takeLatest, takeEvery } from "redux-saga/effects";
import { logger } from "../../utils";
import { implementPromiseAction } from "@adobe/redux-saga-promise";
import {
  connectionStatus,
  storeConnectionSecrets,
} from "../connections/connections.reducer";
import { reading } from "../connections/readings.reducer";
import { dbRecordDeleted, dbRetrieveRecord } from "../reducers/dbAdapter";
import { storeFile } from "../../state/filestores/filestores.reducer";
import {
  types as socketTypes,
  socketApiEvents,
  SOCKET_MESSAGE,
} from "../socket/socket.actions";
import {
  manualTrigger,
  triggerIOCompletion,
} from "../orchestrations/orchestration.reducer";
import { socket, sendMessage } from "../../services/socket";
import { userInteractionCompleted } from "../executions/reducer";
import { ORCHESTRATION_INTERACTION_COMPLETED } from "../executions/constants";
import {
  createOrcTriggeredChannel,
  triggerAndWaitForExecution,
} from "./manualTrigger.saga";
import {
  createConnectionActionChannel,
  sendCommandAndWaitForResponse,
  sendConnectionActionRequest,
} from "./connectionAction.saga";

const log = logger("socket:saga");

function* handleIncomingMessage({ payload }) {
  log("handleIncomingMessage", { payload });
  try {
    const { event, data } = payload;
    switch (event) {
      case socketApiEvents.CONNECTION_READING:
        yield put(reading(data));
        break;
      case socketApiEvents.COLLECTION_CHANGED:
        yield handleCollectionChange(data);
        break;
      case socketApiEvents.CONNECTION_STATUS_UPDATED:
        yield put(connectionStatus(data));
        break;
      default:
        break;
    }
  } catch (error) {
    log(`Socket message error: ${error.message}`, payload);
    yield put({ type: socketTypes.WS_ERROR, payload, message: error.message });
  }
}

function* handleCollectionChange(data) {
  log("handleCollectionChange", data);
  const { action, documentId, collection } = data;
  switch (action) {
    case "created":
    case "updated":
      yield put(
        dbRetrieveRecord({
          tableName: collection,
          id: documentId,
          label: "ws",
        })
      );
      break;
    case "deleted":
      yield put(
        dbRecordDeleted({
          tableName: collection,
          id: documentId,
          label: "ws",
        })
      );
      break;
    default:
      log("unrecognized collection change event", data);
  }
}

function* messageTask({ payload }) {
  const { type, message } = payload;
  yield call(sendSocketMessage, type, message);
}

function* triggerOrchestration(action) {
  const { type, message } = action.payload;
  yield call(implementPromiseAction, action, function* () {
    return yield call(sendSocketMessage, type, message);
  });
}

function* interactionCompletedTask(action) {
  yield call(implementPromiseAction, action, function* () {
    return yield call(
      sendSocketMessage,
      ORCHESTRATION_INTERACTION_COMPLETED,
      action.payload
    );
  });
}

function* secretsTask(action) {
  yield call(implementPromiseAction, action, function* () {
    return yield call(
      sendSocketMessage,
      socketApiEvents.STORE_SECRETS,
      action.payload
    );
  });
}

function* storeFileTask(action) {
  yield call(implementPromiseAction, action, function* () {
    return yield call(sendSocketMessage, socketApiEvents.STORE_FILE, action.payload);
  });
}

export function* sendSocketMessage(event, message) {
  return yield call(sendMessage, event, message);
}

export default function* websocketSaga() {
  log("socketWatcher");
  yield takeEvery(SOCKET_MESSAGE, handleIncomingMessage);
  yield takeLatest(socketTypes.WS_SEND_MESSAGE, messageTask);
  yield takeEvery(triggerIOCompletion, triggerOrchestration);
  yield takeEvery(userInteractionCompleted, interactionCompletedTask);
  yield takeEvery(storeConnectionSecrets, secretsTask);
  yield takeEvery(storeFile, storeFileTask);
  const triggerChan = yield call(createOrcTriggeredChannel, socket);
  yield takeEvery(manualTrigger, triggerAndWaitForExecution, triggerChan);
  const connectionActionChan = yield call(createConnectionActionChannel, socket);
  yield takeEvery(
    sendConnectionActionRequest,
    sendCommandAndWaitForResponse,
    connectionActionChan
  );
}
