import Button from "@dlx/atoms/Button";
import React, { useState } from "react";
import { OrchestrationIcon } from "../../icons";
import ConfirmDialog from "../ConfirmDialog";

/**
 * @param {{
 * disabled: boolean,
 * upgradeOrchestration: Function,
 * setHideBanner: Function,
 * }} props
 */
const OrchestrationUpdateBanner = ({
  disabled,
  upgradeOrchestration,
  setHideBanner,
}) => {
  const [showConfirm, setShowConfirm] = useState(false);

  return (
    <div
      className="flex items-center text-contrast pa4"
      style={{ backgroundColor: "#2a3e49" }}
    >
      {showConfirm && (
        <ConfirmDialog
          title="Are you sure you want to upgrade the Orchestration?"
          messageText={
            <span>This upgrade cannot be reversed once it is completed</span>
          }
          open={showConfirm}
          onClickNo={() => setShowConfirm(false)}
          onClickYes={() => {
            setShowConfirm(false);
            upgradeOrchestration();
          }}
          submitButtonLabel="Confirm Upgrade"
          cancelButtonLabel="Cancel"
        />
      )}
      <div
        className="flex items-center flex-auto"
        data-testid="dtid_orchestration_update_banner"
      >
        <div>
          <OrchestrationIcon height="30" width="30" />
        </div>
        <div className="pl4">
          <div className="b">
            This orchestration can be updated to support new features
          </div>
          <div className="pt2 font-small">
            The update includes error handling and updates to the data available for
            referencing during execution.
          </div>
        </div>
      </div>
      <div>
        <Button
          className="pv2 ph3 bg-primary-light"
          dataTestId="dtid_orchestration_update_banner_button"
          onClick={() => setShowConfirm(!disabled)}
          disabled={disabled}
        >
          Update
        </Button>
        <Button
          className="pv2 ml3 text-contrast"
          dataTestId="dtid_orchestration_update_banner_remind_me_later"
          onClick={() => setHideBanner(true)}
        >
          Remind Me Later
        </Button>
      </div>
    </div>
  );
};

export default OrchestrationUpdateBanner;
