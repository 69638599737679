import React from "react";
import { rankWith, uiTypeIs } from "@jsonforms/core";
import {
  areEqual,
  withJsonFormsCellProps,
  withJsonFormsControlProps,
} from "@jsonforms/react";
import MuiSelectorControl from "./MuiSelectorControl";

const EnumSelectorTester = rankWith(
  103, //increase rank as needed
  uiTypeIs("DlxCustomSelector")
);

const EnumSelector = (props) => {
  props.uischema.options = {
    ...props.uischema.options,
    labelRequiresLookup: !props.uischema.options.simple,
    selections: props.uischema.options.items,
  };
  return MuiSelectorControl(props);
};

export const EnumSelectorControl = {
  renderer: withJsonFormsControlProps(React.memo(EnumSelector, areEqual)),
  tester: EnumSelectorTester,
};

export const EnumSelectorCell = {
  cell: withJsonFormsCellProps(React.memo(EnumSelector, areEqual)),
  tester: rankWith(1000, uiTypeIs("DlxCustomSelector")),
};
