export const ORCHESTRATION_COMPLETED = "orchestration:completed";
export const ORCHESTRATION_FAILED = "orchestration:failed";
export const ORCHESTRATION_STEP_FAILED = "orchestration:step-failed";
export const ORCHESTRATION_ABORTED = "orchestration:aborted";
export const ORCHESTRATION_EXECUTION_UPDATE = "orchestration:execution-update";
export const ORCHESTRATION_INTERACTION_COMPLETED =
  "orchestration:interaction-completed";
export const ORCHESTRATION_STEP_STARTED = "orchestration:step-started";
export const ORCHESTRATION_STEP_COMPLETED = "orchestration:step-completed";
export const ORCHESTRATION_TRIGGERED = "orchestration:triggered";
export const ORCHESTRATION_INTERACTION_REQUESTED =
  "orchestration:interaction-requested";

export const ORCHESTRATION_STATUS = Object.freeze({
  EXECUTING: "executing",
  COMPLETED: "completed",
  ABORTED: "aborted",
  FAILED: "failed",
});
