const api = {
  list: () => ({
    method: "get",
    url: "/executions",
  }),
  get: ({ id }) => ({
    method: "get",
    url: `/executions/${id}`,
  }),
  getTriggerOutput: ({ id }) => ({
    method: "get",
    url: `/executions/${id}/trigger-output`,
  }),
  getStepOutput: ({ id, stepId }) => ({
    method: "get",
    url: `/executions/${id}/step-output/${stepId}`,
  }),
  mappingSources: ({ id, stepNumber }) => ({
    method: "get",
    url: `/executions/${id}/expression-sources${
      stepNumber !== undefined ? `?stepNumber=${stepNumber}` : ""
    }`,
  }),
  getStepInputs: (id, { stepNumber }) => ({
    method: "get",
    url:
      `/executions/${id}/expression-sources` + stepNumber
        ? `stepNumber=${stepNumber}`
        : "",
  }),

  getTriggerSources: (id) => ({
    method: "get",
    url: `/executions/${id}/trigger-sources`,
  }),
};

export default api;
