import React from "react";
import styles from "./StepChip.module.scss";

/**
 * @param {{
 * index: number,
 * trigger: boolean,
 * }} props
 */
const StepIndex = ({ index, trigger }) => {
  let indexBubble = null;
  if (!trigger) {
    indexBubble = (
      <div className={styles.stepIndex}>
        <div>{index}</div>
      </div>
    );
  }
  return indexBubble;
};

export default StepIndex;
