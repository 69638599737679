import NiceModal, { useModal } from "@ebay/nice-modal-react";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import React, { useCallback, useState } from "react";

import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";

function getTilteAndButtonText(requireSignatures, requireReasonForChange) {
  let title = "Signature Required";
  let buttonText = "Sign";
  if (requireSignatures && requireReasonForChange) {
    title = "Signature and Reasons for change Required";
  } else if (requireReasonForChange) {
    title = "Reasons for change Required";
    buttonText = "Continue";
  }
  return {
    title,
    buttonText,
  };
}

export const EsignDialogModal = NiceModal.create(
  /** @param {{
   * onSubmit: function,
   * requireReasonForChange: boolean,
   * requireSignatures: boolean,
   * reasons: []
   *}} props */
  ({ onSubmit, requireReasonForChange, requireSignatures, reasons }) => {
    const modal = useModal();
    const [isLoading, setIsLoading] = useState(false);
    const [reason, setReason] = useState();
    const [error, setError] = useState(false);

    const { title, buttonText } = getTilteAndButtonText(
      requireSignatures,
      requireReasonForChange
    );

    const handleOnSubmit = useCallback(async () => {
      try {
        if (!reason && requireReasonForChange) {
          setError("Reason for change is required");
          return;
        }
        setIsLoading(true);
        await onSubmit(reason).then(modal.resolve);
      } catch (err) {
        modal.reject(err);
      }
      setIsLoading(false);
      modal.hide();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reason]);

    const handleOnCancel = () => {
      setIsLoading(false);
      const channel = new BroadcastChannel("dlx");
      channel.postMessage({ action: "cancel-esign" });
      modal.reject(new Error("Signature canceled"));
      channel.close();
      modal.hide();
    };

    return (
      <Dialog open={modal.visible}>
        <DialogTitle
          style={{
            border: "1px solid #dadce0",
            borderTopLeftRadius: "6px",
            borderTopRightRadius: "6px",
            backgroundColor: "#f6f6f6",
          }}
        >
          {title}
        </DialogTitle>
        <DialogContent>
          {requireReasonForChange ? (
            <ReasonsForChange
              requireSignatures={requireSignatures}
              reasons={reasons}
              setReason={setReason}
              error={error}
            />
          ) : (
            <Typography>
              Please verify your identity to continue with the operation.
            </Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleOnCancel}>Cancel</Button>
          <Button
            variant="contained"
            color="primary"
            disableElevation
            disableRipple
            disabled={isLoading}
            onClick={handleOnSubmit}
            style={{
              textTransform: "capitalize",
            }}
          >
            {buttonText}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
);

const ReasonsForChange = ({ requireSignatures, reasons, setReason, error }) => {
  let instructions = `Please specify the reason for change to continue with the operation`;
  if (requireSignatures) {
    instructions = `Please specify the reason for change and verify your identity to continue with the operation`;
  }
  return (
    <React.Fragment>
      <Typography>{instructions}</Typography>
      <Select fullWidth onChange={(e) => setReason(e.target.value)} error={error}>
        {reasons.map((r) => (
          <MenuItem key={r.id} value={r.text}>
            {r.text}
          </MenuItem>
        ))}
      </Select>
      {error && <div className="text-error font-small mt2">{error}</div>}
    </React.Fragment>
  );
};
