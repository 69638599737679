import useGetCollection from "../hooks/useGetCollection";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { dbRetrieveRecord } from "../reducers/dbAdapter";
import { actions } from "../hooks/useApiResource";

const slice = "connectors";

export const useGetConnectorInstallerDownloadUrl = (id) => {
  const dispatch = useDispatch();
  const [url, setUrl] = useState();
  const { connector } = useGetConnector(id);
  const [error, setError] = useState();

  useEffect(() => {
    const getUrl = async () => {
      const response = await dispatch(
        actions.apiTransaction({
          route: `/connectors/${id}/download-url`,
        })
      );
      setError(response.error);
      setUrl(response.error ? "" : response.url);
    };
    getUrl();
    // eslint-disable-next-line
  }, [connector]);
  return { notAvailable: error, downloadUrl: url };
};

export default function useGetConnector(id) {
  const dispatch = useDispatch();
  const connector = useSelector((state) => {
    if (!id || !state.connectors) {
      return null;
    }
    return state.connectors.entities[id];
  });

  const fetched = useSelector((state) => {
    if (!id || !state.connectors) {
      return false;
    }
    return state.connectors.fetched[id];
  });

  useEffect(() => {
    if (!fetched && id) {
      dispatch(dbRetrieveRecord({ tableName: "connectors", id }));
    }
  }, [dispatch, id, connector, fetched]);
  return { fetched, connector };
}
const filterResults = (list, filters) => {
  return list.filter((item) => {
    if (filters.state) {
      return item.state === filters.state;
    }
    return true;
  });
};

export function useGetConnectorsCollection(filters) {
  const [loaded, connectors] = useGetCollection(slice, filters);
  if (filters) {
    return { loaded, connectors: filterResults(connectors, filters) };
  }
  return { loaded, connectors };
}
