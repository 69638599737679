import React, { useState } from "react";
import clsx from "clsx";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { makeStyles } from "@material-ui/core/styles";
import { CopyIcon } from "../../icons";
import { InputUtility } from ".";

export const INPUT_VARIANTS = Object.freeze({
  OUTLINED: "outlined",
  STANDARD: "standard",
});

const useStyles = makeStyles(() => ({
  adornTextField: {
    width: "100%",
  },
  noBorderedTextField: {
    padding: "7.5px 14px",
  },
  copyIcon: {
    height: 30,
    fontSize: 14,
  },
}));

/**
 * InputText component.
 * @component
 * @param {{
 *   testId: string,
 *   label: string,
 *   name: string,
 *   type: string,
 *   error: string,
 *   readOnly: Boolean,
 *   value: string,
 *   multiline: Boolean,
 *   maxRows: Number,
 *   onChange: Function,
 *   utility: string,
 *   variant?: string,
 *   placeholder?: string,
 *   styles?: object
 * }} props
 * @returns {JSX.Element} - The rendered InputText component.
 */
const InputText = ({
  testId = "",
  label = "",
  name,
  type = "text",
  error,
  readOnly = false,
  value = "",
  multiline = false,
  maxRows = 4,
  onChange = () => {},
  utility = "",
  variant = INPUT_VARIANTS.OUTLINED,
  placeholder = "",
  styles = {},
  onBlur = null,
}) => {
  const classes = useStyles();

  const [valueType, setValueType] = useState(type);
  const [showPassword, setShowPassword] = useState(false);

  const handleClickCopy = () => {
    navigator.clipboard.writeText(value);
  };

  const handleMouseDownPassword = () => {
    setValueType("text");
    setShowPassword(true);
  };
  const handleMouseUpPassword = () => {
    setValueType(type);
    setShowPassword(false);
  };

  return (
    <TextField
      label={label}
      error={!!error}
      helperText={error}
      size="small"
      type={valueType}
      variant={variant}
      placeholder={placeholder}
      id={`${label}_id`}
      name={name}
      value={value}
      multiline={multiline}
      maxRows={maxRows}
      onChange={onChange}
      onBlur={onBlur ?? onChange}
      autoFocus={variant !== INPUT_VARIANTS.STANDARD}
      className={clsx(
        classes.adornTextField,
        variant === INPUT_VARIANTS.STANDARD && classes.noBorderedTextField
      )}
      inputProps={{
        "data-testid": testId,
      }}
      InputProps={{
        disableUnderline: variant === INPUT_VARIANTS.STANDARD,
        style: styles,
        readOnly: readOnly,
        endAdornment: (
          <InputAdornment position="end">
            {utility === InputUtility.DISPLAY_PASSWORD && (
              <IconButton
                data-testid={`${testId}_display_password`}
                onMouseDown={handleMouseDownPassword}
                onMouseUp={handleMouseUpPassword}
              >
                {showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            )}

            {utility === InputUtility.COPY_VALUE && (
              <IconButton
                data-testid={`${testId}_copy_value`}
                onClick={() => handleClickCopy()}
                className={classes.copyIcon}
              >
                <CopyIcon />
              </IconButton>
            )}
          </InputAdornment>
        ),
      }}
    />
  );
};

export default InputText;
