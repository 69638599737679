import React, { useEffect, useState } from "react";
import { MultiSelect, SingleSelect } from "../DLXFilterBar/controls";
import Button, { BUTTON_VARIANTS } from "@dlx/atoms/Button";
import { DeleteIcon } from "../../icons";

export default function LabelsListFilter({
  options,
  loading,
  label,
  value,
  updateValue,
  optionIdValueMap,
}) {
  const [labels, setLabels] = useState(value || []);

  const handleAddMore = () => {
    setLabels([...labels, { name: "" }]);
  };

  const updateTags = (i, data) => {
    const updatedValue = [...labels];
    updatedValue[i] = data;
    setLabels(updatedValue);
    updateValue(updatedValue);
  };

  const handleRemove = (index) => {
    labels.splice(index, 1);
    setLabels([...labels]);
    updateValue([...labels]);
  };

  const getOptionList = (current) => {
    const selectedLabels =
      value.map((l) => l.name).filter((name) => name !== current.name) || [];
    return options.filter((opt) => !selectedLabels.includes(opt.label));
  };

  return (
    <div>
      {loading ? (
        <div className="pa4">Fetching {label}...</div>
      ) : (
        <div>
          {labels.length &&
            labels.map((tag, i) => {
              return (
                <div
                  className="pv4 ph3 flex items-center bg-hover-secondary-light"
                  key={tag?.name + i}
                >
                  {labels.length > 1 && (
                    <div
                      className="mr3 rounded4 tc bg-primary-light text-primary"
                      style={{ lineHeight: "24px", width: "24px" }}
                    >
                      {i + 1}
                    </div>
                  )}
                  <LabelTags
                    index={i}
                    value={tag}
                    options={getOptionList(tag)}
                    loading={loading}
                    updateTags={updateTags}
                    optionIdValueMap={optionIdValueMap}
                  />
                  {labels.length > 1 && (
                    <span
                      data-testid={`dtid_delete_tag_${i}`}
                      className="ml3 pointer text-secondary text-hover-primary"
                      onClick={() => handleRemove(i)}
                    >
                      <DeleteIcon size={24} color="inherit" />
                    </span>
                  )}
                </div>
              );
            })}
          {!labels.length && (
            <div className="pb3">
              <LabelTags
                index={0}
                options={options}
                loading={loading}
                updateTags={updateTags}
                optionIdValueMap={optionIdValueMap}
              />
            </div>
          )}
        </div>
      )}
      {!loading && labels.length < 10 && (
        <Button
          className="mt3 ml2 text-primary"
          variant={BUTTON_VARIANTS.TEXT}
          onClick={handleAddMore}
        >
          + Add Label
        </Button>
      )}
    </div>
  );
}

export const LabelTags = ({ index = 1, value, updateTags, options, loading }) => {
  const [labelValue, setLabelValue] = useState(value);
  const [selectedValues, setSelectedValues] = useState(value?.value || []);

  useEffect(() => {
    let labelName;
    if (selectedValues.length > 2) {
      labelName = ` (${selectedValues.length} selected)`;
    } else {
      labelName = ` (${selectedValues.join(" or ")})`;
    }
    const label =
      labelValue?.name &&
      `${labelValue.name}${selectedValues.length ? labelName : ""}`;

    updateTags(index, {
      label: label,
      name: labelValue?.name,
      value: selectedValues,
    });
  }, [selectedValues, labelValue]);

  return (
    <div>
      <SingleSelect
        setValue={setLabelValue}
        options={options}
        loading={loading}
        value={labelValue}
        placeholder="Search labels"
      />
      <div className="pt2">
        <MultiSelect
          freeSolo={true}
          placeholder="Type and press enter to add value"
          defaultValue={selectedValues}
          selectedData={selectedValues}
          setSelectedData={setSelectedValues}
          loading={loading}
          checkBoxOptions={false}
        />
      </div>
    </div>
  );
};
