import React, { useEffect, useState } from "react";
import clsx from "clsx";

import {
  ArrayEditor,
  BooleanEditor,
  NumberEditor,
  ObjectEditor,
  ReferenceEditor,
  StringEditor,
} from "../Editors";

import { BaseTypeSelector } from "../Selectors";
import { ElementActions, ExpressionNode } from "../Wrappers";
import {
  getAstElementFromValueType,
  getAstElementValueType,
  getDataType,
} from "../util";
import { TYPE_REFERENCE } from "../constants";
import { useEditorContext } from "../context";

const ValueEditors = {
  reference: ReferenceEditor,
  string: StringEditor,
  number: NumberEditor,
  boolean: BooleanEditor,
  array: ArrayEditor,
  object: ObjectEditor,
};

function LiteralElement({
  schema,
  element,
  setElement,
  hasFunction,
  isBlank = false,
}) {
  //outputType controls the availability of a function in the list
  const [outputType, setOutputType] = useState(null);
  const { context, executeAst } = useEditorContext();
  const dataType = getDataType(element.value);

  useEffect(() => {
    if (
      element.type === TYPE_REFERENCE.TYPE &&
      element.value !== TYPE_REFERENCE.DEFAULT_VALUE
    ) {
      //Evaluate the reference type value for setting the outputType
      context &&
        executeAst(element, context).then(({ data, error: evaluationError }) => {
          if (evaluationError) {
            setOutputType(schema.type);
          } else {
            setOutputType(getDataType(data));
          }
        });
    } else {
      //If it is not a reference, the default outputType is the data type of element value
      setOutputType(dataType);
    }
  }, [element.value, context]);

  const handleBaseTypeSelect = ({ type, value }) => {
    setElement({ type, value });
  };

  const handleSetElement = (updates) => {
    setElement(updates);
  };

  const handleClearValue = () => {
    const blankElement = getAstElementFromValueType(dataType);
    setElement(blankElement);
  };

  const valueType = getAstElementValueType(element);
  const ValueEditor = ValueEditors[valueType];
  return (
    <ExpressionNode
      className={clsx(
        { "rounded-tr0 rounded-br0": hasFunction },
        "dtid_literal_expression_element"
      )}
    >
      {/*
          ElementActions provides:
          1. Clear value and add transform menu functions.
      */}
      <ElementActions
        element={element}
        setElement={setElement}
        deleteElement={handleClearValue}
        functionInputType={outputType}
        hasFunction={hasFunction}
      >
        {/* BaseTypeSelector provides a menu for selecting base value data type  */}
        <BaseTypeSelector
          value={valueType}
          onSelect={handleBaseTypeSelect}
          error={element.error}
        />

        {/* ValueEditor can be a component from ValueEditors based on the data type selected by BaseTypeSelector */}
        <ValueEditor
          schema={schema}
          element={element}
          setElement={handleSetElement}
          isBlank={isBlank}
        />
      </ElementActions>
    </ExpressionNode>
  );
}

export default LiteralElement;
