import { io } from "socket.io-client";
import config from "config";
export const socket = io(`${config.apiUrl}/user`, {
  autoConnect: false,
  reconnection: true,
  timeout: 20000,
  transports: ["websocket"],
});

const SOCKET_TIMEOUT = 5 * 1000;
/** @param {string} event */
/** @param {any} message */
export const sendMessage = async (event, message) => {
  await shareSocket();
  return new Promise((resolve, reject) => {
    let id = null;
    socket.emit(event, message, (err, data) => {
      clearTimeout(id);
      err ? reject(err) : resolve(data);
    });
    id = setTimeout(() => {
      reject(new Error("socket timed out"));
    }, SOCKET_TIMEOUT);
  });
};

const shareSocket = () => {
  return navigator.locks.request("socket", { mode: "shared" }, () =>
    Promise.resolve()
  );
};

export const lockSocket = () => {
  let [resolve, reject] = [null, null];
  const promise = new Promise((...args) => {
    [resolve, reject] = args;
  });
  navigator.locks.request("socket", { mode: "exclusive" }, () => {
    return promise;
  });
  return { resolve, reject };
};

export default socket;
