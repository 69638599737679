import React from "react";
import DefaultEditor from "./DefaultEditor";

function StringEditor({ schema, element, setElement, isBlank }) {
  return (
    <DefaultEditor
      schema={schema}
      element={element}
      setElement={setElement}
      inputType="text"
      isBlank={isBlank}
    />
  );
}

export default StringEditor;
