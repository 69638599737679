import { createSelector } from "@reduxjs/toolkit";

export const getOrchestrationSelector = (state, id) =>
  state.orchestrations.entities[id];
export const getOrchestrationStepSelector = (state, id, stepNum) =>
  state.orchestrations.entities[id].steps[stepNum];
export const getOrchestrationTriggerSelector = (state, id) =>
  state.orchestrations.entities[id].trigger;
export const getOrchestrations = createSelector(
  (state) => state.orchestrations.ids,
  (state) => state.orchestrations.entities,
  (ids, entities) =>
    ids
      .map((id) => entities[id])
      .sort(function (a, b) {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      })
      .sort((a, b) => {
        if (b.status === "enabled") {
          return -1;
        }
        if (a.status === "enabled") {
          return 1;
        }
        return 0;
      })
      .filter(Boolean)
);

export const getOrchestrationEntities = (state) => state.orchestrations.entities;

export const getOrchestrationLoadingStatus = (state) => state.orchestrations.loading;
