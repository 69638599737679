import "ace-builds/src-noconflict/ext-language_tools";
import "ace-builds/src-noconflict/mode-json";
import "ace-builds/src-noconflict/theme-github";
import React, { useState, useEffect } from "react";
import styles from "./SchemaInput.module.scss";
import AceEditor from "react-ace";
import "ace-builds/webpack-resolver";
import ace from "ace-builds/src-noconflict/ace";
import clsx from "clsx";
import { IconButton } from "@material-ui/core";
import UserInputInterface from "../../../OrchestrationFlow/UserInputInterface";
import JsonViewer from "../../../jsonviewer/JsonViewer";
import DLXModal from "../../../DLXModal/DLXModal";
import { useLookup } from "../../../OrchestrationFlow/useLookup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DLXTooltip from "../../../DLXTooltip/DLXTooltip";
import { InputDataSelector } from "../../../SourceDataSelector/SourceDataSelector";
import LookupSelectorsMenu from "./LookupSelectorsMenu";
import ErrorBoundary from "../../../ErrorBoundary";
import { TimesIcon } from "../../../../icons";
import { useOrchestrationEditorContext } from "../../BaseEditor";

ace.config.set("basePath", "ace-builds/src-noconflict");

const schemaPlaceholder = {
  type: "object",
  properties: {
    name: {
      type: "string",
      default: "Test",
    },
  },
  required: ["name"],
};

const uiSchemaPlaceholder = {
  type: "Group",
  label: "This is the form label",
  elements: [
    {
      type: "Control",
      label: "You can add a label here",
      scope: "#/properties/name",
      options: {},
    },
  ],
};

const formatJSON = (data) => JSON.stringify(data, null, 2);

const defaultSchema = {
  inputsSchema: { schema: schemaPlaceholder, uiSchema: uiSchemaPlaceholder },
};

const validateSchema = (json) => {
  const value = JSON.parse(json);
  if (typeof value !== "object") {
    throw new Error("Must be an object!");
  }
};

/**
+ * @param {{
 *  name: string,
 *  onChange: function,
 *  stepNum: number
 *  value: any,
 *  step: object,
 * }} props
*/
const SchemaInput = ({ name, onChange, stepNum, value = defaultSchema, step }) => {
  const [showDemo, setShowDemo] = useState(false);
  const [demoSchema, setDemoSchema] = useState({});
  const [schemaDraft, setSchemaDraft] = React.useState(
    formatJSON(value?.schema || schemaPlaceholder)
  );
  const [uiSchemaDraft, setUiSchemaDraft] = React.useState(
    formatJSON(value?.uiSchema || uiSchemaPlaceholder)
  );
  const [errors, setErrors] = React.useState([]);

  const canUpdate = !errors.length;
  React.useEffect(() => {
    try {
      if (!canUpdate) {
        throw new Error("Invalid JSON");
      }
      const schema = JSON.parse(schemaDraft);
      const uiSchema = JSON.parse(uiSchemaDraft);
      setDemoSchema({ schema, uiSchema });
      onChange({ [name]: { schema, uiSchema } });
      setErrors([]);
    } catch (error) {
      onChange(null, { field: name, message: error.message });
    }
  }, [canUpdate, schemaDraft, uiSchemaDraft, name]);

  const handleBeautify = () => {
    try {
      setSchemaDraft(formatJSON(JSON.parse(schemaDraft)));
    } catch (error) {
      //bad json
    }
    try {
      setUiSchemaDraft(formatJSON(JSON.parse(uiSchemaDraft)));
    } catch (error) {
      //bad json
    }
  };

  useEffect(() => {
    const { uiSchema } = value;
    if (uiSchema && uiSchemaDraft !== formatJSON(uiSchema)) {
      setUiSchemaDraft(formatJSON(uiSchema));
    }
  }, [value]);

  return (
    <div className={styles.schemaInputContainer}>
      <div className={styles.iconContainer}>
        <DLXTooltip placement="top-start" text="Browse for data reference">
          <IconButton className={styles.iconButton}>
            <InputDataSelector stepNum={stepNum} useCopy={true} />
          </IconButton>
        </DLXTooltip>
        <LookupSelectorsMenu />
        <DLXTooltip text="Beautify schemas">
          <IconButton
            className={styles.iconButton}
            onClick={handleBeautify}
            disabled={!!errors.length}
          >
            <FontAwesomeIcon icon="check" />
          </IconButton>
        </DLXTooltip>
        {!errors.length && (
          <DLXTooltip text="Test form">
            <IconButton
              className={styles.iconButton}
              onClick={() => setShowDemo(true)}
            >
              <FontAwesomeIcon icon="running" />
            </IconButton>
          </DLXTooltip>
        )}
      </div>
      <div className={styles.schemaEditorsContainer}>
        <Editor
          title="Data Schema"
          setSchema={setSchemaDraft}
          schema={schemaDraft}
          placeholder={schemaPlaceholder}
          errors={errors}
          setErrors={setErrors}
          step={step}
        />
        <Editor
          title="UI Schema"
          setSchema={setUiSchemaDraft}
          schema={uiSchemaDraft}
          placeholder={uiSchemaPlaceholder}
          errors={errors}
          setErrors={setErrors}
          step={step}
        />
        {showDemo && (
          <Demo
            inputsSchema={demoSchema}
            setShow={setShowDemo}
            stepNum={stepNum}
            step={step}
          />
        )}
      </div>
    </div>
  );
};

/**
 * @param {{
 *  inputsSchema: any,
 *  setShow: function,
 *  stepNum: number,
 * }} props
 */
const Demo = ({ inputsSchema, setShow, stepNum, step }) => {
  const [data, setData] = useState({});
  const { executeLookup } = useLookup();
  const { executionInfo } = useOrchestrationEditorContext();

  const { getStepInputs, execution } = executionInfo;
  return (
    <DLXModal
      hideSubmitButton
      onCancel={() => setShow(false)}
      cancelButtonLabel="Close"
      open
      size="small"
      title={"User input demo"}
    >
      <ErrorBoundary
        component="Demo"
        noReset={true}
        title="Demo Error"
        message="Please provide valid schema/ui-schema"
      >
        <UserInputInterface
          inputsSchema={inputsSchema}
          executeLookupCommand={executeLookup}
          submitUserInput={setData}
          getStepInputs={getStepInputs}
          stepNum={stepNum}
          step={step}
          execution={execution}
        />
        <div className={styles.demoData}>
          {Object.keys(data).length ? (
            <JsonViewer
              title="Form output"
              json={data}
              allowSelect={false}
              trail={true}
              onSelect={() => null}
            />
          ) : (
            <p>Click submit to see form data output</p>
          )}
        </div>
      </ErrorBoundary>
    </DLXModal>
  );
};

/**
 * @param {{
 * title: string,
 * schema: any,
 * setSchema:  function,
 * placeholder: string,
 * errors: any,
 * setErrors: function,
 * step: object
 * }} props
 */
const Editor = ({
  title,
  schema,
  setSchema,
  placeholder,
  errors,
  setErrors,
  step,
}) => {
  const ref = React.useRef();
  const [schemaError, setSchemaError] = useState(false);

  useEffect(() => {
    if (step?.validationError?.description) {
      setSchemaError(true);
    }
  }, [step?.validationError]);

  const canUpdate = !errors.length;

  const onBlur = () => {
    if (!schema.length) {
      setSchema(formatJSON(placeholder));
    }
  };

  const updateAnnotations = (annotation) => {
    const annotations = ref.current.editor.session.getAnnotations();
    ref.current.editor.session.setAnnotations([...annotations, annotation]);
  };

  React.useEffect(() => {
    try {
      validateSchema(schema);
    } catch (err) {
      const annotation = { row: 0, column: 2, type: "error", text: err.message };
      updateAnnotations(annotation);
    }
  }, [canUpdate, schema]);

  return (
    <div className={clsx(styles.editorContainer, errors.length && styles.error)}>
      <div className={styles.title}>{title}</div>
      <AceEditor
        height="100%"
        width="100%"
        language="json"
        ref={ref}
        mode="json"
        onBlur={onBlur}
        fontSize={16}
        onChange={setSchema}
        onValidate={setErrors}
        placeholder={formatJSON(uiSchemaPlaceholder)}
        theme="github"
        value={schema}
      />
      {title === "Data Schema" && schemaError && (
        <div className={styles.footer}>
          <div className={styles.message}>{step?.validationError?.description}</div>
          <div className={styles.close} onClick={() => setSchemaError(false)}>
            <div className={styles.closeButton}>
              <TimesIcon />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default SchemaInput;
