import _ from "lodash";
export { default as logger } from "./logger";
export { timeSince, formatDate, deltaT, createEndStartDates } from "./date";
export {
  isBefore,
  addYears,
  startOfDay,
  startOfMonth,
  startOfWeek,
  startOfYesterday,
} from "date-fns";
export { removeLastChar, objectCopy } from "./commons";
export { parseExpression, createExpression } from "./expressions";

const createActionType = (verb, subject, state) =>
  _.compact([verb, subject, state]).map(_.snakeCase).join("_").toUpperCase();
const createAction = (type) => (payload) => ({ type, payload });

export function createActionsAndTypes(subject, verbs, states) {
  const actions = {};
  const actionTypes = {};
  verbs.forEach((verb) => {
    const actionName = _.camelCase(verb);
    actions[actionName] = {};
    states.forEach((state) => {
      const stateAction = _.camelCase(state);
      const type = createActionType(verb, subject, state);
      actionTypes[type] = type;
      actions[actionName][stateAction] = createAction(type);
    });
  });
  return { actions, actionTypes };
}

export function createActionTypes(subject, verbs, states) {
  const actionTypes = {};
  verbs.forEach((verb) => {
    states.forEach((state) => {
      const type = createActionType(verb, subject, state);
      actionTypes[type] = type;
    });
  });
  return actionTypes;
}

export function createActionCreators(subject, verbs, states) {
  const actionCreators = {};
  verbs.forEach((verb) => {
    const actionName = _.camelCase(verb);
    actionCreators[actionName] = {};
    states.forEach((state) => {
      const stateAction = _.camelCase(state);
      const type = createActionType(verb, subject, state);
      actionCreators[actionName][stateAction] = createAction(type);
    });
  });
  return actionCreators;
}

export function isInIframe() {
  try {
    return window.self !== window.top;
  } catch (e) {
    return true;
  }
}

export const clearStorage = () => {
  const debug = localStorage.getItem("debug");
  if (process.env.NODE_ENV === "development") {
    localStorage.setItem("debug", debug);
  }
  sessionStorage.clear();
  localStorage.clear();
};
