import React, { useCallback, useState } from "react";
import clsx from "clsx";
import styles from "./OrchestrationFlow.module.scss";
import OrchestrationEditorStepSelector from "./OrchestrationEditorStepSelector";
import OrchestrationEditorStepDisplay from "./OrchestrationEditorStepDisplay";
import "react-multi-carousel/lib/styles.css";
import isEqual from "lodash/isEqual";
import { generateStepId } from "../../utils/step-id-generator";
import { ERROR_HANDLING_SCHEMA } from "./state/constants";

/**
 * @param {{
 * orchestration: Orchestration,
 * updateOrchestration: Function,
 * editorType: string,
 * }} props
 */
function OrchestrationFlow({ orchestration, updateOrchestration, editorType }) {
  const { steps, trigger } = orchestration;
  const [activeStep, setActiveStep] = useState(0);
  const [activeLink, setActiveLink] = useState(0);

  const addStep = useCallback(
    (index, option) => {
      const newSteps = [...steps];

      const usedIds = new Set(steps.map((step) => step.id));
      const stepId = generateStepId(index + Date.now(), usedIds);

      const stepOptions = { kind: option.type, id: stepId };
      if (orchestration?.orchestrationVersion === 2) {
        stepOptions.errorHandling = {
          errorResponse: ERROR_HANDLING_SCHEMA.properties.errorResponse.default,
        };
      }

      newSteps.splice(index, 0, { ...stepOptions });
      updateOrchestration({ steps: newSteps });
      setActiveStep(index + 1);
      setActiveLink(index + 1);
    },
    [steps, updateOrchestration]
  );

  const updateTrigger = useCallback(
    (triggerUpdate = {}) => {
      const { kind, connection } = triggerUpdate;
      if (trigger.kind === kind && !connection) {
        updateOrchestration({ trigger: { ...trigger, ...triggerUpdate } });
      } else {
        updateOrchestration({ trigger: triggerUpdate });
      }
    },
    [trigger, updateOrchestration]
  );

  const updateStep = useCallback(
    /** @param {{num: number}} step */
    (step, error) => {
      if (error) {
        updateOrchestration(null, error);
      } else {
        const { num, ...stepUpdate } = step || {};
        const newSteps = [...steps];
        newSteps[num] = stepUpdate;
        updateOrchestration({ steps: newSteps });
      }
    },
    [steps, updateOrchestration]
  );

  const deleteStep = useCallback(
    (index) => {
      const newSteps = [...steps];
      newSteps.splice(index, 1);
      updateOrchestration({ steps: newSteps });

      index < 0 ? setActiveStep(0) : setActiveStep(index);

      index < 0 ? setActiveLink(0) : setActiveLink(index);
    },
    [steps, updateOrchestration]
  );

  const handleClick = useCallback((id) => {
    setActiveLink(id);
    setActiveStep(id);
  }, []);

  return (
    <div
      className="flex flex-auto"
      data-testid="dtid_orchestration_flow"
      style={{ height: 0 }}
    >
      <div className={clsx(styles.carouselContainer, "br border-secondary")}>
        <OrchestrationEditorStepSelector
          activeLink={activeLink}
          addStep={addStep}
          deleteStep={deleteStep}
          onStepClick={handleClick}
          setActiveStep={setActiveStep}
          activeStep={activeStep}
          updateStep={updateStep}
          updateTrigger={updateTrigger}
          steps={steps}
          trigger={trigger}
        />
      </div>
      <div className={styles.flowContainer}>
        <div className={styles.flowEditor}>
          <OrchestrationEditorStepDisplay
            key={steps[activeStep - 1]?.id}
            activeStep={activeStep}
            deleteStep={deleteStep}
            orchestration={orchestration}
            setActiveStep={setActiveStep}
            steps={steps}
            trigger={trigger}
            updateStep={updateStep}
            updateTrigger={updateTrigger}
            editorType={editorType}
          />
        </div>
      </div>
    </div>
  );
}

export default React.memo(OrchestrationFlow, isEqual);
