import { client } from "@dlx/services";

export async function executeExpression(expression, context) {
  let response = {};
  try {
    response = await client.post(`/expressions/evaluation`, {
      source: "Request",
      expression: expression,
      context,
    });
  } catch (e) {
    ({ response } = e);
  }
  const { data } = response;

  return {
    data,
    error: data?.error ? data.message : false,
  };
}

//A semaphore variable to prevent multiple API request on concurrent call of fetchTransforms()
let fetchingTransforms = null;
let transforms = [];
export async function fetchTransforms() {
  //Return a cached list of transform
  if (transforms.length) {
    return transforms;
  }

  //Return a cached promise to any other call of fetchTransforms() to wait until response fetched from API
  if (fetchingTransforms) {
    return fetchingTransforms;
  }
  fetchingTransforms = new Promise((resolve) => {
    client.get(`/expressions/transforms`).then((response) => {
      if (!response?.data?.items) {
        resolve(null);
        return;
      }
      const {
        data: { items },
      } = response;
      resolve(items);
      transforms = items;
      fetchingTransforms = null;
    });
  });
  return fetchingTransforms;
}
