import React, { useEffect, useState } from "react";
import { startCase } from "lodash";
import clsx from "clsx";

import Popover from "@dlx/atoms/Popover";
import Button, { BUTTON_VARIANTS } from "@dlx/atoms/Button";
import { useEditorContext } from "../context";
import { filterTransforms } from "../util";

function FunctionSelector({
  showAll = false,
  inputType,
  onSelect,
  onInit = null,
  children,
}) {
  const [transformsGroups, setTransformsGroups] = useState([]);
  const [clearFilter, setClearFilter] = useState(false);
  const { transforms } = useEditorContext();

  useEffect(() => {
    const list = filterTransforms(transforms, {
      ...(!clearFilter && { inputType }),
    });
    setTransformsGroups(getTransformGroups(list));
  }, [inputType, clearFilter, transforms]);

  let popoverHandler = null;

  return (
    <Popover
      onInit={(handler) => {
        popoverHandler = handler;
        onInit && onInit(popoverHandler);
      }}
    >
      {children}
      <section>
        {transformsGroups.map(({ label, list }) => {
          return (
            <div key={label}>
              <div className="ph3 pv2 bg-primary text-contrast">{label}</div>
              {list.map((transform) => {
                const { name } = transform;
                return (
                  <div
                    key={name}
                    className={clsx(
                      "flex items-center pv2 ph3 pointer bb border-secondary-light bg-hover-secondary-light",
                      `dtid_transform_select_${name}`
                    )}
                    onClick={() => {
                      onSelect(transform);
                      popoverHandler.close();
                    }}
                  >
                    {startCase(name)}
                  </div>
                );
              })}
            </div>
          );
        })}
      </section>
      {!clearFilter && showAll && (
        <div>
          <Button
            variant={BUTTON_VARIANTS.TEXT}
            onClick={() => setClearFilter(true)}
            className="ph3 pv2"
          >
            Show All
          </Button>
        </div>
      )}
    </Popover>
  );
}

function getTransformGroups(transforms) {
  const groups = [];
  const groupMap = {};
  transforms.forEach((transform) => {
    const { group: groupLabel } = transform;
    let group = groupMap[groupLabel];
    if (!group) {
      group = {
        label: groupLabel,
        list: [],
      };
      groups.push(group);
      groupMap[groupLabel] = group;
    }
    group.list.push(transform);
  });
  return groups;
}

export default FunctionSelector;
