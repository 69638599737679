import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useStoreFile } from "../../state/filestores/filestores.reducer";
import { alertError } from "../../state/alerts/actions";
import { useLookup } from "./useLookup";
import UserReadingInterface from "./UserReadingInterface";
import UserInputInterface from "./UserInputInterface";

/**
 * @param {{
 * step: Step,
 * stepNum: Number,
 * submitUserInput: Function,
 * getStepInputs: Function,
 * execution: Object,
 * }} props
 */
function InputInterface({
  step,
  stepNum,
  submitUserInput,
  getStepInputs,
  execution,
}) {
  const { executeLookup } = useLookup();
  const dispatch = useDispatch();
  const [working, setWorking] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const storeFile = useStoreFile();

  const submitUserData = async (data) => {
    if (data.filePackage) {
      setWorking(true);
      const response = await storeFile(data.filePackage);
      setWorking(false);
      if (response.error) {
        dispatch(
          alertError(`There was an error uploading ${data?.filePackage?.filename}`)
        );
        return;
      }
      data.filePackage = response;
    }
    setSubmitted(true);
    try {
      await submitUserInput(stepNum, data);
    } catch (error) {
      setWorking(false);
      setSubmitted(false);
      dispatch(alertError(`Error: ${error}`));
    }
  };

  if (step.kind === "user-input") {
    return (
      <UserInputInterface
        inputsSchema={step.inputsSchema}
        working={working}
        submitUserInput={submitUserData}
        executeLookupCommand={executeLookup}
        submitted={submitted}
        stepNum={stepNum}
        getStepInputs={getStepInputs}
        step={step}
        execution={execution}
      />
    );
  }
  if (step.kind === "user-reading") {
    return (
      <UserReadingInterface
        {...step}
        working={working}
        submitUserInput={submitUserData}
        submitted={submitted}
        getStepInputs={getStepInputs}
        stepNum={stepNum}
      />
    );
  }
  return null;
}

export default InputInterface;
