import React, { useCallback, useEffect, useState } from "react";
import {
  AST_ELEMENT_TYPES,
  TYPE_OBJECT,
  TYPE_REFERENCE,
  VALUE_TYPES,
} from "../constants";
import { expressionToAst } from "../util";
import { AstSchemaRows } from "../Helpers";
import { Context, useEditorContext } from "../context";

function FunctionArgumentsEditor({
  transform,
  isIterator,
  schema,
  element,
  setElement,
}) {
  const { args, iteratorContext, handleSetElement } = useEditHelpers({
    transform,
    isIterator,
    element,
    setElement,
  });
  const { context, ...rest } = useEditorContext();
  return (
    <div className="pa3">
      <Context.Provider
        value={{
          ...rest,
          context: { ...context, ...iteratorContext },
        }}
      >
        <AstSchemaRows
          schema={transform.optionsSchema}
          rootSchema={!(schema.enum || schema.lookup) && schema}
          element={args}
          setElement={handleSetElement}
        />
      </Context.Provider>
    </div>
  );
}

function useEditHelpers({ transform, isIterator, element, setElement }) {
  const [args, setArgs] = useState({
    type: TYPE_OBJECT.TYPE,
    value: TYPE_OBJECT.DEFAULT_VALUE,
  });
  const [iteratorContext, setIteratorContext] = useState(null);

  // When the root expression is ultimately updated, the ast is converted to an expression (i.e. a string)
  // So, even transform function arguments exist as ast elements, that are ultimately reduced to an expression
  // But, there are some transform functions (i.e. arrayMap, arrayFilter) that accept an expression as an argument
  // This expression needs to be evaluated in the local context of the function, tho, rather than the global context
  // of the expression.  So, to avoid these expression transform function argument functions from being evaluated when
  // the root expression is parsed, they must be stored as a Literal string.  Functions that accept expressions as
  // arguments will separately parse these expressions using the local context.

  // Here's an example:
  //   trigger.samples | mapArray("{sampleId : sample_id, vial : vial_Num}")

  // In the above expression, the mapArray transform will use a map function to apply the expression argument on
  // each element of the trigger.samples array.  If the expression had been written as:

  //   trigger.samples | mapArray({sampleId : sample_id, vial : vial_Num})

  // The jexl parser would attempt to evaluate the the argument passed to mapArray as an expression, it
  // would pass {} as the argument to the transform function (since no sample_id or vialNum exist in the global context
  // provided).  To avoid this, we encode the expresion as a string (i.e. with quotes), so jexl will pass the
  // string expression to the transform function.  But, we want to allow the user to use the visual expression editor
  // to create this expression.  Otherwise, the user would need to hand-type in the expression.

  // So, the below functions identify any arguments with the name "expression" and convert the Literal string into an
  // ast so the visual editor can be used, then convert it back to a Literal string to be stored on the root ast

  const convertArgs = useCallback(
    (newArgs) => {
      const value = Object.assign({}, newArgs?.value);
      if (value.expression?.type === AST_ELEMENT_TYPES.LITERAL) {
        value.expression = value.expression?.value
          ? expressionToAst(value.expression.value)
          : { type: TYPE_REFERENCE.TYPE, value: TYPE_REFERENCE.DEFAULT_VALUE };
      }
      setArgs({
        type: TYPE_OBJECT.TYPE,
        value,
        error: element.error,
        validationErrors: element.validationErrors,
      });
    },
    [args]
  );

  useEffect(() => {
    convertArgs(element.args[1]);
  }, [element.args[1]]);

  const handleSetElement = (updates) => {
    element.args[1] = updates;
    setElement({ ...element });
  };

  const { context, executeAst } = useEditorContext();

  useEffect(() => {
    // Iterator function function applied on an array may use such expression which references the array item.
    // The problem is that the array item reference in not part of the root context, it is part of the array from a root context.
    // Following code adds { $item, $itemIndex } to the root context for evaluating such expression.
    // $item will be available as a reference to the array item while using in an iterator function expression

    if (isIterator && context) {
      executeAst(element.args[0], context).then(({ data }) => {
        if (Array.isArray(data)) {
          setIteratorContext(getArrayIteratorContext(data, context));
        } else {
          setIteratorContext(data);
        }
      });
    }
  }, [element, transform]);

  return {
    args,
    iteratorContext,
    handleSetElement,
  };
}

function getArrayIteratorContext(data, context) {
  let itemContext = {};
  data.forEach((item) => {
    if (Array.isArray(item)) {
      itemContext = [...item];
    } else if (typeof item === VALUE_TYPES.OBJECT) {
      itemContext = { ...itemContext, ...item };
    } else {
      itemContext = item;
    }
  });
  let depth = 1;
  while (context[`${"$".repeat(depth)}item`]) {
    depth++;
  }
  const $depth = `${"$".repeat(depth)}`;
  return {
    ...context,
    [`${$depth}item`]: itemContext,
    [`${$depth}index`]: 0,
  };
}

export default FunctionArgumentsEditor;
