import React from "react";
import { useDispatch } from "react-redux";
import ExecutionFlow from "../../../components/OrchestrationFlow/ExecutionFlow";
import { updateExecution } from "../../../state/executions/reducer";
import {
  ORCHESTRATION_ABORTED,
  ORCHESTRATION_COMPLETED,
  ORCHESTRATION_EXECUTION_UPDATE,
  ORCHESTRATION_FAILED,
  ORCHESTRATION_INTERACTION_REQUESTED,
  ORCHESTRATION_STEP_COMPLETED,
  ORCHESTRATION_STEP_FAILED,
} from "../../../state/executions/constants";
import useSocketEvent from "../../../state/hooks/useSocketEvent";

const TOPICS = [
  ORCHESTRATION_INTERACTION_REQUESTED,
  ORCHESTRATION_STEP_COMPLETED,
  ORCHESTRATION_STEP_FAILED,
  ORCHESTRATION_COMPLETED,
  ORCHESTRATION_ABORTED,
  ORCHESTRATION_FAILED,
];

export default function OrchestrationExecutionViewer(props) {
  const dispatch = useDispatch();
  useSocketEvent({
    event: ORCHESTRATION_EXECUTION_UPDATE,
    skip: (message) =>
      props.executionId !== message.executionId || !TOPICS.includes(message.topic),
    onCompleted: async (message) => dispatch(updateExecution(message.executionId)),
  });

  return <ExecutionFlow {...props} />;
}
