import React, { useEffect, useState } from "react";
import { DeleteIcon } from "../../../icons";

import Popover from "@dlx/atoms/Popover";
import Button, { BUTTON_VARIANTS } from "@dlx/atoms/Button";
import Tooltip from "@dlx/atoms/Tooltip";

import { LookupValueSelector } from "../Selectors";
import { AstRow, ExecuteElement } from "../Helpers";
import { SchemaTitle } from "../Wrappers";
import { getAstElementFromValue, schemaToAst } from "../util";
import clsx from "clsx";

function ArrayEditor({ schema, element, setElement }) {
  const {
    itemSchema,
    draft,
    setDraft,
    valueLength,
    addElement,
    updateElement,
    deleteElement,
  } = useArrayEditHelpers({ schema, element });

  const isBlank = valueLength === 0;
  const usesLookup = Boolean(schema?.enum || schema?.lookup);
  let popoverCloseHandler = null;

  if (usesLookup) {
    return (
      <LookupValueSelector
        schema={schema}
        value={(element.value || []).filter((v) => v?.value).map((v) => v?.value)}
        setValue={(v) => setElement(getAstElementFromValue(v))}
      />
    );
  }

  return (
    <Popover
      onInit={({ close }) => {
        popoverCloseHandler = close;
      }}
      dataTestId="dtid_array_edit"
    >
      <div className="ml1 pv2 pointer">
        {isBlank ? (
          <span className="text-primary-light">Click to create array</span>
        ) : (
          <span>
            Array {`[`}
            {draft.length}
            {`]`}
          </span>
        )}
      </div>
      <div className="font-medium" style={{ minWidth: "500px" }}>
        <div className="flex items-center bb border-secondary ph3 pv3 sticky top0 bg-default">
          <SchemaTitle title="Array Editor" subtitle={schema?.title} />
          <Button
            variant={BUTTON_VARIANTS.TEXT}
            onClick={() => {
              setDraft(element.value);
              popoverCloseHandler();
            }}
            dataTestId="dtid_cancel_changes"
          >
            Cancel
          </Button>
          <Button
            variant={BUTTON_VARIANTS.PRIMARY}
            className="pv2 ph2 ml2"
            onClick={() => {
              setElement(getAstElementFromValue(draft));
              popoverCloseHandler();
            }}
            dataTestId="dtid_apply_changes"
          >
            Apply
          </Button>
        </div>
        <div>
          {draft.map((elem, index) => {
            return (
              <div key={index} className="flex items-center bb border-secondary">
                <div className="ph3">{index}</div>
                <div className="flex-auto">
                  <AstRow
                    schema={itemSchema}
                    element={elem}
                    setElement={(updates) => updateElement(updates, index)}
                    className={`dtid_array_item_${index}`}
                  />
                </div>
                <Tooltip text="Remove Item">
                  <div
                    className={clsx(
                      "flex items-center ma2 text-primary pointer",
                      `dtid_delete_array_item_${index}`
                    )}
                    onClick={() => deleteElement(index)}
                  >
                    <DeleteIcon name={"Clear"} size={24} color="inherit" />
                  </div>
                </Tooltip>
              </div>
            );
          })}
          <div className="tl pa3 sticky bottom0 bg-default flex items-center justify-between">
            <Button
              className="pa2 text-primary"
              variant={BUTTON_VARIANTS.BORDER}
              onClick={addElement}
            >
              + Add Item
            </Button>
            <ExecuteElement
              element={getAstElementFromValue(draft)}
              tooltip="Test array"
            />
          </div>
        </div>
      </div>
    </Popover>
  );
}

const useArrayEditHelpers = ({ schema, element }) => {
  const itemSchema = schema?.items;
  const defaultCell = schemaToAst(itemSchema);
  const [draft, setDraft] = useState([defaultCell]);
  const valueLength = element.value.length;

  useEffect(() => {
    if (valueLength !== 0 && draft !== element.value) {
      setDraft([...element.value]);
    }
  }, [element.value]);

  const addElement = () => {
    draft.push(defaultCell);
    setDraft([...draft]);
  };

  const updateElement = (updates, index) => {
    draft[index] = updates;
    setDraft([...draft]);
  };

  const deleteElement = (index) => {
    draft.splice(index, 1);
    setDraft([...draft]);
  };

  return {
    itemSchema,
    draft,
    setDraft,
    valueLength,
    addElement,
    updateElement,
    deleteElement,
  };
};

export default ArrayEditor;
