import createSagaMiddleware from "redux-saga";
import { composeWithDevTools } from "redux-devtools-extension";
import rootSaga from "../state/sagas";
import reducers from "../state/reducers";
import history from "../services/History";
import { connectedRouterMiddleware } from "./connectedRouterMiddleware";
import { promiseMiddleware } from "@adobe/redux-saga-promise";
import { client } from "../services/api/client";
import { socket } from "../services/socket";
import { configureStore } from "@reduxjs/toolkit";

export default function buildStore(preloadedState) {
  const reducer = reducers(history);

  const rootReducer = (state, action) => {
    if (action.type === "CLEAR_STORE") {
      const { router, system } = state;
      return reducer({ router, system }, action);
    }
    return reducer(state, action);
  };
  const sagaMiddleware = createSagaMiddleware();
  let middleware = [connectedRouterMiddleware, promiseMiddleware, sagaMiddleware];
  if (process.env.NODE_ENV !== "production") {
    middleware = composeEnhancers(middleware);
  }

  const store = configureStore({
    reducer: rootReducer,
    preloadedState,
    middleware,
  });

  sagaMiddleware.run(rootSaga, { client, socket });
  return store;
}

const composeEnhancers = composeWithDevTools({
  actionCreators: {
    sessionExpired: () => ({
      type: "SESSION_EXPIRED",
      broadcast: true,
      resolve: () => Promise.resolve(true),
      reject: () => Promise.reject(false),
    }),

    tokenExpired: () => ({
      type: "TOKEN_EXPIRED",
      broadcast: true,
      resolve: () => Promise.resolve(),
      reject: () => Promise.reject(),
    }),

    userIdle: () => ({
      type: "USER_IDLE",
      broadcast: true,
      resolve: () => Promise.resolve(true),
      reject: () => Promise.reject(false),
    }),
  },
  trace: false,
  actionSanitizer: (action) => {
    if (action.type === "API_SUCCESS") {
      return { ...action, payload: {} };
    }
    return action;
  },
});
