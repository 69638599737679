import {
  REQUEST_LEASE,
  ABANDON_LEASE,
  UPDATE_LEASES,
  START_LEASE,
} from "./lease.constants";

export const requestLease = (connectionId, readingAttributes) => ({
  type: REQUEST_LEASE,
  payload: { connectionId, readingAttributes },
});
export const abandonLease = (connectionId) => ({
  type: ABANDON_LEASE,
  payload: { connectionId },
});
export const updateLeases = (payload) => ({ type: UPDATE_LEASES, payload });
export const startLease = (payload) => ({ type: START_LEASE, payload });
