import React from "react";
import clsx from "clsx";
import { snakeCase } from "lodash";
import Popover from "@dlx/atoms/Popover";

import {
  EXPRESSION_VALUE_TYPES,
  TYPE_ARRAY,
  TYPE_BOOLEAN,
  TYPE_NUMBER,
  TYPE_OBJECT,
  TYPE_REFERENCE,
  TYPE_STRING,
} from "../constants.js";
import {
  ReferenceIcon,
  ArrayIcon,
  NumberIcon,
  ObjectIcon,
  TextIcon,
  ToggleIcon,
  ErrorIcon,
} from "../../../icons";
import { IconButton } from "../Wrappers/";

export const TYPE_ICONS = {
  [TYPE_REFERENCE.VALUE_TYPE]: ReferenceIcon,
  [TYPE_STRING.VALUE_TYPE]: TextIcon,
  [TYPE_NUMBER.VALUE_TYPE]: NumberIcon,
  [TYPE_BOOLEAN.VALUE_TYPE]: ToggleIcon,
  [TYPE_ARRAY.VALUE_TYPE]: ArrayIcon,
  [TYPE_OBJECT.VALUE_TYPE]: ObjectIcon,
};

export default function BaseTypeSelector({ value, onSelect, error }) {
  const Icon = TYPE_ICONS[value];
  return (
    <Popover autoclose={true} dataTestId="dtid_base_type_select">
      <IconButton
        className="ma1 bg-primary-light bg-hover-default text-primary"
        tooltip={error ? "" : "Change type"}
      >
        {error ? (
          <ErrorIcon size={24} color="red" />
        ) : (
          <Icon size={20} color="inherit" />
        )}
      </IconButton>
      <section>
        {EXPRESSION_VALUE_TYPES.map((item) => {
          const { TYPE, VALUE_TYPE, DEFAULT_VALUE } = item;
          const OptionIcon = TYPE_ICONS[VALUE_TYPE];
          return (
            <div
              key={VALUE_TYPE}
              className={clsx(
                "flex items-center pv2 ph3 pointer bb border-secondary-light bg-hover-secondary-light",
                snakeCase(`dtid_base_type_select_${VALUE_TYPE}`)
              )}
              onClick={() => onSelect({ type: TYPE, value: DEFAULT_VALUE })}
            >
              <OptionIcon size={20} color="inherit" />
              <span className="pl2 capitalize">{VALUE_TYPE}</span>
            </div>
          );
        })}
      </section>
    </Popover>
  );
}
