const validationErrorMessageGenerator = (step) => {
  if (!step?.validationError) {
    return "";
  }
  switch (step?.validationError?.kind) {
    case "InvalidEnumValue":
      return `Select a ${step.kind ? "step" : "trigger"} type"`;

    case "InvalidDocumentId":
    case "DocumentNotFound":
      if (step.validationError?.documentType === "TransformFunction") {
        return `Select a transform function`;
      }
      if (step.validationError?.documentType === "Connection") {
        return `Select a connection`;
      }
      return `Unknown error`;

    case "DocumentArchived":
      return "Data is archived";

    case "ConnectionActionNotFound":
      return `Select an action`;

    case "ConnectionEventNotFound":
      return `Select an event`;

    case "EmptyTransformContent":
      return `No transform content provided`;

    case "InvalidInputsSchema":
      return "Unsupported input schema";

    case "MissingRequiredOption":
      return `Missing required option`;

    case "UnsupportedOption":
      return `Unsupported option provided`;

    case "InvalidExpression":
      return `Invalid expression`;

    default:
      return step.validationError?.message || "Unknown error";
  }
};

export default validationErrorMessageGenerator;
