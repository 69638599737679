import React from "react";
import MuiButton from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import MuiCardActions from "@material-ui/core/CardActions";
import MuiCardHeader from "@material-ui/core/CardHeader";
import { withStyles } from "@material-ui/core/styles";

const CardHeader = withStyles({
  root: {
    background: "#f6f6f6",
    borderBottom: "1px solid #dadce0",
    paddingBottom: 8,
    paddingTop: 8,
  },
  title: {
    color: "#6f6f6f",
    fontSize: 16,
  },
})(MuiCardHeader);

const CardActions = withStyles({
  root: {
    justifyContent: "flex-end",
  },
})(MuiCardActions);

const Button = withStyles({
  label: {
    fontSize: 14,
    textTransform: "capitalize",
  },
})((props) => <MuiButton {...props} size="small" color="primary" />);
/**
 *
 * @param {{
 *  children: import("react").ReactElement
 *  disabled: boolean,
 *  label: string
 *  onCancel: function,
 *  onSubmit: function,
 * }} props
 */
export const BaseControl = withStyles({
  root: {
    minWidth: 250,
  },
})((props) => {
  const { children, classes, label, onCancel, onSubmit, disabled } = props;
  return (
    <Card
      classes={{
        root: classes.root,
      }}
    >
      <CardHeader title={label} />
      <div style={{ maxHeight: "70vh", overflowY: "auto" }}>{children}</div>
      <CardActions>
        <Button
          style={{
            color: "#6f6f6f",
          }}
          onClick={onCancel}
        >
          Cancel
        </Button>
        <Button variant="contained" onClick={onSubmit} disabled={disabled}>
          Apply
        </Button>
      </CardActions>
    </Card>
  );
});
