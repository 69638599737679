import { useState, useEffect, useCallback } from "react";
import { client } from "../../services/api/client";

/**
 *
 * @param {{ path: string, lazy?: boolean }} props
 * @returns {{
 *  options: {label: string, value: any}[],
 *  loading: boolean,
 *  getOptions: function,
 * }}
 */
export default function useLookup({ path, lazy }) {
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);

  const getOptions = useCallback(async () => {
    setLoading(true);
    try {
      const paths = Array.isArray(path) ? path : [path];
      const data = await fetchLookupOptions(paths);
      setOptions(data);
    } catch (e) {
      console.log(e);
    }
    setLoading(false);
  }, [path]);

  useEffect(() => {
    if (!lazy) {
      getOptions();
    }
  }, [getOptions, lazy]);
  return {
    options,
    loading,
    getOptions,
  };
}

export async function fetchLookupOptions(paths) {
  const promises = await Promise.allSettled(paths.map(client.get));
  const requests = promises
    .filter((p) => p.status === "fulfilled")
    .map((p) => p.value);

  return requests.flatMap(({ data }) => {
    return data.items.flatMap((group) => {
      const labelOption = (o) => ({ ...o, group: group.label });
      let lookup = group.options ? group.options.map(labelOption) : group;
      if (typeof group === "string") {
        lookup = { label: group, value: group };
      }
      return lookup;
    });
  });
}
