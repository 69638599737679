import { put, takeEvery } from "redux-saga/effects";
import { push } from "connected-react-router";

import { generateStepId } from "@dlx/utils/step-id-generator";
import { expressionToAst } from "@dlx/components/ExpressionEditor";

const VERSION_BELIZE = "2";
const VERSION_FLAMBEAU = "3";

export const IMPORT_ORCHESTRATION_REQUEST = "IMPORT_ORCHESTRATION_REQUEST";

export default function* saga() {
  yield takeEvery(IMPORT_ORCHESTRATION_REQUEST, handleImportOrchestration);
}

function mapStepUuidVersion(step, connectorList, connectorVal, version) {
  if (step.connection) {
    version = `${version}`;
    //Set step uuid and version for Belize to >= Belize import(connection: object)
    if (version >= VERSION_BELIZE) {
      if (validConnection(step.connection, version)) {
        step.uuid = connectorList[connectorVal]?.uuid;
        step.version = connectorList[connectorVal]?.version;
      }
    }
    //Set step uuid for Danube to Belize import(connection: string)
    if (version < VERSION_BELIZE) {
      step.uuid = connectorList[step.connection]?.uuid;
    }
  }
}

function* handleImportOrchestration(action) {
  try {
    const { data } = action.payload;
    const { id, createdBy, $links, $export, ...orchestration } = data;
    const latestExport = `${$export.version}` === VERSION_FLAMBEAU;
    const connectorList = latestExport
      ? $export.connectors
      : $export.connectionConnectors;

    const usedIds = new Set();
    orchestration.steps.forEach((step, index) => {
      const connectorVal = latestExport ? step.connector : step.connection?.value;
      mapStepUuidVersion(step, connectorList, connectorVal, $export.version);
      if (!step.id) {
        step.id = generateStepId(index + Date.now(), usedIds);
      }
      usedIds.add(step.id);
      migrateMappingToExpression(step);
    });

    if (orchestration.trigger.connection) {
      const mapConnector = latestExport
        ? orchestration.trigger.connector.id
        : orchestration.trigger.connection?.id || orchestration.trigger.connection;
      orchestration.trigger.uuid = connectorList[mapConnector]?.uuid;
    }
    yield put(
      push(`/orchestrations/new`, {
        formType: "import",
        orchestration: {
          ...orchestration,
          state: "disabled",
        },
      })
    );
  } catch (e) {
    console.log(e);
  }
}

function migrateMappingToExpression(step) {
  step.options &&
    Object.entries(step.options).forEach(([key, option]) => {
      if (typeof option === "object") {
        step.options[key] = getExpressionFromValue(option);
      }
    });

  step.credentials &&
    Object.entries(step.credentials).forEach(([key, credential]) => {
      if (typeof credential === "object") {
        step.credentials[key] = getExpressionFromValue(credential);
      }
    });

  ["orchestration", "connection"].forEach((key) => {
    if (step[key] && typeof step[key] === "object") {
      step[key] = getExpressionFromValue(step[key]);
    }
  });
}

function getExpressionFromValue({ type, value }) {
  if (type === "literal") {
    return JSON.stringify(value);
  }
  return value;
}

function validConnection(connection, version) {
  if (connection?.type) {
    return (
      connection.type !== "path" ||
      (connection.type === "path" && version === VERSION_FLAMBEAU)
    );
  }
  return expressionToAst(connection)?.value || "";
}
