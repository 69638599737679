import React from "react";
import DefaultEditor from "./DefaultEditor";

function NumberEditor({ schema, element, setElement, isBlank }) {
  return (
    <DefaultEditor
      schema={schema}
      element={element}
      setElement={setElement}
      inputType="number"
      isBlank={isBlank}
    />
  );
}

export default NumberEditor;
