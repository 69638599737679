import React from "react";

function SchemaTitle({ title = "Title", subtitle }) {
  return (
    <div className="flex-auto">
      <div className="b">{title}</div>
      {subtitle && <div className="text-secondary font-small mt1">{subtitle}</div>}
    </div>
  );
}

export default SchemaTitle;
