import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import api from "../../services/api";
import dbAdapter from "../reducers/dbAdapter";
import { NAME } from "./reducer";
import logger from "../../utils/logger";
import { client } from "../../services/api/client";
import isEqual from "lodash/isEqual";

const log = logger("useExecution");

export const executionActions = {
  fetchExecutionById: (id) => dbAdapter.actions.getRecord({ tableName: NAME, id }),
  saveRecord: (record) =>
    dbAdapter.actions.recordRetrieved({ tableName: NAME, record }),
};
const selectOne = (state, id) => state.executions.entities[id];

//mappingSources endpoint returns steps as an object, not an array
//Until we switch to identifying steps by id instead of index, we need to reformat
//steps to be an array
export const reMapStepData = (stepData) => {
  let prevIndex = 0;
  const filterData = Object.entries(stepData?.steps || {})
    .filter(([k, _v]) => Number.isInteger(Number(k)))
    .map(([key, v], index) => {
      const mapData = [];
      for (let i = index === 0 ? 0 : 1; i < Number(key) - prevIndex; i++) {
        mapData.push({});
      }
      mapData.push(v);
      prevIndex = Number(key);
      return mapData;
    });
  return filterData.flatMap((m) => m);
};

function checkExecutionVersion(orchestration, execution) {
  return orchestration === undefined
    ? true
    : execution?.orchestration?.orchestrationVersion ===
        orchestration?.orchestrationVersion;
}

/**
 * @param {{
 *  id?: Execution["id"],
 *  lazy?: boolean
 *  poll? : boolean
 * }} props
 */
export default function useExecution({ id, lazy, poll = false, orchestration }) {
  const dispatch = useDispatch();

  /** @type {Execution} */
  const execution = useSelector((state) => selectOne(state, id));
  const fetched = useSelector((state) => state.executions.fetched[id]);
  const error = useSelector((state) => state.executions.error[id]);
  const [loading, setLoading] = useState(false);
  const statusOfSteps = execution?.statusOfSteps;
  /** @type [TriggerOutput, Function] */
  const [triggerOutput, setTriggerOutput] = useState(null);
  /** @type [StepOutput[], Function] */
  const [stepOutputs, setStepOutputs] = useState([]);

  const isSameVersion = checkExecutionVersion(orchestration, execution);

  // Fetch execution data
  useEffect(() => {
    const shouldFetchExecution = id && !fetched && !loading && !lazy;
    if (shouldFetchExecution) {
      dispatch(executionActions.fetchExecutionById(id));
      setLoading(true);
    }
  }, [id, fetched, loading, dispatch, lazy]);

  // Handle lazy loading
  useEffect(() => {
    if (id && fetched) {
      setLoading(false);
    }
  }, [id, fetched]);

  // Reload execution data
  const reload = useCallback(() => {
    if (id) {
      dispatch(executionActions.fetchExecutionById(id));
    }
  }, [dispatch, id]);

  // Get trigger output
  const getTriggerOutput = useCallback(async () => {
    if (!id || !isSameVersion) {
      log(`Missing execId`);
      setTriggerOutput(null);
      return null;
    }

    try {
      const data = await api.executions.getTriggerOutput({ id: id });
      setTriggerOutput(data.payload || data);
      return data;
    } catch (err) {
      log(err);
      return null;
    }
  }, [id, isSameVersion]);

  const stepIdFound = (statusList, stepId) => {
    if (statusList) {
      return statusList.find((obj) => obj.stepId === stepId);
    }
    return null;
  };

  // Get step output
  const getStepOutput = useCallback(
    async (stepNumber, stepId) => {
      if (!id || !isSameVersion) {
        log("Missing execId");
        return null;
      }

      if (~stepNumber && !stepIdFound(statusOfSteps, stepId)) {
        log(`No status of step for ${stepNumber}`);
        return null;
      }

      try {
        const data = await api.executions.getStepOutput({ id, stepId });
        const dataResult = {
          ...(!data.result && data.error),
          ...data.result,
          ...(!data.result &&
            data?.statusMessage && { statusMessage: data.statusMessage }),
        };

        setStepOutputs((s) => ({ ...s, [stepNumber]: dataResult }));
        setTriggerOutput(data.trigger);

        return data;
      } catch (err) {
        log(err);
        setStepOutputs((s) => ({ ...s, [stepNumber]: {} }));
        return null;
      }
    },
    [id, statusOfSteps, isSameVersion]
  );

  // Get step inputs
  const getStepInputs = useCallback(
    async (index) => {
      if (!id || !isSameVersion) {
        log("Missing execId");
        return {};
      }

      try {
        const stepData =
          index === -1
            ? await api.executions.getTriggerSources(id)
            : await api.executions.mappingSources({ id, stepNumber: index });

        stepData.steps = reMapStepData(stepData);
        return stepData;
      } catch (err) {
        log(err);
        return null;
      }
    },
    [id, isSameVersion]
  );

  // Helper function to retrieve output for a step
  const outputForStep = useCallback((index) => stepOutputs?.[index], [stepOutputs]);

  // Helper function to retrieve step inputs
  const stepInputs = useCallback(() => stepOutputs, [stepOutputs]);

  const getStatusOfStep = (statusList, stepId, index) => {
    const stepObj = { stepNumber: index, stepId, status: "future" };
    statusList.forEach((obj) => {
      if (obj.stepId === stepId) {
        stepObj.status = obj.status;
      }
    });
    return stepObj;
  };

  // Get status of a step
  const statusOfStep = useCallback(
    (index, stepId) => {
      if (isSameVersion) {
        return ~index
          ? getStatusOfStep(statusOfSteps, stepId, index)
          : { stepNumber: index, status: id ? "completed" : "future" };
      }
      return { stepNumber: index, status: "future" };
    },
    [statusOfSteps, id, isSameVersion]
  );

  // Poll for execution updates
  useEffect(() => {
    if (poll && execution?.status === "executing" && !execution?.orchestration) {
      dispatch(executionActions.fetchExecutionById(id));
    }
  }, [dispatch, execution, id, poll]);

  useEffect(() => {
    let intervalId = null;
    if (poll && execution?.status === "executing" && execution?.orchestration) {
      intervalId = setInterval(async () => {
        if (execution.status === "executing") {
          const { data } = await client.get(`/executions/${id}`);
          if (!isEqual(data, execution)) {
            dispatch(executionActions.saveRecord(data));
          }
        } else {
          clearInterval(intervalId);
        }
      }, 1000);
    }
    return () => clearInterval(intervalId);
  }, [poll, execution, id, dispatch]);

  return {
    error,
    execution,
    statusOfStep,
    getStepInputs,
    getStepOutput,
    getTriggerOutput,
    loading: !fetched,
    outputForStep,
    poll,
    stepInputs,
    stepOutputs,
    triggerOutput,
    reload,
    isSameVersion,
  };
}
