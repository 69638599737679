import React, {
  useContext,
  useMemo,
  useState,
  useEffect,
  useCallback,
  useRef,
} from "react";
import { useLocation } from "react-router-dom";
import { client } from "../../services/api/client";
import ReactDOM from "react-dom";
import clsx from "clsx";
import { CircularProgress } from "@material-ui/core";

export const ScitaraHelpContext = React.createContext(null);

export const useScitaraHelp = () => useContext(ScitaraHelpContext);

const _ko19 = {
  config: {},
  buttonId: "ko-launch-button",
  widget_width: 370,
};

_ko19.postMessage = (message) => {
  _ko19.iframe.contentWindow.postMessage(message, _ko19.getSourceDomain());
};

_ko19.close = () => {
  _ko19.postMessage({ action: "closed" });
  _ko19.onClose();
};

_ko19.open = () => {
  _ko19.widget.style.opacity = 1;
  _ko19.postMessage({ action: "open" });
  _ko19.onOpen();
};

_ko19.addButton = () => {
  _ko19.btn = document.getElementById(_ko19.buttonId);
  _ko19.btn.onclick = (e) => {
    e.preventDefault();
    _ko19.toggle(e);
  };
  _ko19.onReady();
};

_ko19.loadConfig = async () => {
  const res = await fetch(_ko19.configUrl);
  if (res.ok) {
    const config = await res.json();
    _ko19.addButton();
    _ko19.postMessage({
      action: "update-config",
      data: JSON.stringify(config),
    });
  } else {
    throw new Error(`Error loading config`);
  }
};

_ko19.authenticate = (tokenObj) => {
  _ko19.postMessage({ action: "authenticate", data: tokenObj });
};

_ko19.updateRecommended = (page) => {
  _ko19.postMessage({ action: "update-recommended", data: page });
};

_ko19.setParentWidth = (width) => {
  _ko19.postMessage({ action: "parent-width", data: width });
};

_ko19.getSourceDomain = () => {
  const { origin, pathname } = new URL(_ko19.iframe.getAttribute("src"));
  return origin + pathname;
};

const processMessage = async (e) => {
  if (e.origin !== _ko19.baseUrl) {
    return;
  }
  const action = e.data.action;
  const data = e.data.data;
  try {
    switch (action) {
      case "loaded":
        await _ko19.loadConfig();
        _ko19.setParentWidth(window.innerWidth);
        await _ko19.onLoad();
        break;
      case "authError":
        _ko19.onAuthError();
        break;
      case "authResp":
        _ko19.onAuthResp(data);
        break;
      case "close":
        _ko19.close();
        break;
      default:
        break;
    }
  } catch (err) {
    _ko19.onError(err);
  }
};

const WIDGET_OPEN = "_ko19-widget-open";
const WIDGET_CLOSED = "_ko19-widget-closed";

const getToken = async () => {
  const { data } = await client.get("/help/knowledgeowl/widget-auth");
  return data?.access_token;
};

export const ScitaraHelpProvider = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isReady, setIsReady] = useState(false);
  const [status, setStatus] = useState("");
  const [helpConfig, setHelpConfig] = useState({});
  const location = useLocation();
  const koWidgetRef = useRef(null);
  const koIframe = useRef(null);
  const koStylesheet = useRef(null);

  const isEnabled = helpConfig.enableKnowledgeOwlWidget;
  const baseUrl = helpConfig.knowledgeOwlWidgetBaseUrl;
  const widgetId = helpConfig.knowledgeOwlWidgetIdentifier;

  useEffect(() => {
    let mounted = true;
    client.get("/help").then(({ data }) => {
      if (mounted) {
        setHelpConfig(data);
      }
    });
    return () => {
      mounted = false;
    };
  }, []);

  const openLink = useCallback(async () => {
    const { data } = await client.get("/help/knowledgeowl/remote-auth");
    window.open(data.url);
  }, []);

  useMemo(() => {
    _ko19.onReady = () => {
      setIsReady(true);
      _ko19.updateRecommended(location.pathname);
    };
    _ko19.onLoad = async () => {
      const token = await getToken();
      const authtoken = { type: "oauth", token };
      _ko19.authenticate(authtoken);
      setStatus("loaded");
    };
    _ko19.onOpen = () => {
      setIsOpen(true);
    };
    _ko19.onClose = () => {
      setIsOpen(false);
    };
    _ko19.onAuthResp = (data) => {
      if (data.status === "success") {
        _ko19.open();
      } else {
        throw new Error(data.status);
      }
    };
    _ko19.onAuthError = (error) => {
      throw new Error(error);
    };
    _ko19.onError = (error) => {
      setStatus(error?.message || "error");
    };
  }, [location.pathname]);

  _ko19.toggle = (event) => {
    event.stopPropagation();
    isOpen ? _ko19.close() : _ko19.open();
  };

  const launchKO = useCallback(() => {
    setStatus("loading");
    setIsOpen(true);
    _ko19.iframe = koIframe.current;
    _ko19.widget = koWidgetRef.current;
    _ko19.configUrl = `${baseUrl}/widget/config?__pc=${widgetId}`;
    _ko19.baseUrl = baseUrl;

    if (window.innerWidth < 500) {
      _ko19.widget_width = window.innerWidth;
    }
    koStylesheet.current.href = `${baseUrl}/widget-app/assets/css/load.css`;
    _ko19.iframe.src = `${baseUrl}/widget?__pc=${widgetId}&__loc=${location.pathname}`;

    window.addEventListener("message", processMessage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [baseUrl, widgetId]);

  useEffect(() => {
    return () => {
      window.removeEventListener("message", processMessage);
    };
  }, []);

  useEffect(() => {
    if (status === "loaded" && !isOpen) {
      _ko19.updateRecommended(location.pathname);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status, isOpen, location.pathname]);

  const values = useMemo(() => {
    return {
      widgetWidth: _ko19.widget_width,
      isEnabled,
      launchKO,
      isReady,
      openLink,
      status,
      isOpen,
    };
  }, [isEnabled, isReady, launchKO, openLink, status, isOpen]);

  return (
    <ScitaraHelpContext.Provider value={values}>
      {ReactDOM.createPortal(
        <link type="text/css" rel="stylesheet" ref={koStylesheet} />,
        document.head
      )}
      {ReactDOM.createPortal(
        <div
          ref={koWidgetRef}
          id="ko-widget"
          className={clsx(isOpen ? WIDGET_OPEN : WIDGET_CLOSED)}
          style={{
            opacity: isOpen ? 1 : 0,
            width: _ko19.widget_width,
          }}
        >
          {status === "loading" ? (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "100%",
                width: "100%",
              }}
            >
              <CircularProgress />
            </div>
          ) : null}
          <iframe
            ref={koIframe}
            id="ko-widget-iframe"
            title="Help Widget"
            style={{
              width: "100%",
              height: "100%",
              border: "none",
              visibility: status === "loading" ? "hidden" : "visible",
            }}
          />
        </div>,
        document.body
      )}
      {children}
    </ScitaraHelpContext.Provider>
  );
};
