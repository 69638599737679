import React, { useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import { JSONTree } from "react-json-tree";
import Popover from "@material-ui/core/Popover";
import variables from "../../assets/styles/variables.scss";
import { useClipboard } from "use-clipboard-copy";
import Tooltip from "@material-ui/core/Tooltip";
import Zoom from "@material-ui/core/Zoom";
import isEmpty from "lodash/isEmpty";
import { CopyIcon, SelectPropertyIcon } from "../../icons";
import logger from "../../utils/logger";
const log = logger("JsonViewer");

const treeTheme = {
  scheme: "google",
  author: "seth wright (http://sethawright.com)",
  base00: "#1d1f21",
  base01: "#282a2e",
  base02: "#373b41",
  base03: "#969896",
  base04: "#b4b7b4",
  base05: "#c5c8c6",
  base06: "#e0e0e0",
  base07: "#ffffff",
  base08: "#CC342B",
  base09: "#F96A38",
  base0A: "#FBA922",
  base0B: "#198844",
  base0C: "#3971ED",
  base0D: "#3971ED",
  base0E: "#A36AC7",
  base0F: "#3971ED",
};

const StyledTooltip = withStyles(() => ({
  tooltip: {
    backgroundColor: variables.lightgraybackground,
    color: variables.darkgraytext,
    maxWidth: 220,
    fontSize: "14px",
    border: "1px solid #dadde9",
  },
}))(Tooltip);

const fullPath = (keyPath, replaceRoot, type = "") => {
  if (replaceRoot && Array.isArray(keyPath)) {
    keyPath.push(replaceRoot);
  }
  const letters = /^[A-Za-z]+$/;
  try {
    const path = keyPath
      .slice()
      .reverse()
      .reduce((str, i) => {
        if (!str) {
          return i;
        }
        if (typeof i === "number") {
          return `${str}[${i}]`;
        }
        if (i.match(letters)) {
          return `${str}.${i}`;
        }
        return `${str}['${i}']`;
      }, "");
    log("fullpath", { keyPath, path, type });
    return path;
  } catch (err) {
    log("fullpath:error", { err });
  }
  return "";
};

function JsonViewer({
  allowCopy = false,
  allowSelect = false,
  anchorEl = null,
  json,
  onCancel = null,
  onSelect = null,
  replaceRoot = null,
  title = null,
  trail = false,
  copyAll = false,
}) {
  const clipboard = useClipboard();

  const [showToolTip, setShowToolTip] = useState(null);
  const [copiedPath, setCopiedPath] = useState("");
  const isEmptyData = isEmpty(json);

  const copyValue = (val, path) => {
    setShowToolTip(path ? path.join("") : val);
    clipboard.copy(val);
    setCopiedPath(`${path ? val : "Output"} copied!`);
    setTimeout(() => setShowToolTip(false), 3000);
  };

  const copyPath = (keyPath) => {
    const copyData = fullPath(keyPath, replaceRoot);
    copyValue(copyData, keyPath);
  };

  const handleResize = () => {
    window.dispatchEvent(new CustomEvent("resize"));
  };

  if (!json) {
    return null;
  }

  const jsonContent = (
    <>
      {title && (
        <>
          <div className="bb border-secondary pa3 b flex items-center">
            <span className="flex-auto">{title}</span>
            {copyAll && !isEmptyData && (
              <StyledTooltip
                leaveDelay={2000}
                TransitionComponent={Zoom}
                open={showToolTip}
                title={copiedPath}
              >
                <span
                  className="text-secondary text-hover-primary pointer ml3"
                  onClick={() => copyValue(JSON.stringify(json))}
                >
                  <CopyIcon size="16" />
                </span>
              </StyledTooltip>
            )}
          </div>
        </>
      )}
      <div className="tl ph2" onClick={handleResize}>
        {!isEmptyData ? (
          <JSONTree
            valueRenderer={(raw) =>
              raw === "undefined" ? "No data in viewer" : raw
            }
            labelRenderer={(keyPath, type) => {
              return (
                <div className="flex items-center">
                  <span>{keyPath[0]}</span>
                  {allowCopy && (
                    <StyledTooltip
                      leaveDelay={2000}
                      TransitionComponent={Zoom}
                      open={showToolTip === keyPath.join("")}
                      title={copiedPath}
                    >
                      <span
                        className="text-secondary text-hover-primary pointer ml3"
                        onClick={() => copyPath(keyPath)}
                      >
                        <CopyIcon size="16" />
                      </span>
                    </StyledTooltip>
                  )}

                  {allowSelect && (
                    <span
                      className="text-secondary text-hover-primary pointer ml3"
                      onClick={() => {
                        onCancel();
                        onSelect(fullPath(keyPath, replaceRoot, type), type);
                      }}
                    >
                      <SelectPropertyIcon size="18" />
                    </span>
                  )}
                </div>
              );
            }}
            data={json}
            theme={treeTheme}
            hideRoot
            invertTheme
          />
        ) : (
          <div className="pl1 pv2">No data</div>
        )}
      </div>
    </>
  );

  return trail ? (
    jsonContent
  ) : (
    <Popover
      open={true}
      anchorEl={anchorEl}
      onClose={onCancel}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
    >
      <div className="pb1">{jsonContent}</div>
    </Popover>
  );
}

export default JsonViewer;
