import React, { useState, useRef, useEffect, useCallback } from "react";
import AceEditor from "react-ace";
import "ace-builds/webpack-resolver";
import ace from "ace-builds/src-noconflict/ace";
import "ace-builds/src-noconflict/ext-language_tools";
import "ace-builds/src-noconflict/mode-javascript";
import "ace-builds/src-noconflict/mode-python";
import "ace-builds/src-noconflict/theme-github";
import { logger } from "../../utils";
ace.config.set("basePath", "ace-builds/src-noconflict");

const log = logger("codeSnippetEditor");

/**
 * @param {CodeSnippetEditorProps} { code, source, language, onChange, onSave, ...children }
 */
const CodeSnippetEditor = ({
  code,
  source,
  language,
  onChange,
  onSave,
  ...children
}) => {
  const [snippets, setSnippets] = useState(code || "");
  const [wordList, setWordList] = useState(["source"]);
  const ref = useRef();

  const staticWordCompleter = {
    getCompletions: function (_editor, session, _pos, _prefix, callback) {
      callback(null, [
        ...wordList.map(function (word) {
          return {
            caption: word,
            value: word,
            score: 10,
            meta: "property",
          };
        }),
        ...session.$mode.$highlightRules.$keywordList.map((keyword) => {
          return {
            caption: keyword,
            value: keyword,
            score: 1,
            meta: "keyword",
          };
        }),
      ]);
    },
  };

  const getLastToken = useCallback((editor) => {
    const pos = editor.selection.getCursor();
    const session = editor.session;

    let curLine = session.getDocument().getLine(pos.row);
    if (curLine.indexOf(" ") !== -1) {
      curLine = curLine.split(" ");
      curLine = curLine[curLine.length - 1];
    }
    const curTokens = curLine.slice(0, pos.column).split(/\s+/);
    if (!curTokens[0]) {
      return false;
    }
    return curTokens[curTokens.length - 1];
  }, []);

  const getPropertyList = useCallback(
    (propertySource, tokens = [], properties = []) => {
      // Get the first token and remove it from tokens array
      const token = tokens[0];
      tokens.shift();
      console.log("IN getPropertyList...");

      // check array
      if (Array.isArray(propertySource)) {
        try {
          const lastTokenString = getLastToken(ref.current.editor);
          const lastTokens = lastTokenString.split(".");

          if (!lastTokens[lastTokens.length - 2].match(/^(\w+\[\d+\])/)) {
            return [];
          }
        } catch (err) {
          log("PropertyList:error", err);
          return [];
        }
        // get the properties of that obj
      }
      if (propertySource instanceof Object) {
        // get the properties of the obj
        properties = Object.keys(propertySource);

        // check if tokens array is not empty
        if (tokens.length) {
          // check for a perfect match with the token
          const match = properties.find((property) => property === token);
          if (match) {
            // return the property list for the value of the key matching with the next token
            return getPropertyList(source[token], tokens, properties, token);
          }
        } else {
          // return the property list with a partial match with the token
          properties = properties.filter(
            (property) => property.indexOf(token) !== -1
          );
          return properties;
        }
      }

      // else return empty properties array
      return [];
    },
    [getLastToken, source]
  );

  useEffect(() => {
    const lastToken = getLastToken(ref.current.editor);
    if (!lastToken) {
      wordList.splice(0, wordList.length);
      wordList.push("source");
      setWordList(wordList);
      return;
    }
    const tokens = lastToken.toString().split(/[.[\]]+/);
    if (tokens.length <= 1) {
      wordList.splice(0, wordList.length);
      wordList.push("source");
      setWordList(wordList);
      return;
    }

    const firstToken = tokens[0];
    if (firstToken !== "source") {
      wordList.splice(0, wordList.length);
      if (firstToken.trim() === "") {
        wordList.push("source");
      }
      setWordList(wordList);
      return;
    }
    tokens.shift();

    const properties = getPropertyList(source || {}, tokens);
    wordList.splice(0, wordList.length);
    wordList.push(...properties);
    setWordList([wordList]);
  }, [snippets, source, wordList, getLastToken, getPropertyList]);

  useEffect(() => {
    const { session } = ref.current.editor;
    session.setMode(`ace/mode/${language}`);
    if (session.$worker) {
      session.$worker.send("setOptions", [
        {
          esversion: 11,
        },
      ]);
    }
  }, [language]);

  return (
    <AceEditor
      {...children}
      mode={language}
      theme="github"
      ref={ref}
      wrapEnabled={true}
      width="100%"
      height="100%"
      showPrintMargin={false}
      onChange={(val, e) => {
        setSnippets(val);
        onChange(val, e);
      }}
      name="editor"
      editorProps={{ $blockScrolling: true }}
      enableBasicAutocompletion={[staticWordCompleter]}
      enableLiveAutocompletion={true}
      enableSnippets={true}
      commands={[
        {
          name: "spaceCommand1",
          bindKey: { win: "space", mac: "space" },
          exec: () => {
            ref.current.editor.insert(" ");
            wordList.splice(0, wordList.length);
            wordList.push("source");
            setWordList(wordList);
          },
        },
        {
          name: "saveCommand1",
          bindKey: { win: "ctrl+s", mac: "command+s" },
          exec: () => {
            onSave(snippets);
          },
        },
      ]}
    />
  );
};

export default CodeSnippetEditor;
