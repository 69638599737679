import React, { useEffect, useState } from "react";
import styles from "../OrchestrationFlow.module.scss";
import ActorContent from "./ActorContent";
import isEqual from "lodash/isEqual";
import { useDispatch } from "react-redux";
import { actions } from "../../../state/hooks/useApiResource";
import { satisfies } from "semver";

function checkRuntimeSelection(stepUpdate, updatedStep) {
  if (stepUpdate?.runtime) {
    delete updatedStep?.connection;
  }
  return updatedStep;
}

/**
 * @param {{
 *  activeStep: number,
 *  setActiveStep: Function,
 *  step: Step,
 *  stepNum: number,
 *  updateStep: Function,
 * }} props
 */
function Step({ activeStep, setActiveStep, step, stepNum, updateStep }) {
  const dispatch = useDispatch();
  const [connectorVersion, setConnectorVersion] = useState();

  useEffect(() => {
    let mounted = true;
    if (mounted && step?.connector) {
      const fetchConnector = async () => {
        const response = await dispatch(
          actions.apiTransaction({
            route: `/connectors/${step.connector}`,
          })
        );
        response && setConnectorVersion(response.version);
      };
      fetchConnector();
    }
    return () => {
      mounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [step?.connector]);

  const handleUpdate = React.useCallback(
    /** @param {{connector: string, version: string, runtime: boolean}} stepUpdate */
    (stepUpdate, error) => {
      if (stepUpdate) {
        const { options, ...rest } = step;
        let updatedStep = { ...rest, ...stepUpdate };
        updatedStep = checkRuntimeSelection(stepUpdate, updatedStep);
        if (stepSet(stepUpdate) && options) {
          updatedStep = { options: options, ...updatedStep };
        }
        if (stepUpdate.connector && !stepUpdate.version) {
          ["connection", "command", "version"].forEach((t) => delete updatedStep[t]);
        }
        if (stepUpdate.connector && stepUpdate.version) {
          if (satisfies(updatedStep.version, `^${connectorVersion}`)) {
            updatedStep = { options: options, ...updatedStep };
          } else {
            ["connection", "command"].forEach((t) => delete updatedStep[t]);
          }
        }
        updateStep({ ...updatedStep, num: stepNum - 1 });
      }
      if (error) {
        updateStep(null, error);
      }
    },
    [step, stepNum, updateStep, connectorVersion]
  );

  if (!step) {
    return null;
  }

  return (
    <div
      className={styles.stepContainer}
      onClick={() => (activeStep !== stepNum ? setActiveStep(stepNum) : null)}
    >
      {step.kind && (
        <ActorContent step={step} onUpdate={handleUpdate} stepNum={stepNum} />
      )}
    </div>
  );
}

const stepSet = (set) => {
  return !(set.connector || set.command || set.libraryTransformId);
};

export default React.memo(Step, isEqual);
