import { useMemo } from "react";
import { useLocation, useParams } from "react-router-dom";
import parseJson from "../../utils/parseJson";

export function useSearchParams() {
  const { search } = useLocation();
  return useMemo(() => {
    const searchParams = new URLSearchParams(search);
    return toObject(searchParams);
  }, [search]);
}

export default function useUrlParams() {
  const searchParams = useSearchParams();
  const params = useParams();
  return {
    ...params,
    queryParams: searchParams,
  };
}

// Object.fromEntries can't handle searchParams that contain arrays.
// foo=1&foo=2&baz=2 will combine into {foo: 2, baz: 2}
// toObject will combine multiple of the same key into an array
function toObject(searchParams) {
  const obj = {};
  Array.from(searchParams).forEach(([k, v]) => {
    const data = parseJson(v);
    if (obj[k]) {
      if (!Array.isArray(obj[k])) {
        obj[k] = [obj[k]];
      }
      obj[k].push(data);
    } else {
      obj[k] = data;
    }
  });
  return obj;
}
