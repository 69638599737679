import React, { useState } from "react";
import JsonViewer from "../jsonviewer/JsonViewer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import IconButton from "@material-ui/core/IconButton";
import variables from "../../assets/styles/variables.scss";
import styles from "./SourceDataSelector.module.scss";
import {
  faChevronCircleDown,
  faEllipsisH,
  faExclamationCircle,
} from "@fortawesome/free-solid-svg-icons";
import DLXTooltip from "../DLXTooltip/DLXTooltip";
import { OutputSourceIcon } from "../../icons";
import Spinner from "../../atoms/Spinner";
import { useOrchestrationEditorContext } from "../orchestrationeditor/BaseEditor";
import { STEP_STATUS } from "../OrchestrationFlow/constants";

const noDataMessage = "No data currently available";
const iconTypes = {
  future: faEllipsisH,
  failed: faChevronCircleDown,
  completed: faChevronCircleDown,
  executing: faEllipsisH,
  skipped: faChevronCircleDown,
  aborted: faExclamationCircle,
};

/**
 * @param {{
 *   onSelect?: function,
 *   useCopy?: boolean,
 *   stepNum: number
 * }} props
 */
export const InputDataSelector = ({ onSelect, useCopy, stepNum }) => {
  const [loading, setLoading] = useState(false);
  const [sourceData, setSourceData] = useState({});
  const { executionInfo = {} } = useOrchestrationEditorContext() || {};

  const { getStepInputs = () => null } = executionInfo;

  const handleShowStepInputs = React.useCallback(async () => {
    setLoading(true);
    const data = await getStepInputs(stepNum);
    setSourceData(data);
    setLoading(false);
  }, [getStepInputs, stepNum]);

  return (
    <SourceDataSelectorBase
      data={sourceData || noDataMessage}
      loading={loading}
      onClick={handleShowStepInputs}
      onSelect={onSelect}
      useCopy={useCopy}
    />
  );
};

export const OutputDataSelector = ({
  onSelect,
  stepNum,
  stepId,
  useCopy,
  copyAll,
  title,
}) => {
  const { executionInfo = {} } = useOrchestrationEditorContext() || {};

  const {
    getStepOutput = () => null,
    outputForStep = () => null,
    getTriggerOutput = () => null,
    triggerOutput,
    statusOfStep = () => null,
  } = executionInfo;

  const [loading, setLoading] = useState(false);

  const handleShowStepOutput = React.useCallback(async () => {
    setLoading(true);
    stepNum >= 0 ? await getStepOutput(stepNum, stepId) : await getTriggerOutput();
    setLoading(false);
  }, [getStepOutput, getTriggerOutput, stepNum, stepId]);

  const data = ~stepNum ? outputForStep(stepNum) : triggerOutput;

  return (
    <SourceDataSelectorBase
      data={data || noDataMessage}
      loading={loading}
      onClick={handleShowStepOutput}
      onSelect={onSelect}
      useCopy={useCopy}
      statusOfStep={statusOfStep(stepNum, stepId)}
      copyAll={copyAll}
      title={title}
    />
  );
};

/**
 * @param {{
 *  data: object | string,
 *  loading: boolean,
 *  onClick: Function,
 *  onSelect: Function,
 *  replaceRoot?: string,
 *  stepState: "validationError" | "fullyConfigured" | "unconfigured",
 *  useCopy: boolean,
 *  statusOfStep: StatusOfStep["status"] | "future",
 * }} props
 */
const SourceDataSelectorBase = ({
  data,
  loading,
  onClick,
  onSelect,
  replaceRoot,
  useCopy,
  statusOfStep,
  copyAll,
  title,
}) => {
  const [anchor, setAnchor] = useState(false);
  const handleOnCancel = () => setAnchor(null);
  const handleOnClick = async (evt) => {
    setAnchor(evt.currentTarget);
    try {
      await onClick();
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <>
      {anchor && !loading && (
        <JsonViewer
          allowCopy={useCopy}
          allowSelect={!!onSelect}
          anchorEl={anchor}
          json={data}
          onCancel={handleOnCancel}
          onSelect={onSelect}
          replaceRoot={replaceRoot}
          copyAll={copyAll}
          title={title}
        />
      )}
      <SourceDataButton
        onClick={handleOnClick}
        pending={loading}
        statusOfStep={statusOfStep}
      />
    </>
  );
};

const SourceDataButton = ({ onClick, pending, statusOfStep }) => {
  const disabled = [
    STEP_STATUS.FUTURE,
    STEP_STATUS.EXECUTING,
    STEP_STATUS.WAITING,
    STEP_STATUS.SKIPPED,
    STEP_STATUS.ABORTED,
  ].includes(statusOfStep?.status);
  const tipText = {
    future: "This step has not been run, yet",
    executing: "This step is currently executing",
    waiting: "This step is currently waiting for user intervention",
    skipped: "This step has been skipped",
    aborted: "This step was aborted",
  };
  const showOutputSourceIcon = [
    STEP_STATUS.FAILED,
    STEP_STATUS.COMPLETED,
    STEP_STATUS.SKIPPED,
    STEP_STATUS.CONTINUED,
  ].includes(statusOfStep?.status);

  return (
    <div onClick={disabled ? () => null : onClick}>
      {pending ? (
        <div style={{ width: "30px", textAlign: "center" }}>
          <Spinner />
        </div>
      ) : (
        <DLXTooltip text={tipText[statusOfStep?.status]}>
          <IconButton
            component="div"
            style={{
              fontSize: 25,
              cursor: "pointer",
              width: 30,
              height: 30,
            }}
          >
            {!showOutputSourceIcon ? (
              <FontAwesomeIcon
                spin={pending}
                color={disabled ? variables.lightgraytext : variables.green}
                icon={iconTypes[statusOfStep?.status || STEP_STATUS.COMPLETED]}
              />
            ) : (
              <div className={styles[statusOfStep?.status]}>
                <OutputSourceIcon />
              </div>
            )}
          </IconButton>
        </DLXTooltip>
      )}
    </div>
  );
};

export default SourceDataSelectorBase;
