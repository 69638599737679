import React, { useState } from "react";
import styles from "./MemoModal.module.scss";
import ReactQuill from "react-quill";
import DLXModal from "../DLXModal/DLXModal";
import "react-quill/dist/quill.snow.css";

/**
 * @param {MemoModalProps} { visible, setVisible, handleSubmit, memo }
 */
function MemoModal({ visible, onSubmit, onCancel, memo }) {
  const [value, setValue] = useState(memo || "");

  const handleOnChange = (_content, _delta, _source, editor) => {
    setValue(editor.getText());
  };
  return (
    <DLXModal
      title={memo ? "Update memo" : "Create memo"}
      submitButtonLabel={memo ? "Update memo" : "Create memo"}
      size="small"
      open={visible}
      onSubmit={() => onSubmit(value)}
      onCancel={onCancel}
    >
      <div className={styles.notes}>
        <ReactQuill theme="snow" onChange={handleOnChange} />
      </div>
    </DLXModal>
  );
}

export default MemoModal;
