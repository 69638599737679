import { combineReducers } from "@reduxjs/toolkit";
import { connectRouter } from "connected-react-router";
import auth from "../auth/auth.reducer";
import alerts from "../alerts/alerts.reducer";
import connections from "../connections";
import connectors from "../connectors/connectors.reducer";
import orchestrations from "../orchestrations/orchestration.reducer";
import users from "../users/users.reducer";
import eventlogs from "../eventlogs/eventlog.reducer";
import userroles from "../user-roles/userroles.reducer";
import usergroups from "../user-groups/usergroups.reducer";
import filestore from "../filestores/filestores.reducer";
import transforms from "../transforms/transforms.reducer";
import actors from "../actors/actors.reducer";
import modal from "../modal/modal.reducer";
import constants from "../system/constants.reducer";
import enums from "../system/enums.reducer";
import permissions from "../system/permissions.reducer";
import config from "../system/config.reducer";
import eventStream from "../eventstream/eventstream.reducer";
import auditEvents from "../audit-events/audit-events.reducer";
import reasonsforchange from "../reasons-for-change/reasonsforchange.reducer";
import operations from "../system/operations.reducer";
import leaseReducer from "../connections/lease/lease.reducer";
import liveReadingsReducer from "../connections/live-readings/live-readings.reducer";
import orchestrationEditor from "../../components/orchestrationeditor/state/reducer";
import executionsReducer from "../executions/reducer";

const reducers = (history) =>
  combineReducers({
    auth,
    alerts,
    orchestrations,
    users,
    userRoles: userroles,
    userGroups: usergroups,
    eventlogs,
    connectors,
    filestore,
    transforms,
    actors,
    system: combineReducers({
      constants: constants,
      enums: enums,
      permissions: permissions,
      operations: operations,
      config: config,
    }),
    ...connections,
    router: connectRouter(history),
    modal,
    eventStream,
    auditEvents,
    reasonsForChange: reasonsforchange,
    leases: leaseReducer,
    liveReadings: liveReadingsReducer,
    orchestrationEditor,
    executions: executionsReducer,
  });

export default reducers;
