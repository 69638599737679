import React, { useState, useContext, useMemo, useEffect } from "react";
import { discoverProvider } from "../../services/sso";
import { Route, Switch, useLocation } from "react-router-dom";
import { ROOT, SIGN_OFF_SUCCESS } from "../../views/routes";
import { useTitle } from "react-use";
import { useSessionContext } from "../session/Provider";
import Button, { BUTTON_VARIANTS } from "@dlx/atoms/Button";
import { SignOffSuccess } from "@dlx/views/session/SignOffSuccess";
import { LoadingIndicator } from "@dlx/components/LoadingScreen";

export const SSOContext = React.createContext(null);
export const useSSOContext = () => useContext(SSOContext);

const PrivateRoute = ({ render, ...rest }) => {
  const { iamToken } = useSessionContext();
  const location = useLocation();
  return (
    <Route
      {...rest}
      render={() => {
        return iamToken ? (
          render
        ) : (
          <SignOnRedirect redirectUrl={location.pathname} />
        );
      }}
    />
  );
};

const SignOnRedirect = ({ redirectUrl = ROOT }) => {
  useTitle("authenticating...", { restoreOnUnmount: true });

  useEffect(() => {
    discoverProvider({
      action: "sign-on",
      redirectUrl,
    });
  }, [redirectUrl]);
  return <LoadingIndicator />;
};

export const SignInError = () => {
  const message = window.history.state?.error?.message;
  useTitle(`Error! ${message}`);
  const loginAttempt = Number(localStorage.getItem("loginAttempt") || 0);
  return (
    <>
      <section
        style={{
          minWidth: "350px",
          left: "50%",
          right: "50%",
          top: "50%",
          transform: "translate(-50%, -50%)",
        }}
        className="fixed ba border-secondary rounded3 overflow-hidden"
      >
        <div className="bg-secondary-light pa3 tc font-large">Error</div>
        <div className="pa3 tc">
          <div className="pv3">{message}</div>
          <div className="mv3">
            {loginAttempt >= 3 && (
              <Button
                variant={BUTTON_VARIANTS.primary}
                className="ph3 pv2"
                data-testid="dtid_signin_email"
                onClick={() => {
                  discoverProvider({
                    action: "sign-on",
                    redirectUrl: "/",
                  });
                }}
              >
                Back to Login
              </Button>
            )}
          </div>
        </div>
      </section>
    </>
  );
};

export const SSOProvider = ({ children }) => {
  const [error, setError] = useState(null);

  const value = useMemo(
    () => ({
      error,
      setError,
    }),
    [error]
  );
  return (
    <SSOContext.Provider value={value}>
      <Switch>
        <Route exact path={SIGN_OFF_SUCCESS} component={SignOffSuccess} />
        <Route path="/error" component={SignInError} />
        <PrivateRoute path="/*" render={children} />
      </Switch>
    </SSOContext.Provider>
  );
};
