import * as connectionSelectors from "./connections/selectors";
import * as connectorSelectors from "./connectors/selectors";
import * as orchestrationSelectors from "./orchestrations/selectors";
import * as eventLogSelectors from "./eventlogs/selectors";
import * as userSelectors from "./users/selectors";
import * as userRolesSelectors from "./user-roles/selectors";
import * as userGroupsSelectors from "./user-groups/selectors";
import * as filestoreSelectors from "./filestores/selectors";
import * as transformsSelectors from "./transforms/selectors";
import * as actorsSelectors from "./actors/selectors";
import * as triggerSelectors from "./triggers/selectors";
import * as auditEventsSelectors from "./audit-events/selectors";
import { createSelector } from "@reduxjs/toolkit";
import * as reasonsForChange from "./reasons-for-change/selectors";
import * as eventStreamSelectors from "./eventstream/selectors";

export const createTableSelector = (table) =>
  createSelector(
    (state) => state[table].ids,
    (state) => state[table].entities,
    (ids, entities) => ids.map((id) => entities[id])
  );

const sourceMap = {
  connectors: connectorSelectors.getConnectorSelector,
  connections: connectionSelectors.getConnectionSelector,
  readings: connectionSelectors.getReadingSelector,
  eventlogs: eventLogSelectors.getEventlogSelector,
  orchestrations: orchestrationSelectors.getOrchestrationSelector,
  filestore: filestoreSelectors.getFilestoreMetaSelector,
  users: userSelectors.getUserSelector,
  transforms: transformsSelectors.getTransformSelector,
  actors: actorsSelectors.getActorSelector,
  triggers: triggerSelectors.getTriggers,
  auditEvents: auditEventsSelectors.getAuditEventsSelector,
};

const getEntity = (source, id) => (state) => {
  const getter = sourceMap[source];
  const entity = getter ? getter(state, id) : null;
  if (!entity) {
    return null;
  }
  if (source === "connections") {
    return {
      ...entity,
      connector: connectorSelectors.getConnectorSelector(state, entity.connector),
    };
  }
  return entity;
};

const allSelectors = {
  ...connectionSelectors,
  ...connectorSelectors,
  ...orchestrationSelectors,
  ...eventLogSelectors,
  ...userSelectors,
  ...userRolesSelectors,
  ...userGroupsSelectors,
  ...filestoreSelectors,
  ...transformsSelectors,
  ...actorsSelectors,
  ...triggerSelectors,
  ...auditEventsSelectors,
  getEntity,
  createTableSelector,
  ...reasonsForChange,
  ...eventStreamSelectors,
};

export default allSelectors;
