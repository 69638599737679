import { useSelector } from "react-redux";
import useGetCollection from "../hooks/useGetCollection";
import useGetRecord from "../hooks/useGetRecord";

const slice = "orchestrations";

export default function useGetOrchestration(id) {
  const [fetched, orchestration] = useGetRecord(slice, id);
  return { fetched, orchestration };
}

export function useGetOrchestrationsCollection(filter) {
  const [loaded, orchestrations] = useGetCollection(slice, filter);
  return { loaded, orchestrations };
}

export function usePopulateOrchestrations(orchestrations) {
  const connections = useSelector((state) => state.connections.entities);
  return orchestrations.map((orc) => {
    const orchestration = JSON.parse(JSON.stringify(orc));
    if (orchestration.trigger.connection) {
      const con = connections[orchestration.trigger.connection];
      orchestration.trigger.connection = con
        ? { name: con.name, id: con.id, connector: con.connector }
        : { name: "Unknown connection" };
    }
    return orchestration;
  });
}
