import React, { useEffect, useState } from "react";
import { getLabelNames, LabelsListEditor } from "@dlx/components/Labels";

export const Settings = ({
  value,
  errors,
  onTextChange,
  list = [],
  onChange,
  getExecutionOutput,
  onBlur = null,
}) => {
  const [context, setContext] = useState();

  useEffect(() => {
    getExecutionOutput().then((data) => setContext(data));
  }, []);

  return (
    <div className="pa5 overflow-y-auto">
      <div className="font-large text-default pb2">Description</div>
      <textarea
        className="border-secondary rounded2 mt2 font-default"
        onChange={onTextChange}
        value={value}
        style={{
          width: "100%",
          height: "100px",
          padding: "16px",
          borderColor: "#c4c4c4",
        }}
        maxLength={1000}
        placeholder="Example: Creates a new spreadsheet and sends notification."
        data-testid="dtid_orchestration_description"
        onBlur={onBlur}
      />
      <span className="ml4 text-error">{errors?.description}</span>
      <div className="font-large text-default pb2 mt5">
        Labels ({list?.length || 0})
      </div>
      <LabelsListEditor
        list={list}
        onChange={onChange}
        getLabelNames={getLabelNames}
        errors={errors?.labelExpressions}
        context={context}
      />
    </div>
  );
};
