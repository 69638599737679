import React from "react";
import styles from "./OrchestrationFlow.module.scss";
import TriggerStep from "./trigger/TriggerStep";
import Step from "./step/Step";
import isEqual from "lodash/isEqual";

/**
 * @param {{
 *  activeStep: number,
 *  deleteStep: Function,
 *  orchestration: Orchestration,
 *  setActiveStep: Function,
 *  steps: Step[],
 *  trigger: Trigger,
 *  updateStep: Function,
 *  updateTrigger: Function,
 *  editorType: string,
 * }} props
 */
function OrchestrationEditorStepDisplay({
  activeStep,
  deleteStep,
  orchestration,
  setActiveStep,
  steps,
  trigger,
  updateStep,
  updateTrigger,
  editorType,
}) {
  return (
    <div className={styles.leftContainer}>
      {activeStep === 0 && (
        <TriggerStep
          activeStep={activeStep}
          orchestration={orchestration}
          setActiveStep={setActiveStep}
          trigger={trigger}
          updateTrigger={updateTrigger}
          editorType={editorType}
        />
      )}
      {activeStep !== 0 && (
        <Step
          activeStep={activeStep}
          deleteStep={deleteStep}
          setActiveStep={setActiveStep}
          step={steps[activeStep - 1]}
          stepNum={activeStep}
          updateStep={updateStep}
        />
      )}
    </div>
  );
}

export default React.memo(OrchestrationEditorStepDisplay, isEqual);
