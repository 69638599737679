import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { hide, modals } from "../../state/modal/modal.actions";

export default function ModalRoot() {
  const dispatch = useDispatch();
  const handleOnClose = () => dispatch(hide());
  const { name, props } = useSelector((state) => state.modal);
  if (!name) {
    return null;
  }
  const Modal = modals[name];
  return <Modal {...props} onClose={handleOnClose} />;
}
