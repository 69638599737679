import { TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import React, { useState } from "react";

/**
 * InputSelect component.
 * @component
 * @param {{
 *   options: ({ label: string, value: any } | string)[],
 *   value: string | object,
 *   label?: string,
 *   error?: string,
 *   multiple?: Boolean,
 *   freeSolo?: Boolean,
 *   onBlur?: Function,
 *   onChange: Function,
 *   maxLength?: Number,
 *   placeholder?: string,
 *   testId: string
 * }} props
 * @returns {JSX.Element} - The rendered InputSelect component.
 */
const InputSelect = ({
  options,
  value,
  error = null,
  label = "",
  multiple = false,
  freeSolo = false,
  onChange,
  onBlur = null,
  maxLength = 100,
  placeholder = "",
  testId,
}) => {
  const [inputValue, setInputValue] = useState(value);

  const handleChange = (_event, newValue) => {
    setInputValue(newValue);
  };
  const handleBlurChange = () => {
    if (inputValue !== null) {
      setInputValue((val) => val.trim());
    }
  };

  const getOptionList = () => {
    if (typeof inputValue !== "string") {
      return options;
    }
    const val = inputValue.trim().toLowerCase();
    return options.filter(
      (opt) => typeof opt === "string" && opt.toLowerCase().includes(val)
    );
  };
  return (
    <Autocomplete
      multiple={multiple}
      size="small"
      freeSolo={freeSolo}
      value={inputValue}
      inputValue={inputValue || ""}
      options={getOptionList()}
      label={label}
      name={label}
      style={{ backgroundColor: "#fff" }}
      renderInput={(params) => (
        <TextField
          variant="outlined"
          placeholder={placeholder}
          {...params}
          label={label}
          error={!!error}
          helperText={error}
          inputProps={{
            ...params.inputProps,
            "data-testid": testId,
            maxLength: maxLength,
          }}
          onBlur={(e) => {
            freeSolo && onChange(e, e.target.value.trim() || null);
            onBlur && onBlur();
          }}
        />
      )}
      getOptionLabel={(opt) => opt?.label || opt}
      onChange={onChange}
      onInputChange={handleChange}
      onBlur={handleBlurChange}
    />
  );
};

export default InputSelect;
