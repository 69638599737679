import React from "react";
import { Stepper } from "@material-ui/core";
import styles from "./OrchestrationFlow.module.scss";
import clsx from "clsx";

const ContentComponent = ({ activePanel, availablePanels }) => {
  return (
    <div className={styles.stepperContainer}>
      <div
        className={styles.stepNavigator}
        data-testid="dtid_orchestration_flow_step_navigator"
      >
        <Stepper
          activeStep={activePanel}
          style={{ backgroundColor: "transparent", padding: "0px" }}
        >
          {availablePanels.map((item) => item.stepSelector)}
        </Stepper>
      </div>

      <div
        className={clsx(styles.stepContent, "overflow-y-auto")}
        data-testid="dtid_orchestration_flow_step_content"
      >
        {availablePanels[activePanel].stepDisplay}
      </div>
    </div>
  );
};

export default ContentComponent;
