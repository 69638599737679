import React, { useState, Fragment } from "react";
import { useSelector } from "react-redux";
import styles from "../OrchestrationFlow.module.scss";
import variables from "../../../assets/styles/variables.scss";
import selectors from "../../../state/selectors";
import useLookup from "../../../state/hooks/useLookup";
import { ChevronDownIcon, SearchThroughIcon, Status } from "../../../icons";

import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import Grid from "@material-ui/core/Grid";
import clsx from "clsx";
import { useGetFullConnection } from "../../../state/connections/useGetConnection";
import Spinner from "../../../atoms/Spinner";

const buildPath = (connector) => {
  const param = connector ? `?compatibleWithConnectorId=${connector}` : "";
  return `/lookups/connections${param}`;
};

const checkStatus = (status, type) => {
  return Object.keys(status).includes[type] && (status[type] || !status[type].valid);
};
const statusIcon = (connection) => {
  const invalidState = {
    invalid: checkStatus("authentication") || checkStatus("settings") || false,
    message:
      (checkStatus("authentication") ? "Authenticated" : "Not authenticated") ||
      (checkStatus("settings") ? "" : "Please edit settings") ||
      "",
  };
  return (
    <section className={styles.icon}>
      <Status
        status={invalidState.invalid ? "exclamation" : "heartbeat"}
        style={{
          color:
            (connection?.status?.online === true ||
              connection?.status?.online?.valid) &&
            (connection?.enabled || connection?.state !== "disabled")
              ? variables.red
              : variables.lightgraytext,
          fontSize: "18px",
        }}
      />
      <p className={styles.popupText}>
        {(invalidState.invalid && invalidState.message) ||
          `Connection is ${
            connection?.status?.online === true || connection?.status?.online?.valid
              ? "online"
              : "offline"
          }`}
      </p>
    </section>
  );
};

const checkOnlineStatus = (connection, onlineOnly) =>
  !onlineOnly || connection?.status?.online;

const checkEnabledState = (connection, enableOnly) =>
  !enableOnly || connection?.enabled;

const ConnectionSelector = ({
  enableOnly,
  id,
  onlineOnly,
  setConnection,
  uuid,
  connector,
  openList = false,
  setOpenList,
}) => {
  const [open, setOpen] = useState(openList);
  const [searchText, setSearchText] = useState("");

  const { fullConnection: connection } = useGetFullConnection(id);
  const connectorDetails = useSelector((state) =>
    selectors.getConnectorSelector(state, connector)
  );

  const { loading: isLoadingConnection, options: connectionsList } = useLookup({
    path: buildPath(connector),
  });

  const connections = React.useMemo(() => {
    return connectionsList
      .filter((c) => !c.archived)
      .filter((c) => checkOnlineStatus(c, onlineOnly))
      .filter((c) => checkEnabledState(c, enableOnly))
      .filter((c) => c.label !== connection?.name)
      .sort((a, b) => (a.label < b.label ? -1 : 1));
  }, [connection, connectionsList, onlineOnly, enableOnly]);

  const allConnections = React.useMemo(() => {
    return [
      ...connections.filter((c) => c?.status?.online?.valid),
      ...connections.filter((c) => !c?.status?.online?.valid),
    ]
      .filter(Boolean)
      .filter(
        (con) =>
          !searchText || con?.label?.toLowerCase().includes(searchText.toLowerCase())
      );
  }, [connections, searchText]);
  const pinnedConnector =
    uuid && allConnections.length ? connectorDetails?.name : "unknown";

  const connectionObj = (connectionId, name, enabled, status) => {
    return { id: connectionId, name, enabled, status };
  };

  const handleOptionChange = React.useCallback(
    (selection) => {
      const conn = `"${selection.value}"`;
      const getConnection = connectionObj(
        selection.value,
        selection.label,
        selection.enabled,
        selection.status
      );
      setConnection({ connection: (connector && conn) || getConnection });
      setOpen(false);
      if (connector) {
        setOpenList(false);
      }
    },
    [setConnection, setOpen, connector, setOpenList]
  );

  return (
    <Fragment>
      <ClickAwayListener onClickAway={() => setOpen(false)}>
        <div className={styles.connectionStep}>
          <div
            className={clsx(
              styles.connectionInner,
              open && styles.active,
              connection.name && styles.selected
            )}
            onClick={() => setOpen((o) => !o)}
          >
            <div
              className={`${styles.menuLabel} ${styles.boldText} `}
              data-testid="dtid_orchestration_flow_menu_label"
            >
              <ConnectionName connection={connection} />
            </div>

            <ConnectionStatusIcon connection={connection} enableOnly={enableOnly} />
            {!isLoadingConnection && (
              <ChevronDownIcon
                style={{
                  display: connections.length > 0 ? "inline-block" : "none",
                  transform: !open ? "none" : "rotate(180deg)",
                }}
                className={styles.dropdownIcon}
                data-testid="dtid_orchestration_flow_dropdown_connection_icon"
              />
            )}

            {isLoadingConnection && <Spinner />}
          </div>
          <ul
            className={!open ? "" : styles.activeDropdown}
            data-testid="dtid_orchestration_flow_active_dropdown"
          >
            <li key="search" className={styles.search}>
              {uuid && pinnedConnector && (
                <div className={styles.pinnedConnector}>
                  Connector: {pinnedConnector}
                </div>
              )}
              <TextField
                variant="outlined"
                fullWidth
                size="small"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <SearchThroughIcon className={styles.searchIcon} />
                    </InputAdornment>
                  ),
                }}
                onChange={(e) => {
                  setSearchText(e.target.value);
                }}
              />
            </li>
            {allConnections.map((option, i) => {
              return (
                <li
                  key={i}
                  onClick={() =>
                    option !== connection ? handleOptionChange(option) : null
                  }
                >
                  <section style={{ display: "flex", alignItems: "center" }}>
                    <div style={{ flexGrow: 1 }}>
                      <h2>{option.label}</h2>
                      <p>{connectorDetails?.name}</p>
                    </div>
                    {statusIcon(option)}
                  </section>
                </li>
              );
            })}
            {allConnections.length === 0 && (
              <div className={styles.emptyList}>
                <div className={styles.emptyListMsg}>No connection available!</div>
              </div>
            )}
          </ul>
        </div>
      </ClickAwayListener>
    </Fragment>
  );
};

const ConnectionName = ({ connection = {} }) => {
  return connection.name ? (
    <>
      {connection.name}

      {connection.connector?.version && (
        <div className={styles.ConnectionConnectorversion}>
          v {connection.connector.version}
        </div>
      )}
    </>
  ) : (
    <span>Select a connection</span>
  );
};

const ConnectionStatusIcon = ({ connection = {}, enableOnly }) => {
  if (enableOnly) {
    return connection.id && connection.state === "enabled" ? (
      statusIcon(connection)
    ) : (
      <Grid item xs={1} />
    );
  }
  return connection.id ? statusIcon(connection) : <Grid item xs={1} />;
};

export default ConnectionSelector;
