import { createSlice } from "@reduxjs/toolkit";
import { initialState, reducer } from "../reducers/dbAdapter";
import { types as socketTypes, socketApiEvents } from "../socket/socket.actions";
import { createPromiseAction } from "@adobe/redux-saga-promise";

const tableName = "orchestrations";

export const TRIGGER_ORCHESTRATION = "TRIGGER_ORCHESTRATION";

function buildMessage(user, execution, action) {
  const executedStepslength = execution.statusOfSteps.length;
  return {
    customer: user.customer,
    user: user.id,
    executionId: execution.id,
    response: {
      action,
    },
    stepNumber:
      executedStepslength > 0
        ? execution.statusOfSteps[executedStepslength - 1].stepNumber
        : undefined,
    retryCount:
      execution.statusOfSteps.length > 0
        ? execution.statusOfSteps[executedStepslength - 1].retryCount
        : undefined,
  };
}

export const triggerIOCompletion = createPromiseAction(
  socketTypes.WS_SEND_MESSAGE,
  (orchestrationId, data) => ({
    type: socketApiEvents.IO_COMPLETED,
    message: {
      data,
      orchestrationId,
    },
  })
);

export const manualTrigger = createPromiseAction(
  socketApiEvents.MANUAL_TRIGGER,
  (orchestrationId, data) => ({
    orchestrationId,
    data,
  })
);

export const abortOrchestration = (user, execution) => ({
  type: socketTypes.WS_SEND_MESSAGE,
  payload: {
    type: socketApiEvents.ABORT_ORCHESTRATION,
    message: buildMessage(user, execution),
  },
});

export const errorActionCompleted = (user, execution, action) => ({
  type: socketTypes.WS_SEND_MESSAGE,
  payload: {
    type: socketApiEvents.STEP_ACTION_COMPLETED,
    message: buildMessage(user, execution, action),
  },
});

const slice = createSlice({
  name: tableName,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    reducer(tableName)(builder);
  },
});

export default slice.reducer;
