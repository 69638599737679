import React from "react";
import styles from "./DLXChip.module.scss";
import PropTypes from "prop-types";
import DLXTooltip from "../DLXTooltip/DLXTooltip";

export default function DLXChip({
  disabled,
  invalid,
  label,
  leftIcon,
  onLeftIconClick,
  onRightIconClick,
  rightIcon,
  secondary,
  tooltip,
}) {
  const handleRightClick = React.useCallback(
    (e) => {
      e.stopPropagation();
      onRightIconClick(e);
    },
    [onRightIconClick]
  );

  const handleLifeClick = React.useCallback(
    (e) => {
      e.stopPropagation();
      if (onLeftIconClick) {
        onLeftIconClick(e);
      }
    },
    [onLeftIconClick]
  );

  return (
    <div
      className={`${styles.chipContainer} ${secondary && styles.secondary} ${
        invalid && styles.invalid
      } ${disabled && styles.disabled} `}
      onClick={handleLifeClick}
    >
      <DLXTooltip text={tooltip}>
        <div className={styles.inner}>
          <button
            className={styles.iconButton}
            style={{ visibility: leftIcon ? "visible" : "hidden" }}
          >
            {leftIcon}
          </button>
          <p className={`${styles.label} ${disabled && styles.disabled} `}>
            {label}
          </p>
          <button
            className={styles.iconButton}
            style={{ visibility: rightIcon ? "visible" : "hidden" }}
            onClick={handleRightClick}
          >
            {rightIcon}
          </button>
        </div>
      </DLXTooltip>
    </div>
  );
}

DLXChip.propTypes = {
  label: PropTypes.string.isRequired,
  leftIcon: PropTypes.node,
  rightIcon: PropTypes.node,
  onLeftIconClick: PropTypes.func,
  onRightIconClick: PropTypes.func,
};
