import React, { Fragment, useState } from "react";

import { useGetFullConnection } from "@dlx/state/connections/useGetConnection";
import { Step, StepLabel, Typography } from "@material-ui/core";

import styles from "../OrchestrationFlow.module.scss";
import ConnectionSelector from "../selectors/ConnectionSelector";
import TriggerEventSelector from "../selectors/TriggerEventSelector";
import TriggerTimerSelector from "../selectors/TriggerTimerSelector";
import TimerTriggerStepContent from "./TimerTriggerStepContent";
import { triggers } from "../../../state/triggers/triggers";
import UserTriggerStepContent from "./UserTriggerStepContent";
import TriggerConditions from "./TriggerConditions";
import validationErrorMessageGenerator from "../../StepChip/validationErrorMessageGenerator";
import ContentComponent from "../ContentComponent";

/**
 * @param {{
 *  activeStep: number,
 *  orchestration: Orchestration,
 *  setActiveStep: Function,
 *  editorType: string,
 *  trigger: Trigger,
 *  updateTrigger: Function,
 * }} props
 */
function TriggerStep({
  activeStep,
  orchestration,
  trigger,
  updateTrigger,
  editorType,
}) {
  const [activePanel, setActivePanel] = useState(0);

  if (!trigger?.kind) {
    trigger.kind = triggers.event.initialState;
  }

  const { fullConnection } = useGetFullConnection(
    trigger?.connection?.id || trigger?.connection
  );
  const triggerEvents = fullConnection?.connector?.events || {};

  const availablePanels = [
    TriggerDetailsPanel({
      trigger,
      setActivePanel,
      updateTrigger,
      activeStep,
      orchestration,
      triggerEvents,
      editorType,
    }),
    TriggerConditionsPanel({ trigger, setActivePanel, updateTrigger }),
  ];

  return (
    <div className={styles.stepContainer}>
      <ContentComponent
        activePanel={activePanel}
        availablePanels={availablePanels}
      />
    </div>
  );
}

function triggerReadyStatus(trigger) {
  if (trigger.kind === "event") {
    return trigger.connection && trigger.event
      ? `Triggers on ${trigger.event} event`
      : "Select connection and event";
  }

  if (trigger.kind === "timer") {
    return trigger.interval && trigger.units
      ? `Triggers every ${trigger.interval} ${trigger.units}s`
      : "Select interval and units";
  }

  if (trigger.kind === "manual") {
    return "Triggers on url or user trigger";
  }

  return "";
}

function TriggerDetailsPanel({
  trigger,
  setActivePanel,
  updateTrigger,
  activeStep,
  orchestration,
  triggerEvents,
  editorType,
}) {
  const triggerType = triggers[trigger.kind] || {};
  const connectionHasNoEvents =
    trigger.connection && triggerEvents && Object.keys(triggerEvents).length === 0;

  const validationErrorMessage = validationErrorMessageGenerator(trigger);

  return {
    title: trigger.name,
    buttonTitle: "Trigger details",
    stepSelector: (
      <Step
        key="details"
        onClick={() => setActivePanel(0)}
        style={{ padding: "0px" }}
      >
        <StepLabel
          optional={
            validationErrorMessage ? (
              <Typography variant="subtitle1" color="error">
                {validationErrorMessage}
              </Typography>
            ) : (
              <Typography variant="subtitle1" color="primary">
                {triggerReadyStatus(trigger)}
              </Typography>
            )
          }
          error={Boolean(validationErrorMessage)}
        >
          {!triggerType.name ? (
            <div className="pr4">Unknown trigger</div>
          ) : (
            <div>{triggerType.name}</div>
          )}
        </StepLabel>
      </Step>
    ),
    stepDisplay: (
      <div
        className={styles.eventConfigurationContainer}
        data-testid="dtid_orchestration_flow_event_configuration_container"
      >
        <div>
          {trigger.kind === "timer" && (
            <Fragment>
              <div className={styles.timerEvent}>
                <p>Every</p>
                <TriggerTimerSelector
                  interval={trigger.interval || triggerType.initialState.interval}
                  units={trigger.units || triggerType.initialState.units}
                  setTriggerEvent={(update) =>
                    updateTrigger({ ...update, kind: "timer" })
                  }
                />
              </div>
              <div
                className={styles.stepContent}
                style={{
                  opacity: activeStep === 0 ? "1" : "0",
                  height: activeStep === 0 ? "auto" : "0",
                  pointerEvents: activeStep === 0 ? "auto" : "none",
                }}
                data-testid="dtid_orchestration_flow_step_content"
              >
                <TimerTriggerStepContent
                  trigger={trigger}
                  setTriggerEvent={updateTrigger}
                />
              </div>
            </Fragment>
          )}
          <span className={styles.vertically} />
          {trigger.kind === "event" && (
            <>
              <div className={styles.connectionContainer}>
                <ConnectionSelector
                  id={trigger?.connection?.id || trigger.connection}
                  setConnection={(update) => {
                    updateTrigger({
                      ...update,
                      kind: "event",
                      conditions: trigger.conditions,
                    });
                  }}
                  uuid={trigger.uuid}
                />
              </div>
              {connectionHasNoEvents && trigger.connection && !trigger.event && (
                <p
                  className={styles.subTitle}
                >{`Selected connection has no events`}</p>
              )}
              {!connectionHasNoEvents && trigger.connection && (
                <div className={styles.connectionContainer}>
                  <TriggerEventSelector
                    triggerEvent={trigger.event}
                    setTriggerEvent={(update) =>
                      updateTrigger({ ...update, kind: "event" })
                    }
                    triggerEvents={triggerEvents}
                  />
                </div>
              )}
            </>
          )}
          {trigger.kind === "manual" && (
            <UserTriggerStepContent
              orchestration={orchestration}
              editorType={editorType}
            />
          )}
          {trigger.kind === "interactive" && (
            <div className={styles.manualEvent}>
              <div className={styles.manualTriggerText}>Interactive trigger</div>
            </div>
          )}
        </div>
      </div>
    ),
  };
}

function TriggerConditionsPanel(props) {
  const { trigger, setActivePanel, updateTrigger } = props;
  return {
    title: "Conditions",
    buttonTitle: "Add conditions",
    stepSelector: (
      <Step key="conditions" onClick={() => setActivePanel(1)}>
        <StepLabel
          optional={
            trigger.conditions?.length ? (
              <Typography variant="subtitle1" color="primary">
                {`${trigger.conditions.length} condition${
                  trigger.conditions.length > 1 ? "s" : ""
                } in effect`}
              </Typography>
            ) : (
              <Typography variant="subtitle1" color="primary">
                No conditions in effect
              </Typography>
            )
          }
        >
          Conditions
        </StepLabel>
      </Step>
    ),
    stepDisplay: (
      <TriggerConditions
        conditions={trigger.conditions}
        onUpdate={(update) => updateTrigger({ ...update, kind: trigger.kind })}
      />
    ),
  };
}

export default TriggerStep;
