import { SHOW_MODAL, HIDE_MODAL } from "./modal.actions";

const initialState = {
  name: null,
  props: {},
};

export default function modal(state = initialState, action) {
  switch (action.type) {
    case SHOW_MODAL:
      return {
        name: action.payload.name,
        props: action.payload.props,
      };
    case HIDE_MODAL:
      return initialState;
    default:
      return state;
  }
}
