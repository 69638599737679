import React from "react";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import DateFnsUtils from "@date-io/date-fns";
import variables from "../assets/styles/variables.scss";
import "./App.module.scss";
import "./fontawesome";

const theme = createTheme({
  props: {
    MuiButtonBase: {
      disableRipple: true,
    },
  },
  typography: {
    fontFamily: [
      `'Lato'`,
      `'Helvetica Neue'`,
      `Arial`,
      `Helvetica`,
      `sans-serif`,
    ].join(", "),
  },
  palette: {
    primary: {
      main: "#025687",
    },
    secondary: {
      main: "#373737",
    },
  },
  overrides: {
    MuiToolbar: {
      regular: {
        minHeight: "1px !important",
      },
    },
    MuiPaper: {
      root: {
        borderRadius: 6,
      },
      rounded: {
        borderRadius: 6,
      },
    },
    MuiStepLabel: {
      label: {
        color: variables.lightgraytext,
        fontSize: "20px",

        "&:hover": {
          cursor: "pointer",
        },

        "&$active": {
          color: variables.darkgrayhover,
          fontSize: "20px",

          "&:hover": {
            cursor: "auto",
          },
        },
        "&$completed": {
          color: variables.lightgraytext,
        },
      },
    },
  },
});

export default function MaterialUiProvider({ children }) {
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </MuiPickersUtilsProvider>
  );
}
