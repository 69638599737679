import jsonpath from "jsonpath";
import React, { useContext } from "react";
import { executeExpression } from "./api";
import { astToExpression, findParentElements } from "./util";

const defaultValue = {
  rootSchema: {},
  context: {},
  output: {},
  executeLookup: () => {},
  setOutput: () => {},
};

export const Context = React.createContext(defaultValue);

export function useEditorContext() {
  return useContext(Context);
}

export function getContextValue({
  schema,
  context,
  executeLookup,
  transforms,
  output,
  setOutput,
}) {
  return {
    context,
    rootSchema: schema,
    executeLookup,
    transforms,
    output,
    setOutput,
    executeAst: async (element, ctx) => {
      const response = await executeExpression(astToExpression(element), ctx || {});
      const { data, error } = response;
      let err = error;
      if (error && data.cause) {
        let cause = data.cause;
        let elements = [];
        const messages = [cause.message];
        while ((cause = cause.cause)) {
          const { path, message } = cause;
          messages.push(message);
          if (path) {
            const [errorElement] = jsonpath.query(element, path);
            if (errorElement) {
              const {
                cause: { validationErrors },
              } = cause;
              errorElement.validationErrors = {};
              validationErrors?.forEach(({ instancePath, params, message: msg }) => {
                const property =
                  instancePath.split("/").pop() || params.missingProperty;
                errorElement.validationErrors[property] = true;
                messages.push(`${property} ${msg}`);
              });
              elements = [
                ...elements,
                errorElement,
                ...findParentElements(element, errorElement),
              ];
            }
          }
        }
        elements.forEach((elm) => {
          elm.error = true;
        });
        err = {
          elements,
          messages,
        };
        setOutput(data);
      }
      return { data, error: err };
    },
  };
}
