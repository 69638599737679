import React from "react";
import { useDispatch } from "react-redux";
import DLXModal from "../DLXModal/DLXModal";
import { CONFIRM, CANCEL } from "../../state/constants";

export default function ConfirmationDialog({ title = "Confirm", messageText }) {
  const dispatch = useDispatch();
  return (
    <DLXModal
      open={true}
      title={title}
      size="micro"
      onSubmit={() => {
        dispatch({ type: CONFIRM });
      }}
      onCancel={() => {
        dispatch({ type: CANCEL });
      }}
      submitButtonLabel="Yes"
      cancelButtonLabel="No"
    >
      {messageText}
    </DLXModal>
  );
}
