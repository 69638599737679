import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Menu } from "../Menu";
import { MenuItem } from "../MenuItem";
import { useSelector } from "react-redux";
import { getActors } from "../../../state/actors/selectors";

const stepsTransformed = (actors) =>
  Object.entries(actors).map(([key, value]) => ({
    name: key,
    label: value.name,
    Icon: (props) => <FontAwesomeIcon icon={value.icon} {...props} />,
    value,
  }));

const StepTypeRow = ({ label, Icon }) => (
  <div
    style={{
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
      width: "100%",
      padding: 6,
    }}
  >
    <Icon style={{ margin: 0, fontSize: "2em" }} />
    <p style={{ fontSize: 12, margin: 0 }}>{label}</p>
  </div>
);

/**
 * @param {{
 *  selected?: string,
 *  onClose: Function,
 *  onSelect: Function,
 *  anchorEl: any,
 *  setAnchorEl: Function,
 *  index: number
 * }} props
 */
const StepMenu = ({ selected, onClose, onSelect, anchorEl, setAnchorEl, index }) => {
  const actors = useSelector(getActors);
  const steps = stepsTransformed(actors);
  const options = steps.filter((step) => step.name !== selected);

  return (
    <Menu open={Boolean(anchorEl)} anchorEl={anchorEl} onClose={onClose}>
      {options.map((option) => (
        <MenuItem
          key={option.name}
          selected={option.name !== selected}
          onClick={() => {
            onSelect(index, option.value);
            setAnchorEl(null);
          }}
        >
          <StepTypeRow {...option} />
        </MenuItem>
      ))}
    </Menu>
  );
};

export default StepMenu;
