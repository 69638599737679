import {
  formatDistance,
  parseISO,
  format,
  startOfHour,
  endOfHour,
  startOfDay,
  endOfDay,
  startOfWeek,
  endOfWeek,
  startOfMonth,
  endOfMonth,
} from "date-fns";

const DEFAULT_DATE_FORMAT = "MMM dd, yy h:mm:ss a";

const isValidDate = (date) => date instanceof Date && !isNaN(date);

export const timeSince = (date) => {
  if (typeof date === "string") {
    date = parseISO(date);
  }
  if (!isValidDate(date)) {
    throw new Error(`${date} is invalid`);
  }
  return formatDistance(date, new Date(Date.now()));
};

export const formatDate = (date, dateFormat = DEFAULT_DATE_FORMAT) => {
  const origDate = date;
  if (typeof date === "string") {
    date = parseISO(date);
    if (date.toString() === "Invalid Date") {
      return origDate;
    }
  }
  if (!isValidDate(date)) {
    throw new Error(`${date} is invalid`);
  }
  return format(date, dateFormat);
};

export const deltaT = (t1, t2) => {
  const dT = new Date(t2).valueOf() - new Date(t1).valueOf();
  return (
    (dT < 1000 && `${Math.round(dT)}ms`) ||
    (dT < 60 * 1000 && `${Math.round(dT / 1000)}s`) ||
    (dT < 60 * 60 * 1000 && `${(dT / 60000).toFixed(1)}min`) ||
    (dT < 60 * 60 * 60 * 1000 &&
      `${(dT / 3600000).toFixed(1)}hr${dT / 3600000 > 1 && "s"}`) ||
    `${(dT / 86400000).toFixed(1)}day${dT / 86400000 > 1 && "s"}`
  );
};

export const createEndStartDates = (period, date) => {
  let timeFrom, timeTo;
  switch (period) {
    case "hour":
      timeFrom = startOfHour(date);
      timeTo = endOfHour(date);
      break;

    case "day":
      timeFrom = startOfDay(date);
      timeTo = endOfDay(date);
      break;

    case "week":
      timeFrom = startOfWeek(date, { weekStartsOn: 1 });
      timeTo = endOfWeek(date, { weekStartsOn: 1 });
      break;

    case "month":
      timeFrom = startOfMonth(date);
      timeTo = endOfMonth(date);
      break;

    default:
      break;
  }
  return { timeFrom, timeTo };
};

export const getGMTTimezone = (dateString) => {
  const date = new Date(dateString);

  const gmtOffsetMinutes = date.getTimezoneOffset();
  const gmtOffsetHours = -gmtOffsetMinutes / 60;
  const gmtOffsetSign = gmtOffsetHours >= 0 ? "+" : "-";
  const gmtOffsetHoursFormatted = Math.abs(Math.floor(gmtOffsetHours));
  const gmtOffsetMinutesFormatted = Math.abs(gmtOffsetMinutes % 60);

  return `GMT ${gmtOffsetSign}${gmtOffsetHoursFormatted}:${gmtOffsetMinutesFormatted
    .toString()
    .padStart(2, "0")}`;
};
