import React from "react";
import { withStyles } from "@material-ui/styles";
import MuiMenu from "@material-ui/core/Menu";
import Grow from "@material-ui/core/Grow";

export const Menu = withStyles({
  paper: {
    borderRadius: 6,
    display: "inline-flex",
  },
  list: {
    padding: 0,
  },
})((props) => (
  <MuiMenu
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    TransitionComponent={Grow}
    {...props}
  />
));
