import { Button, Dialog, DialogActions } from "@material-ui/core";
import styles from "./DLXFrameModal.module.scss";
import React from "react";

function DLXFrameModal({ title, url, open, onClose }) {
  return (
    <Dialog fullWidth={true} maxWidth="md" open={open} onClose={onClose}>
      <div className={styles.container}>
        <iframe
          height={"100%"}
          className={styles.frame}
          width="100%"
          title={title}
          src={url}
        />
      </div>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}
export default DLXFrameModal;
