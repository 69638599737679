import useGetCollection from "../hooks/useGetCollection";
import useGetRecord from "../hooks/useGetRecord";

const slice = "users";

export default function useGetUser(id) {
  const [fetched, user] = useGetRecord(slice, id);
  return { fetched, user };
}

export function useGetUsersCollection() {
  const [loaded, users] = useGetCollection(slice);
  return { loaded, users };
}
