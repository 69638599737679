import React, { useState } from "react";
import styles from "./SchemaInput.module.scss";
import { IconButton } from "@material-ui/core";
import UserReadingInterface from "../../../OrchestrationFlow/UserReadingInterface";
import JsonViewer from "../../../jsonviewer/JsonViewer";
import DLXModal from "../../../DLXModal/DLXModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DLXTooltip from "../../../DLXTooltip/DLXTooltip";
import { useOrchestrationEditorContext } from "../../BaseEditor";

/**
 * @param {{
 *  step: Step,
 *  stepNum: number,
 * }} props
 */
const UserReading = ({ step, stepNum }) => {
  const [showDemo, setShowDemo] = useState(false);

  return (
    <div style={{ paddingLeft: "15px" }}>
      <Demo show={showDemo} setShow={setShowDemo} step={step} stepNum={stepNum} />
      <div className={styles.iconContainer}>
        <DLXTooltip text="Test user reading">
          <IconButton
            className={styles.iconButton}
            onClick={() => setShowDemo(true)}
          >
            <FontAwesomeIcon icon="running" />
          </IconButton>
        </DLXTooltip>
      </div>
    </div>
  );
};

/**
 *
 * @param {{
 *   step: Step,
 *   show: boolean,
 *   setShow: function,
 *   stepNum: number
 * }} props
 */
const Demo = ({ step, show, setShow, stepNum }) => {
  const { executionInfo } = useOrchestrationEditorContext();
  const { getStepInputs } = executionInfo;
  const [data, setData] = useState({});
  return (
    <DLXModal
      hideSubmitButton
      onCancel={() => setShow(false)}
      cancelButtonLabel="Close"
      open={show}
      size="small"
      title={"User input demo"}
    >
      <UserReadingInterface
        {...step}
        submitUserInput={setData}
        stepNum={stepNum}
        getStepInputs={getStepInputs}
      />
      <div className={styles.demoData}>
        {Object.keys(data).length ? (
          <JsonViewer
            title="Form output"
            json={data}
            allowSelect={false}
            trail={true}
            onSelect={() => null}
          />
        ) : (
          <p>Click submit to see form data output</p>
        )}
      </div>
    </DLXModal>
  );
};

export default UserReading;
