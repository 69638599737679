import React, {
  createContext,
  useContext,
  useRef,
  useEffect,
  useCallback,
  useState,
} from "react";
import { useHistory } from "react-router-dom";
import ConfirmDialog from "../../ConfirmDialog";

const DiscardPromptContext = createContext(null);

export const useDiscardPrompt = () => useContext(DiscardPromptContext);

export const DiscardDialog = () => {
  const { isOpen, cancel, confirm } = useDiscardPrompt();
  return (
    <ConfirmDialog
      open={isOpen}
      title={"Are you sure?"}
      onClickYes={confirm}
      onClickNo={cancel}
      label="CLOSE_EDITOR"
      submitButtonLabel="Discard changes"
      cancelButtonLabel="Cancel"
      messageText="Your changes will be lost if you don't save them."
    />
  );
};

export const DiscardPromptProvider = ({
  shouldPrompt = false,
  fallback = "",
  children,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [redirectUrl, setRedirectUrl] = useState(fallback);
  const childRef = useRef(null);
  const history = useHistory();

  useEffect(() => {
    const handleClick = (e) => {
      if (shouldPrompt) {
        if (!childRef.current?.contains(e.target) && e.target.tagName === "A") {
          setIsOpen(true);
          e.preventDefault();
          e.stopPropagation();
          setRedirectUrl(new URL(e.target.href).pathname);
        }
      }
    };
    document.body.addEventListener("click", handleClick);
    return () => {
      document.body.removeEventListener("click", handleClick);
    };
  }, [shouldPrompt]);

  useEffect(() => {
    // display the browser's native pop up if a user navigates externally or closes the window
    const handleUnload = (e) => {
      // only running this in production because it fires everytime the development server refreshes
      if (process.env.NODE_ENV === "production" && shouldPrompt) {
        e.preventDefault();
        e.returnValue = "Are you sure you want to exit?";
        return e.returnValue;
      }
      return null;
    };
    window.addEventListener("beforeunload", handleUnload);
    return () => {
      window.removeEventListener("beforeunload", handleUnload);
    };
  }, [shouldPrompt]);

  const cancel = useCallback(() => {
    setIsOpen(false);
    setRedirectUrl(fallback);
  }, []);

  const prompt = useCallback(() => setIsOpen(true), []);

  const confirm = useCallback(() => {
    if (redirectUrl) {
      history.push(redirectUrl);
    } else {
      history.goBack();
    }
  }, [history, redirectUrl]);

  return (
    <DiscardPromptContext.Provider
      value={{
        isOpen,
        shouldPrompt,
        cancel,
        confirm,
        prompt,
      }}
    >
      <DiscardDialog />
      {React.cloneElement(children, { ref: childRef })}
    </DiscardPromptContext.Provider>
  );
};
