import CircularProgress from "@material-ui/core/CircularProgress";
import React from "react";
import Backdrop from "@material-ui/core/Backdrop";
import { withStyles } from "@material-ui/core";

export const LoadingIndicator = withStyles({
  root: {
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    height: "100vh",
    justifyContent: "center",
    margin: "0 auto",
    textAlign: "center",
    width: "100%",
  },
})(({ classes, ...props }) => {
  return (
    <div className={classes.root} {...props}>
      <CircularProgress />
    </div>
  );
});

export default function LoadingScreen({ overlay }) {
  if (overlay) {
    return (
      <Backdrop open={true} style={{ zIndex: 1200 }}>
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  }
  return <LoadingIndicator />;
}
