import React, { useCallback } from "react";
import DLXModal from "../../../components/DLXModal/DLXModal";
import styles from "./OrchestrationExecutionViewer.module.scss";
import { ORCHESTRATIONS } from "../../routes";
import useOrchestrationExecutionViewer from "./useOrchestrationExecutionViewer";
import { useHistory } from "react-router-dom";
import OrchestrationExecutionViewer from "./OrchestrationExecutionViewer";
import { abortOrchestration } from "../../../state/orchestrations/orchestration.reducer";
import { useDispatch } from "react-redux";
import { useCurrentUserContext } from "../../../contexts/current-user/Provider";

export default function OrchestrationExecutionViewerModal({
  orchestrationId,
  executionId,
  onClose,
}) {
  const history = useHistory();
  const dispatch = useDispatch();
  const { currentUser } = useCurrentUserContext();

  const props = useOrchestrationExecutionViewer({
    orchestrationId,
    executionId,
  });

  const abortExecution = useCallback(
    async (execution) => {
      dispatch(abortOrchestration(currentUser, execution));
    },
    [currentUser, dispatch]
  );

  if (props.error) {
    return null;
  }

  return (
    <DLXModal
      hideSubmitButton
      onCancel={() => {
        onClose();
        history.push(ORCHESTRATIONS);
      }}
      cancelButtonLabel="Close"
      open
      size="medium"
      title={"Execution Details"}
    >
      <div className={styles.modalContainer}>
        <OrchestrationExecutionViewer
          {...props}
          abortExecution={abortExecution}
          showDetails={true}
        />
      </div>
    </DLXModal>
  );
}
