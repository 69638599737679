/* eslint-disable */
import React, { useEffect } from "react";
import Dialog from "@material-ui/core/Dialog";
import Button from "@material-ui/core/Button";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import NiceModal, { useModal } from "@ebay/nice-modal-react";
import { discoverProvider } from "../services/sso";
import { useSessionContext } from "../contexts/session/Provider";
import { useIdleTimer } from "react-idle-timer";
import { useCurrentUserContext } from "../contexts/current-user/Provider";
import { isInIframe } from "../utils";
import { openIframeSignin } from "@dlx/components/IFrame/RenderIFrame";

const DEFAULT_TIMEOUT = 30;

const getTimeoutForCustomer = (customer) => {
  const timeout = customer.idleSessionTimeout || DEFAULT_TIMEOUT;
  return timeout * 60 * 1000;
};

export function SessionIdleTimer() {
  const { isIdle, handleLogout, handleExpiredSession } = useSessionContext();
  const { customer } = useCurrentUserContext();

  useEffect(() => {
    if (isIdle) {
      NiceModal.show(IdleDialog)
        .then(() => {
          if (isInIframe()) {
            return openIframeSignin();
          } else {
            return discoverProvider({ action: "popup" });
          }
        })
        .catch(() => handleLogout());
    } else {
      NiceModal.hide(IdleDialog);
    }
  }, [isIdle]);

  const { reset } = useIdleTimer({
    onIdle: async () => {
      handleExpiredSession();
    },
    onAction: () => {
      reset();
    },
    throttle: 250,
    timeout: getTimeoutForCustomer(customer),
    crossTab: true,
  });

  useEffect(() => {
    if (!isIdle) {
      reset();
    }
  }, [isIdle]);
  return null;
}

export const IdleDialog = NiceModal.create(() => {
  const modal = useModal();
  return (
    <Dialog open={modal.visible}>
      <DialogTitle
        style={{
          border: "1px solid #dadce0",
          borderTopLeftRadius: "6px",
          borderTopRightRadius: "6px",
          backgroundColor: "#f6f6f6",
        }}
      >
        Your session has expired
      </DialogTitle>
      <DialogContent>
        <DialogContentText>Sign in again to continue.</DialogContentText>
        <DialogActions>
          <Button
            variant="contained"
            disableElevation
            disableRipple
            onClick={() => {
              modal.reject();
              modal.hide();
            }}
            style={{
              textTransform: "capitalize",
            }}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            disableElevation
            disableRipple
            onClick={() => {
              modal.resolve();
              modal.hide();
            }}
            style={{
              textTransform: "capitalize",
            }}
          >
            Sign in
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
});
