import React from "react";
import { ScitaraLogo } from "../../icons";
import AccountDropdown from "./AccountDropdown";
import AppBar from "@material-ui/core/AppBar";
import styles from "./Header.module.scss";
import Toolbar from "@material-ui/core/Toolbar";
import { Link } from "react-router-dom";
import { ROOT } from "../../views/routes";
import { useCurrentUserContext } from "../../contexts/current-user/Provider";
import { ScitaraHelpButton } from "../ScitaraHelp";
import { isInIframe } from "@dlx/utils/index";

export function Header({ children }) {
  const { currentUser } = useCurrentUserContext();
  return (
    <div className={styles.header}>
      <div className={styles.logoContainer}>
        <ScitaraLogoContent />
      </div>
      {currentUser && (
        <div className={styles.controlsContainer}>
          <div className={styles.controls}>{children}</div>
          <AccountDropdown />
        </div>
      )}
    </div>
  );
}

export const AppHeader = () => {
  return (
    <AppBar
      position="absolute"
      square
      elevation={0}
      classes={{
        root: styles.appBar,
      }}
    >
      <Toolbar disableGutters>
        <div className={styles.headerContainer}>
          <Header>
            <div className={styles.HelpLinks}>
              <ScitaraHelpButton />
            </div>
          </Header>
        </div>
      </Toolbar>
    </AppBar>
  );
};

export default AppHeader;

export function ScitaraLogoContent() {
  if (isInIframe()) {
    return <ScitaraLogo className={styles.headerLogo} />;
  }
  return (
    <Link to={ROOT}>
      <ScitaraLogo className={styles.headerLogo} />
    </Link>
  );
}
