import React, { useState } from "react";
import DLXFormModal from "../../../DLXModal/DLXModal";
import JsonForm from "../../../JsonForm/JsonForm";

export default function ActionOptionsDialog({
  action,
  onSubmit,
  onCancel,
  executeCommand,
}) {
  const [formData, setFormData] = useState({});

  const handleSubmit = () => {
    onSubmit(formData);
    onCancel();
  };

  const handleOnChange = ({ data }) => {
    setFormData(data);
  };

  return (
    <DLXFormModal
      open={true}
      title={`Enter options for ${action.title} action`}
      size="extra_small"
      onSubmit={handleSubmit}
      onCancel={onCancel}
      submitButtonLabel="Execute action"
    >
      <JsonForm
        data={formData}
        onChange={handleOnChange}
        schema={action.optionsSchema}
        executeLookupCommand={(lookup) =>
          executeCommand(lookup.command, lookup.options)
        }
      />
    </DLXFormModal>
  );
}
