export const STEP_STATUS = Object.freeze({
  EXECUTING: "executing",
  FUTURE: "future",
  COMPLETED: "completed",
  SKIPPED: "skipped",
  ABORTED: "aborted",
  FAILED: "failed",
  CONTINUED: "continued",
  WAITING: "waiting",
  STARTED: "started",
});

export const EDITOR_TYPES = Object.freeze({
  ADD: "add",
  UPDATE: "update",
  VIEW: "view",
});

export const ORCHESTRATION_VERSION_ONE = 1;
