import React, { useEffect, useState } from "react";
import CodeSnippetEditor from "../../../CodeEditor/CodeSnippetEditor";
import styles from "./CodeInput.module.scss";
import { InputDataSelector } from "../../../SourceDataSelector/SourceDataSelector";
import { IconButton } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import beautify from "js-beautify";
import DLXTooltip from "../../../DLXTooltip/DLXTooltip";
import functionTransformHelp from "./function-transform-help";

/**
 * @param {{
 *  step: Step,
 *  stepNum: number,
 *  onUpdate: function,
 * }} props
 */
const CodeInput = ({ step, stepNum, onUpdate }) => {
  return <CodeEditor step={step} stepNum={stepNum} onUpdate={onUpdate} />;
};

const JAVASCRIPT = "javascript";
const PYTHON = "python";

/**
 * @param {{
 *  step: Step,
 *  stepNum: number,
 *  onUpdate: function,
 * }} props
 */
const CodeEditor = ({ step, stepNum, onUpdate }) => {
  const [language, setLanguage] = useState(step.language || JAVASCRIPT);

  useEffect(() => {
    if (!step.language || !step.content) {
      onUpdate({
        content: functionTransformHelp[language],
        language,
      });
    }
    // eslint-disable-next-line
  }, [stepNum, language]);

  const handleLanguageChange = (evt) => {
    const newLanguage = evt.target.value;
    const stepContent = step?.content.trim();
    const isCodeUpdated =
      stepContent === "" || stepContent === functionTransformHelp[language].trim();

    const newCode = isCodeUpdated
      ? functionTransformHelp[newLanguage]
      : step?.content;

    setLanguage(newLanguage);

    onUpdate({
      content: newCode,
      language: newLanguage,
    });
  };
  const handleChange = (code) => {
    onUpdate({ content: code, source: "inline", language });
  };

  const handleBeautify = () => {
    onUpdate({
      content: beautify(step.content),
      source: "inline",
      language: step.language,
    });
  };

  return (
    <div className={styles.codeInputContainer}>
      <div className="flex items-center justify-between">
        <div className={styles.iconContainer}>
          <DLXTooltip placement="top-start" text="Browse for data reference">
            <IconButton className={styles.iconButton}>
              <InputDataSelector stepNum={stepNum} useCopy={true} />
            </IconButton>
          </DLXTooltip>
          {language === JAVASCRIPT && (
            <DLXTooltip text="Beautify code">
              <IconButton className={styles.iconButton} onClick={handleBeautify}>
                <FontAwesomeIcon icon="check" />
              </IconButton>
            </DLXTooltip>
          )}
        </div>

        <select
          value={language}
          onChange={handleLanguageChange}
          className="ph2 pv1 rounded2 ba border-secondary"
          data-testid="dtid_select"
        >
          <option value={JAVASCRIPT}>JavaScript</option>
          <option value={PYTHON}>Python</option>
        </select>
      </div>
      <div className={styles.codeEditorContainer}>
        <section className={styles.editor}>
          <CodeSnippetEditor
            height="100%"
            fontSize={16}
            value={step.content}
            language={language}
            onChange={handleChange}
          />
        </section>
      </div>
    </div>
  );
};

export default CodeInput;
