import React from "react";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import styles from "./LoadingButton.module.scss";
import clsx from "clsx";

/**
 * @param {{
 *  children: React.ReactNode,
 *  className?: string,
 *  totalRecords: number,
 *  hasMoreRecords: boolean,
 *  emptyText: string,
 *  onClick: React.MouseEventHandler,
 *  style?: React.CSSProperties,
 *  dataTestIdSuffix? : string,
 * }} props
 */
export function LoadingButton({
  children,
  className,
  totalRecords,
  hasMoreRecords,
  emptyText,
  onClick,
  style,
  dataTestIdSuffix,
}) {
  const buttonElement = hasMoreRecords ? (
    <Button
      onClick={onClick}
      style={style}
      className={className}
      data-testid={`dtid_loading_button_has_records${
        dataTestIdSuffix ? `_` + dataTestIdSuffix : ``
      }`}
    >
      {children}
    </Button>
  ) : null;

  return totalRecords === 0 ? (
    <div
      className={styles.noRecords}
      data-testid="dtid_loading_button_has_no_records"
    >
      {emptyText}
    </div>
  ) : (
    buttonElement
  );
}

/**
 * @param {{
 *  children: React.ReactNode,
 *  className?: string,
 *  hasMoreRecords?: boolean,
 *  totalRecords?: number,
 *  emptyText?: string,
 *  loading?: boolean,
 *  onClick?: React.MouseEventHandler,
 *  dataTestIdSuffix? : string,
 *  style?: React.CSSProperties,
 * }} props
 */
export default function LoadMoreButton({
  children,
  className,
  hasMoreRecords = false,
  totalRecords = 0,
  emptyText = "No records found",
  loading = false,
  onClick = () => {},
  dataTestIdSuffix = null,
  style,
}) {
  return loading ? (
    <Button
      style={style}
      disabled
      className={clsx(className, styles.hideButton)}
      data-testid="dtid_loading_button"
    >
      <CircularProgress
        size={32}
        color="inherit"
        style={{ position: "absolute", margin: "20px" }}
      />
      <span style={{ visibility: "hidden" }}>{children}</span>
    </Button>
  ) : (
    <LoadingButton
      totalRecords={totalRecords}
      hasMoreRecords={hasMoreRecords}
      emptyText={emptyText}
      onClick={onClick}
      style={style}
      className={className}
      dataTestIdSuffix={dataTestIdSuffix}
    >
      {children}
    </LoadingButton>
  );
}
