import React from "react";
import Sidebar from "./sidebar/Sidebar";
import { Redirect, Route, Switch } from "react-router-dom";
import Header from "../../components/header/Header";
import useStyles from "./sidebar/useSidebarStyles";
import styles from "./Dashboard.module.scss";
import {
  UpdateOrchestrationEditor,
  AddOrchestrationEditor,
  ViewOrchestrationEditor,
} from "../../components/orchestrationeditor/OrchestrationEditor";
import { useScitaraHelp } from "@dlx/components/ScitaraHelp";

const UserPreferencesForm = React.lazy(() =>
  import("../../components/forms/UserPreferencesForm")
);
const Administration = React.lazy(() => import("../administration/Administration"));
const AuditTrail = React.lazy(() => import("../AuditTrail/AuditTrailView"));
const Connections = React.lazy(() => import("../ConnectionsView/ConnectionsView"));
const EventStream = React.lazy(() => import("../eventstream/EventStreamView"));
const Orchestrations = React.lazy(() =>
  import("../OrchestrationsView/OrchestrationsViewer")
);
const PlatformHealth = React.lazy(() => import("../platformhealth/PlatformHealth"));

const Dashboard = () => {
  const classes = useStyles();
  const { isOpen, widgetWidth } = useScitaraHelp();

  return (
    <div
      className={classes.root}
      style={{
        width: isOpen ? `calc(100% - ${widgetWidth - 10}px)` : "100%",
        transition: "0.5s width cubic-bezier(.55,0,.1,1)",
      }}
    >
      <Header />
      <div className={styles.contentWrapper}>
        <React.Suspense fallback={null}>
          <Sidebar />
          <React.Suspense fallback={null}>
            <Switch>
              <Route
                exact
                path="/orchestrations/:id/edit"
                component={UpdateOrchestrationEditor}
              />
              <Route
                exact
                path={"/orchestrations/new"}
                component={AddOrchestrationEditor}
              />
              <Route
                exact
                path="/orchestrations/:id/view-executed"
                component={ViewOrchestrationEditor}
              />

              <Route
                exact
                path="/user-preferences"
                component={UserPreferencesForm}
              />

              <Route path="/orchestrations" component={Orchestrations} />
              <Route exact path="/eventstream" component={EventStream} />
              <Route exact path="/audittrail" component={AuditTrail} />
              <Route path="/administration" component={Administration} />
              <Route exact path="/platform-health" component={PlatformHealth} />
              <Route
                path={["/connectors", "/connections", "/attachments/:uuid"]}
                component={Connections}
              />
              <Redirect to="/connections" />
            </Switch>
          </React.Suspense>
        </React.Suspense>
      </div>
    </div>
  );
};

export default Dashboard;
