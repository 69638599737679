import React, { useState } from "react";
import styles from "./FrameDrawer.module.scss";
import { Drawer, IconButton } from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import { selectFrameUrl } from "../orchestrationeditor/state/selectors";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import { setFrameUrl } from "../orchestrationeditor/state/actions";
import DLXTooltip from "../DLXTooltip/DLXTooltip";
import FullscreenIcon from "@material-ui/icons/Fullscreen";
import DLXFrameModal from "../DLXFrameModal";

const FrameDrawer = () => {
  const dispatch = useDispatch();
  const frameUrl = useSelector(selectFrameUrl);
  const [modalUrl, setModalUrl] = useState("");

  const handleClose = () => {
    dispatch(setFrameUrl({ frameUrl: null }));
  };

  const handleExpand = (e) => {
    e.stopPropagation();
    const url =
      document.getElementById("drawer-frame")?.contentWindow?.location?.href;
    if (url) {
      setModalUrl(url);
      dispatch(setFrameUrl({ frameUrl: null }));
    }
  };

  return (
    <Drawer variant="persistent" anchor="right" open={!!frameUrl}>
      <DLXFrameModal
        open={!!modalUrl}
        onClose={() => setModalUrl("")}
        url={modalUrl}
        title="Execution view"
      />
      <div className={styles.drawer}>
        <div className={styles.drawerHeader}>
          <IconButton onClick={handleClose}>
            <ChevronRightIcon />
          </IconButton>
          <DLXTooltip text={"Expanded execution view"}>
            <IconButton className={styles.expandButton} onClick={handleExpand}>
              <FullscreenIcon />
            </IconButton>
          </DLXTooltip>
        </div>
        <div className={styles.container}>
          <iframe
            id="drawer-frame"
            className={styles.frame}
            title="Execution view"
            src={frameUrl}
          />
        </div>
      </div>
    </Drawer>
  );
};

export default FrameDrawer;
