import _ from "lodash";

export const deMinifyMappings = (mappings) => {
  if (!mappings) {
    return {};
  }
  const mapsCopy = _.cloneDeep(mappings);
  Object.entries(mapsCopy).forEach(([k, v]) => {
    if (typeof v === "string") {
      if (/^\$\$/.test(v)) {
        const value = v.split(".arrayMapping[")[0];
        const parts = v.match(/\.arrayMapping\[([^\]]*)\]/);
        mapsCopy[k] = { type: "path", value: value.slice(2) };
        if (parts) {
          mapsCopy[k].mappings = parts[1]
            .split(",")
            .filter((m) => m.split(">>"))
            .map((m) => m.split(">>"))
            .map(([sourceValue, targetValue]) => {
              return {
                source: {
                  type: "path",
                  value: sourceValue,
                },
                target: {
                  type: "path",
                  value: targetValue,
                },
              };
            });
        }
      } else {
        mapsCopy[k] = { type: "literal", value: v };
      }
    } else if (typeof v !== "object") {
      mapsCopy[k] = { type: "literal", value: v };
    } else if (v === null) {
      delete mapsCopy[k];
    }
  });
  return mapsCopy;
};
