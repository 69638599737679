import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useCurrentUserContext } from "../../../contexts/current-user/Provider";
import * as actions from "./lease.actions";
import * as selectors from "./lease.selectors";

/**
 * A React hook to manage leasing a connection.
 * @memberof module:React
 */

export default function useLeaseConnection() {
  const dispatch = useDispatch();
  const connection = useSelector(selectors.selectLeasedConnection);
  const isInQueue = useSelector(selectors.isInQueue);
  const { currentUser } = useCurrentUserContext();
  const controllingUser = useSelector(selectors.selectControllingUser);
  const queuedLeases = useSelector(selectors.selectQueuedLeases);
  const isLeaseHolder = currentUser.id === controllingUser?.id;

  const requestLease = React.useCallback(
    (connectionId, metadata) =>
      dispatch(actions.requestLease(connectionId, metadata)),
    [dispatch]
  );

  const abandonLease = React.useCallback(
    () => dispatch(actions.abandonLease()),
    [dispatch]
  );

  return {
    abandonLease,
    connection,
    controllingUser,
    isInQueue,
    isLeaseHolder,
    queuedLeases,
    requestLease,
  };
}
