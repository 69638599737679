import { useEffect, useMemo, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { dbLoad } from "../reducers/dbAdapter";

const filtertoQueryString = (filter) => {
  if (!filter) {
    return "";
  }
  if (typeof filter === "string") {
    return filter;
  }
  return Object.entries(filter)
    .map((f) => f.join("="))
    .join("&");
};

// get an entity from the store or if it's not loaded fetch from the API
export default function useGetCollection(slice, filter) {
  const filterRef = useRef(filter);
  const key = slice.replace(/-/g, "");
  const dispatch = useDispatch();

  const entities = useSelector((state) => state[key].entities);
  const ids = useSelector((state) => state[key].ids);
  const loaded = useSelector((state) => state[key].loaded);

  const result = useMemo(() => {
    const collection = ids.map((id) => entities[id]);
    return [loaded, collection];
  }, [entities, ids, loaded]);

  useEffect(() => {
    if (!loaded) {
      dispatch(
        dbLoad({
          tableName: slice,
          filter: filtertoQueryString(filterRef.current),
        })
      );
    }
  }, [dispatch, slice, loaded]);

  return result;
}
