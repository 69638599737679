const functionTransformHelp = {
  javascript: `// The following are available for use in a function transform:
// =====================================================================================
// Variables:
//   name                type      description
//   attachment          object    If a file id was provided, attachment is an object representing the file { id, name, description, content, contentBuffer }
//   executionUrl        string    The url of an execution view of the orchestration
//   options             object    This is used with a library transform function
//   steps               array     An array of objects representing the data output for each preceding step
//   trigger             object    An object representing the data the orchestration was triggered with
//   triggerConnection   object    An object representing the connection details whose event triggered the orchestration { id, name, event }.
//   triggeredAt         string    An ISO format date at which orchestration is triggered
//   triggeredBy         object    An object representing the data about the user who triggered orchestration { id, name, email }
//   triggerKind         string    The kind used to trigger the orchestration.


// Functions:
//   addFile({name, description, content})  Create a file to the DLX file store

// Enter your transform code below; (this line and the above comments are for information only, and can be removed)
`,
  python: `# The following are available for use in a function transform:
# =====================================================================================
# Variables:
#   name                type      description
#   attachment          dict      If a file id was provided, attachment is an dictionary representing the file { id, name, description, content, contentBuffer }
#   executionUrl        string    The url of an execution view of the orchestration
#   options             dict      This is used with a library transform function
#   steps               array     An array of dictionaries representing the data output for each preceding step
#   trigger             dict      A dictionary representing the data the orchestration was triggered with
#   triggerConnection   dict      A dictionary representing the connection details whose event triggered the orchestration { id, name, event }
#   triggeredAt         string    An ISO format date at which orchestration is triggered
#   triggeredBy         dict      A dictionary representing the data about the user who triggered orchestration { id, name, email }
#   triggerKind         string    The kind used to trigger the orchestration

# Functions:
#   addFile({name, description, content})  Create a file to the DLX file store

# Enter your transform code below; (this line and the above comments are for information only, and can be removed)
return
`,
};

export default functionTransformHelp;
