import { ENQUEUE_SNACKBAR } from "./actions";

const defaultState = {
  message: "test message",
  options: {},
  display: false,
};

export default function alertsReducer(_state = defaultState, action = {}) {
  if (action?.type === ENQUEUE_SNACKBAR) {
    return {
      message: action.message,
      options: action.options,
      display: true,
    };
  }
  return defaultState;
}
