import {
  UPDATE_READING,
  CLEAR_READINGS,
  TRANSIENT,
  PERMANENT,
} from "./live-readings.constants";

export const initialState = {
  permanentReadings: [],
  transientReadings: {},
};

export default function liveReadingsReducer(state = initialState, action) {
  switch (action.type) {
    case UPDATE_READING:
      return updateReadingReducer(state, action.payload);
    case CLEAR_READINGS:
      return { ...state, permanentReadings: [] };
    default:
      return state;
  }
}

function updateReadingReducer(state, reading) {
  if (reading.type === TRANSIENT) {
    state.transientReadings[reading.connectionId] = reading;
    return { ...state };
  }
  if (reading.type === PERMANENT) {
    return {
      ...state,
      permanentReadings: state.permanentReadings.concat(reading),
    };
  }
  return state;
}
