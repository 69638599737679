import "@dlx/assets/styles";
import { getStoredIamToken, isTokenExpired } from "@dlx/services/tokens";
import { waitForSessionAuthenticated } from "@dlx/services/authChannel";
import ReactDOM from "react-dom";
import React from "react";
import RenderIFrame, { IFRAME_SCREEN } from "./components/IFrame/RenderIFrame";

const requiresSignIn = () => {
  const token = getStoredIamToken();
  return (
    !token || isTokenExpired(token.access_token) || isTokenExpired(token.id_token)
  );
};

(async () => {
  if (requiresSignIn()) {
    ReactDOM.render(
      <RenderIFrame screen={IFRAME_SCREEN.DEFAULT} />,
      document.getElementById("app")
    );
    await waitForSessionAuthenticated();
  }
  return require("./main");
})();
