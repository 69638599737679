import React, { useEffect, useState } from "react";
import isEmpty from "lodash/isEmpty";

import { fetchLookupOptions } from "@dlx/state/hooks/useLookup";
import {
  expressionToAst,
  AstEditor,
  astToExpression,
} from "@dlx/components/ExpressionEditor";

import { useOrchestrationEditorContext } from "../BaseEditor";

const lookupSchema = {
  type: ["string"],
  lookup: [
    {
      kind: "api-resource",
      href: "/lookups/orchestrations",
      groupName: "Orchestrations",
    },
  ],
};

/**
 * @param {{
 *  setOrchestration: Function,
 *  stepNum: Number,
 *  step: Step,
 * }} props
 */
export default function StepOrchestrationSelector({
  setOrchestration,
  stepNum,
  step,
}) {
  const { executionInfo = {} } = useOrchestrationEditorContext() || {};
  const [context, setContext] = useState(null);
  const [draft, setDraft] = useState(expressionToAst(step?.orchestration || `""`));
  const { getStepInputs } = executionInfo;

  useEffect(() => {
    const { orchestration } = step;
    const expression = astToExpression(draft);
    if (orchestration && expression !== orchestration) {
      setDraft(expressionToAst(step.orchestration || `""`));
    }
  }, [step]);

  useEffect(() => {
    getStepInputs(stepNum)?.then((data) => {
      if (stepNum === 0) {
        const { steps, ...ctx } = data;
        return setContext(ctx);
      }
      return setContext(data);
    });
  }, [stepNum, getStepInputs]);

  useEffect(() => {
    if (!isEmpty(draft)) {
      handleSelection();
    }
  }, [draft]);

  const handleSelection = () => {
    const exp = astToExpression(draft);
    if (exp) {
      setOrchestration({ orchestration: draft.value ? exp : null });
    }
  };

  const executeLookup = async () => {
    return await fetchLookupOptions([lookupSchema.lookup[0].href]);
  };

  return (
    <AstEditor
      schema={lookupSchema}
      element={draft}
      setElement={setDraft}
      context={context}
      executeLookup={executeLookup}
    />
  );
}
