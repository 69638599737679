import React, { useState, useRef, useEffect } from "react";
import styles from "./QrCodeModal.module.scss";
import DLXModal from "../DLXModal/DLXModal";
import QRCode from "easyqrcodejs";
import {
  Checkbox,
  FormControlLabel,
  IconButton,
  Radio,
  RadioGroup,
  TextField,
} from "@material-ui/core";
import { toBlob, toPng } from "html-to-image";
import DLXTooltip from "../DLXTooltip/DLXTooltip";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowAltCircleDown } from "@fortawesome/free-regular-svg-icons";
import { alertError, alertSuccess } from "../../state/alerts/actions";
import { useDispatch } from "react-redux";
import { useReactToPrint } from "react-to-print";
import scitaraLogo from "../../assets/images/scitara-logo.png";
import PreviewFeature from "../PreviewFeature";

const sizes = {
  small: {
    width: 100,
    height: 100,
    captionFontSize: "12px",
    margin: "35px 30px",
  },
  large: {
    width: 200,
    height: 200,
    captionFontSize: "18px",
    margin: "50px",
  },
};

/**
 * @param {{
 * url: String,
 * caption: String,
 * tooltip: String,
 * }} props
 */
function QrCodeGenerator({ url, tooltip, caption, ...rest }) {
  const [showDialog, setShowDialog] = useState(false);
  return (
    <PreviewFeature>
      <QrCodeModal
        visible={showDialog}
        setVisible={() => setShowDialog(false)}
        qrUrl={url}
        qrCaption={caption}
      />
      <DLXTooltip text={tooltip}>
        <span>
          <IconButton {...rest} onClick={() => setShowDialog(true)}>
            <FontAwesomeIcon icon="qrcode" />
          </IconButton>
        </span>
      </DLXTooltip>
    </PreviewFeature>
  );
}
/**
 * @param {{
 * visible: Boolean,
 * setVisible: Function,
 * qrUrl: String,
 * qrCaption: String,
 * }} props
 */
function QrCodeModal({ visible, setVisible, qrUrl, qrCaption }) {
  const dispatch = useDispatch();
  const [draftUrl, setDraftUrl] = useState(qrUrl);
  const [url, setUrl] = useState(qrUrl);
  const [draftCaption, setDraftCaption] = useState(qrCaption);
  const [caption, setCaption] = useState(qrCaption);
  const [size, setSize] = useState("large");
  const [showLogo, setShowLogo] = useState(true);
  const [showCaption, setShowCaption] = useState(true);
  const qrRef = useRef(null);
  const { ClipboardItem } = window;

  useEffect(() => {
    setDraftUrl(qrUrl);
    setUrl(qrUrl);
    setDraftCaption(qrCaption);
    setCaption(qrCaption);
  }, [qrUrl, qrCaption]);

  const handleCopyToClipboard = async () => {
    toBlob(qrRef.current).then((blob) => {
      const clip = new ClipboardItem({ "image/png": blob });
      navigator.clipboard.write([clip]);
      dispatch(alertSuccess("QR code image copied to the clipboard"));
    });
  };

  const handlePrintImage = useReactToPrint({
    content: () => qrRef.current,
  });

  const handleDownloadImage = async () => {
    toPng(qrRef.current).then((dataUrl) => {
      const link = document.createElement("a");
      link.download = "qrcode.png";
      link.href = dataUrl;
      link.click();
      dispatch(alertSuccess("QR code downloaded as qrcode.png"));
    });
  };

  return (
    <DLXModal
      title="Create QR code to trigger orchestration"
      cancelButtonLabel="Close"
      open={visible}
      onCancel={() => setVisible(false)}
      hideSubmitButton
      hideCloseButton
    >
      <div className={styles.modalContainer}>
        <div className={styles.inputsContainer}>
          <TextField
            id="url"
            fullWidth
            label="QR code url"
            variant="standard"
            value={draftUrl || ""}
            onChange={(e) => setDraftUrl(e.target.value)}
            InputProps={{
              endAdornment: (
                <DLXTooltip text="Update url">
                  <IconButton
                    aria-label="update-url"
                    onClick={() => setUrl(draftUrl)}
                    edge="end"
                  >
                    <FontAwesomeIcon icon={faArrowAltCircleDown} />
                  </IconButton>
                </DLXTooltip>
              ),
            }}
          />
          <TextField
            id="caption"
            fullWidth
            label="Caption"
            variant="standard"
            value={draftCaption || ""}
            onChange={(e) => setDraftCaption(e.target.value)}
            InputProps={{
              endAdornment: (
                <DLXTooltip text="Update caption">
                  <IconButton
                    aria-label="update-caption"
                    onClick={() => setCaption(draftCaption)}
                    edge="end"
                  >
                    <FontAwesomeIcon icon={faArrowAltCircleDown} />
                  </IconButton>
                </DLXTooltip>
              ),
            }}
          />
        </div>
        <div className={styles.preferencesContainer}>
          <FormControlLabel
            control={
              <Checkbox
                id="use-caption"
                checked={showCaption}
                onChange={(_e, value) => setShowCaption(value)}
                size="small"
              />
            }
            label="Caption"
          />
          <FormControlLabel
            control={
              <Checkbox
                id="use-logo"
                checked={showLogo}
                onChange={(_e, value) => setShowLogo(value)}
                size="small"
              />
            }
            label="Logo"
          />
          <RadioGroup
            row
            name="size"
            value={size}
            onChange={(e) => setSize(e.target.value)}
          >
            {Object.entries(sizes).map(([k, _v]) => (
              <FormControlLabel
                key={k}
                value={k}
                control={<Radio size="small" />}
                label={k}
              />
            ))}
          </RadioGroup>
        </div>
        <div className={styles.qrcodeContainer}>
          <div ref={qrRef}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                backgroundColor: "#FFF",
              }}
            >
              <div
                style={{
                  display: showLogo ? "inline" : "none",
                }}
              >
                <img
                  src={scitaraLogo}
                  style={{ height: sizes[size].height }}
                  alt="logo"
                />
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    padding: sizes[size].padding,
                    margin: sizes[size].margin,
                    textAlign: "center",
                  }}
                >
                  <QrCode url={url} size={sizes[size]} />
                  <div
                    style={{
                      display: showCaption ? "inline" : "none",
                      fontSize: sizes[size].captionFontSize,
                      maxWidth: sizes[size].width,
                    }}
                  >
                    {caption}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ButtonBar
          handleCopyToClipboard={handleCopyToClipboard}
          handleDownloadImage={handleDownloadImage}
          handlePrintImage={handlePrintImage}
        />
      </div>
    </DLXModal>
  );
}

const ButtonBar = ({
  handleCopyToClipboard,
  handleDownloadImage,
  handlePrintImage,
}) => {
  return (
    <div className={styles.buttonBar}>
      <DLXTooltip text="Copy QR code image">
        <IconButton className={styles.iconButton} onClick={handleCopyToClipboard}>
          <FontAwesomeIcon icon="copy" />
        </IconButton>
      </DLXTooltip>
      <DLXTooltip text="Download QR code image">
        <IconButton className={styles.iconButton} onClick={handleDownloadImage}>
          <FontAwesomeIcon icon="download" />
        </IconButton>
      </DLXTooltip>
      <DLXTooltip text="Print QR code">
        <IconButton className={styles.iconButton} onClick={handlePrintImage}>
          <FontAwesomeIcon icon="print" />
        </IconButton>
      </DLXTooltip>
    </div>
  );
};

const QrCode = ({ url, size }) => {
  const dispatch = useDispatch();
  const [qrCode, setQrCode] = useState(null);
  const code = useRef(null);

  useEffect(() => {
    if (qrCode) {
      qrCode.clear();
    }
    try {
      setQrCode(
        new QRCode(code.current, {
          width: size.width,
          height: size.height,
          text: url,
          colorDark: "#000000",
          colorLight: "#ffffff",
        })
      );
    } catch (error) {
      dispatch(
        alertError("Unable to create QR code, try to reduce the length of the url")
      );
    }
    // eslint-disable-next-line
  }, [code, url, size]);

  return <div ref={code}></div>;
};

export default QrCodeGenerator;
