import React from "react";
import OptionsHolder from "../options/OptionsHolder";

function StepOptions({ name = "options", stepNum, onUpdate, step, optionsSchema }) {
  return (
    <OptionsHolder
      key={`${stepNum}-options`}
      onUpdate={onUpdate}
      optionsSchema={optionsSchema}
      step={step}
      name={name}
      stepNum={stepNum}
    />
  );
}

export default StepOptions;
