import React, { memo, useState } from "react";

import { Context, getContextValue } from "./context";
import { AstSchemaRows } from "./Helpers";
import { useTransforms } from "./hooks";

function AstEditorSchema({
  context = {},
  schema = {},
  ast,
  onClear = null,
  onChange,
  executeLookup,
  className = "",
}) {
  const { transforms } = useTransforms();
  const [output, setOutput] = useState({});
  return (
    <Context.Provider
      value={getContextValue({
        schema,
        context,
        executeLookup,
        transforms,
        output,
        setOutput,
      })}
    >
      <AstSchemaRows
        schema={schema}
        element={ast}
        setElement={onChange}
        onClear={onClear}
        className={className}
      />
    </Context.Provider>
  );
}

export default memo(AstEditorSchema);
