import React, { useState, useRef, useEffect } from "react";
import styles from "./Trigger.module.scss";
import { IconButton } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DLXModal from "../../DLXModal/DLXModal";
import DLXTooltip from "../../DLXTooltip/DLXTooltip";
import AceEditor from "react-ace";
import QrCodeGenerator from "../../QrCodeModal";
import { useOrchestrationEditorContext } from "../BaseEditor";

const beautify = (data) => JSON.stringify(data, null, 2);

/**
 * @param {{
 * show: Boolean,
 * setShow : Function,
 * executeTrigger: Function,
 * handleCopyUrl: Function,
 * createUrl: Function,
 * orchestration: Orchestration,
 * }} props
 */
const ExecuteTriggerDialog = ({
  show,
  setShow,
  executeTrigger,
  handleCopyUrl,
  createUrl,
  orchestration,
}) => {
  const [draft, setDraft] = useState("");
  const [errors, setErrors] = useState([]);
  const ref = useRef();
  const { executionInfo } = useOrchestrationEditorContext();

  const { getTriggerOutput, triggerOutput } = executionInfo;

  useEffect(() => {
    getTriggerOutput();
  }, [getTriggerOutput]);

  useEffect(() => setDraft(beautify(triggerOutput || {})), [triggerOutput]);

  const handleBeautify = () => {
    try {
      setDraft(beautify(JSON.parse(draft)));
    } catch (error) {
      //parsing error
    }
  };

  const parseDraft = () => {
    try {
      return JSON.parse(draft);
    } catch (error) {
      return {};
    }
  };

  return (
    <DLXModal
      onCancel={() => setShow(false)}
      onSubmit={() => executeTrigger(JSON.parse(draft))}
      size="micro"
      cancelButtonLabel="Cancel"
      submitButtonLabel="Trigger orchestration"
      submitDisabled={!!errors.length}
      open={show}
      title={"Trigger orchestration"}
    >
      <DLXTooltip text="Beautify data">
        <IconButton
          className={styles.iconButton}
          disabled={!!errors.length}
          onClick={handleBeautify}
        >
          <FontAwesomeIcon icon="check" />
        </IconButton>
      </DLXTooltip>
      <DLXTooltip text="Copy trigger url (data will be encoded in the url)">
        <IconButton
          className={styles.iconButton}
          onClick={() => handleCopyUrl(parseDraft())}
        >
          <FontAwesomeIcon icon="copy" />
        </IconButton>
      </DLXTooltip>
      <QrCodeGenerator
        url={createUrl(parseDraft())}
        caption={orchestration.name}
        tooltip="Create QR code to trigger orchestration (data will be encoded in the QR code)"
      />
      <div className={styles.triggerDataContainer}>
        <AceEditor
          height="100%"
          language="json"
          ref={ref}
          mode="json"
          fontSize={16}
          placeholder={JSON.stringify(triggerOutput)}
          onChange={(draftObj) => {
            setDraft(draftObj || "{}");
          }}
          onValidate={setErrors}
          style={{ height: "20vh" }}
          theme="github"
          value={draft}
          width="100%"
        />
      </div>
    </DLXModal>
  );
};

export default ExecuteTriggerDialog;
