import React, { useState, useEffect } from "react";
import Switch, { SWITCH_SIZES, SWITCH_VARIANTS } from "@dlx/atoms/Switch";
import { getAstElementFromValue } from "../util";

function BooleanEditor({ element, setElement }) {
  const [draft, setDraft] = useState(element.value);
  const [isEditing, setEditing] = useState(false);

  useEffect(() => {
    if (element.value !== draft) {
      setDraft(element.value);
    }
  }, [element.value]);

  const handleSave = () => {
    setElement(getAstElementFromValue(draft));
    setEditing(false);
  };

  return (
    <div className="flex items-center" onClick={() => setEditing(true)}>
      {isEditing && (
        <Switch
          name="Switch"
          checked={draft}
          onChange={setDraft}
          onBlur={handleSave}
          variant={SWITCH_VARIANTS.CONTRAST}
          size={SWITCH_SIZES.SMALL}
        />
      )}
      <div className="ml1 pv1 capitalize">{draft.toString()}</div>
    </div>
  );
}

export default BooleanEditor;
