import React, { useState, useEffect } from "react";
import isEqual from "lodash/isEqual";
import isEmpty from "lodash/isEmpty";
import { useLookup } from "../../OrchestrationFlow/useLookup";
import { useOrchestrationEditorContext } from "../BaseEditor";
import {
  astToExpression,
  expressionDataToAst,
  AstEditorSchema,
  expressionToAst,
} from "@dlx/components/ExpressionEditor";

/**
 * @param {{
 *   name: string,
 *   onUpdate: Function,
 *   optionsSchema: object,
 *   step: Step,
 *   stepNum: number,
 *  }} props
 */
const OptionsHolderContainer = ({
  name,
  onUpdate,
  optionsSchema,
  step,
  stepNum,
}) => {
  const { executeLookup } = useLookup();
  const [context, setContext] = useState(null);
  const { executionInfo } = useOrchestrationEditorContext();
  const { getStepInputs } = executionInfo;
  const connectionId = expressionToAst(step?.connection)?.value;

  useEffect(() => {
    getStepInputs(stepNum - 1)?.then((data) => setContext(data));
  }, [stepNum, getStepInputs]);

  const handleExecuteLookup = async (lookup) => {
    const { result } = await executeLookup(
      typeof lookup === "string"
        ? {
            command: lookup.split("/").reverse()[0],
            connection: connectionId,
          }
        : lookup
    );
    const items = [];
    result.forEach((listItem) => {
      if (listItem.items) {
        listItem.items.forEach((item) =>
          items.push({ ...item, group: listItem.label })
        );
      } else {
        items.push(
          typeof listItem === "string"
            ? { label: listItem, value: listItem }
            : listItem
        );
      }
    });
    return items;
  };

  if (!optionsSchema.properties) {
    return null;
  }

  return (
    <OptionsHolder
      optionsSchema={optionsSchema}
      optionsData={step[name]}
      context={context}
      onChanged={(update) => onUpdate({ [name]: update })}
      executeLookup={handleExecuteLookup}
    />
  );
};

export const OptionsHolder = ({
  optionsSchema,
  optionsData,
  context,
  onChanged,
  executeLookup,
}) => {
  const [draft, setDraft] = useState({});
  const [expressionObject, setExpressionObject] = useState({});
  useEffect(() => {
    //Convert step data to an expression object
    const updatedExpressionObj = {};
    Object.entries(optionsData || {}).forEach(([k, v]) => {
      if (!optionsSchema.properties[k]) {
        return;
      }
      updatedExpressionObj[k] = v;
    });
    if (!isEqual(updatedExpressionObj, expressionObject)) {
      setExpressionObject(updatedExpressionObj);
    }
  }, [optionsData]);

  useEffect(() => {
    setDraft(expressionDataToAst(expressionObject));
  }, [expressionObject]);

  useEffect(() => {
    if (!isEmpty(draft) && !isEmpty(draft.value)) {
      handleExpressionDataObject();
    }
  }, [draft]);

  const handleExpressionDataObject = () => {
    const expressionDataObject = {};
    const expressions = Object.entries(draft.value).map(([key, ast]) => [
      key,
      astToExpression(ast),
    ]);

    const isValid = expressions.every(([, exp]) => exp !== undefined);

    if (isValid) {
      expressions
        .filter(([, exp]) => exp)
        .forEach(([key, exp]) => {
          expressionDataObject[key] = exp;
        });
      onChanged(expressionDataObject);
    }
  };

  const handleOnClear = (key) => {
    delete draft.value[key];
    delete expressionObject[key];

    setDraft({ ...draft });
    onChanged(expressionObject);
  };

  return (
    <AstEditorSchema
      context={context}
      schema={optionsSchema}
      ast={draft}
      onChange={setDraft}
      onClear={handleOnClear}
      executeLookup={executeLookup}
    />
  );
};

export default React.memo(OptionsHolderContainer, isEqual);
