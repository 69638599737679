import React, { useState } from "react";
import styles from "../OrchestrationFlow.module.scss";

import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import { ChevronDownIcon } from "../../../icons";
import { snakeCase } from "lodash";

/**
 * @param {{
 *  command: string,
 *  setCommand: Function,
 *  commands: object
 * }} props
 */
export default function StepCommandSelector({ command, setCommand, commands }) {
  const [open, setOpen] = useState(false);

  const handleOptionChange = (selection) => {
    if (selection !== command) {
      setCommand({ command: selection });
      setOpen(false);
    }
  };

  const commandKeys = Object.keys(commands || {});

  const handleOnClick = () => {
    if (commandKeys.length) {
      setOpen(!open);
    }
  };

  const isSelected = commands && commands[command];

  return (
    <ClickAwayListener onClickAway={() => setOpen(false)}>
      <div className={styles.connectionStep}>
        <div
          className={`${styles.connectionInner} ${isSelected && styles.selected}`}
          onClick={handleOnClick}
        >
          {isSelected ? (
            <section className={styles.menuLabel}>
              <span className={styles.boldText}>{commands[command].title}</span>
              <div className={styles.subTitle} style={{ paddingTop: "5px" }}>
                {commands[command].description}
              </div>
            </section>
          ) : (
            <section className={`${styles.menuLabel} ${styles.boldText}`}>
              Select an action
            </section>
          )}
          {commandKeys.length > 0 && (
            <ChevronDownIcon
              style={{ transform: !open ? "none" : "rotate(180deg)" }}
              className={styles.dropdownIcon}
            />
          )}
        </div>
        <ul className={!open ? "" : styles.activeDropdown}>
          {Object.entries(commands || {})
            .filter(([name, c]) => c.usage.includes("published") && name !== command)
            .map(([name, c]) => {
              return (
                <CommandOption
                  active={name === command}
                  description={c.description}
                  key={name}
                  name={name}
                  onClick={handleOptionChange}
                  title={c.title}
                />
              );
            })}
        </ul>
      </div>
    </ClickAwayListener>
  );
}

/**
 * @param {{
 *  active: boolean,
 *  onClick: Function,
 *  title: string,
 *  description: string,
 *  name: string
 * }} props
 */
function CommandOption({ active, onClick, title, description, name }) {
  return (
    <li
      onClick={() => onClick(name)}
      className={active ? styles.activeItem : null}
      data-testid={snakeCase(`dtid_${name}`)}
    >
      <h2>
        {title}
        {!name && " (not enabled)"}
      </h2>
      <p>{description}</p>
    </li>
  );
}
