import React, { useState } from "react";
import styles from "../OrchestrationFlow.module.scss";
import { ChevronDownIcon } from "../../../icons";
import { useSelector } from "react-redux";
import selectors from "../../../state/selectors";

import ClickAwayListener from "@material-ui/core/ClickAwayListener";

/**
 * @param {{
 *  step: Step,
 *  updateStep: Function
 * }} props
 */
const FunctionLibrarySelector = ({ step, updateStep }) => {
  const [open, setOpen] = useState(false);
  const functionLibrary = useSelector(selectors.getTransformLibraries);
  const functionLibraryKeys = Object.keys(functionLibrary || {});
  const selectedLibrary = functionLibrary[step.libraryTransformId];
  const handleOptionChange = (selection) => {
    updateStep({ libraryTransformId: selection });
    setOpen(false);
  };

  function handleOnClick() {
    if (functionLibraryKeys.length > 0) {
      setOpen(!open);
    }
  }

  return (
    <ClickAwayListener onClickAway={() => setOpen(false)}>
      <div className={styles.connectionStep}>
        <div
          className={`${styles.connectionInner} ${open && styles.active} ${
            selectedLibrary && styles.selected
          }`}
          onClick={handleOnClick}
        >
          <TriggerTitle step={selectedLibrary} />
          {functionLibraryKeys.length > 0 && (
            <ChevronDownIcon
              style={{ transform: !open ? "none" : "rotate(180deg)" }}
              className={styles.dropdownIcon}
            />
          )}
        </div>
        <ul className={open ? styles.activeDropdown : ""}>
          {Object.entries(functionLibrary || {})
            .filter(([name]) => {
              return name !== selectedLibrary?.id;
            })
            .map(([name, trigger]) => {
              return (
                <TriggerEvent
                  name={trigger.name}
                  description={trigger.description}
                  onClick={() => handleOptionChange(name)}
                  active={name === step.libraryTransformId}
                  key={name}
                />
              );
            })}
        </ul>
      </div>
    </ClickAwayListener>
  );
};

/**
 * @param {{
 *  onClick: Function,
 *  description: string,
 *  name: string,
 *  active: boolean
 * }} props
 */
function TriggerEvent({ onClick, description, name, active }) {
  return (
    <li onClick={() => onClick(name)} className={active ? styles.activeItem : null}>
      <h2>{name}</h2>
      <p>{description}</p>
    </li>
  );
}

/** @param {{step: Step}} props */
function TriggerTitle({ step }) {
  if (!step) {
    return (
      <span className={`${styles.menuLabel} ${styles.boldText}`}>
        Select a library transform function
      </span>
    );
  }
  return (
    <div className={styles.menuLabel}>
      <span className={styles.boldText}>{step.name}</span>
      <div className={styles.subTitle} style={{ paddingTop: "5px" }}>
        {step.description}
      </div>
    </div>
  );
}
export default FunctionLibrarySelector;
