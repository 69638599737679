import React from "react";
import { Button } from "@material-ui/core";
import Switch from "@material-ui/core/Switch";
import styles from "./OrchestrationEditor.module.scss";
import { useHistory } from "react-router-dom";
import { useDiscardPrompt } from "./DiscardDialog";
import { ORCHESTRATIONS_PATH } from "./state/constants";
import { DataSelect } from "../SourceDataSelector";
import { useOrchestrationEditorContext } from "./BaseEditor";
import { OutputSourceIcon } from "../../icons";
import InputText, { INPUT_VARIANTS } from "@dlx/atoms/InputText";
import clsx from "clsx";

function OrchestrationEditorName({ formik, editor, disabled, hideSubmit }) {
  const { executionInfo = {} } = useOrchestrationEditorContext() || {};
  const { execution, isSameVersion } = executionInfo;
  const { prompt } = useDiscardPrompt();
  const history = useHistory();
  const handleOnCancel = React.useCallback(
    (e) => {
      if (e.target.type !== "textarea") {
        if (!editor.isEditing || editor.mode === "view") {
          history.push(ORCHESTRATIONS_PATH);
        } else {
          prompt();
        }
      }
    },
    [editor.isEditing, editor.mode, history, prompt]
  );

  return (
    <div className={clsx(styles.orcTitle, "mr4")}>
      <div className={styles.orcInfo}>
        <div className={clsx(styles.orchestrationName, "flex")}>
          <InputText
            name="name"
            value={formik.values.name}
            onChange={formik.handleChange}
            variant={INPUT_VARIANTS.STANDARD}
            placeholder="Enter a name for the orchestration"
            styles={{ fontSize: 18 }}
            testId="dtid_orchestration_editor_name"
            onBlur={() => formik.setFieldValue("name", formik.values.name.trim())}
          />
          <div className={clsx("text-error font-small", styles.error)}>
            {formik.errors.name}
          </div>
        </div>

        <div className={styles.orcSwitch}>
          <div
            className={styles.switchState}
            data-testid="dtid_orchestration_editor_switch_state"
          >
            {formik.values.state}
          </div>

          <div className={styles.switchContainer}>
            <Switch
              name="state"
              color={"primary"}
              checked={formik.values.state === "enabled"}
              value={formik.values.state}
              onChange={(e) => {
                formik.setFieldValue(
                  "state",
                  e.target.checked ? "enabled" : "disabled"
                );
              }}
            />
          </div>
          {isSameVersion && (
            <DataSelect
              executionId={execution?.id}
              className="pl3 pr2 pv2 bl border-secondary"
            >
              <div className=" text-secondary text-hover-primary pointer">
                <OutputSourceIcon />
              </div>
            </DataSelect>
          )}
        </div>
      </div>
      <div className={styles.buttonHolder}>
        <Button
          color="primary"
          onClick={formik.handleSubmit}
          disabled={disabled || hideSubmit}
          variant="contained"
        >
          Save
        </Button>
        <Button onClick={handleOnCancel}>
          {editor.isEditing ? "Cancel" : "Back"}
        </Button>
      </div>
    </div>
  );
}

export default OrchestrationEditorName;
