import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import styles from "./AuditTrailView.module.scss";
import { useApiCollection } from "@dlx/state/hooks/useApiCollection";
import { OrchestrationEnabled, ShieldAltIcon } from "../../icons";
import { formatDate, getGMTTimezone } from "@dlx/utils/date";
import { replaceDashes } from "@dlx/utils/commons";
import { capitalize } from "lodash";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { useCurrentUserContext } from "../../contexts/current-user/Provider";
import Spinner from "@dlx/atoms/Spinner";
import { createTheme, MuiThemeProvider } from "@material-ui/core/styles";
import variables from "../../assets/styles/variables.scss";
import DetailedView from "./DetailedView";
import { ScitaraLogoContent } from "@dlx/components/header/Header";
import { CustomerDetails } from "@dlx/components/header/AccountDropdown";
import clsx from "clsx";

const exportDate = new Date(Date.now()).toISOString();
const getMuiTheme = createTheme({
  overrides: {
    MuiPaper: {
      elevation4: {
        boxShadow: "none",
      },
    },
    MuiTableCell: {
      root: {
        fontFamily: "inherit",
        fontSize: "inherit",
        padding: "10px 5px",
        cursor: "pointer",
      },
      head: {
        backgroundColor: `${variables.lightgrayhover} !important`,
        padding: "10px 15px !important",
      },
      body: {
        color: variables.darkgraytext,
      },
    },
    MuiTableRow: {
      root: {
        "&:nth-of-type(even)": {
          backgroundColor: variables.lightgraybackground,
        },
      },
    },
  },
});

const columnMap = {
  id: "ID",
  timestamp: "Timestamp",
  identity: "Operator",
  kind: "Event Kind",
  documentType: "Entity Type",
  action: "Operation",
  documentName: "Entity Name",
  documentId: "Entity ID",
  sign: "Esign",
  reason: "Reason",
};

const AuditTrailHeader = ({ recordCount, loading, filterLabels }) => {
  const { currentUser, customer } = useCurrentUserContext();

  return (
    <>
      <div>
        <div className="header flex justify-between bg-secondary-light pa3">
          <div className="flex">
            <div className="flex-inline">
              <ScitaraLogoContent />
            </div>
            <div className="flex-inline font-large text-default pt3 pl4">
              DLX Audit Trail
            </div>
          </div>
          <div className="text-default tr">
            <CustomerDetails customer={customer} user={currentUser} />
          </div>
        </div>
        {filterLabels && (
          <div className="filters flex flex-wrap pa2">
            {Object.entries(filterLabels).map(([key, name]) => {
              return (
                <div
                  className="flex-inline rounded-pill pv1 ph3 mt1 mh1 bg-secondary"
                  key={key}
                >
                  {key}: {name}
                </div>
              );
            })}
          </div>
        )}
        {!loading && (
          <div className="flex justify-between pa3">
            <div>
              Total Records: <span>{recordCount}</span>
            </div>
            <div>
              Generated At:{" "}
              <span>
                {formatDate(exportDate)}({getGMTTimezone(exportDate)})
              </span>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

const AuditTrailExportView = () => {
  const { columns, filters, filterLabels } = useParams();
  const columnsName = columns?.split("-");
  const parsedFilterLabels = filterLabels && JSON.parse(filterLabels);

  useEffect(() => {
    document.body.classList.add(styles.scrollableBody);
  }, []);

  const {
    entities: auditEvents,
    loading,
    recordCount,
  } = useApiCollection({
    url: `audit-events?${filters ? filters : ""}`,
    paginate: false,
  });

  if (!columns || (!loading && auditEvents.length === 0)) {
    return (
      <>
        <AuditTrailHeader
          recordCount={recordCount}
          loading={loading}
          filterLabels={parsedFilterLabels}
        />
        <div className="flex justify-center text-secondary font-large">
          NO RECORDS FOUND
        </div>
      </>
    );
  }

  return (
    <div>
      <AuditTrailHeader
        recordCount={recordCount}
        loading={loading}
        filterLabels={parsedFilterLabels}
      />
      {recordCount > 1000 && (
        <div
          className={clsx(styles.warningWrapper, "tc bg-warning text-default pa3")}
        >
          <span>
            Printing a large dataset may cause an unresponsive page. In case page
            becomes unresponsive, you may retry with a more detailed filter criteria
            to reduce the dataset size
          </span>
        </div>
      )}
      {loading ? (
        <div className="flex justify-center flex-column items-center">
          <div className="text-secondary mt3">Loaded Records: {recordCount}</div>
          <Spinner className="inline-block text-secondary mt3" size={25} />
        </div>
      ) : (
        <MuiThemeProvider theme={getMuiTheme}>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  {Object.entries(columnMap).map(
                    ([key, name]) =>
                      columnsName.includes(key) && (
                        <TableCell key={key}>{name}</TableCell>
                      )
                  )}
                </TableRow>
              </TableHead>

              <TableBody>
                {auditEvents.map((details, index) => [
                  <TableRow key={`row-${index}`}>
                    <Cell details={details} columns={columnsName} />
                  </TableRow>,
                  <TableRow key={`row-details-${index}`}>
                    <TableCell colSpan={columnsName.length}>
                      <DetailedView
                        details={details}
                        capitalize={capitalize}
                        replaceDashes={replaceDashes}
                        print
                      />
                    </TableCell>
                  </TableRow>,
                ])}
              </TableBody>
            </Table>
          </TableContainer>
        </MuiThemeProvider>
      )}
    </div>
  );
};

const Cell = ({ details, columns }) => {
  return Object.entries(columnMap).map(
    ([key]) =>
      columns.includes(key) && (
        <TableCell key={key}>
          {["id", "action", "documentType", "kind", "documentId"].includes(key) &&
            (capitalize(details[key]) ?? "-")}
          {key === "documentName" && details[key]}
          {key === "timestamp" && formatDate(details[key])}
          {key === "identity" && (
            <>
              <ShieldAltIcon className={styles.authenticatedUser} />
              {details[key]?.name}
            </>
          )}
          {key === "sign" && details?.controls?.signature && (
            <OrchestrationEnabled className={styles.esign} />
          )}
          {key === "reason" && (details?.controls?.reasonForChange ?? "-")}
        </TableCell>
      )
  );
};

export default AuditTrailExportView;
