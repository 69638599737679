import React from "react";
import Badge from "@material-ui/core/Badge";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import Tooltip from "@material-ui/core/Tooltip";
import { withStyles, createStyles } from "@material-ui/core/styles";

const styles = createStyles(({ palette }) => ({
  badge: {
    color: palette.error.main,
  },
}));

const ValidationIcon = ({ classes, errorMessages, id }) => {
  const errorLength = errorMessages.split("\n").length;
  let errorTitle = errorMessages;
  if (errorLength > 1) {
    errorTitle = errorMessages
      .split("\n")
      .map((error, i) => <div key={i}>{`${i + 1}. ${error}`}</div>);
  }

  return (
    <Tooltip id={id} title={errorTitle}>
      <Badge className={classes.badge} badgeContent={errorLength}>
        <ErrorOutlineIcon color="inherit" />
      </Badge>
    </Tooltip>
  );
};

export default withStyles(styles, { name: "ValidationIcon" })(ValidationIcon);
