import { useCallback, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { dbRetrieveRecord } from "../reducers/dbAdapter";
import { useCurrentUserContext } from "../../contexts/current-user/Provider";

// get an entity from the store or if it's not loaded fetch from the API

const getSliceName = (slice) =>
  ["userGroups", "userRoles"].includes(slice) ? slice : slice.replace(/-/g, "");

export default function useGetRecord(slice, id = null) {
  const dispatch = useDispatch();
  const { currentUser } = useCurrentUserContext();
  const sliceName = useMemo(() => getSliceName(slice), [slice]);

  const selectRecord = useCallback(
    (state) => {
      return state?.[sliceName]?.entities[id];
    },
    [id, sliceName]
  );

  const selectFetched = useCallback(
    (state) => {
      return state?.[sliceName]?.fetched?.[id];
    },
    [id, sliceName]
  );

  const selectError = useCallback(
    (state) => {
      return state?.[sliceName]?.error?.[id];
    },
    [id, sliceName]
  );

  const record = useSelector(selectRecord);
  const fetched = useSelector(selectFetched);
  const error = useSelector(selectError);

  useEffect(() => {
    if (!fetched && id && !error) {
      let source = null;
      if (slice === "reasonsForChange") {
        source = `customers/${currentUser.customer}/reasons-for-change`;
      }
      dispatch(dbRetrieveRecord({ tableName: slice, source, id, label: "hook" }));
    }
  }, [dispatch, error, id, slice, fetched, currentUser]);
  return useMemo(() => [fetched, record], [fetched, record]);
}
