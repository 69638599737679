import { call, put, race, take, takeLeading } from "redux-saga/effects";
import { eventChannel } from "redux-saga";
import { updateLeases } from "./lease.actions";
import {
  updateReading,
  clearReadings,
} from "../live-readings/live-readings.actions";
import { CONNECTION_READING } from "../live-readings/live-readings.constants";
import {
  ABANDON_LEASE,
  LEASE_ABANDONED,
  LEASE_REQUESTED,
  LEASES_UPDATED,
  REQUEST_LEASE,
} from "./lease.constants";
import socketService from "../../../services/socket";
import logger from "../../../utils/logger";

const log = logger("lease:saga");

const LEASE_REQUEST_INTERVAL = 50 * 1000;

const noop = () => null;

/**
 * Maintains the user's lease by sending the server a lease request every
 * 50 seconds and updates live connection readings in state.
 * When canceled, the socket will stop listening for connection readings and
 * live readings will be cleared from state.
 */
export function* handleRequestLease(socket, msg, interval) {
  yield call([socket, socket.emit], LEASE_REQUESTED, msg, noop);
  const chan = yield call(leasedReadingsChannel, socket, msg, interval);
  log("channel open");
  try {
    while (true) {
      const payload = yield take(chan);
      yield put(payload);
    }
  } finally {
    chan.close();
    socket.emit(LEASE_ABANDONED, msg, noop);
    yield put(clearReadings());
    log("channel closed");
  }
}

export function leasedReadingsChannel(socket, msg, interval) {
  return eventChannel((emit) => {
    const id = setInterval(() => {
      socket.emit(LEASE_REQUESTED, msg, noop);
    }, interval);

    socket.on(CONNECTION_READING, (message) => {
      emit(updateReading(message));
    });

    socket.on(LEASES_UPDATED, (message) => {
      emit(updateLeases(message));
    });

    return () => {
      socket.off(LEASES_UPDATED);
      socket.off(CONNECTION_READING);
      clearInterval(id);
    };
  });
}

export default function* leaseSaga() {
  yield takeLeading(REQUEST_LEASE, function* (action) {
    yield race({
      task: call(
        handleRequestLease,
        socketService,
        action.payload,
        LEASE_REQUEST_INTERVAL
      ),
      cancel: take(ABANDON_LEASE),
    });
  });
}
