import React from "react";
import styles from "./StepChip.module.scss";
import { ChevronDownIcon } from "../../icons";
import StepMenu from "../orchestrationeditor/StepMenu/StepMenu";
import TriggerMenu from "../orchestrationeditor/StepMenu/TriggerMenu";
import clsx from "clsx";

/**
 * @param {{
 * stepLabel: string,
 * stepNum: number,
 * icon: object,
 * step: Step,
 * onSelect: Function,
 * active: boolean
 * }} props
 */
const StepMenuSelector = ({ stepLabel, stepNum, icon, step, onSelect, active }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const isTrigger = stepNum === -1;

  const handleClick = (event) => {
    if (active) {
      setAnchorEl(event.currentTarget);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div className={styles.stepMenuHolder}>
      <div className={styles.stepTypeMenu} onClick={handleClick}>
        {!isTrigger && <div>{icon}</div>}
        <div
          className={clsx(
            styles.selectedMenuLabel,
            isTrigger && styles.triggerStepLabel,
            active && styles.activeChip
          )}
          data-testid="dtid_step_chip_trigger_step_active_label"
        >
          {stepLabel}
        </div>
        {active && (
          <div className={clsx(styles.dropdown, active && styles.activeChip)}>
            <ChevronDownIcon />
          </div>
        )}
      </div>
      {isTrigger && (
        <TriggerMenu
          onClose={handleClose}
          onSelect={(kind) => onSelect(kind)}
          anchorEl={anchorEl}
          setAnchorEl={setAnchorEl}
          selected={step.kind}
        />
      )}
      {!isTrigger && (
        <StepMenu
          onClose={handleClose}
          onSelect={(_i, actor) => onSelect(actor.type)}
          anchorEl={anchorEl}
          setAnchorEl={setAnchorEl}
          index={stepNum}
          selected={step.kind}
        />
      )}
    </div>
  );
};

export default StepMenuSelector;
