import React, { useEffect, useMemo, useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { cloneDeep } from "lodash";
import { useHistory, useLocation } from "react-router-dom";
import {
  createOrchestrationSchema,
  updateOrchestrationSchema,
} from "../../utils/schemas/orchestration";
import BaseEditor from "./BaseEditor";
import { openEditor, saveOrchestration } from "./state/actions";
import useExecution from "../../state/executions/useExecution";
import { format, parseISO } from "date-fns";
import { actions } from "../../state/hooks/useApiResource";
import LoadingScreen from "../LoadingScreen";
import { selectOrchestrationEditor } from "./state/selectors";
import { usePermission } from "../../contexts/current-user/Provider";
import { alertError } from "../../state/alerts/actions";
import { ERROR_HANDLING_SCHEMA, ORCHESTRATIONS_PATH } from "./state/constants";
import {
  expressionToAst,
  AST_ELEMENT_TYPES,
} from "@dlx/components/ExpressionEditor";
import { EDITOR_TYPES } from "../OrchestrationFlow/constants";

const addErrorHandling = (orc) => {
  orc.steps.forEach((step) => {
    if (!step.errorHandling) {
      step.errorHandling = {
        errorResponse: ERROR_HANDLING_SCHEMA.properties.errorResponse.default,
      };
    }
    return step;
  });
  orc.orchestrationVersion = 2;
};

const kindsWithConnection = ["connection-command", "event-query", "user-reading"];

const shouldReplace = (kind, key) =>
  (kind === "schema" && key === "default") || kind !== "schema";

const findReplaceDynamic = (obj, kind) => {
  if (typeof obj === "object") {
    if (Array.isArray(obj)) {
      return obj.map((item) => findReplaceDynamic(item, kind));
    }
    for (const key in obj) {
      if (typeof obj[key] === "object") {
        obj[key] = findReplaceDynamic(obj[key], kind);
      } else if (typeof obj[key] === "string") {
        if (shouldReplace(kind, key)) {
          obj[key] = findReplaceHere(obj[key]);
        }
      }
    }
  } else if (typeof obj === "string") {
    obj = findReplaceHere(obj);
  }
  return obj;
};

const findReplaceHere = (obj) => {
  const regex = /steps\[(\d+)\]/g;
  obj = obj.replace(regex, "steps[$1].result");
  return obj;
};

const propertyNeedsReplace = (obj) => {
  if (obj.orchestrationVersion === 2) {
    return obj;
  }
  return replaceProperty(obj);
};

const replaceProperty = (object) => {
  object.steps.forEach((step) => {
    if (step.kind === "function-transform") {
      step.content = findReplaceDynamic(step.content);
    }
    if (step.kind === "user-input" && step.inputsSchema) {
      step.inputsSchema.uiSchema = findReplaceDynamic(step.inputsSchema.uiSchema);
      step.inputsSchema.schema = findReplaceDynamic(
        step.inputsSchema.schema,
        "schema"
      );
    }
    if (kindsWithConnection.includes(step.kind)) {
      step.connection = findReplaceDynamic(step.connection);
    }
    if (step.kind === "trigger-orchestration") {
      step.orchestration = findReplaceDynamic(step.orchestration);
    }
    if (step.options) {
      step.options = findReplaceDynamic(step.options);
    }
    if (step.conditions) {
      step.conditions = findReplaceDynamic(step.conditions);
    }
    if (step.credentials) {
      step.credentials = findReplaceDynamic(step.credentials);
    }
  });
  return object;
};

export function UpdateOrchestrationEditor(props) {
  const dispatch = useDispatch();
  const canModify = usePermission("orchestrations:modify");
  const history = useHistory();
  const orchestrationId = props.match.params.id;
  const [orchestration, setOrchestration] = useState(null);
  const { executionId } = useSelector(selectOrchestrationEditor);

  const executionInfo = useExecution({ id: executionId, poll: true, orchestration });

  useEffect(() => {
    let active = true;
    (async () => {
      const response = await dispatch(
        actions.apiTransaction({
          route: `/orchestrations/${orchestrationId}`,
        })
      );
      if (response.error) {
        dispatch(alertError("Unable to load orchestration"));
        history.push(ORCHESTRATIONS_PATH);
      }
      if (response && active) {
        setOrchestration(response);
      }
    })();
    return () => {
      active = false;
    };
  }, [orchestrationId]);

  useEffect(() => {
    dispatch(openEditor({ orchestrationId, mode: "edit" }));
  }, [dispatch, orchestrationId]);

  function upgradeOrchestration() {
    const replacedOrc = propertyNeedsReplace(orchestration);
    addErrorHandling(replacedOrc);
    handleSubmit(replacedOrc);
  }

  const handleSubmit = (values) => {
    const trigger = { ...values.trigger };
    if (trigger?.connection?.id) {
      trigger.connection = trigger.connection.id;
    }
    const updatedOrchestration = {
      id: orchestrationId,
      ...values,
      trigger,
    };
    dispatch(
      saveOrchestration({
        orchestration: updatedOrchestration,
        mode: "update",
      })
    );
    setOrchestration(updatedOrchestration);
  };

  if (!orchestration) {
    return null;
  }

  return (
    <div className="flex flex-column flex-auto">
      <BaseEditor
        disabled={!canModify}
        onSubmit={handleSubmit}
        executionInfo={executionInfo}
        orchestration={orchestration}
        title={`Update`}
        validationSchema={updateOrchestrationSchema}
        upgradeOrchestration={upgradeOrchestration}
        editorType={EDITOR_TYPES.UPDATE}
      />
    </div>
  );
}

export function ViewOrchestrationEditor(props) {
  const dispatch = useDispatch();
  const executionId = props.match.params.id;
  const executionInfo = useExecution({ id: executionId });
  const { execution } = executionInfo;

  useEffect(() => {
    dispatch(openEditor({ executionId, mode: "view" }));
  }, [dispatch, executionId]);

  if (!execution?.orchestration) {
    return null;
  }

  const title = `Viewing orchestration executed at ${formattedExecutionDate(
    execution.triggeredAt
  )}`;

  return (
    <BaseEditor
      disabled={true}
      executionInfo={executionInfo}
      onSubmit={() => null}
      orchestration={executionInfo?.execution?.orchestration}
      title={title}
      editorType={EDITOR_TYPES.VIEW}
    />
  );
}

function useQuery() {
  const search = useLocation().search;
  return useMemo(() => {
    const params = new URLSearchParams(search);
    return Object.fromEntries(params.entries());
  }, [search]);
}

export function AddOrchestrationEditor() {
  const dispatch = useDispatch();
  const query = useQuery();
  const loc = useLocation();
  const [orchestration, setOrchestration] = useState(null);
  const canCreate = usePermission("orchestrations:create");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    dispatch(openEditor({ mode: "new" }));
  }, [dispatch]);

  const loadRequests = useCallback(
    async (route) => {
      if (route.includes("undefined")) {
        return {};
      }
      return await dispatch(
        actions.apiTransaction({
          route: route,
        })
      );
    },
    [dispatch]
  );

  const getPresentIds = useCallback(
    async (ids) => {
      const presentIds = [];
      for (const id of ids) {
        let response = await loadRequests(`/users/${id}`);
        if (!response.error) {
          presentIds.push(id);
        } else {
          response = await loadRequests(`/user-groups/${id}`);
          if (!response.error) {
            presentIds.push(id);
          }
        }
      }
      return presentIds;
    },
    [loadRequests]
  );

  const usersAndGroupsCheck = useCallback(
    async (orc, step, stepNum) => {
      if (["user-input", "user-reading", "notification"].includes(step.kind)) {
        const optionsKey = step.kind === "notification" ? "to" : "for";
        const optionValueExpression = step.options?.[`${optionsKey}`];
        if (!optionValueExpression) {
          return;
        }
        const astExpression = expressionToAst(optionValueExpression);
        const isArray = astExpression.type === AST_ELEMENT_TYPES.ARRAY_LITERAL;
        const ids = isArray ? [] : astExpression.value;
        if (isArray) {
          astExpression.value.map((i) => ids.push(i.value));
        }
        const presentIds = await getPresentIds(ids);
        if (presentIds.length) {
          let optionValue = presentIds;
          if (Array.isArray(presentIds)) {
            optionValue = presentIds.map((i) => `"${i}"`);
            optionValue = `[${optionValue}]`;
          }
          orc.steps[stepNum].options[`${optionsKey}`] = optionValue;
        } else {
          delete orc.steps[stepNum].options[`${optionsKey}`];
        }
      }
    },
    [getPresentIds]
  );

  const orchestrationsCheck = useCallback(
    async (orc, step, stepNum) => {
      if (step.orchestration && step.kind === "trigger-orchestration") {
        const expressionOrch = expressionToAst(step.orchestration);
        if (expressionOrch.type === AST_ELEMENT_TYPES.LITERAL) {
          const checkOrchestration = await loadRequests(
            `/orchestrations/${expressionOrch.value}`
          );
          if (checkOrchestration.error) {
            delete orc.steps[stepNum].orchestration;
          }
        }
      }
    },
    [loadRequests]
  );

  const connectionCheck = useCallback(
    async (orc, step, stepNum) => {
      if (step.connection) {
        const expressionConn = expressionToAst(step.connection);
        if (expressionConn.type === AST_ELEMENT_TYPES.LITERAL) {
          const checkConnection = await loadRequests(
            `/connections/${expressionConn.value}`
          );
          if (checkConnection.error) {
            //Set step connection with default value if no connection present
            orc.steps[stepNum].connection = "";
          } else {
            //Set step connection value
            orc.steps[stepNum].connection = `"${expressionConn.value}"`;
          }
        }
      }
    },
    [loadRequests]
  );

  const connectorCheck = useCallback(
    async (orc, step, stepNum) => {
      const params = step.uuid ? `uuid=${step.uuid}` : `id=${step.connector}`;
      const checkConnector = await loadRequests(`/connectors?${params}`);
      if (checkConnector.items) {
        let validConnector = checkConnector.items.find(
          (con) => con.id === step.connector
        );
        if (!validConnector) {
          if (step.version) {
            //filter on version
            validConnector = checkConnector.items.find(
              (connector) => connector.version === step.version
            );
          } else {
            //filter on state = current
            validConnector = checkConnector.items.find(
              (connector) => connector.state === "current"
            );
          }
        }
        orc.steps[stepNum].connector = validConnector?.id || "";
      }
      if (checkConnector.error) {
        orc.steps[stepNum].connector = "";
      }
    },
    [loadRequests]
  );

  const triggerCheck = useCallback(
    async (orc, trigger) => {
      if (trigger.connection) {
        const checkValidConnection = await loadRequests(
          `/connections/${trigger.connection.id}`
        );
        if (checkValidConnection.error) {
          orc.trigger.connection = "";
        }
      }
      orc.trigger.kind = "event";
    },
    [loadRequests]
  );

  async function checkImportOrc() {
    setLoading(true);
    const orch = cloneDeep(loc.state?.orchestration);
    const data = propertyNeedsReplace(orch);
    const trigger = loc.state?.orchestration?.trigger;
    if (["event", "reading"].includes(trigger?.kind)) {
      await triggerCheck(data, trigger);
    }
    if (loc.state?.orchestration?.steps) {
      for (const [i, step] of loc.state.orchestration.steps.entries()) {
        await connectionCheck(data, step, i);
        await connectorCheck(data, step, i);
        await usersAndGroupsCheck(data, step, i);
        await orchestrationsCheck(data, step, i);
      }
      addErrorHandling(data);
    }
    setOrchestration(data);
    setLoading(false);
  }
  async function getCopyOrc(mounted) {
    const response = await dispatch(
      actions.apiTransaction({
        route: `/orchestrations/${query.copy}`,
      })
    );
    if (response && mounted) {
      const replacedOrc = propertyNeedsReplace(response);
      addErrorHandling(replacedOrc);
      setOrchestration(replacedOrc);
    }
  }

  useEffect(() => {
    let mounted = true;
    let action = openEditor({
      mode: "new",
    });
    if (loc.state?.orchestration && mounted) {
      (async () => {
        await checkImportOrc();
      })();
    } else if (query.copy) {
      action = openEditor({
        orchestrationId: query.copy,
        mode: "copy",
      });
      (async () => {
        await getCopyOrc(mounted);
      })();
    } else {
      setOrchestration({
        attributes: {},
        id: null,
        name: "",
        state: "disabled",
        steps: [],
        trigger: { kind: "event" },
        orchestrationVersion: 2,
      });
    }
    dispatch(action);
    return () => {
      mounted = false;
    };
  }, []);

  const handleSubmit = useCallback(
    async (values) => {
      const data = cloneDeep(values);
      if (values.trigger.kind === "event" && values.trigger?.connection?.id) {
        data.trigger.connection = values.trigger.connection.id;
      }
      dispatch(saveOrchestration({ orchestration: data, mode: "new" }));
    },
    [dispatch]
  );

  if (!orchestration) {
    return <LoadingScreen overlay />;
  }
  return (
    <BaseEditor
      disabled={!canCreate}
      onSubmit={handleSubmit}
      orchestration={orchestration}
      validationSchema={createOrchestrationSchema}
      loading={loading}
      editorType={EDITOR_TYPES.ADD}
    />
  );
}

const formattedExecutionDate = (date) => {
  const timeFormat = "h:mm:ss a";
  const dateFormat = "MMM dd, yyyy";
  const parsedDate = parseISO(date);
  return `${format(parsedDate, timeFormat)} on ${format(parsedDate, dateFormat)}`;
};
