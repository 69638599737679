import { client } from "@dlx/services";

export async function getLabelNames() {
  let response = {};
  try {
    response = await client.get(`/orchestrations-label-names`);
  } catch (e) {
    ({ response } = e);
  }
  return response.data;
}
