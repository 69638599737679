import config from "config";
import axios from "axios";
import { getAccessToken } from "../tokens";

const APPLICATION_JSON = "application/json";

export const addAuthorizationHeaders = (request) => {
  if (!request.skipAuthHeaders) {
    request.headers["Authorization"] = `Bearer ${getAccessToken()}`;
  }
  return request;
};

const createInstance = () => {
  return axios.create({
    baseURL: config.apiUrl,
    headers: {
      "Content-Type": APPLICATION_JSON,
      Accept: APPLICATION_JSON,
    },
  });
};

export const client = createInstance();

export const auth = createInstance();
