import { Jexl } from "@firehammer/jexl";
import isEmpty from "lodash/isEmpty";
import {
  AST_ELEMENT_TYPES,
  OPERATORS,
  EXPRESSION_VALUE_TYPES,
  TYPE_ARRAY,
  TYPE_OBJECT,
  TYPE_REFERENCE,
  TYPE_STRING,
  VALUE_TYPES,
  TYPE_NUMBER,
  TYPE_BOOLEAN,
} from "./constants";
import { executeExpression } from "./api";

const jexl = new Jexl();

export function expressionToAst(expression) {
  try {
    return jexl.compile(expression)._getAst();
  } catch (error) {
    return {};
  }
}

export function astToExpression(ast) {
  if (!ast || ast.value === TYPE_REFERENCE.DEFAULT_VALUE) {
    return undefined;
  }

  try {
    const expression = jexl.createExpression("");
    expression._ast = ast;
    return expression.toString();
  } catch (error) {
    return undefined;
  }
}

export function expressionDataToAst(expressionData, schema = null) {
  const ast = {};
  Object.entries(expressionData || {}).forEach(([key, expression]) => {
    if (schema && !schema.properties[key]) {
      return;
    }
    ast[key] = expressionToAst(expression);
  });
  return { type: TYPE_OBJECT.TYPE, value: ast };
}

export function schemaToAst(schema) {
  if (
    !schema ||
    schema.format === "urn:scitara-dlx:expressions:json-schema-format"
  ) {
    return { type: TYPE_REFERENCE.TYPE, value: TYPE_REFERENCE.DEFAULT_VALUE };
  }

  let valueType = Array.isArray(schema.type) ? schema.type[0] : schema.type;
  if (valueType === "integer") {
    valueType = TYPE_NUMBER.VALUE_TYPE;
  }
  const baseType =
    EXPRESSION_VALUE_TYPES.find((b) => {
      return b.VALUE_TYPE === valueType;
    }) || TYPE_REFERENCE;

  return {
    type: baseType.TYPE,
    value: baseType.DEFAULT_VALUE,
  };
}

export function getAstElementValueType(element) {
  if (element.type === TYPE_REFERENCE.TYPE) {
    return TYPE_REFERENCE.VALUE_TYPE;
  }
  if (Array.isArray(element.value)) {
    return TYPE_ARRAY.VALUE_TYPE;
  }
  return typeof element.value;
}

export function filterTransforms(list, { name = null, inputType = null }) {
  if (!list) {
    return [];
  }
  //Prepare a dataType array because inputType and inputSchema.type can be a string or an array.
  //Match against undefined because inputSchema.type can be undefined.
  const dataType = [
    ...(Array.isArray(inputType) ? inputType : [inputType]),
    undefined,
  ];
  return list.filter((item) => {
    const schemaType = item.inputSchema?.type;
    //Prepare an array from inputSchema.type because it can be an array or a string.
    const inputSchemaType = Array.isArray(schemaType) ? schemaType : [schemaType];
    //Intersection of both sets dataType and inputSchemaType.
    const commonElements = inputSchemaType.filter((element) =>
      dataType.includes(element)
    );
    return (
      (name === null || item.name === name) &&
      (inputType === null ||
        commonElements.length > 0 ||
        //Show all functions for input type reference
        inputType === TYPE_REFERENCE.VALUE_TYPE)
    );
  });
}

export function getAstElementFromValue(value) {
  if (Array.isArray(value)) {
    value = value.map((v) =>
      ["string", "number", "boolean"].includes(typeof v)
        ? { type: "Literal", value: v }
        : v
    );
  }
  const valueType = typeof value;
  let type = TYPE_REFERENCE.TYPE;
  if (
    [VALUE_TYPES.STRING, VALUE_TYPES.NUMBER, VALUE_TYPES.BOOLEAN].includes(valueType)
  ) {
    type = TYPE_STRING.TYPE;
  } else if (Array.isArray(value)) {
    type = TYPE_ARRAY.TYPE;
  } else if (valueType === VALUE_TYPES.OBJECT) {
    type = TYPE_OBJECT.TYPE;
  }
  return { type, value };
}

export function getDataType(value) {
  if (Array.isArray(value)) {
    return VALUE_TYPES.ARRAY;
  }
  return typeof value;
}

export function getAstElementFromValueType(type) {
  switch (type) {
    case VALUE_TYPES.STRING:
      return { type: TYPE_STRING.TYPE, value: TYPE_STRING.DEFAULT_VALUE };
    case VALUE_TYPES.NUMBER:
      return { type: TYPE_NUMBER.TYPE, value: TYPE_NUMBER.DEFAULT_VALUE };
    case VALUE_TYPES.BOOLEAN:
      return { type: TYPE_BOOLEAN.TYPE, value: TYPE_BOOLEAN.DEFAULT_VALUE };
    case VALUE_TYPES.OBJECT:
      return { type: TYPE_OBJECT.TYPE, value: TYPE_OBJECT.DEFAULT_VALUE };
    case VALUE_TYPES.ARRAY:
      return { type: TYPE_ARRAY.TYPE, value: TYPE_ARRAY.DEFAULT_VALUE };
    default:
      return { type: TYPE_REFERENCE.TYPE, value: TYPE_REFERENCE.DEFAULT_VALUE };
  }
}

//Helper function to get a new BinaryExpressionElement
export function getOperatorExpressionElement(element, group, operator, type) {
  if ([OPERATORS.BINARY].includes(group)) {
    return {
      type: AST_ELEMENT_TYPES.BINARY_EXPRESSION,
      left: element,
      operator: operator.symbol,
      right: getAstElementFromValueType(type),
    };
  }
  return {};
}

//Helper function to get a new FunctionCall Element
export function getFunctionCallElement({ name }, element) {
  return {
    type: AST_ELEMENT_TYPES.FUNCTION_CALL,
    args: [element],
    name,
    pool: "transforms",
  };
}

export function findParentElements(element, errorElement) {
  let parentElements = [];

  if (element === errorElement) {
    return [element];
  }

  if (element.type === AST_ELEMENT_TYPES.BINARY_EXPRESSION) {
    return [
      ...findParentElements(element.left, errorElement),
      ...findParentElements(element.right, errorElement),
    ];
  }

  if (!isSimpleLiteralType(element.type)) {
    const arr =
      element.type === AST_ELEMENT_TYPES.OBJECT_LITERAL
        ? Object.values(element.value)
        : element.value;
    arr.forEach((fieldElement) => {
      const elements = findParentElements(fieldElement, errorElement);
      parentElements = [...parentElements, ...elements];
      if (elements.length) {
        parentElements = [...parentElements, element];
      }
    });
    return parentElements;
  }

  const { args } = element;
  if (!args) {
    return [];
  }

  if (args[0]?.type === AST_ELEMENT_TYPES.FUNCTION_CALL) {
    return findParentElements(args[0], errorElement);
  }

  if (!isSimpleLiteralType(args[0]?.type)) {
    return findParentElements(args[0], errorElement);
  }

  switch (args[1]?.type) {
    case AST_ELEMENT_TYPES.FUNCTION_CALL:
      return [...findParentElements(args[1], errorElement)];
    case AST_ELEMENT_TYPES.OBJECT_LITERAL:
      Object.values(args[1].value).forEach((fieldElement) => {
        const elements = findParentElements(fieldElement, errorElement);
        parentElements = [...parentElements, ...elements];
        if (elements.length) {
          parentElements = [...parentElements, element, args[1]];
        }
      });
      break;
    default:
      return parentElements;
  }
  return parentElements;
}

function isSimpleLiteralType(type) {
  return (
    [AST_ELEMENT_TYPES.OBJECT_LITERAL, AST_ELEMENT_TYPES.ARRAY_LITERAL].includes(
      type
    ) === false
  );
}

export function comparePrecedence(op1, op2) {
  const { [op1]: operator1, [op2]: operator2 } = jexl._grammar.elements;
  return operator1.precedence > operator2.precedence;
}

export async function getExpressionValue(option, context) {
  const ast = expressionToAst(option);
  let valueText;
  if (ast?.type === AST_ELEMENT_TYPES.IDENTIFIER && !isEmpty(context)) {
    const response = await executeExpression(astToExpression(ast), context || {});
    const { data } = response;
    valueText = data;
  } else {
    valueText = ast?.value;
  }
  return valueText;
}
