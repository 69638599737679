import React, { useState, useEffect } from "react";
import styles from "./StepChip.module.scss";
import clsx from "clsx";
import TextField from "@material-ui/core/TextField";

/**
 * @param {{
 * name: string,
 * active: boolean,
 * editStep: Function,
 * }} props
 */
const StepName = ({ name, active, editStep }) => {
  const [stepName, setStepName] = useState(name);

  useEffect(() => {
    setStepName(name);
  }, [name]);

  const input = (
    <div className={styles.stepNameInput}>
      <TextField
        fullWidth
        name="name"
        error={!stepName}
        helperText={!stepName && "Please provide step name"}
        InputProps={{
          disableUnderline: true,
          style: {
            fontSize: 16,
            fontWeight: "bold",
            padding: 0,
          },
        }}
        onBlur={() => {
          editStep(stepName);
        }}
        onChange={(e) => {
          setStepName(e.target.value);
        }}
        placeholder="Enter name for the step"
        type="text"
        value={stepName}
        InputLabelProps={{
          shrink: true,
        }}
      />
    </div>
  );

  return (
    <div className={styles.stepNameHolder}>
      <div className={clsx(styles.stepTitle)}>{active ? input : stepName}</div>
    </div>
  );
};

export default StepName;
