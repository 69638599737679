import React from "react";
import { OperatorSelector } from "../Selectors";
import { AstElement } from "../Helpers";
import { IconButton } from "../Wrappers";
import { OPERATORS } from "..";

function BinaryExpressionElement({ element, setElement }) {
  const changeOperator = (_group, operator) =>
    setElement({ ...element, operator: operator.symbol });
  const deleteOperator = () => setElement(element.left);
  const updateLeft = (left) => setElement({ ...element, left });
  const updateRight = (right) => setElement({ ...element, right });
  const Icon = OPERATORS.BINARY.list.find(
    (elem) => element.operator === elem.symbol
  )?.icon;

  return (
    <>
      <AstElement element={element.left} setElement={updateLeft} />
      <span className="inline-flex">
        <OperatorSelector onSelect={changeOperator} onRemove={deleteOperator}>
          <IconButton
            className="mt2 mh1 bg-default bg-hover-primary text-primary text-hover-contrast b"
            size={32}
            tooltip="Edit operator"
          >
            <Icon size={18} />
          </IconButton>
        </OperatorSelector>
      </span>
      <AstElement element={element.right} setElement={updateRight} />
    </>
  );
}

export default BinaryExpressionElement;
