import React, { useState } from "react";
import { useClipboard } from "use-clipboard-copy";
import { CopyIcon } from "../../icons";
import api from "@dlx/services/api";
import { reMapStepData } from "@dlx/state/executions/useExecution";
import ConfirmInline from "@dlx/atoms/ConfirmInline";
import Popover, { POPOVER_POSITIONS } from "@dlx/atoms/Popover";
import JsonViewer from "@dlx/components/jsonviewer";
import Spinner from "@dlx/atoms/Spinner";

const DataSelect = ({ executionId, children = null, className = "" }) => {
  const clipboard = useClipboard();
  const [displayMessage, setDisplayMessage] = useState(false);
  const [executionOutput, setExecutionOutput] = useState(null);
  const [isLoading, setLoading] = useState(false);

  const fetchExecutionOutput = async () => {
    setLoading(true);
    const output = await api.executions.mappingSources({ id: executionId });
    const steps = reMapStepData(output);
    setLoading(false);
    return { ...output, steps };
  };

  const copyValue = async () => {
    const output = await fetchExecutionOutput();
    clipboard.copy(JSON.stringify(output));
    setDisplayMessage(true);
  };

  const displayValue = async () => {
    const output = await fetchExecutionOutput();
    setExecutionOutput(output);
  };

  if (children) {
    return (
      <Popover position={POPOVER_POSITIONS.BOTTOM_RIGHT}>
        <div className={className} onClick={displayValue}>
          {isLoading ? (
            <Spinner className="inline-block text-secondary" size={20} />
          ) : (
            children
          )}
        </div>
        <div>
          {isLoading ? (
            <div className="pa3 text-secondary">Loading...</div>
          ) : (
            <JsonViewer
              json={executionOutput}
              copyAll={true}
              title="Output"
              trail={true}
            />
          )}
        </div>
      </Popover>
    );
  }
  return (
    <>
      <span className={className} onClick={copyValue}>
        {isLoading ? (
          <Spinner className="inline-block text-secondary" size={20} />
        ) : (
          <CopyIcon size="14" />
        )}
      </span>

      {displayMessage && (
        <ConfirmInline
          onClose={() => setDisplayMessage(false)}
          className="inline-block ml2 font-small text-default"
        >
          Copied!!
        </ConfirmInline>
      )}
    </>
  );
};

export default DataSelect;
