import { eventChannel } from "redux-saga";
import { call } from "redux-saga/effects";
import {
  createPromiseAction,
  implementPromiseAction,
} from "@adobe/redux-saga-promise";
import { sendSocketMessage } from "./ws.saga";

const CONNECTION_ACTION_REQUESTED = "connection-action-requested";
const CONNECTION_ACTION_RESPONSE = "connection-action-response";

const requestIdResponseMap = {};

function connectActionRequest(msg) {
  return { options: {}, ...msg, type: CONNECTION_ACTION_REQUESTED };
}

export const sendConnectionActionRequest = createPromiseAction(
  CONNECTION_ACTION_REQUESTED,
  connectActionRequest
);

export const createConnectionActionChannel = (socket) =>
  eventChannel((emit) => {
    const handleEvent = (data) => {
      requestIdResponseMap[data.requestId]?.(data);
      delete requestIdResponseMap[data.requestId];
      emit(data);
    };
    socket.on(CONNECTION_ACTION_RESPONSE, handleEvent);
    return () => {
      socket.off(CONNECTION_ACTION_RESPONSE, handleEvent);
    };
  });

function updatePayload(payload) {
  payload.actionName = payload.actionName || payload.command;
  payload.connectionId = payload.connectionId || payload.connection;
  return payload;
}

export function* sendCommandAndWaitForResponse(_, action) {
  return yield call(implementPromiseAction, action, function* () {
    const { requestId } = yield call(
      sendSocketMessage,
      CONNECTION_ACTION_REQUESTED,
      updatePayload(action.payload)
    );
    const promise = new Promise((resolve) => {
      requestIdResponseMap[requestId] = resolve;
    });
    return yield promise;
  });
}
