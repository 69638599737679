import {
  actionChannel,
  all,
  call,
  fork,
  put,
  take,
  takeLatest,
} from "redux-saga/effects";
import AuthService from "../../services/Auth";
import { logger } from "../../utils";
import { dbPreLoad, dbLoad, dbLoadSuccess, dbLoading } from "../reducers/dbAdapter";
import { types as authTypes } from "../auth/auth.actions";
import { LOAD_TABLE_ERROR } from "../constants";

const log = logger("tables.saga");
const authService = new AuthService();

export default function* tablesSaga() {
  log("tableWatcher");
  yield takeLatest(dbPreLoad, bootSystem);
}

function* handleDbLoad() {
  const chan = yield actionChannel(dbLoad);
  while (true) {
    const action = yield take(chan);
    yield fork(getTable, action.payload);
  }
}

function* reloadTables() {
  yield all([
    fork(getTable, { tableName: "connections", clearTable: true }),
    fork(getTable, {
      tableName: "connectors",
      filters: "state=current",
      clearTable: true,
    }),
    fork(getTable, {
      tableName: "orchestrations",
      clearTable: true,
    }),
  ]);
}

function* bootSystem() {
  yield fork(handleDbLoad);
  while (true) {
    yield take(authTypes.AUTH_REACTIVATE_SUCCESS);
    yield fork(reloadTables);
  }
}

function* getTable({ tableName, filter, clearTable }) {
  try {
    yield put(dbLoading({ tableName }));
    const response = yield call(fetchCollection, tableName, filter);
    yield put(dbLoadSuccess({ tableName, data: response, clearTable }));
  } catch (error) {
    log("error fetching table", tableName, error);
    yield put({ type: LOAD_TABLE_ERROR, error: error.message });
  }
}

async function fetchCollection(resource, filter = "") {
  try {
    const url = `/${resource}${filter ? "?" + filter : ""}`;
    log("fetching collection", resource, url);
    const response = await authService.fetch({ url, method: "GET" });
    const { items } = response;
    try {
      items.forEach(validateId);
      return response;
    } catch (error) {
      log("FIXME: no ids for", resource, error);
      log("adding IDS for", resource);
      if (resource === "actors") {
        return { items: items.map((item) => ({ ...item, id: item.type })) };
      }
      return { items: items.map((item, index) => ({ ...item, id: index })) };
    }
  } catch (error) {
    log("error fetching collection", resource, error);
    return { items: [] };
  }
}

function validateId(item) {
  if (!item.id) {
    throw new Error("Missing ID for ", item);
  }
}
