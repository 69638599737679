import { createPromiseAction } from "@adobe/redux-saga-promise";
import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import { initialState, reducer } from "../reducers/dbAdapter";
import { types as socketTypes } from "../socket/socket.actions";

const tableName = "connections";

export const connectionStatus = (message) => ({
  type: socketTypes.WS_CONNECTION_STATUS_UPDATED,
  payload: message,
});

export const storeConnectionSecrets = createPromiseAction(
  socketTypes.WS_STORE_SECRETS,
  (connectionId, secrets) => ({
    connectionId,
    secrets,
  })
);

export const connectionAdapter = createEntityAdapter({
  selectId: (entity) => entity.id,
});

const slice = createSlice({
  name: tableName,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    reducer(tableName)(builder);
    builder.addCase(socketTypes.WS_CONNECTION_STATUS_UPDATED, (state, action) => {
      connectionAdapter.upsertOne(state, {
        id: action.payload.id || action.payload.connectionId,
        status: action.payload.status,
      });
      return state;
    });
  },
});

export default slice.reducer;
