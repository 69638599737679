import React, { useContext, useEffect, useRef, useMemo, useCallback } from "react";
import { JsonForms } from "@jsonforms/react";
import { materialRenderers, materialCells } from "@jsonforms/material-renderers";
import MuiPassword from "./controls/MuiPassword";
import { FileSelectorControl, FileSelectorCell } from "./controls/FileSelector";
import {
  UserSelectorControl,
  UserSelectorCell,
} from "./controls/LookupSelectors/UserSelector";
import {
  ConnectionSelectorControl,
  ConnectionSelectorCell,
} from "./controls/LookupSelectors/ConnectionSelector";
import {
  OrchestrationSelectorControl,
  OrchestrationSelectorCell,
} from "./controls/LookupSelectors/OrchestrationSelector";
import {
  ConnectionLookupControl,
  ConnectionLookupCell,
} from "./controls/LookupSelectors/ConnectionLookupSelector";
import {
  EnumSelectorControl,
  EnumSelectorCell,
} from "./controls/LookupSelectors/EnumSelector";
import MuiSelector, {
  MuiSelectorCell,
} from "./controls/LookupSelectors/MuiSelector";
import MuiTable from "./controls/MuiTable";
import {
  MaterialDateTimeRenderer,
  MaterialDateTimeCell,
} from "./controls/MaterialDateTimeControl";
import {
  MaterialTimeCell,
  MaterialTimeRenderer,
} from "./controls/MaterialTimeControl";
import ajvToYupAdapter from "./ajvToYupAdapter";
import isEqual from "lodash/isEqual";
import {
  getInitialErrors,
  setReadOnlyUiFields,
  setReadOnlyFields,
  populateMappings,
  getDefaultDataForSchema,
} from "./helpers";
import ConnectionOptionLookup from "./controls/LookupSelectors/ConnectionOptionLookup";
import {
  ConnectionActionControl,
  ConnectionActionCell,
} from "./controls/ConnectionAction";
import {
  TriggerOrchestrationControl,
  TriggerOrchestrationCell,
} from "./controls/TriggerOrchestration";

export const JsonFormContext = React.createContext(null);

export function useJsonFormContext() {
  return useContext(JsonFormContext);
}

const renderers = [
  ...materialRenderers,
  //register custom renderers here
  MuiPassword,
  MuiSelector,
  MuiTable,
  FileSelectorControl,
  UserSelectorControl,
  ConnectionSelectorControl,
  OrchestrationSelectorControl,
  ConnectionLookupControl,
  EnumSelectorControl,
  MaterialTimeRenderer,
  MaterialDateTimeRenderer,
  ConnectionOptionLookup,
  ConnectionActionControl,
  TriggerOrchestrationControl,
];

const cells = [
  ...materialCells,
  MuiSelectorCell,
  MaterialDateTimeCell,
  MaterialTimeCell,
  ConnectionActionCell,
  TriggerOrchestrationCell,
  UserSelectorCell,
  ConnectionLookupCell,
  ConnectionSelectorCell,
  OrchestrationSelectorCell,
  EnumSelectorCell,
  FileSelectorCell,
];

/**
 * @param {{
 * mappingData: any,
 * executeLookupCommand: function,
 * schema: any,
 * uischema: any,
 * onSubmit: function,
 * data: any,
 * onChange: function,
 * reset: boolean,
 * setReset: function
 * }} props
 */
export default function JsonForm({
  mappingData,
  executeLookupCommand,
  schema,
  uischema,
  onSubmit,
  data,
  onChange,
  reset,
  setReset,
  ...rest
}) {
  const adapter = useRef(ajvToYupAdapter({ ...schema, type: "object" }));
  const { ajv, yupSchema, getErrors } = adapter.current;
  const initialValues = useRef(data);

  useEffect(() => {
    let mounted = true;
    const defaultData = getDefaultDataForSchema(schema, data);
    if (mounted) {
      onChange({
        data: defaultData,
        isDirty: false,
        isValid: isValidInput(defaultData),
        errors: getInitialErrors(defaultData, yupSchema),
      });
    }
    return () => {
      mounted = false;
    };
    /* eslint-disable-next-line */
  }, []);

  const handleSubmit = useCallback(
    (e) => {
      e.preventDefault();
      try {
        ajv.validate(data);
        onSubmit(data, e);
        return true;
      } catch (err) {
        return false;
      }
    },
    [ajv, data, onSubmit]
  );

  const isValidInput = useCallback(
    (validInput) => {
      const errors = getErrors(validInput);
      return errors.length === 0;
    },
    [getErrors]
  );

  const handleOnChange = useCallback(
    /** @param {{data: any, errors: any}} input */
    (input) => {
      const values = {
        ...input,
        isDirty: !isEqual(initialValues.current, input.data),
        isValid: isValidInput(input.data),
      };
      onChange(values);
    },
    [isValidInput, onChange]
  );

  const props = useMemo(() => {
    const args = {
      schema: { ...schema, type: "object" },
      uischema,
    };

    if (uischema && Object.keys(uischema).length) {
      // only set uischema if present.
      // if ui schema is an empty object jsonform won't use its default renders
      setReadOnlyUiFields(args.schema, uischema);
      args.uischema = populateMappings(
        setReadOnlyFields(args.schema, uischema),
        mappingData
      );
    } else {
      args.uischema = undefined;
    }
    return args;
  }, [mappingData, schema, uischema]);

  useEffect(() => {
    let mounted = true;
    if (reset && mounted) {
      setReset(false);
      try {
        onChange({ data: initialValues.current, errors: [] });
      } catch (error) {
        console.log(error);
      }
    }
    return () => {
      mounted = false;
    };
    // eslint-disable-next-line
  }, [reset]);

  return (
    <JsonFormContext.Provider
      value={{ executeLookupCommand, mappingData, reset, data }}
    >
      <form onSubmit={handleSubmit} data-testid="dtid_json-form">
        <JsonForms
          {...props}
          onChange={handleOnChange}
          renderers={renderers}
          ajv={ajv}
          data={data}
          cells={cells}
          {...rest}
        />
      </form>
    </JsonFormContext.Provider>
  );
}
