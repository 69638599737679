import "./wdyr";
import React from "react";
import ReactDOM from "react-dom";
import { Provider as ReduxProvider } from "react-redux";
import buildStore from "./store/buildStore";
import { App } from "./App";
import AppStorage from "./services/appStorage";
import MaterialUiProvider from "./App/MaterialUiProvider";
import { ConnectedRouterProvider } from "./store/connectedRouterMiddleware";
import SnackbarProvider from "./App/SnackbarProvider";
import ModalRoot from "./components/Modal";
import { SystemEnvProvider } from "./contexts/system/Provider";
import { SSOProvider } from "./contexts/sso/Provider";
import NiceModal from "@ebay/nice-modal-react";
import ErrorBoundary from "./components/ErrorBoundary";
import { SessionIdleTimer } from "./App/SessionIdleTimer";
import { client } from "./services/api/client";
import { ApiProvider } from "./contexts/api/Provider";
import { CurrentUserProvider } from "./contexts/current-user/Provider";
import { SocketProvider, SocketStatus } from "./contexts/socket/Provider";
import socket from "./services/socket";
import { SessionProvider } from "./contexts/session/Provider";
import { ScitaraHelpProvider } from "./components/ScitaraHelp";

AppStorage.setDebug("auth,session,api,socket");
const store = buildStore();

ReactDOM.render(
  <ReduxProvider store={store}>
    <ConnectedRouterProvider>
      <ErrorBoundary component="App">
        <NiceModal.Provider>
          <MaterialUiProvider>
            <SessionProvider>
              <ApiProvider client={client}>
                <SSOProvider>
                  <SystemEnvProvider>
                    <SocketProvider client={socket}>
                      <SnackbarProvider>
                        <React.Suspense fallback={"Loading..."}>
                          <CurrentUserProvider>
                            <ScitaraHelpProvider>
                              <SocketStatus />
                              <SessionIdleTimer />
                              <ModalRoot />
                              <App />
                            </ScitaraHelpProvider>
                          </CurrentUserProvider>
                        </React.Suspense>
                      </SnackbarProvider>
                    </SocketProvider>
                  </SystemEnvProvider>
                </SSOProvider>
              </ApiProvider>
            </SessionProvider>
          </MaterialUiProvider>
        </NiceModal.Provider>
      </ErrorBoundary>
    </ConnectedRouterProvider>
  </ReduxProvider>,
  document.getElementById("app")
);
