import React from "react";
import { useDispatch } from "react-redux";
import DLXModal from "../DLXModal/DLXModal";
import { CONFIRM, CANCEL } from "../../state/constants";

const cancel = (label) => {
  return label ? { type: `${label}_${CANCEL}` } : { type: CANCEL };
};

const confirm = (label) => {
  return label ? { type: `${label}_${CONFIRM}` } : { type: CONFIRM };
};

export default function ConfirmDialog({
  messageText,
  onClickNo,
  onClickYes,
  open,
  title,
  label,
  submitButtonLabel = "Yes",
  cancelButtonLabel = "No",
}) {
  const dialogTitle = title ? title : "Confirm";
  const dispatch = useDispatch();
  return (
    <DLXModal
      open={open}
      title={dialogTitle}
      size="micro"
      onSubmit={(e) => {
        dispatch(confirm(label));
        return onClickYes?.(e);
      }}
      onCancel={() => {
        dispatch(cancel(label));
        return onClickNo?.();
      }}
      submitButtonLabel={submitButtonLabel}
      cancelButtonLabel={cancelButtonLabel}
    >
      {messageText}
    </DLXModal>
  );
}
