import React from "react";
import { rankWith, formatIs } from "@jsonforms/core";
import { withJsonFormsCellProps, withJsonFormsControlProps } from "@jsonforms/react";
import Input from "@material-ui/core/Input";
import { InputLabel } from "@material-ui/core";

const jsonSchemaFormat = (data = "") => {
  try {
    const pattern = new RegExp(/^\d{2}:\d{2}$/);
    return data.match(pattern) ? `${data}:00` : data;
  } catch (e) {
    return data;
  }
};

const htmlInputFormat = (props) => {
  const { data, schema } = props;
  if (!data) {
    return schema.default || "";
  }
  try {
    const pattern = new RegExp(/^(\d{2}:\d{2})(:\d{2})?$/);
    return data.match(pattern)[1];
  } catch (e) {
    return data;
  }
};

export const MaterialTimeControl = (props) => {
  return (
    <React.Fragment>
      <InputLabel htmlFor={props.path}>{props.label}</InputLabel>
      <Input
        required={props.required}
        disabled={!props.enabled}
        type="time"
        value={htmlInputFormat(props)}
        fullWidth
        id={props.path}
        onChange={(e) =>
          props.handleChange(props.path, jsonSchemaFormat(e.target.value))
        }
      />
    </React.Fragment>
  );
};

// ensure Time fields are rendered properly in tables
export const MaterialTimeCell = {
  cell: withJsonFormsCellProps(MaterialTimeControl),
  tester: rankWith(1000, formatIs("time")),
};

// ensure Time fields are rendered properly
export const MaterialTimeRenderer = {
  renderer: withJsonFormsControlProps(MaterialTimeControl),
  tester: rankWith(1000, formatIs("time")),
};
