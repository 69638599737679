import {
  AndIcon,
  DivideIcon,
  EqualIcon,
  GreaterIcon,
  GreaterOrEqualIcon,
  InIcon,
  LessIcon,
  LessOrEqualIcon,
  MultiplyIcon,
  NotEqualIcon,
  OperatorIcon,
  OrIcon,
  PowerOfIcon,
  SubtractIcon,
} from "./operators";

const REFERENCE = "reference";
const STRING = "string";
const NUMBER = "number";
const BOOLEAN = "boolean";
const ARRAY = "array";
const OBJECT = "object";

const IDENTIFIER = "Identifier";
const LITERAL = "Literal";
const ARRAY_LITERAL = "ArrayLiteral";
const OBJECT_LITERAL = "ObjectLiteral";
const FUNCTION_CALL = "FunctionCall";
const BINARY_EXPRESSION = "BinaryExpression";
const FILTER_EXPRESSION = "FilterExpression";

export const VALUE_TYPES = Object.freeze({
  STRING,
  NUMBER,
  BOOLEAN,
  ARRAY,
  OBJECT,
});

export const AST_ELEMENT_TYPES = Object.freeze({
  IDENTIFIER,
  LITERAL,
  ARRAY_LITERAL,
  OBJECT_LITERAL,
  FUNCTION_CALL,
  BINARY_EXPRESSION,
  FILTER_EXPRESSION,
});

export const TYPE_REFERENCE = Object.freeze({
  VALUE_TYPE: REFERENCE,
  TYPE: IDENTIFIER,
  DEFAULT_VALUE: Symbol("Undefined Reference"),
});

export const TYPE_STRING = Object.freeze({
  VALUE_TYPE: STRING,
  TYPE: LITERAL,
  DEFAULT_VALUE: "",
});

export const TYPE_NUMBER = Object.freeze({
  VALUE_TYPE: NUMBER,
  TYPE: LITERAL,
  DEFAULT_VALUE: 0,
});

export const TYPE_BOOLEAN = Object.freeze({
  VALUE_TYPE: BOOLEAN,
  TYPE: LITERAL,
  DEFAULT_VALUE: false,
});

export const TYPE_ARRAY = Object.freeze({
  VALUE_TYPE: ARRAY,
  TYPE: ARRAY_LITERAL,
  DEFAULT_VALUE: [],
});

export const TYPE_OBJECT = Object.freeze({
  VALUE_TYPE: OBJECT,
  TYPE: OBJECT_LITERAL,
  DEFAULT_VALUE: {},
});

export const EXPRESSION_VALUE_TYPES = [
  TYPE_REFERENCE,
  TYPE_STRING,
  TYPE_NUMBER,
  TYPE_BOOLEAN,
  TYPE_ARRAY,
  TYPE_OBJECT,
];

export const OPERATORS = {
  BINARY: {
    label: "Binary Operator",
    type: BINARY_EXPRESSION,
    list: [
      {
        label: "Add",
        icon: OperatorIcon,
        symbol: "+",
        accepts: ["string", "number"],
      },
      { label: "Subtract", icon: SubtractIcon, symbol: "-", accepts: ["number"] },
      { label: "Multiply", icon: MultiplyIcon, symbol: "*", accepts: ["number"] },
      { label: "Divide", icon: DivideIcon, symbol: "/", accepts: ["number"] },
      { label: "Power of", icon: PowerOfIcon, symbol: "^", accepts: ["number"] },
      {
        label: "AND",
        icon: AndIcon,
        symbol: "&&",
        accepts: ["string", "number", "boolean"],
      },
      {
        label: "OR",
        icon: OrIcon,
        symbol: "||",
        accepts: ["string", "number", "boolean"],
      },
      {
        label: "Equal",
        icon: EqualIcon,
        symbol: "==",
        accepts: ["string", "number", "boolean"],
      },
      {
        label: "Not equal",
        icon: NotEqualIcon,
        symbol: "!=",
        accepts: ["string", "number", "boolean"],
      },
      {
        label: "Greater than",
        icon: GreaterIcon,
        symbol: ">",
        accepts: ["string", "number"],
      },
      {
        label: "Greater than or equal",
        icon: GreaterOrEqualIcon,
        symbol: ">=",
        accepts: ["string", "number"],
      },
      {
        label: "Less than",
        icon: LessIcon,
        symbol: "<",
        accepts: ["string", "number"],
      },
      {
        label: "Less than or equal",
        icon: LessOrEqualIcon,
        symbol: "<=",
        accepts: ["string", "number"],
      },
      {
        label: "Exists in array or object",
        icon: InIcon,
        symbol: "in",
        accepts: ["string", "array"],
      },
    ],
  },
};
