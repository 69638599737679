import config from "config";
import { client } from "./api/client";
import { logger } from "../utils";
import { getAccessToken } from "./tokens";

const log = logger("auth:service");
const USER_NOT_LOGGED_IN = "User is not logged in";

const headers = {
  Accept: "application/json",
  "Content-Type": "application/json",
};
export default class AuthService {
  // Initializing important variables
  constructor(domain) {
    this.domain = domain || config.apiUrl; // API server domain
    this.fetch = this.fetch.bind(this); // React binding stuff
    this.getList = this.getList.bind(this);
    this.getRecord = this.getRecord.bind(this);
    this.saveRecord = this.saveRecord.bind(this);
  }

  async getInfo() {
    const response = await client.get(`/account/info`, {
      skipAuthHeaders: true,
    });
    return response.data.features;
  }

  getList(source) {
    if (!this.isLoggedIn()) {
      throw new Error(USER_NOT_LOGGED_IN);
    }

    return this.fetch({ url: `/${source}`, method: "GET" })
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  }

  getRecord(source, id) {
    if (!this.isLoggedIn()) {
      throw new Error(USER_NOT_LOGGED_IN);
    }

    return this.fetch({ url: `/${source}/${id}`, method: "GET" })
      .then(async (response) => {
        if (response.success) {
          return Promise.resolve(response);
        }
        return Promise.reject(response);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  getMethod(formType) {
    if (formType === "Add") {
      return "POST";
    }
    return formType === "Update" ? "PUT" : "DELETE";
  }

  saveRecord(source, record, formType) {
    if (!this.isLoggedIn()) {
      throw new Error(USER_NOT_LOGGED_IN);
    }
    return this.fetch({
      url: `/${source}${formType === "Add" ? "" : "/" + record.id}`,
      method: this.getMethod(formType),
      body: JSON.stringify(record),
    })
      .then(async (response) => {
        if (response.error) {
          return Promise.reject(response);
        }
        return Promise.resolve(response);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  isLoggedIn() {
    // Checks if there is a saved token
    const token = getAccessToken(); // Getting token from localstorage
    return !!token;
  }

  async fetch(options = {}) {
    if (options?.body) {
      options.data =
        typeof options.body === "string" ? JSON.parse(options.body) : options.body;
    }
    try {
      const response = await client(options);
      return this.handleResponse(response);
    } catch (error) {
      log("error fetching", error);
      if (error?.isAxiosError) {
        throw error;
      }
      throw new HttpError(error);
    }
  }

  logout() {
    console.log("handle logout");
  }

  handleResponse(response = {}) {
    switch (response.status) {
      case 200:
      case 202:
        return response.data;
      case 204:
        return null;
      default:
        log("FIXME: uncaught status code!", response);
        throw response;
    }
  }

  static async requestVerificationCode(email) {
    const response = await fetch(`${config.apiUrl}/account/recovery`, {
      headers,
      method: "POST",
      body: JSON.stringify({ username: email }),
    });

    if (!response.ok) {
      const data = await response.json();
      throw new HttpError(data);
    }
  }

  static async resetPassword(email, password, code) {
    const response = await fetch(`${config.apiUrl}/account/recovery`, {
      headers,
      method: "POST",
      body: JSON.stringify({ username: email, password: password, response: code }),
    });
    if (!response.ok) {
      const data = await response.json();
      throw new HttpError(data);
    }
  }
}

export class HttpError extends Error {
  constructor(response) {
    super(response.message);
    this.error = response?.error;
    this.statusCode = response?.statusCode;
  }
}

export const getCurrentUser = () => {
  const u = localStorage.getItem("user");
  if (u) {
    return JSON.parse(u);
  }
  return null;
};
