import {
  race,
  take,
  put,
  select,
  call,
  takeEvery,
  takeLeading,
} from "redux-saga/effects";
import AuthService, { HttpError } from "../../services/Auth";
import { alertSuccess, alertError } from "../alerts/actions";
import {
  dbCreateRecord,
  dbDeleteRecord,
  dbRecordUpdated,
  dbRecordDeleted,
  recordError,
  dbRecordRetrieved,
  dbRetrieveRecord,
  dbUpdateRecord,
  dbRecordCreated,
  dbLoadSuccess,
} from "../reducers/dbAdapter";
import { kebabCase } from "lodash";
import { hide } from "../modal/modal.actions";
import { goBack, push } from "connected-react-router";
import { logger } from "../../utils";
import { CANCEL, CANCELED } from "../constants";

const log = logger("record.saga");
const authService = new AuthService();

export default function* recordSaga() {
  yield takeEvery(dbRetrieveRecord, getRecordSaga);
  yield takeLeading(
    [dbCreateRecord, dbUpdateRecord, dbDeleteRecord],
    function* (action) {
      yield race({
        cancel: take(CANCEL),
        request: call(handleRequest, action),
      });
    }
  );
}

function* handleRequest(action) {
  switch (action.type) {
    case dbCreateRecord.toString(): {
      yield createRecordSaga(action);
      break;
    }
    case dbUpdateRecord.toString(): {
      yield updateRecordSaga(action);
      break;
    }
    case dbDeleteRecord.toString(): {
      yield deleteRecordSaga(action);
      break;
    }
    default:
      break;
  }
}

export function* hasRecordBeenFetched(payload) {
  const { tableName: slice, id } = payload;
  return yield select((state) => state[slice].fetched[id]);
}

export function* selectRecord(payload) {
  const { tableName: slice, id } = payload;
  return yield select((state) => state[slice].entities[id]);
}

export function* fetchRecord(payload) {
  const request = { url: getPathFromPayload(payload), method: "GET" };
  const data = yield call(authService.fetch, request);
  if (payload.tableName === "customers") {
    const response = yield call(
      authService.fetch,
      `${request.url}/reasons-for-change`,
      {
        method: "GET",
      }
    );
    yield put(dbLoadSuccess({ tableName: "reasons-for-change", data: response }));
  } else {
    yield put(
      dbRecordRetrieved({
        tableName: payload.tableName,
        record: data,
      })
    );
  }
  return data;
}

export function getPathFromPayload(payload) {
  try {
    const { href, tableName, id, record, filter = "", source } = payload;
    if (href) {
      return href;
    }

    const path = source || kebabCase(tableName);
    // Review of below line needed
    const path2 = path.startsWith("/") ? path : "/" + path;
    const identifier = id || record?.id;
    const query = !filter || filter.startsWith("?") ? filter : "?" + filter;
    return [path2, identifier].filter(Boolean).join("/") + query;
  } catch (error) {
    log("Error constructing url", { payload }, error);
    throw error;
  }
}

function* shouldFetchRecord(payload) {
  const fetched = yield hasRecordBeenFetched(payload);
  return !fetched || payload.forceUpdate;
}

function* getRecordSaga({ payload }) {
  try {
    if (yield !shouldFetchRecord(payload)) {
      return yield selectRecord(payload);
    }
    return yield fetchRecord(payload);
  } catch (error) {
    return yield handleError(error, payload);
  }
}

export function* makeRequest(method, url, data = {}, headers = {}) {
  const request = { url, method, body: JSON.stringify(data), headers };
  return yield call([authService, authService.fetch], request);
}

const errorMessages = {
  400: "Something unexpected occured. Please report this error to your administrator",
  401: "You are not currently authenticated. Please login and try again",
  403: "You do not have the permissions necessary to perform this action",
  409: "A record with that name already exists",
  404: "No record found",
};

export function* handleError(error, payload = {}) {
  if (error.message === CANCELED) {
    throw new Error(CANCELED);
  }
  let message = error.message;
  if (error?.isAxiosError) {
    const { data } = error.response;
    message = data.message || errorMessages[data.statusCode];
    if (data.code === "DLX_SIGNATURE_REQUIRED") {
      return null;
    }
  }
  if (error instanceof HttpError && errorMessages[error.statusCode]) {
    message = errorMessages[error.statusCode] || error.message;
  }
  yield put(recordError({ ...payload, message }));
  if (payload.label !== "ws") {
    yield put(alertError(message));
  }
  return { error: message };
}

export function* createRecordSaga({ payload }) {
  try {
    const response = yield call(
      makeRequest,
      "POST",
      getPathFromPayload(payload),
      payload.record
    );
    yield put(dbRecordCreated({ tableName: payload.tableName, record: response }));
    if (!payload.skipRedirect) {
      yield put(hide());
      yield put(goBack());
      yield put(alertSuccess("Record added successfully"));
    }
    return response;
  } catch (error) {
    return yield handleError(error, payload);
  }
}

function* updateRecordSaga({ payload }) {
  try {
    const url = getPathFromPayload(payload);
    const data = yield call(makeRequest, "PUT", url, payload.record);
    yield put(alertSuccess("Record updated successfully"));
    yield put(
      dbRecordUpdated({ tableName: payload.tableName, id: data.id, changes: data })
    );
    // bit of a hack until we specify by resource
    const [path] = window.location.pathname.split(`/${data.id}`);
    yield put(hide());
    yield put(push(path));
    return payload.record;
  } catch (error) {
    return yield handleError(error, payload);
  }
}

function* deleteRecordSaga({ payload }) {
  try {
    if (!payload.permitted) {
      return yield put(
        alertError("You do not have the necessary permissions to archive this item")
      );
    }
    yield call(makeRequest, "DELETE", getPathFromPayload(payload));
    yield put(dbRecordDeleted({ tableName: payload.tableName, id: payload.id }));
    const successType = payload.type || "archived";
    return yield put(alertSuccess(`Successfully ${successType}`));
  } catch (error) {
    return yield handleError(error, payload);
  }
}
