import React from "react";
import { TriggerIcon } from "../../../icons";

export default function TriggerTypeRow({ type, label }) {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        width: "100%",
        padding: 6,
      }}
    >
      <TriggerIcon trigger={type} style={{ margin: 0, fontSize: "2em" }} />
      <p style={{ fontSize: 12, margin: 0 }}>{label}</p>
    </div>
  );
}
