import React from "react";
import Switch from "@material-ui/core/Switch";
import { makeStyles } from "@material-ui/styles";
import { colors } from "@dlx/assets/styles";

const PRIMARY = {
  base: colors.bgPrimaryLight,
  checked: colors.bgPrimaryDark,
  track: colors.bgSecondaryDark,
};
const SECONDARY = {
  base: colors.bgSecondary,
  checked: colors.bgSecondaryDark,
  track: colors.bgSecondaryDark,
};
const CONTRAST = {
  base: colors.bgSecondaryLight,
  checked: colors.bgDefault,
  track: colors.bgSecondaryLight,
};
export const SWITCH_VARIANTS = Object.freeze({
  PRIMARY,
  SECONDARY,
  CONTRAST,
});

const MEDIUM = "medium";
const SMALL = "small";
export const SWITCH_SIZES = {
  MEDIUM,
  SMALL,
};

const SwitchWrapper = ({
  name = "",
  checked = false,
  disabled = false,
  onChange,
  onBlur = () => {},
  variant = SWITCH_VARIANTS.PRIMARY,
  size = SWITCH_SIZES.MEDIUM,
  dataTestId = "",
}) => {
  const classes = useStyles(variant)();
  return (
    <Switch
      color="default"
      classes={classes}
      checked={checked}
      onChange={(e) => onChange(e.target.checked)}
      onBlur={onBlur}
      name={name}
      disabled={disabled}
      size={size}
      inputProps={{
        "data-testid": dataTestId,
      }}
    />
  );
};

const useStyles = ({ base, checked, track }) =>
  makeStyles({
    switchBase: {
      color: base,
    },
    checked: {
      color: checked,
    },
    track: {
      backgroundColor: track,
    },
  });

export default SwitchWrapper;
