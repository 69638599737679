import { createPromiseAction } from "@adobe/redux-saga-promise";
import { deMinifyMappings } from "../../utils/mapping";

// dispatch an action to retrieve a record either from the store or through the api
// the record is returned in a promise and sent to the redux store
const API_REQUEST = "API_REQUEST";
const API_TRANSACTION = "API_TRANSACTION";
const API_CREATE_RECORD = "API_CREATE_RECORD";
const API_GET_LINK = "API_GET_LINK";

export const actions = {
  apiRequest: createPromiseAction(API_REQUEST, (payload) => payload),
  apiTransaction: createPromiseAction(API_TRANSACTION, (payload) => payload),
  applyMappings: createPromiseAction(API_TRANSACTION, (mappings, context) => {
    return {
      route: `/mapping`,
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: {
        mappings: deMinifyMappings(mappings),
        context,
      },
    };
  }),
  getLink: createPromiseAction(API_GET_LINK, (payload) => payload),
  createRecord: createPromiseAction(API_CREATE_RECORD, (payload) => payload),
};

const getSelfHrefForRecord = (record) =>
  record.$links.find((l) => l.rel === "self")?.href;

export function getRecordRequest(record) {
  const href = getSelfHrefForRecord(record);
  const [tableName, id] = href.split("/");
  return actions.apiRequest({
    href,
    tableName,
    id,
  });
}
