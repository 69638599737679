import React from "react";
import { uiTypeIs, rankWith } from "@jsonforms/core";
import {
  areEqual,
  withJsonFormsCellProps,
  withJsonFormsControlProps,
} from "@jsonforms/react";
import MuiSelectorControl from "./MuiSelectorControl";

const ConnectionSelectorTester = rankWith(
  103, //increase rank as needed
  uiTypeIs("DlxConnectionSelector")
);

const ConnectionSelector = (props) => {
  props.uischema.options = {
    ...props.uischema.options,
    labelRequiresLookup: true,
    lookup: {
      route: "/lookups/connections",
      options: {},
    },
  };

  if (props.uischema.options.nameContains) {
    props.uischema.options.lookup.options.name = props.uischema.options.nameContains;
  }

  if (props.uischema.options.connector) {
    props.uischema.options.lookup.options.compatibleWithConnectorId =
      props.uischema.options.connector;
  }

  if (!props.uischema.options.showOffline) {
    props.uischema.options.filterBy = [
      ...(props.uischema.options.filterBy || []),
      { path: "status.online", value: true },
    ];
  }

  return MuiSelectorControl(props);
};

export const ConnectionSelectorControl = {
  renderer: withJsonFormsControlProps(React.memo(ConnectionSelector, areEqual)),
  tester: ConnectionSelectorTester,
};

export const ConnectionSelectorCell = {
  cell: withJsonFormsCellProps(React.memo(ConnectionSelector, areEqual)),
  tester: rankWith(1000, uiTypeIs("DlxConnectionSelector")),
};
