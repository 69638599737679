import { put, call, takeLeading, putResolve } from "redux-saga/effects";
import { push } from "connected-react-router";
import { alertError, alertSuccess } from "../alerts/actions";
import api from "../../services/api";
import logger from "../../utils/logger";
import { manualTrigger, TRIGGER_ORCHESTRATION } from "./orchestration.reducer";
import { ORCHESTRATIONS, EXECUTION_DETAILS } from "../../views/routes";
import { generatePath } from "react-router-dom";
const log = logger("triggerOrchestration.saga");

export default function* triggerOrchestrationSaga() {
  yield takeLeading(TRIGGER_ORCHESTRATION, attemptExecuteOrchestration);
}

function* attemptExecuteOrchestration(action) {
  const { id, data, source, permissions } = action.payload;
  log(`Triggering orchestration ${id} from ${source}`);
  try {
    validateUser(permissions);
    const orc = yield call(getOrchestration, id);
    validateOrchestration(orc);
    const response = yield putResolve(manualTrigger(orc.id, data));
    yield call(executeOrchestrationSuccess, { orc, response, source });
  } catch (error) {
    log(error);
    yield call(executeOrchestrationFailure, { error, id });
  }
}

async function getOrchestration(id) {
  try {
    return api.orchestrations.get({ id });
  } catch (err) {
    log(err);
    throw new Error(`No orchestration found with id ${id}`);
  }
}

/**
 * @param {{
 *  orc: Orchestration,
 *  response: import("../sagas/manualTrigger.saga").OrchestrationTriggeredMessage,
 *  source: string
 *}}
 */
function* executeOrchestrationSuccess({ orc, response, source }) {
  if (source === "url") {
    yield call(handleExecutionFromURLSuccess, orc, response);
  }
  if (source === "web") {
    yield call(handleExecutionFromWebSuccess, orc);
  }
}

function* executeOrchestrationFailure({ error, id }) {
  log("executeOrchestration:failure", error);
  yield put(push(ORCHESTRATIONS));
  yield put(alertError(`No orchestration found with id ${id}`));
}

/**
 *
 * @param {Orchestration} orc
 * @param {import("../sagas/manualTrigger.saga").OrchestrationTriggeredMessage} response
 */
function* handleExecutionFromURLSuccess(orc, response) {
  log("executeOrchestration:success", orc, response);
  const { executionId } = response;
  const url = generatePath(EXECUTION_DETAILS, { executionId });
  yield put(push(url));
  yield put(alertSuccess(`${orc.name} triggered!`));
}

/**
 * @param {Orchestration} orchestration
 */
function* handleExecutionFromWebSuccess(orchestration) {
  yield put(alertSuccess(`${orchestration.name} triggered!`));
}

/* Validations */
const canView = (permissions) => permissions.includes("orchestrations:view");
const canTrigger = (permissions) => permissions.includes("orchestrations:trigger");
const isEnabled = (orc) => orc.state === "enabled";
const canBeTriggered = (orc) => ["manual", "timer"].includes(orc.trigger.kind);

function validateUser(permissions) {
  if (!permissions) {
    throw new Error("No permissions found");
  }
  if (!canView(permissions)) {
    throw new Error("You are not permitted to view this orchestration");
  }
  if (!canTrigger(permissions)) {
    throw new Error("You are not permitted to trigger this orchestration");
  }
  return true;
}

function validateOrchestration(orc) {
  if (!isEnabled(orc)) {
    throw new Error("This orchestration must be enabled");
  }
  if (!canBeTriggered(orc)) {
    throw new Error("This orchestration can not be manually triggered");
  }
  return true;
}
