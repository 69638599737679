import React from "react";

import { TYPE_REFERENCE } from "@dlx/components/ExpressionEditor";

import styles from "./ConditionsEditor.module.scss";
import ConditionalParameter from "./ConditionalParameter/ConditionalParameter";
import { PlusIcon } from "../../../icons";

/**
 * @param {{
 *  conditions: Array[],
 *  onUpdate: Function,
 *  stepNum: number
 * }} props
 */
export default function ConditionsEditor({ conditions = [], onUpdate, stepNum }) {
  const handleUpdateCondition = (expression, index) => {
    const newConditions = JSON.parse(JSON.stringify(conditions));
    newConditions[index] = expression;
    onUpdate({ conditions: newConditions });
  };

  const addCondition = () => {
    onUpdate({ conditions: [...conditions, TYPE_REFERENCE.DEFAULT_VALUE] });
  };

  const handleRemoveCondition = (i) => {
    const newConditions = JSON.parse(JSON.stringify(conditions));
    newConditions.splice(i, 1);
    onUpdate({ conditions: newConditions });
  };

  return (
    <div className={styles.conditionsEditorHolder}>
      {!conditions.length && (
        <p className={styles.noConditions}>No conditions have been added</p>
      )}
      {conditions.map((condition, i) => (
        <div key={i}>
          {i > 0 && (
            <div className={styles.conditionButtonContainer}>
              <div className={styles.conditionsOperator}>AND</div>
            </div>
          )}
          <ConditionalParameter
            key={`condition-${i}`}
            stepNum={stepNum}
            condition={condition}
            onUpdateCondition={(expression) => handleUpdateCondition(expression, i)}
            onRemoveCondition={() => handleRemoveCondition(i)}
          />
        </div>
      ))}
      <div className={styles.conditionButtonContainer}>
        <div className={styles.conditionAdd} onClick={addCondition}>
          <p className="font-small ml3">Add condition</p>
          <PlusIcon className="font-small mr3" />
        </div>
      </div>
    </div>
  );
}
