import React, { useState, useEffect, useRef } from "react";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import FormGroup from "@material-ui/core/FormGroup";
import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import MuiAutocomplete from "@material-ui/lab/Autocomplete";
import useLookup from "../../state/hooks/useLookup";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import Chip from "@material-ui/core/Chip";

import styles from "./FilterBar.module.scss";
import { useCurrentUserContext } from "../../contexts/current-user/Provider";
import { BaseControl } from "./BaseControl";
import { LabelsListFilter } from "../Labels";
import clsx from "clsx";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const Autocomplete = withStyles({
  root: {
    minWidth: 300,
  },
  listbox: {
    overflowX: "hidden",
  },
})(MuiAutocomplete);

/**
 * optionIdValueMap is an object/dictionary created by reducing the "options" array,
 * where each object's "value"(id) property serves as the key
 * and the corresponding object is the value.
 */
const createOptionIdValueMap = (options) => {
  return options.reduce((option, obj) => {
    if (typeof obj === "object") {
      option[obj.value] = obj;
    }
    if (typeof obj === "string") {
      option[obj] = {
        label: obj,
        value: obj,
      };
    }
    return option;
  }, {});
};

export const SingleSelect = ({
  setValue,
  options,
  loading,
  value,
  children = null,
  placeholder,
}) => {
  return (
    <Autocomplete
      fullWidth
      disablePortal={false}
      disableCloseOnSelect
      getOptionSelected={(o) => {
        let selected = o?.value === value?.value;
        if (value?.name) {
          selected = o?.label === value.name;
        }
        return selected;
      }}
      getOptionLabel={(o) => {
        return o?.name || o?.label || (typeof o === "object" ? "" : o);
      }}
      onChange={(_event, selected) => {
        setValue(
          selected
            ? {
                label: selected.label || "",
                value: selected.value,
                name: selected.name || selected.label,
              }
            : null
        );
      }}
      options={options}
      loading={loading}
      size="small"
      value={value}
      renderInput={(params) => {
        return (
          <div>
            <TextField
              {...params}
              placeholder={placeholder || "Search"}
              variant="outlined"
              color="primary"
            />
            {children}
          </div>
        );
      }}
    />
  );
};

export const MultiSelect = ({
  label,
  placeholder,
  defaultValue,
  selectedData,
  setSelectedData,
  mapValue = null,
  loading,
  options = [],
  freeSolo = false,
  checkBoxOptions = true,
}) => {
  const handleOnChange = (_event, val) => {
    val.forEach((record, i) => {
      if (typeof record === "string") {
        val[i] = mapValue ? mapValue[record] : record;
      }
    });
    setSelectedData(val);
  };

  const props = {
    freeSolo: freeSolo,
    renderInput: (params) => (
      <TextField
        {...params}
        variant="outlined"
        placeholder={placeholder || "Search"}
        inputProps={{
          ...params.inputProps,
        }}
        onBlur={(e) => {
          freeSolo &&
            e?.target.value &&
            handleOnChange(e, [...selectedData, e.target.value]);
        }}
      />
    ),
  };

  if (freeSolo) {
    props.autoSelect = true;
    props.renderTags = (value, getTagProps) => {
      return value.map((option, index) => (
        <Chip
          size="small"
          label={option?.label || option}
          {...getTagProps({ index })}
        />
      ));
    };
  }

  if (checkBoxOptions) {
    props.renderOption = (option, { selected }) => {
      return (
        <React.Fragment>
          <Checkbox
            icon={icon}
            checkedIcon={checkedIcon}
            style={{ marginRight: 8 }}
            checked={selected}
          />
          {option.label}
        </React.Fragment>
      );
    };
  }

  return (
    <>
      {selectedData.length > FILTER_LIMIT && (
        <div className={(clsx(styles.helperText), "text-error")}>
          Maximum 10 items can be added
        </div>
      )}
      {loading ? (
        <div className={styles.fetchingLookup}>Fetching {label}s...</div>
      ) : (
        <Autocomplete
          {...props}
          multiple
          limitTags={1}
          id="select"
          onChange={handleOnChange}
          loading={loading}
          options={options}
          disableCloseOnSelect
          getOptionLabel={(option) => {
            const selectedItem = mapValue[option?.value || option];
            return selectedItem?.label || selectedItem || "";
          }}
          getOptionSelected={(option, val) => (val?.value || val) === option.value}
          getOptionDisabled={() => selectedData.length > FILTER_LIMIT - 1}
          defaultValue={(Array.isArray(defaultValue) && defaultValue) || []}
          size="small"
          style={{ width: 400 }}
        />
      )}
    </>
  );
};

/**
 *
 * @param {{
 *  control: { type: "text "},
 *  defaultValue?: string,
 *  label: string,
 *  name: string,
 *  onCancel: function,
 *  onSubmit: function,
 * }} props
 */
export const TextControl = (props) => {
  const { onSubmit, onCancel, label, defaultValue } = props;
  const [value, setValue] = useState(defaultValue);
  return (
    <BaseControl
      disabled={value?.length === 0}
      label={label}
      onCancel={onCancel}
      onSubmit={() => onSubmit({ label: `${label}: ${value}`, value })}
    >
      <div className="pa4">
        <TextField
          id="outlined-basic"
          fullWidth
          defaultValue={defaultValue}
          variant="outlined"
          size="small"
          onChange={(e) => setValue(e.target.value.trimStart())}
        />
      </div>
    </BaseControl>
  );
};

/**
 *
 * @param {{
 *   control: { type: "select", options: Option[] } ,
 *   defaultValue?: {FilterValue}[]
 *   label: string,
 *   loading: boolean,
 *   onCancel: function,
 *   onSubmit: function,
 *   options: Option[],
 *   flags: object,
 *   setFlags: function
 * }} props
 */
export const SelectControl = (props) => {
  const {
    onSubmit,
    onCancel,
    options,
    label,
    loading,
    defaultValue,
    flags,
    setFlags,
  } = props;
  const [value, setValue] = useState(defaultValue || null);
  return (
    <BaseControl
      onCancel={onCancel}
      disabled={!value}
      onSubmit={() => onSubmit(value)}
      label={label}
    >
      <div className="pa4">
        <SingleSelect
          setValue={setValue}
          options={options}
          loading={loading}
          value={value}
        >
          {flags &&
            Object.keys(flags).map((f) => {
              return (
                <FormControlLabel
                  key={f}
                  control={
                    <Checkbox
                      checked={flags[f].value}
                      onChange={(e) =>
                        setFlags({
                          ...flags,
                          [f]: { ...flags[f], value: e.target.checked },
                        })
                      }
                      size="small"
                      inputProps={{ "aria-label": f }}
                    />
                  }
                  label={flags[f].label}
                />
              );
            })}
        </SingleSelect>
      </div>
    </BaseControl>
  );
};

/**
 *
 * @param {{
 *  onSubmit: function,
 *  onCancel: function,
 *  options: Option[],
 *  label: string,
 *  defaultValue?: any,
 * }} props
 */
export const CheckBoxFieldSetControl = (props) => {
  const { onSubmit, onCancel, options, label, defaultValue } = props;
  const defaultFilter =
    Array.isArray(defaultValue) &&
    defaultValue.map((d) => options.filter((v) => d === v.value)[0]);
  const [value, setValue] = useState(defaultFilter || []);

  return (
    <BaseControl
      label={label}
      onSubmit={() => {
        onSubmit({
          label: value.map((v) => v.label).join(" or "),
          value: value.map((v) => v.value),
        });
      }}
      onCancel={onCancel}
      disabled={value.length === 0}
    >
      <div className="pa4">
        <FormGroup>
          {options.map((option) => (
            <FormControlLabel
              key={option.value}
              control={
                option.field ? (
                  <div>{option.field}</div>
                ) : (
                  <Checkbox
                    defaultChecked={value.some((v) => v.value === option.value)}
                    onChange={(_e, checked) => {
                      if (checked) {
                        setValue(value.concat(option));
                      } else {
                        setValue(value.filter((v) => v.value !== option.value));
                      }
                    }}
                  />
                )
              }
              label={
                option.field ? (
                  <div className={styles.checkBoxGroup}>{option.label}</div>
                ) : (
                  option.label
                )
              }
            />
          ))}
        </FormGroup>
      </div>
    </BaseControl>
  );
};

/**
 *
 * @param {{
 *  control: { type: "radio" }
 *  defaultValue?: string
 *  label: string,
 *  name: string,
 *  onCancel: function,
 *  onSubmit: function,
 *  options: Option[],
 * }} props
 */
export const RadioControl = (props) => {
  const { onSubmit, onCancel, options, label, defaultValue } = props;
  const [value, setValue] = useState(defaultValue);

  return (
    <BaseControl
      label={label}
      onSubmit={() => {
        const option = options.find((o) => o.value === value);
        onSubmit({ label: option.label, value: option.value });
      }}
      onCancel={onCancel}
      disabled={value.length === 0}
    >
      <div className="pa4">
        <RadioGroup
          onChange={(_e, choice) => setValue(choice)}
          defaultValue={defaultValue}
        >
          {options.map((option) => (
            <FormControlLabel
              key={option.value}
              value={option.value}
              control={<Radio color="primary" />}
              label={option.label}
            />
          ))}
        </RadioGroup>
      </div>
    </BaseControl>
  );
};

const DateInput = withStyles({
  input: {
    fontFamily: "'Lato', 'Helvetica Neue', Arial, Helvetica, sans-serif",
    padding: "10px 14px",
    border: 0,
    borderRadius: 4,
    letterSpacing: "inherit",
    color: "#838383",
    textTransform: "uppercase",
  },
  root: {
    fontSize: 14,
    border: "1px solid #e1e1e1",
    borderRadius: 4,
    margin: 4,
  },
})(({ classes, ...props }) => (
  <div className={classes.root}>
    <input {...props} type="datetime-local" className={classes.input} />
  </div>
));

/**
 *
 * @param {{
 *  control: { type: "date" },
 *  defaultValue: string,
 *  label: string,
 *  onCancel: function,
 *  onSubmit: function,
 *  options?: any,
 * }} props
 */
export function DateControl(props) {
  const { onCancel, onSubmit, label, defaultValue = "" } = props;
  const [value, setValue] = useState(defaultValue);
  return (
    <BaseControl
      label={label}
      onCancel={onCancel}
      onSubmit={() => onSubmit({ label: value, value })}
      disabled={!value}
    >
      <div className="pa4">
        <DateInput
          defaultValue={value}
          onChange={(e) => {
            setValue(e.target.value);
          }}
        />
      </div>
    </BaseControl>
  );
}
/**
 *
 * @param {{
 *  control: { type: "daterange" },
 *  defaultValue: [min: string, max: string] | []
 *  label: string,
 *  onCancel: function,
 *  onSubmit: function,
 *  options?: any,
 * }} props
 */
export function DateRangeControl(props) {
  const { onCancel, onSubmit, label, defaultValue = [] } = props;
  const [range, setRange] = useState(defaultValue);
  const [min, max] = range;
  const [minSelect, setMinSelect] = useState(null);
  const maxSelect = new Date().toISOString().substring(0, 16);
  return (
    <BaseControl
      label={label}
      onCancel={onCancel}
      onSubmit={() => onSubmit({ label: range.join(" ... "), value: range })}
      disabled={!min || !max}
    >
      <div className="pa4">
        <div style={{ display: "flex", flexDirection: "column" }}>
          <DateInput
            name="min"
            defaultValue={min}
            onChange={(e) => {
              const { value } = e.target;
              setRange([value, max]);
              setMinSelect(value);
            }}
            max={maxSelect}
          />
          <DateInput
            name="max"
            defaultValue={max}
            onChange={(e) => {
              const { value } = e.target;
              setRange([min, value]);
            }}
            min={minSelect}
            max={maxSelect}
          />
        </div>
      </div>
    </BaseControl>
  );
}

/**
 *
 * @param {{
 *   onSubmit: function,
 *   onCancel: function,
 *   label: string,
 *   control: { type: "lookup", options: { path: string, query: object}},
 *   defaultValue: {label: string, value: string}[],
 * }} props
 */
export function LookupSelectControl(props) {
  const { control, label, ...rest } = props;
  const [checks, setChecks] = useState(control.options.query || {});
  const [query, setQuery] = useState("");

  useEffect(() => {
    if (checks) {
      let temp = "";
      Object.keys(checks).forEach((q, i) => {
        temp += `${i === 0 ? "?" : "&"}${q}=${checks[q].value}`;
        if (checks[q].value && checks[q].getAll) {
          temp += `&${q}=${!checks[q].value}`;
        }
      });
      setQuery(temp);
    }
  }, [checks]);

  const { options, loading } = useLookup({
    path: control.options.path + query,
  });

  return (
    <SelectControl
      {...rest}
      flags={checks}
      setFlags={setChecks}
      options={options}
      label={label}
      loading={loading}
      defaultValue={rest.value}
    />
  );
}

/**
 *
 * @param {{
 *   control: { type: "multiselect", options: Option[] } ,
 *   defaultValue?: {FilterValue}[]
 *   label: string,
 *   loading: boolean,
 *   onCancel: function,
 *   onSubmit: function,
 *   options: Option[],
 * }} props
 */
const FILTER_LIMIT = 10;
export function LookupMultiSelectControl(props) {
  const { control, label, onCancel, onSubmit, value, defaultValue } = props;
  const { options, loading } = useLookup({
    path: control.options.path,
  });
  const [selectedData, setSelectedData] = useState(value?.value || []);

  const optionIdValueMap = createOptionIdValueMap(options);

  const handleOnSubmit = (selected) => {
    if (typeof selected[0] !== "object") {
      selected = selected.map((select) => optionIdValueMap[select] || null);
    }
    const setNames = selected.map((v) => v.label);
    const setValues = selected.map((v) => v.value);
    let names;
    if (setNames.length > 2) {
      names = `${setNames.length} selected`;
    } else {
      names = `${setNames.join(" or ")}`;
    }
    onSubmit({ label: names, value: setValues });
  };

  return (
    <BaseControl
      onCancel={onCancel}
      disabled={selectedData.length === 0}
      onSubmit={() => handleOnSubmit(selectedData)}
      label={label}
    >
      <div className="pa4">
        <MultiSelect
          label={label}
          defaultValue={defaultValue}
          selectedData={selectedData}
          setSelectedData={setSelectedData}
          mapValue={optionIdValueMap}
          loading={loading}
          options={options}
        />
      </div>
    </BaseControl>
  );
}

/**
 *
 * @param {{
 *  control: { type: "userInput"},
 *  onSubmit: function,
 * }} props
 */
export const UserInputControl = (props) => {
  const { currentUser } = useCurrentUserContext();
  const user = useRef({ label: currentUser.name, value: currentUser.id });
  const { onSubmit } = props;
  useEffect(() => {
    onSubmit(user.current);
  }, [onSubmit]);
  return null;
};

export const UserIntervention = ({ onSubmit }) => {
  useEffect(() => {
    onSubmit({ value: "intervention" });
  }, [onSubmit]);

  return null;
};

export const LabelControl = (props) => {
  const { control, label, onSubmit, value, defaultValue, ...rest } = props;
  const [controlValue, setControlValue] = useState(value?.value || []);

  const { options, loading } = useLookup({
    path: control.options.path,
  });

  const handleOnSubmit = () => {
    let labelName = "";
    if (controlValue.length > 2) {
      labelName = `${controlValue.length} selected`;
    } else {
      const labels = controlValue.map((v) => v.label);
      labelName = `${labels.join(" and ")}`;
    }

    onSubmit({
      label: `${controlValue.length ? labelName : ""}`,
      name: "Labels",
      value: controlValue,
    });
  };

  return (
    <BaseControl
      onCancel={rest.onCancel}
      disabled={controlValue.some(
        (item) => !item.name || item.value.length > FILTER_LIMIT
      )}
      onSubmit={() => handleOnSubmit()}
      label={label}
    >
      <LabelsListFilter
        options={options}
        loading={loading}
        label={label}
        defaultValue={defaultValue}
        value={controlValue}
        updateValue={setControlValue}
        optionIdValueMap={createOptionIdValueMap(options)}
      />
    </BaseControl>
  );
};
