import { EDITOR_TYPES } from "@dlx/components/OrchestrationFlow/constants";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconButton } from "@material-ui/core";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { usePermission } from "../../../contexts/current-user/Provider";
import { alertSuccess } from "../../../state/alerts/actions";
import DLXFrameModal from "../../DLXFrameModal";
import DLXTooltip from "../../DLXTooltip/DLXTooltip";
import QrCodeGenerator from "../../QrCodeModal";
import styles from "../OrchestrationFlow.module.scss";
import ExecuteTriggerDialog from "./ExecuteTriggerDialog";
import { useOrchestrationEditorContext } from "../BaseEditor";

const createUrl = (triggerUrl, data) => {
  if (typeof data === "object" && Object.keys(data).length) {
    const params = new URLSearchParams(data);
    Object.entries(data).forEach(([k, v]) =>
      params.set(k, typeof v === "object" ? JSON.stringify(v) : v)
    );
    return `${triggerUrl}?${params.toString()}`;
  }
  return triggerUrl;
};

/**
 * @param {{
 * orchestration: Orchestration,
 * editorType: string,
 * }} props
 */
const UserTriggerStepContent = ({ orchestration, editorType }) => {
  const dispatch = useDispatch();
  const [modalUrl, setModalUrl] = useState("");
  const [showTriggerDialog, setShowTriggerDialog] = useState(false);
  const url = new URL(window.location.href);
  const baseTriggerUrl = `${url.protocol}//${url.host}/orchestrations/${orchestration?.id}/execute`;
  const canTrigger = usePermission("orchestrations:trigger");
  const { orchestration: prevOrchestration = {} } = useOrchestrationEditorContext();

  const handleTriggerTest = () => {
    setShowTriggerDialog(true);
  };

  const executeTrigger = (data) => {
    setModalUrl(createUrl(baseTriggerUrl, data));
    setShowTriggerDialog(false);
  };

  const handleCopyUrl = (data) => {
    const trigUrl = createUrl(baseTriggerUrl, data);
    navigator.clipboard.writeText(trigUrl);
    dispatch(alertSuccess(`Trigger url copied to clipboard`));
  };

  return (
    <div className={styles.manualEvent}>
      <DLXFrameModal
        open={!!modalUrl}
        onClose={() => setModalUrl("")}
        url={modalUrl}
        title="Execution view"
      />
      <ExecuteTriggerDialog
        handleCopyUrl={handleCopyUrl}
        executeTrigger={executeTrigger}
        show={showTriggerDialog}
        setShow={setShowTriggerDialog}
        createUrl={(data) => createUrl(baseTriggerUrl, data)}
        orchestration={orchestration}
      />
      <div className={styles.buttonContainer}>
        <DLXTooltip text="Copy trigger url">
          <span>
            <IconButton
              className={styles.iconButton}
              onClick={() => handleCopyUrl()}
              disabled={
                editorType === EDITOR_TYPES.ADD ||
                prevOrchestration.state === "disabled"
              }
            >
              <FontAwesomeIcon icon="copy" />
            </IconButton>
          </span>
        </DLXTooltip>
        <QrCodeGenerator
          className={styles.iconButton}
          url={createUrl(baseTriggerUrl)}
          tooltip="Create QR code to trigger orchestration"
          caption={orchestration.name}
          disabled={
            editorType === EDITOR_TYPES.ADD || prevOrchestration.state === "disabled"
          }
        />
        {canTrigger && (
          <DLXTooltip text="Trigger orchestration">
            <span>
              <IconButton
                className={styles.iconButton}
                onClick={handleTriggerTest}
                disabled={
                  editorType === EDITOR_TYPES.ADD ||
                  prevOrchestration.state === "disabled"
                }
              >
                <FontAwesomeIcon icon="running" />
              </IconButton>
            </span>
          </DLXTooltip>
        )}
      </div>
    </div>
  );
};

export default UserTriggerStepContent;
