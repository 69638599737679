import cloneDeep from "lodash/cloneDeep";
import convertToYup from "json-schema-yup-transformer";

// @jsonforms/react uses AJV for validation.
// AJV is a security vulnerability because of its use of eval
// so we make our own

const ajvToYupAdapter = (jsonSchema) => {
  const yupSchema = convertToYup(cloneDeep(jsonSchema));
  const validate = (data) => {
    return yupSchema.validateSync(data, { abortEarly: false });
  };
  const buildError = (e) => {
    return {
      keyword: e.type || "Unknown",
      dataPath: `/${e.path}`,
      schemaPath: `#/properties/${e.path}/${e.type}`,
      params: e.params,
      message: e.message,
      parentSchema: jsonSchema.properties[e.path],
      data: e.value,
    };
  };
  const getErrors = (data) => {
    try {
      validate(data);
      return [];
    } catch (errors) {
      return (
        errors?.inner?.filter(handleInvalidTimes).map(buildError) || [
          buildError(errors),
        ]
      );
    }
  };
  const ajv = {
    compile: () => {
      const validator = (...validatorArgs) => {
        const [data] = validatorArgs;
        validator.errors = getErrors(data);
      };
      return validator;
    },
    validate: (...validateArgs) => {
      const [input, val = false] = validateArgs;
      return input?.const === val;
    },
  };
  return { ajv, yupSchema, getErrors };
};

/**
 * The json validator uses this regex to parse strings as dates:
 * /^([01]\d|2[0-3]):[0-5]\d:[0-5]\d(?:Z|[+-][01]\d:[0-5]\d)$/i
 * So this is valid: 20:20:39+00:00 but this 20:20:39 is not.
 * HTML input elements can accept HH:mm or HH:mm:ss
 * This filters out any time errors that match the correct HTML pattern
 */
function handleInvalidTimes(e) {
  if (e.message.includes("invalid time format")) {
    const timeRegex = /(\d{2}:\d{2})(:\d{2})?/;
    const match = e.params.value.match(timeRegex);
    return !match;
  }
  if (e.message.includes("invalid date and time format")) {
    return !Date.parse(e.params.value);
  }
  if (e.message.includes("invalid date format")) {
    return !e.params.value;
  }
  return true;
}

export default ajvToYupAdapter;
