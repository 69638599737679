import { takeEvery, put, call } from "redux-saga/effects";
import AuthService from "../../services/Auth";
import { alertSuccess, alertError } from "../alerts/actions";

const authService = new AuthService();

const EXPORT_VERSION = 1;
const PREPARE_ORCHESTRATION_FOR_EXPORT = "PREPARE_ORCHESTRATION_FOR_EXPORT";
const EXPORT_JSON_REQUEST = "EXPORT_JSON_REQUEST";
const EXPORT_JSON_SUCCESS = "EXPORT_JSON_SUCCESS";
const EXPORT_JSON_FAILURE = "EXPORT_JSON_FAILURE";

export default function* saga() {
  yield takeEvery(PREPARE_ORCHESTRATION_FOR_EXPORT, prepareOrchestrationForExport);
  yield takeEvery(EXPORT_JSON_REQUEST, handleExport);
}

function getExportRecord(record) {
  const link = getSelfLink(record);
  return authService.fetch({
    method: "GET",
    url: `${link}?$export=true`,
  });
}

export function* prepareOrchestrationForExport(action) {
  try {
    const orchestration = yield call(getExportRecord, action.payload.orchestration);
    yield put({
      type: EXPORT_JSON_REQUEST,
      payload: { data: orchestration },
    });
  } catch (e) {
    yield put(alertError("We're having a problem exporting that orchestration."));
  }
}

function* handleExport(action) {
  try {
    const { data, additionalMetaData } = action.payload;
    const meta = yield call(generateMetaData, additionalMetaData);
    const file = yield call(
      createJsonFile,
      { data, meta },
      `${data.name || "export"}.json`
    );
    yield call(downloadFile, file);
    yield put({ type: EXPORT_JSON_SUCCESS });
    yield put(alertSuccess("Orchestration exported"));
  } catch (e) {
    yield put({ type: EXPORT_JSON_FAILURE, error: e.message });
  }
}

const createJsonFile = (data, filename) => {
  const payload = JSON.stringify(data);
  return new File([payload], filename);
};

const generateMetaData = (meta = {}) => ({
  version: EXPORT_VERSION,
  timestamp: Date.now(),
  environment: window.location.hostname,
  ...meta,
});

const getSelfLink = (item) => {
  return item.$links.find((l) => l.rel === "self")?.href;
};

function downloadFile(file) {
  return new Promise((resolve, reject) => {
    try {
      // Create a link and set the URL using `createObjectURL`
      const link = document.createElement("a");
      link.style.display = "none";
      link.href = URL.createObjectURL(file);
      link.download = file.name;

      // It needs to be added to the DOM so it can be clicked
      document.body.appendChild(link);
      link.click();

      // To make this work on Firefox we need to wait
      // a little while before removing it.
      setTimeout(() => {
        URL.revokeObjectURL(link.href);
        link.parentNode.removeChild(link);
        resolve();
      }, 500);
    } catch (e) {
      reject(e);
    }
  });
}
