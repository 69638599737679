import React, { useEffect, useState } from "react";
import scitaraLogo from "../../assets/images/scitara-logo.png";
import Button, { BUTTON_VARIANTS } from "@dlx/atoms/Button";
import { storeCurrentUser, storeIamToken } from "@dlx/services/tokens";
import { broadcastSessionAuthenticated } from "@dlx/services/authChannel";

export const IFRAME_SCREEN = Object.freeze({
  DEFAULT: "You currently do not have an active session. Please sign in to proceed.",
  AUTHENTICATING: "Please complete the sign in flow.",
  WAITING: "Waiting for authentication to be completed.",
});

export const openIframeSignin = () => {
  const url = new URL(window.location.href);
  url.searchParams.set("signinAction", "iframe");
  window.open(url.toString());
};

export const storeTokenFromEvent = (event) => {
  const token = event.data;
  storeIamToken(token);
  storeCurrentUser(token);
  broadcastSessionAuthenticated(token);
};

export const messageEventHandler = (event) => {
  const allowedOrigin = `${window.location.protocol}//${window.location.host}`;
  if (event.origin === allowedOrigin && event.data.access_token) {
    storeTokenFromEvent(event);
  }
};

const RenderIFrame = ({ screen }) => {
  const [iframeText, setIframeText] = useState(screen);
  useEffect(() => {
    window.addEventListener("message", messageEventHandler);
  });

  const handleClick = () => {
    openIframeSignin();
    setIframeText(IFRAME_SCREEN.WAITING);
  };

  const handleBack = () => {
    setIframeText(screen);
  };

  return (
    <div className="tc pt6 mt4 font-default">
      <img src={scitaraLogo} height="70" />
      <div className="pv5 mt4">{iframeText}</div>
      {iframeText === IFRAME_SCREEN.DEFAULT && (
        <Button
          variant={BUTTON_VARIANTS.PRIMARY}
          className="pv3 ph4"
          onClick={handleClick}
        >
          Sign in
        </Button>
      )}
      {iframeText === IFRAME_SCREEN.WAITING && (
        <Button
          variant={BUTTON_VARIANTS.PRIMARY}
          className="pv3 ph4"
          onClick={handleBack}
        >
          Back
        </Button>
      )}
    </div>
  );
};
export default RenderIFrame;
