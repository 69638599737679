import React from "react";
import clsx from "clsx";
import Tooltip from "@dlx/atoms/Tooltip";

import { TYPE_OBJECT } from "../constants";
import { schemaToAst } from "../util";
import { AstRow } from "./";

function AstSchemaRows({
  schema,
  rootSchema = null,
  element,
  setElement,
  onClear = null,
  className = "",
}) {
  const { properties, required } = schema;
  if (!properties) {
    return <div>Invalid Schema</div>;
  }

  const handleElementUpdate = (updates, field) => {
    const value = element.value;
    setElement({
      type: TYPE_OBJECT.TYPE,
      value: { ...value, [field]: updates },
    });
  };

  return (
    <div className={clsx("font-medium gap2 flex flex-column", className)}>
      {Object.entries(properties).map(([fieldKey, fieldSchema]) => {
        const { title = fieldKey, description, type } = fieldSchema;
        const isRequired = required?.includes(fieldKey);
        const existingField = element?.value?.[fieldKey];
        const elem = existingField || schemaToAst(fieldSchema);
        if (element.validationErrors?.[fieldKey]) {
          elem.error = true;
        }
        const handleClearExpression = onClear ? () => onClear(fieldKey) : null;
        let rowSchema = fieldSchema;
        //If feild schema doesn't have properties then inherit the object schema from rootSchema
        if (rootSchema && !fieldSchema.properties) {
          rowSchema = rootSchema.items || rootSchema;
        }
        //DWC-1402 requires this check
        //string and number input shall not prefilled with a default value until user defines it.
        //If fieldSchema.default is present then do not consider a blank value state
        //If existingField === undefined means user has not entered the value
        const isBlankValue = existingField === undefined && !fieldSchema.default;
        return (
          <section key={fieldKey} className="flex items-start">
            <Tooltip text={description}>
              <div
                className="pr2 pt3"
                style={{ width: "130px", minWidth: "130px", cursor: "default" }}
              >
                <div className="capitalize">
                  {title} {isRequired && "*"}
                </div>
                <div className="text-secondary font-small mt1">
                  {Array.isArray(type) ? type.join(", ") : type}
                </div>
              </div>
            </Tooltip>
            <div className="flex-auto">
              <AstRow
                schema={rowSchema}
                element={elem}
                setElement={(updates) => {
                  handleElementUpdate(updates, fieldKey);
                }}
                onClear={handleClearExpression}
                isBlank={isBlankValue}
                dataTestId={`dtid_option_row_${fieldKey}`}
              />
            </div>
          </section>
        );
      })}
    </div>
  );
}

export default AstSchemaRows;
