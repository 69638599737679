import React from "react";

export const OperatorIcon = (props) => {
  const { size } = props;
  return (
    <svg
      height={size}
      width={size}
      viewBox="0 0 20 20"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.7895 5.78947C10.7895 5.58009 10.7063 5.37929 10.5582 5.23123C10.4102 5.08318 10.2094 5 10 5C9.79062
           5 9.58981 5.08318 9.44176 5.23123C9.2937 5.37929 9.21053 5.58009 9.21053 5.78947V9.21053H5.78947C5.58009
           9.21053 5.37929 9.2937 5.23123 9.44176C5.08318 9.58981 5 9.79062 5 10C5 10.2094 5.08318 10.4102 5.23123
           10.5582C5.37929 10.7063 5.58009 10.7895 5.78947 10.7895H9.21053V14.2105C9.21053 14.4199 9.2937 14.6207 9.44176
           14.7688C9.58981 14.9168 9.79062 15 10 15C10.2094 15 10.4102 14.9168 10.5582 14.7688C10.7063 14.6207 10.7895
           14.4199 10.7895 14.2105V10.7895H14.2105C14.4199 10.7895 14.6207 10.7063 14.7688 10.5582C14.9168 10.4102 15
           10.2094 15 10C15 9.79062 14.9168 9.58981 14.7688 9.44176C14.6207 9.2937 14.4199 9.21053 14.2105
           9.21053H10.7895V5.78947Z"
        fill="currentColor"
      />
    </svg>
  );
};

export const SubtractIcon = (props) => {
  const { size } = props;
  return (
    <svg
      height={size}
      width={size}
      viewBox="0 0 20 20"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="4" y="9" width="12" height="2" rx="1" fill="currentColor" />
    </svg>
  );
};

export const MultiplyIcon = (props) => {
  const { size } = props;
  return (
    <svg
      height={size}
      width={size}
      viewBox="0 0 20 20"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.43116 6.24555C7.27394 6.08833 7.0607 6 6.83836 6C6.61601 6 6.40277 6.08833 6.24555 6.24555C6.08833 6.40277
           6 6.61601 6 6.83836C6 7.0607 6.08833 7.27394 6.24555 7.43117L8.81438 10L6.24555 12.5688C6.08833 12.7261 6 12.9393
           6 13.1616C6 13.384 6.08833 13.5972 6.24555 13.7545C6.40277 13.9117 6.61601 14 6.83836 14C7.0607 14 7.27394 13.9117
           7.43116 13.7545L10 11.1856L12.5688 13.7545C12.7261 13.9117 12.9393 14 13.1616 14C13.384 14 13.5972 13.9117 13.7545
           13.7545C13.9117 13.5972 14 13.384 14 13.1616C14 12.9393 13.9117 12.7261 13.7545 12.5688L11.1856 10L13.7545
           7.43116C13.9117 7.27394 14 7.0607 14 6.83836C14 6.61601 13.9117 6.40277 13.7545 6.24555C13.5972 6.08833 13.384
           6 13.1616 6C12.9393 6 12.7261 6.08833 12.5688 6.24555L10 8.81438L7.43116 6.24555Z"
        fill="currentColor"
      />
    </svg>
  );
};

export const DivideIcon = (props) => {
  const { size } = props;
  return (
    <svg
      height={size}
      width={size}
      viewBox="0 0 20 20"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7 15.1962C6.52171 14.92 6.35783 14.3084 6.63397 13.8301L11.634 5.16988C11.9101 4.69158 12.5217 4.52771 13
           4.80385C13.4783 5.07999 13.6422 5.69158 13.366 6.16988L8.36602 14.8301C8.08988 15.3084 7.47829 15.4723 7 15.1962Z"
        fill="currentColor"
      />
    </svg>
  );
};

export const PowerOfIcon = (props) => {
  const { size } = props;
  return (
    <svg
      height={size}
      width={size}
      viewBox="0 0 20 20"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.7207 12.6482C14.3482 13.1173 13.7444 13.1173 13.3719 12.6482L10 8.40202L6.6281 12.6482C6.25565 13.1173
           5.65179 13.1173 5.27934 12.6482C4.90689 12.1792 4.90689 11.4188 5.27934 10.9497L8.65124 6.70354C9.39614 5.76549
           10.6039 5.76549 11.3488 6.70354L14.7207 10.9497C15.0931 11.4188 15.0931 12.1792 14.7207 12.6482Z"
        fill="currentColor"
      />
    </svg>
  );
};

export const AndIcon = (props) => {
  const { size } = props;
  return (
    <svg
      height={size}
      width={size}
      viewBox="3 3 14 14"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.45638 13.4529C9.50081 13.4974 9.55566 13.5436 9.55566 13.6065C9.55566 13.6571 9.51949 13.6824 9.44715
           13.6824H8.32951C8.2644 13.6824 8.21015 13.6571 8.16675 13.6065L7.67846 13.0639C7.65675 13.0422 7.63144 13.0422
           7.6025 13.0639C6.93698 13.5341 6.20636 13.7692 5.41063 13.7692C4.74511 13.7692 4.19533 13.6137 3.76129
           13.3026C3.33449 12.9843 3.12109 12.5214 3.12109 11.9137C3.12109 11.4218 3.25492 11.0059 3.52258 10.6659C3.79746
           10.3259 4.16639 10.0112 4.62936 9.72185C4.68488 9.68484 4.61834 9.61965 4.57613 9.56799C4.22847 9.14257 3.99664
           8.81046 3.88065 8.57165C3.75768 8.31847 3.69619 8.05443 3.69619 7.77954C3.69619 7.44678 3.783 7.14657 3.95661
           6.87892C4.13022 6.60403 4.37256 6.39063 4.68362 6.23872C5.00191 6.07957 5.36361 6 5.7687 6C6.18827 6 6.56082
           6.07957 6.88634 6.23872C7.21187 6.39063 7.46506 6.60765 7.6459 6.88977C7.83399 7.17189 7.92803 7.49742 7.92803
           7.86635C7.92803 8.30762 7.79782 8.69463 7.5374 9.02739C7.27697 9.36015 6.92613 9.66398 6.48486 9.93886C6.44869
           9.96057 6.44507 9.98588 6.47401 10.0148C6.80677 10.4055 7.21187 10.8648 7.68931 11.3929C7.71101 11.4218 7.73633
           11.4218 7.76526 11.3929C7.93888 11.2048 8.16313 10.9154 8.43802 10.5248C8.48142 10.4452 8.53929 10.4308 8.61163
           10.4814L9.34949 10.9046C9.47377 10.9771 9.35374 11.1389 9.27289 11.258C9.01193 11.6421 8.75366 11.9778 8.49807
           12.2653C8.49421 12.2696 8.49096 12.2744 8.48836 12.2796C8.4838 12.2887 8.48101 12.2989 8.48279 12.3089C8.48703
           12.3327 8.50104 12.3565 8.52482 12.3803C8.78525 12.6841 9.03843 12.9735 9.28438 13.2484C9.33502 13.3135 9.37843
           13.3677 9.4146 13.4111C9.42936 13.4259 9.44329 13.4398 9.45638 13.4529ZM5.80126 7.15019C5.54807 7.15019 5.3419
           7.21891 5.18276 7.35636C5.03085 7.4938 4.95489 7.67465 4.95489 7.8989C4.95489 8.02188 4.99829 8.15932 5.0851
           8.31123C5.17191 8.46315 5.35637 8.7091 5.63849 9.04909C5.6602 9.07803 5.68551 9.08165 5.71445 9.05994C6.02551
           8.86463 6.26061 8.67655 6.41976 8.4957C6.58614 8.30762 6.66933 8.10507 6.66933 7.88805C6.66933 7.6638 6.58975
           7.48657 6.43061 7.35636C6.27146 7.21891 6.06168 7.15019 5.80126 7.15019ZM5.42148 12.7058C5.85644 12.7058 6.31522
           12.5528 6.79781 12.2466C6.82793 12.2275 6.87159 12.2046 6.86387 12.1698C6.85859 12.146 6.83419 12.133 6.81735
           12.1154C6.78971 12.0865 6.76207 12.0554 6.73443 12.0222C6.69826 11.9788 6.66209 11.9354 6.62592 11.892C6.53188
           11.7835 6.37635 11.6063 6.15934 11.3603L5.90977 11.0782C5.79402 10.9552 5.65296 10.7961 5.48658 10.6008C5.45564
           10.5544 5.38687 10.605 5.34044 10.6359C5.04901 10.8298 4.82284 11.0207 4.66192 11.2084C4.4883 11.411 4.4015
           11.6388 4.4015 11.892C4.4015 12.138 4.49192 12.3369 4.67277 12.4888C4.86085 12.6335 5.11042 12.7058 5.42148 12.7058Z"
        fill="currentColor"
      />
      <path
        d="M17.0218 13.4529C17.0662 13.4974 17.1211 13.5436 17.1211 13.6065C17.1211 13.6571 17.0849 13.6824 17.0126
           13.6824H15.8949C15.8298 13.6824 15.7756 13.6571 15.7322 13.6065L15.2439 13.0639C15.2222 13.0422 15.1969 13.0422
           15.1679 13.0639C14.5024 13.5341 13.7718 13.7692 12.9761 13.7692C12.3105 13.7692 11.7608 13.6137 11.3267
           13.3026C10.8999 12.9843 10.6865 12.5214 10.6865 11.9137C10.6865 11.4218 10.8204 11.0059 11.088 10.6659C11.3629
           10.3259 11.7318 10.0112 12.1948 9.72185C12.2503 9.68484 12.1838 9.61965 12.1416 9.56799C11.7939 9.14257 11.5621
           8.81046 11.4461 8.57165C11.3231 8.31847 11.2616 8.05443 11.2616 7.77954C11.2616 7.44678 11.3484 7.14657 11.522
           6.87892C11.6957 6.60403 11.938 6.39063 12.2491 6.23872C12.5673 6.07957 12.929 6 13.3341 6C13.7537 6 14.1263
           6.07957 14.4518 6.23872C14.7773 6.39063 15.0305 6.60765 15.2113 6.88977C15.3994 7.17189 15.4935 7.49742 15.4935
           7.86635C15.4935 8.30762 15.3633 8.69463 15.1028 9.02739C14.8424 9.36015 14.4916 9.66398 14.0503 9.93886C14.0141
           9.96057 14.0105 9.98588 14.0394 10.0148C14.3722 10.4055 14.7773 10.8648 15.2547 11.3929C15.2764 11.4218 15.3018
           11.4218 15.3307 11.3929C15.5043 11.2048 15.7286 10.9154 16.0035 10.5248C16.0469 10.4452 16.1047 10.4308 16.1771
           10.4814L16.9149 10.9046C17.0392 10.9771 16.9192 11.1389 16.8383 11.258C16.5774 11.6421 16.3191 11.9778 16.0635
           12.2653C16.0596 12.2696 16.0564 12.2744 16.0538 12.2796C16.0492 12.2887 16.0464 12.2989 16.0482 12.3089C16.0525
           12.3327 16.0665 12.3565 16.0903 12.3803C16.3507 12.6841 16.6039 12.9735 16.8498 13.2484C16.9005 13.3135 16.9439
           13.3677 16.98 13.4111C16.9948 13.4259 17.0087 13.4398 17.0218 13.4529ZM13.3667 7.15019C13.1135 7.15019 12.9073
           7.21891 12.7482 7.35636C12.5963 7.4938 12.5203 7.67465 12.5203 7.8989C12.5203 8.02188 12.5637 8.15932 12.6505
           8.31123C12.7373 8.46315 12.9218 8.7091 13.2039 9.04909C13.2256 9.07803 13.251 9.08165 13.2799 9.05994C13.5909
           8.86463 13.826 8.67655 13.9852 8.4957C14.1516 8.30762 14.2348 8.10507 14.2348 7.88805C14.2348 7.6638 14.1552
           7.48657 13.996 7.35636C13.8369 7.21891 13.6271 7.15019 13.3667 7.15019ZM12.9869 12.7058C13.4219 12.7058 13.8807
           12.5528 14.3632 12.2466C14.3934 12.2275 14.437 12.2046 14.4293 12.1698C14.424 12.146 14.3996 12.133 14.3828
           12.1154C14.3551 12.0865 14.3275 12.0554 14.2999 12.0222C14.2637 11.9788 14.2275 11.9354 14.1914 11.892C14.0973
           11.7835 13.9418 11.6063 13.7248 11.3603L13.4752 11.0782C13.3595 10.9552 13.2184 10.7961 13.052 10.6008C13.0211
           10.5544 12.9523 10.605 12.9059 10.6359C12.6144 10.8298 12.3883 11.0207 12.2274 11.2084C12.0537 11.411 11.9669
           11.6388 11.9669 11.892C11.9669 12.138 12.0574 12.3369 12.2382 12.4888C12.4263 12.6335 12.6759 12.7058 12.9869 12.7058Z"
        fill="currentColor"
      />
    </svg>
  );
};

export const OrIcon = (props) => {
  const { size } = props;
  return (
    <svg
      height={size}
      width={size}
      viewBox="0 0 20 20"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8 15C7.44772 15 7 14.5523 7 14L7 6C7 5.44772 7.44772 5 8 5C8.55228 5 9 5.44772 9 6L9 14C9 14.5523 8.55229 15 8 15Z"
        fill="currentColor"
      />
      <path
        d="M12 15C11.4477 15 11 14.5523 11 14L11 6C11 5.44772 11.4477 5 12 5C12.5523 5 13 5.44772 13 6L13 14C13 14.5523 12.5523 15 12 15Z"
        fill="currentColor"
      />
    </svg>
  );
};

export const EqualIcon = (props) => {
  const { size } = props;
  return (
    <svg
      height={size}
      width={size}
      viewBox="0 0 20 20"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9 11.25C9 11.6642 8.66421 12 8.25 12L2.75 12C2.33579 12 2 11.6642 2 11.25C2 10.8358 2.33579 10.5 2.75 10.5L8.25 10.5C8.66421 10.5 9 10.8358 9 11.25Z"
        fill="currentColor"
      />
      <path
        d="M18 11.25C18 11.6642 17.6642 12 17.25 12L11.75 12C11.3358 12 11 11.6642 11 11.25C11 10.8358 11.3358 10.5 11.75 10.5L17.25 10.5C17.6642 10.5 18 10.8358 18 11.25Z"
        fill="currentColor"
      />
      <path
        d="M9 8.75C9 9.16421 8.66421 9.5 8.25 9.5L2.75 9.5C2.33579 9.5 2 9.16421 2 8.75C2 8.33579 2.33579 8 2.75 8L8.25 8C8.66421 8 9 8.33579 9 8.75Z"
        fill="currentColor"
      />
      <path
        d="M18 8.75C18 9.16421 17.6642 9.5 17.25 9.5L11.75 9.5C11.3358 9.5 11 9.16421 11 8.75C11 8.33579 11.3358 8 11.75 8L17.25 8C17.6642 8 18 8.33579 18 8.75Z"
        fill="currentColor"
      />
    </svg>
  );
};

export const NotEqualIcon = (props) => {
  const { size } = props;
  return (
    <svg
      height={size}
      width={size}
      viewBox="0 0 20 20"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16 11.25C16 11.6642 15.6642 12 15.25 12L8.75 12C8.33579 12 8 11.6642 8 11.25C8 10.8358 8.33579 10.5 8.75 10.5L15.25 10.5C15.6642 10.5 16 10.8358 16 11.25Z"
        fill="currentColor"
      />
      <path
        d="M16 8.75C16 9.16421 15.6642 9.5 15.25 9.5L8.75 9.5C8.33579 9.5 8 9.16421 8 8.75C8 8.33579 8.33579 8 8.75 8L15.25 8C15.6642 8 16 8.33579 16 8.75Z"
        fill="currentColor"
      />
      <path
        d="M5.25 5C5.66421 5 6 5.33579 6 5.75L6 11.25C6 11.6642 5.66421 12 5.25 12C4.83579 12 4.5 11.6642 4.5 11.25L4.5 5.75C4.5 5.33579 4.83579 5 5.25 5Z"
        fill="currentColor"
      />
      <path
        d="M5.25 13C5.66421 13 6 13.3358 6 13.75C6 14.1642 5.66421 14.5 5.25 14.5C4.83579 14.5 4.5 14.1642 4.5 13.75C4.5 13.3358 4.83579 13 5.25 13Z"
        fill="currentColor"
      />
    </svg>
  );
};

export const GreaterIcon = (props) => {
  const { size } = props;
  return (
    <svg
      height={size}
      width={size}
      viewBox="0 0 20 20"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.35177 14.2207C6.88274 13.8482 6.88274 13.2444 7.35177 12.8719L11.598 9.5L7.35177 6.1281C6.88274 5.75565
           6.88274 5.15179 7.35177 4.77934C7.82079 4.40689 8.58123 4.40689 9.05025 4.77934L13.2965 8.15124C14.2345 8.89614
           14.2345 10.1039 13.2965 10.8488L9.05025 14.2207C8.58123 14.5931 7.82079 14.5931 7.35177 14.2207Z"
        fill="currentColor"
      />
    </svg>
  );
};

export const GreaterOrEqualIcon = (props) => {
  const { size } = props;
  return (
    <svg
      height={size}
      width={size}
      viewBox="0 0 20 20"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17 11.25C17 11.6642 16.6642 12 16.25 12L11.75 12C11.3358 12 11 11.6642 11 11.25C11 10.8358 11.3358 10.5 11.75 10.5L16.25 10.5C16.6642 10.5 17 10.8358 17 11.25Z"
        fill="currentColor"
      />
      <path
        d="M17 8.75C17 9.16422 16.6642 9.5 16.25 9.5L11.75 9.5C11.3358 9.5 11 9.16422 11 8.75C11 8.33579 11.3358 8 11.75 8L16.25 8C16.6642 8 17 8.33579 17 8.75Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.5 13.9999C3.09798 13.702 2.8995 12.9955 3.30152 12.6976L6.94113 10.0001L3.30152 7.30254C2.8995 7.00458
           3.09798 6.29794 3.5 5.99998C3.90202 5.70202 4.35534 5.92557 4.75736 6.22353L8.39697 8.92105C9.20101 9.51697
           9.20101 10.4831 8.39697 11.0791L4.75736 13.7766C4.35534 14.0745 3.90202 14.2979 3.5 13.9999Z"
        fill="currentColor"
      />
    </svg>
  );
};

export const LessIcon = (props) => {
  const { size } = props;
  return (
    <svg
      height={size}
      width={size}
      viewBox="0 0 20 20"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.6482 4.77934C13.1173 5.15179 13.1173 5.75565 12.6482 6.1281L8.40202 9.5L12.6482 12.8719C13.1173 13.2444
           13.1173 13.8482 12.6482 14.2207C12.1792 14.5931 11.4188 14.5931 10.9497 14.2207L6.70354 10.8488C5.76549 10.1039
           5.76549 8.89614 6.70354 8.15124L10.9497 4.77934C11.4188 4.40689 12.1792 4.40689 12.6482 4.77934Z"
        fill="currentColor"
      />
    </svg>
  );
};

export const LessOrEqualIcon = (props) => {
  const { size } = props;
  return (
    <svg
      height={size}
      width={size}
      viewBox="0 0 20 20"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17 11.25C17 11.6642 16.6642 12 16.25 12L11.75 12C11.3358 12 11 11.6642 11 11.25C11 10.8358 11.3358 10.5 11.75 10.5L16.25 10.5C16.6642 10.5 17 10.8358 17 11.25Z"
        fill="currentColor"
      />
      <path
        d="M17 8.75C17 9.16421 16.6642 9.5 16.25 9.5L11.75 9.5C11.3358 9.5 11 9.16421 11 8.75C11 8.33579 11.3358 8 11.75 8L16.25 8C16.6642 8 17 8.33579 17 8.75Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.58156 6.00011C8.98358 6.29807 9.18206 7.0045 8.78004 7.30246L5.14043 9.99998L8.78004 12.6975C9.18206 12.9955
           8.98358 13.7021 8.58156 14.0001C8.17954 14.298 7.72622 14.0745 7.3242 13.7765L3.68459 11.079C2.88055 10.4831
           2.88055 9.51689 3.68459 8.92097L7.3242 6.22345C7.72622 5.92549 8.17954 5.70215 8.58156 6.00011Z"
        fill="currentColor"
      />
    </svg>
  );
};

export const InIcon = (props) => {
  const { size } = props;
  return (
    <svg
      height={size}
      width={size}
      viewBox="0 0 20 20"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.01013 6.96404C5.7161 6.96404 5.47424 6.87183 5.28454 6.68741C5.09485 6.503 5 6.26787 5 5.98202C5 5.69617
           5.09485 5.46104 5.28454 5.27663C5.47424 5.09221 5.7161 5 6.01013 5C6.30415 5 6.54601 5.09221 6.73571 5.27663C6.9254
           5.46104 7.02025 5.69617 7.02025 5.98202C7.02025 6.26787 6.9254 6.503 6.73571 6.68741C6.54601 6.87183 6.30415 6.96404
           6.01013 6.96404ZM5.32722 15C5.32627 15 5.32532 15 5.32438 15C5.23543 14.9989 5.1565 14.923 5.1565 14.834V14.834V8.08437C5.1565
           8.03827 5.17073 8.00138 5.19918 7.97372C5.23712 7.93684 5.2798 7.9184 5.32722 7.9184H6.63612C6.63675 7.9184 6.63738
           7.9184 6.638 7.91841C6.72396 7.9193 6.80684 7.99841 6.80684 8.08437V8.08437V14.834C6.80684 14.8801 6.78788 14.9216
           6.74994 14.9585C6.72148 14.9862 6.68354 15 6.63612 15H5.32722Z"
        fill="currentColor"
      />
      <path
        d="M12.4676 7.82158C13.2358 7.82158 13.8476 8.04288 14.3029 8.48548C14.7676 8.92808 15 9.53204 15 10.2974V14.834C15
           14.8801 14.981 14.9216 14.9431 14.9585C14.9146 14.9862 14.8767 15 14.8293 15H13.5204C13.5194 15 13.5185 15 13.5175
           15C13.4286 14.9989 13.3497 14.923 13.3497 14.834V14.834V10.6293C13.3497 10.2052 13.2264 9.86399 12.9797
           9.60581C12.7331 9.3384 12.4059 9.2047 11.9981 9.2047C11.5902 9.2047 11.2583 9.33379 11.0022 9.59198C10.7556 9.85016
           10.6323 10.1913 10.6323 10.6155V14.834C10.6323 14.8801 10.6133 14.9216 10.5754 14.9585C10.5469 14.9862 10.509 15
           10.4615 15H9.15265C9.1517 15 9.15075 15 9.14981 15C9.06086 14.9989 8.98193 14.923 8.98193 14.834V14.834V8.0982C8.98193
           8.0521 8.99615 8.01521 9.02461 7.98755C9.06255 7.95067 9.10523 7.93223 9.15265 7.93223H10.4615C10.4622 7.93223 10.4628
           7.93223 10.4634 7.93224C10.5494 7.93313 10.6323 8.01224 10.6323 8.0982V8.0982V8.5408C10.6323 8.56847 10.6418 8.58691
           10.6607 8.59613V8.59613C10.6962 8.61337 10.7156 8.55239 10.7408 8.52207C11.1296 8.05508 11.7052 7.82158 12.4676 7.82158Z"
        fill="currentColor"
      />
    </svg>
  );
};

export const EditIcon = (props) => {
  const { size, ...rest } = props;
  return (
    <svg
      height={size}
      width={size}
      viewBox="5 5 10 10"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M9.94828 7.13425L10.931 8.09811L6.37931 12.642V13.6231H7.34483L11.9138 9.06196L12.8966 10.0258L8.32759
           14.5869C8.20115 14.7131 8.05471 14.8137 7.88828 14.8885C7.72138 14.9628 7.54598 15 7.36207 15H5.68966C5.49425
           15 5.33057 14.9341 5.19862 14.8024C5.06621 14.6702 5 14.5066 5 14.3115V12.642C5 12.4584 5.03448 12.2833 5.10345
           12.1167C5.17241 11.9505 5.27011 11.8044 5.39655 11.6781L9.94828 7.13425ZM12.8966 10.0258L9.94828 7.13425L11.6724
           5.41308C11.9483 5.13769 12.2789 5 12.6641 5C13.049 5 13.3736 5.13769 13.6379 5.41308L14.6034 6.39415C14.8678
           6.65806 15 6.97935 15 7.358C15 7.73666 14.8678 8.05795 14.6034 8.32186L12.8966 10.0258Z"
        fill="currentColor"
      />
    </svg>
  );
};
