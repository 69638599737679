import React from "react";
import styles from "./OrchestrationFlow.module.scss";
import { useSelector } from "react-redux";
import { ConnectorLine } from "./ConnectorLine";
import { getTriggers } from "../../state/triggers/selectors";
import TriggerContent from "./TriggerContent";
import { Collapse } from "@material-ui/core";
import { TriggerIcon } from "../../icons";

export function Trigger({
  setTriggerActive,
  trigger,
  triggerActive,
  triggerOutput,
}) {
  const triggers = useSelector(getTriggers);

  const subTitle = {
    timer: `Every ${trigger?.interval} ${trigger?.units}${
      trigger?.interval > 1 ? "s" : ""
    }`,
    manual: "User-triggered",
    event: trigger.event,
    reading: "Reading created",
    interactive: "IO-triggered",
  }[trigger.kind];

  const title = trigger?.connection?.name || triggers[trigger.kind].name;
  const handleOnClick = () => {
    setTriggerActive(!triggerActive);
  };

  return (
    <div className={styles.step} key={"trigger"}>
      <div className={styles.inner}>
        <ConnectorLine
          stepStatus={{ status: "completed", stepNumber: "trigger" }}
          length={1}
        />
        <div className={styles.wrapper}>
          <TriggerDetails
            triggerType={trigger.kind}
            title={title}
            subTitle={subTitle}
            onClick={handleOnClick}
          />
          <Collapse orientation="horizontal" in={triggerActive}>
            <div className={styles.stepContentContainer}>
              <TriggerContent triggerOutput={triggerOutput} />
            </div>
          </Collapse>
        </div>
      </div>
    </div>
  );
}

function TriggerDetails({ triggerType, title, subTitle, onClick }) {
  return (
    <div className={styles.stepDetails} onClick={onClick}>
      <div className={styles.description}>
        <div className={styles.title} data-testid="dtid_orchestration_flow_title">
          <TriggerIcon trigger={triggerType} className={styles.stepIcon} />
          {title}
        </div>
        <div className={styles.subtitle}>{subTitle}</div>
      </div>
    </div>
  );
}
