import { createPromiseAction } from "@adobe/redux-saga-promise";
import { createSlice } from "@reduxjs/toolkit";
import { dbRetrieveRecord, initialState, reducer } from "../reducers/dbAdapter";
import { isBefore, parseISO } from "date-fns";
import { ORCHESTRATION_INTERACTION_COMPLETED } from "./constants";

export const NAME = "executions";

export const SAVE_EXECUTION_FILTER = "SAVE_EXECUTION_FILTER";
export const REMOVE_EXECUTION_FILTER = "REMOVE_EXECUTION_FILTER";
export const CLEAR_EXECUTION_FILTERS = "CLEAR_EXECUTION_FILTERS";
export const SET_EXECUTION_FILTER = "SET_EXECUTION_FILTER";

export const updateExecution = (executionId) => {
  return {
    type: dbRetrieveRecord.type,
    payload: {
      tableName: NAME,
      id: executionId,
    },
  };
};

export const userInteractionCompleted = createPromiseAction(
  ORCHESTRATION_INTERACTION_COMPLETED,
  (stepNumber, executionId, result) => ({
    stepNumber,
    executionId,
    result,
  })
);

const slice = createSlice({
  name: NAME,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    reducer(NAME, {
      entityAdapter: {
        sortComparer: (a, b) => {
          const bDate = parseISO(b.triggeredAt);
          const aDate = parseISO(a.triggeredAt);
          if (isBefore(bDate, aDate)) {
            return -1;
          }
          if (isBefore(aDate, bDate)) {
            return 1;
          }
          return 0;
        },
      },
    })(builder);
  },
});

export default slice.reducer;
