import React, { useCallback, useEffect, useState } from "react";
import styles from "./OrchestrationFlow.module.scss";
import useExecution from "../../state/executions/useExecution";
import Paper from "@material-ui/core/Paper";
import { Step } from "./Step";
import { Trigger } from "./Trigger";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDispatch } from "react-redux";
import { userInteractionCompleted } from "../../state/executions/reducer";
import isEqual from "lodash/isEqual";
import ConfirmDialog from "../ConfirmDialog";
import StepContent from "./StepContent";
import StatusIcon from "./StatusIcon";
import { StopIcon } from "../../icons";
import { formatDistance } from "date-fns";
import { timeSince } from "../../utils";
import DLXTooltip from "../../components/DLXTooltip/DLXTooltip";
import { IconButton } from "@material-ui/core";
import clsx from "clsx";
import { UnfoldLess, UnfoldMore } from "@material-ui/icons";
import Collapse from "@material-ui/core/Collapse";
import { useCurrentUserContext } from "../../contexts/current-user/Provider";
import { DataSelect } from "../SourceDataSelector";
import { LabelsListView } from "@dlx/components/Labels";

const LoadingSpinner = () => <FontAwesomeIcon spin icon="spinner" />;

export default React.memo(
  function ExecutionFlowContainer({
    id,
    abortExecution,
    onlyCurrentStep,
    showDetails,
    execution,
  }) {
    const data = useExecution({
      id,
      poll: true,
      orchestration: execution?.orchestration,
    });
    return (
      <ExecutionFlow
        {...data}
        abortExecution={abortExecution}
        onlyCurrentStep={onlyCurrentStep}
        showDetails={showDetails}
      />
    );
  },
  (prev, next) => {
    if (window.location.pathname.match("/orchestrations/.+/edit")) {
      return true;
    }
    return isEqual(prev, next);
  }
);

/**
 * @param {{
 *  execution: Execution,
 *  getStepInputs: Function,
 *  getStepOutput: Function,
 *  getTriggerOutput: Function,
 *  loading: Boolean,
 *  orchestration: Orchestration,
 *  outputForStep: Function,
 *  abortExecution: Function,
 *  statusOfStep: Object,
 *  triggerOutput: Object,
 *  onlyCurrentStep: Boolean,
 *  showDetails: Boolean,
 *  poll: Function,
 * }} props
 */
export function ExecutionFlow({
  execution,
  getStepInputs,
  getStepOutput,
  getTriggerOutput,
  loading,
  orchestration,
  outputForStep,
  abortExecution,
  statusOfStep,
  triggerOutput,
  onlyCurrentStep,
  showDetails,
}) {
  const [activeStep, setActiveStep] = useState(null);
  const [triggerActive, setTriggerActive] = useState(null);
  const [executionToBeAborted, setExecutionToBeAborted] = useState(null);
  const [showFullExecution, setShowFullExecution] = useState(!onlyCurrentStep);

  const dispatch = useDispatch();
  const { currentUser } = useCurrentUserContext();
  const submitUserInput = useCallback(
    async (stepNum, data) => {
      await dispatch(userInteractionCompleted(stepNum, execution.id, data));
    },
    [dispatch, execution]
  );

  const requiresUserInput =
    execution?.waitingFor === "input" &&
    execution?.waitingForUsers &&
    execution.waitingForUsers.some((u) => u.id === currentUser.id);

  const stepRetryRequired = execution?.waitingFor === "intervention";

  const handleCancelAbort = useCallback(() => setExecutionToBeAborted(null), []);

  const handleConfirmAbort = useCallback(() => {
    abortExecution(executionToBeAborted);
    setExecutionToBeAborted(null);
  }, [executionToBeAborted, abortExecution]);

  const abortRequested = async () => {
    setExecutionToBeAborted(execution);
  };

  useEffect(() => {
    if (execution?.statusOfSteps) {
      const runningStep = execution.statusOfSteps.find((s) =>
        ["executing", "waiting"].includes(s.status)
      );
      if (requiresUserInput || stepRetryRequired) {
        setActiveStep(runningStep?.stepNumber);
        setTriggerActive(false);
      }
    }
    return () => setActiveStep(null);
  }, [execution, setActiveStep, currentUser, requiresUserInput, stepRetryRequired]);

  useEffect(() => {
    (async () => {
      if (triggerActive) {
        if (!triggerOutput) {
          await getTriggerOutput();
        }
        setActiveStep();
      }
    })();
  }, [triggerActive, getTriggerOutput, triggerOutput]);

  const handleSetActiveStep = useCallback((s) => {
    setActiveStep(s);
    setTriggerActive(false);
  }, []);

  const orc = orchestration || execution?.orchestration;

  if (loading || !execution || !orc) {
    return (
      <Paper className={styles.root}>
        <div className={styles.loading}>
          <LoadingSpinner />
        </div>
      </Paper>
    );
  }
  const executingStep = execution.statusOfSteps.findIndex((s) =>
    ["executing", "waiting"].includes(s.status)
  );

  const stepProps = {
    orc,
    setTriggerActive,
    triggerOutput,
    statusOfStep,
    triggerActive,
    activeStep,
    getStepOutput,
    outputForStep,
    submitUserInput,
    getStepInputs,
    setActiveStep: handleSetActiveStep,
    requiresUserInput,
    stepRetryRequired,
    execution,
    abortRequested,
    setShowFullExecution,
    showFullExecution,
    executingStep,
    onlyCurrentStep,
    showDetails,
  };

  return (
    <Paper className={styles.root}>
      {executionToBeAborted && (
        <ConfirmDialog
          messageText={`Abort ${executionToBeAborted.orchestrationName} orchestration run?`}
          onClickNo={handleCancelAbort}
          onClickYes={handleConfirmAbort}
          submitButtonLabel="Abort"
          cancelButtonLabel="Cancel"
          open={true}
        />
      )}
      <div className={styles.steps}>
        {(onlyCurrentStep || showDetails) && (
          <>
            <OrchestrationDetails {...stepProps} />
            <StepRunStatus {...stepProps} />
          </>
        )}
        <Collapse in={showFullExecution}>
          <AllSteps {...stepProps} />
        </Collapse>
        {!showFullExecution && <CurrentStep {...stepProps} />}
      </div>
    </Paper>
  );
}

const CurrentStep = (props) => {
  const {
    statusOfStep,
    orc,
    setShowFullExecution,
    requiresUserInput,
    stepRetryRequired,
    executingStep,
    outputForStep,
    execution,
  } = props;

  return (
    <div className={styles.steps}>
      {(requiresUserInput || stepRetryRequired) && (
        <StepContent
          {...props}
          key={`step-${executingStep}`}
          active={true}
          step={orc.steps[executingStep]}
          stepNum={executingStep}
          stepStatus={statusOfStep(
            executingStep,
            execution?.statusOfSteps[executingStep]?.stepId
          )}
          setShowFullExecution={setShowFullExecution}
          stepOutput={outputForStep(
            executingStep,
            execution?.statusOfSteps[executingStep]?.stepId
          )}
        />
      )}
    </div>
  );
};

const AllSteps = (props) => {
  const { activeStep, statusOfStep, orc, execution } = props;

  return (
    <div className={styles.steps}>
      <Trigger {...props} trigger={orc?.trigger} />
      {orc.steps.map((step, index) => {
        return (
          <Step
            {...props}
            key={`step-${index}`}
            step={step}
            stepNum={index}
            numSteps={orc.steps.length}
            stepStatus={statusOfStep(index, execution?.statusOfSteps[index]?.stepId)}
            activeStep={activeStep}
            execution={execution}
          />
        );
      })}
    </div>
  );
};

const ExecutionDetailsButton = ({ showFullExecution, setShowFullExecution }) => {
  return (
    <DLXTooltip
      text={showFullExecution ? "Hide execution details" : "Show execution details"}
    >
      <IconButton
        className={styles.detailsCollapse}
        size="small"
        variant="contained"
        onClick={() => setShowFullExecution(!showFullExecution)}
      >
        {showFullExecution ? (
          <UnfoldLess className={styles.showDetailsIcon} />
        ) : (
          <UnfoldMore className={styles.showDetailsIcon} />
        )}
      </IconButton>
    </DLXTooltip>
  );
};

const OrchestrationDetails = ({ execution, abortRequested }) => {
  const isExecuting = execution.status === "executing";
  const executionDuration = formatDistance(
    Date.now() + execution.duration,
    Date.now(),
    {
      includeSeconds: true,
    }
  );

  const started = `Started ${
    execution?.triggeredBy?.name ? `by ${execution?.triggeredBy?.name} ` : ""
  }${timeSince(execution.triggeredAt)} ago`;

  let triggerDetails = "";

  switch (execution.status) {
    case "executing":
      triggerDetails = `${started}`;
      break;
    case "completed":
      triggerDetails = `${started}, completed in ${executionDuration}`;
      break;
    case "aborted":
      triggerDetails = `${started}, aborted`;
      break;
    case "failed":
      triggerDetails = `${started}, failed`;
      break;
    default:
      break;
  }

  return (
    <div className={styles.orchestrationDetails}>
      <div className={styles.iconHolder}>
        <div className={styles.completeIcon}>
          <StatusIcon
            statusOfStep={{ stepNumber: "trigger", status: execution.status }}
          />
        </div>
      </div>
      <div className={styles.orchestrationNameHolder}>
        <div className="flex items-center mb2">
          <div className="font-large b">{execution?.orchestration.name}</div>
          {execution?.id && (
            <DataSelect executionId={execution.id} className="ml3 pointer" />
          )}
        </div>
        <div className={execution?.labels ? "mb2" : ""}>
          <LabelsListView list={execution?.labels} />
        </div>
        <div className={styles.timeDetails}>{triggerDetails}</div>
      </div>
      <div className={styles.actionHolder}>
        {isExecuting && (
          <DLXTooltip text={"Abort orchestration"}>
            <IconButton className={styles.abortButton} onClick={abortRequested}>
              <StopIcon />
            </IconButton>
          </DLXTooltip>
        )}
      </div>
    </div>
  );
};

const StepRunStatus = (props) => {
  const {
    execution,
    executingStep,
    showFullExecution,
    setShowFullExecution,
    requiresUserInput,
  } = props;

  return (
    <div className={clsx(requiresUserInput && styles.noDropPointer)}>
      <div
        className={clsx(
          styles.stepMetaData,
          requiresUserInput && styles.disableToggle
        )}
      >
        <ExecutionDetailsButton {...props} />
        <div onClick={() => setShowFullExecution(!showFullExecution)}>
          {execution.status === "executing" && executingStep !== -1 && (
            <div className={clsx(styles.subtitle, styles.executionSubtitle)}>
              {`Step ${executingStep + 1} of ${
                execution.orchestration?.steps?.length
              }`}
            </div>
          )}
          {execution.status !== "executing" && (
            <div className={styles.executionSubtitle}>
              Orchestration execution {execution.status}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
