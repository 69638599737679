import { createSelector } from "@reduxjs/toolkit";

export const isInQueue = createSelector(
  (state) => state.leases.status,
  (status) => status === "queued"
);

export const selectLeasedConnection = createSelector(
  (state) => state.leases.connectionId,
  (state) => state.connections.entities,
  (id, entities) => entities[id]
);

/**
 * User in possession of lease.
 * @typedef {object} ControllingUser
 * @prop {string} id
 * @prop {string} name
 * @prop {string} email
 */

/**
 * @returns {ControllingUser}
 */
export const selectControllingUser = (state) =>
  state.leases[state.leases.connectionId]?.controllingUser;

/**
 * Queued Lease
 * @typedef {object} QueuedLease
 * @prop {string} name
 * @prop {string} email
 * @prop {string} startTime
 */

/**
 * @returns {QueuedLease[]}
 */
export const selectQueuedLeases = (state) =>
  state.leases[state.leases.connectionId]?.queuedLeases || {};
