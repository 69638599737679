import { USER_MISMATCH } from "@dlx/state/constants";
import decode from "jwt-decode";

export const getStoredIamToken = () => {
  return JSON.parse(localStorage.getItem("iamToken") || "false");
};

export const storeIamToken = (token) => {
  return localStorage.setItem("iamToken", JSON.stringify(token));
};

export const storeCurrentUser = (token) => {
  try {
    const user = decode(token.id_token);
    user.id = user.sub;
    localStorage.setItem("currentUser", JSON.stringify(user));
  } catch (error) {
    console.log(error);
    throw error;
  }
};
export const getStoredCurrentUser = () => {
  const token = getStoredIamToken();
  if (!token) {
    return null;
  }
  try {
    return decode(token.id_token);
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const isTokenExpired = (token) => {
  try {
    const expiresAt = decode(token).exp;
    if (!expiresAt) {
      throw new Error("NoExp");
    }
    return Date.now() >= expiresAt * 1000;
  } catch (error) {
    console.log(error);
  }
  return true;
};

export const getStoredAccessToken = () => {
  try {
    const token = getStoredIamToken();
    return token.access_token;
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const isCurrentUser = (token) => {
  try {
    const currentUser = getStoredCurrentUser();
    return currentUser.sub === decode(token.id_token).sub;
  } catch (error) {
    console.log(error);
    return false;
  }
};

export const decodeUser = (token) => {
  const user = decode(token.id_token);
  user.id = user.sub;
  return user;
};

export const validateCurrentUser = (token, nonFatal) => {
  const currentUser = getStoredCurrentUser();
  const user = decodeUser(token);
  if (currentUser && user.sub !== currentUser.sub) {
    localStorage.clear();
    if (!nonFatal) {
      throw new Error(USER_MISMATCH);
    }
  }
  return true;
};

export const getAccessToken = () => {
  return getStoredAccessToken();
};
