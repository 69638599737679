import styles from "./UserReadingsDialog.module.scss";
import React, { useState } from "react";
import DLXModal from "../../../DLXModal/DLXModal";
import UserReadingItem from "./UserReadingItem";

function UserReadingsDialog({ readingTypes, submitReading, onCancel }) {
  const [userReadings, setUserReadings] = useState({});

  const readingsValid = () =>
    readingTypes.every((readingType) => {
      const reading = userReadings[readingType.type];
      if (reading?.required && !reading) {
        return false;
      }
      return (
        reading &&
        reading.value <= readingType.max &&
        reading.value >= readingType.min
      );
    });

  const formatReadings = () => {
    readingTypes.forEach((readingType) => {
      const reading = userReadings[readingType.type];
      if (reading) {
        reading.value = readingType.decimals
          ? Number(reading.value).toFixed(readingType.decimals)
          : reading.value;
      }
    });
    submitReading(userReadings);
    onCancel();
  };

  return (
    <DLXModal
      open={true}
      title="User entered readings"
      size="micro"
      onSubmit={formatReadings}
      onCancel={onCancel}
      submitButtonLabel="Create Reading"
      submitDisabled={!readingsValid()}
    >
      <div className={styles.userReadingsContainer}>
        <div className={styles.userReadings}>
          {readingTypes.map((readingType) => (
            <UserReadingItem
              readingType={readingType}
              key={readingType.type}
              reading={userReadings[readingType.type]}
              updateReading={(reading) =>
                setUserReadings({ ...userReadings, [readingType.type]: reading })
              }
            />
          ))}
        </div>
      </div>
    </DLXModal>
  );
}

export default UserReadingsDialog;
