import React, { useState } from "react";
import clsx from "clsx";
import { ClearIcon } from "../../../icons";

import ConfirmInline from "@dlx/atoms/ConfirmInline";
import Button, { BUTTON_VARIANTS } from "@dlx/atoms/Button";

import { AstElement, ExecuteElement } from ".";
import { AddElementOptions } from "../Selectors";
import { IconButton } from "../Wrappers";
import { schemaToAst } from "../util";

function AstRow({
  schema = {},
  element,
  setElement,
  onClear = null,
  isBlank = false,
  className = "",
  dataTestId = "",
}) {
  const { isCleaning, setCleaning } = useAstEditorHelpers();
  const handleSetElement = (newElement) => {
    const { error, validationErrors, ...rest } = newElement;
    setElement(rest);
  };

  return (
    <section
      className={clsx("flex items-center bg-secondary-light rounded3", className)}
      data-testid={dataTestId}
    >
      {isCleaning ? (
        <ConfirmInline
          onClose={() => setCleaning(false)}
          className={"pa3 flex items-center flex-auto"}
        >
          <span>Are you sure? It will clear the entire expression.</span>
          <Button
            variant={BUTTON_VARIANTS.BORDER}
            className="pa2 ml4"
            onClick={() => (onClear ? onClear() : setElement(schemaToAst(schema)))}
            dataTestId="dtid_clear_expression_yes"
          >
            Yes
          </Button>
          <Button
            variant={BUTTON_VARIANTS.PRIMARY}
            className="pa2 ml2"
            dataTestId="dtid_clear_expression_no"
          >
            No
          </Button>
        </ConfirmInline>
      ) : (
        <div className="ph3 pb3 pt1 pr1 flex flex-wrap flex-auto items-center">
          <AstElement
            schema={schema}
            element={element}
            setElement={handleSetElement}
            isBlank={isBlank}
          />
          <AddElementOptions
            element={element}
            setElement={setElement}
            className="mt2"
          />
        </div>
      )}
      <div className="flex items-center pr2">
        <ExecuteElement element={element} tooltip="Test expression" />
        <IconButton
          onClick={() => setCleaning(true)}
          className="bg-default bg-hover-primary-dark text-primary text-hover-contrast ml2"
          size={32}
          dataTestId="dtid_clear_expression"
          tooltip="Clear expression"
        >
          <ClearIcon size="22" />
        </IconButton>
      </div>
    </section>
  );
}

function useAstEditorHelpers() {
  const [isCleaning, setCleaning] = useState(false);

  return {
    isCleaning,
    setCleaning,
  };
}

export default AstRow;
