import React, { useEffect } from "react";
import styles from "./OrchestrationFlow.module.scss";
import { useSelector } from "react-redux";
import { ConnectorLine } from "./ConnectorLine";
import StepContent from "./StepContent";
import clsx from "clsx";
import { Collapse } from "@material-ui/core";
import { ActorIcon } from "../../icons";
import { STEP_STATUS } from "./constants";

const stepKind = {
  CONNECTION_COMMAND: "connection-command",
  EVENT_QUERY: "event-query",
  FUNCTION_TRANSFORM: "function-transform",
  LIBRARY_TRANSFORM: "library-transform",
  NOTIFICATION: "notification",
  TRIGGER_ORCHESTRATION: "trigger-orchestration",
  TIMER: "timer",
  USER_INPUT: "user-input",
  USER_READING: "user-reading",
};

/**
 * @param {{
 * activeStep: number,
 * getStepOutput: Function,
 * numSteps: number,
 * setActiveStep: Function,
 * step: Step,
 * stepNum: number,
 * outputForStep: Function,
 * stepStatus: StatusOfStep,
 * submitUserInput: Function,
 * getStepInputs: Function,
 * requiresUserInput: Boolean,
 * stepRetryRequired: Boolean,
 * execution: Execution,
 * abortExecution: Function,
 * }} props
 **/
export function Step({
  activeStep,
  getStepOutput,
  numSteps,
  setActiveStep,
  step,
  stepNum,
  outputForStep,
  stepStatus,
  submitUserInput,
  getStepInputs,
  requiresUserInput,
  stepRetryRequired,
  execution,
  abortExecution,
}) {
  const functionLibrary = useSelector((state) => state.transforms.entities);
  const connections = useSelector((state) => state.connections.entities);
  const actors = useSelector((state) => state.actors.entities);

  useEffect(() => {
    if (stepRetryRequired && stepStatus.status === STEP_STATUS.WAITING) {
      getStepOutput(stepNum, execution?.statusOfSteps[stepNum]?.stepId);
    }
  }, [stepRetryRequired, stepStatus.status, execution]);

  const getTitle = () =>
    connections[step.connection]?.name ||
    actors[step.kind]?.name ||
    "Unknown step type";

  const getStepSubtitle = () => {
    if (
      [
        STEP_STATUS.SKIPPED,
        STEP_STATUS.ABORTED,
        STEP_STATUS.FAILED,
        STEP_STATUS.EXECUTING,
        STEP_STATUS.FUTURE,
        STEP_STATUS.CONTINUED,
        STEP_STATUS.WAITING,
      ].includes(stepStatus.status)
    ) {
      return `Step ${stepStatus.status}`;
    }
    switch (step.kind) {
      case stepKind.CONNECTION_COMMAND:
        return `${step.command} action completed`;
      case stepKind.EVENT_QUERY:
        return "n/a";
      case stepKind.FUNCTION_TRANSFORM:
        return `${step.language} transform completed`;
      case stepKind.LIBRARY_TRANSFORM:
        return `${
          functionLibrary[step.libraryTransformId]?.name
        } transform completed`;
      case stepKind.NOTIFICATION:
        return "Notification sent";
      case stepKind.USER_INPUT:
        return "User input completed";
      case stepKind.USER_READING:
        return "User reading completed";
      case stepKind.TRIGGER_ORCHESTRATION:
        return "Orchestration triggered";
      default:
        return "";
    }
  };

  const title = getTitle();
  const subtitle = getStepSubtitle();

  const handleOnClick = (index, stepId) => () => {
    setActiveStep((s) => (s === index ? undefined : index));
    if (stepStatus.status !== STEP_STATUS.FUTURE) {
      getStepOutput(index, stepId);
    }
  };

  return (
    <div className={styles.step} key={stepNum}>
      <div className={styles.inner}>
        <ConnectorLine stepStatus={stepStatus} length={numSteps} />
        <div className={styles.wrapper}>
          <StepDetails
            actor={step.kind}
            title={title}
            subtitle={subtitle}
            stepStatus={stepStatus}
            onClick={handleOnClick(
              stepNum,
              execution?.statusOfSteps[stepNum]?.stepId
            )}
          />
          <Collapse orientation="horizontal" in={activeStep === stepNum}>
            <StepContent
              active={activeStep === stepNum}
              requiresUserInput={requiresUserInput}
              stepRetryRequired={stepRetryRequired}
              stepStatus={stepStatus}
              step={step}
              stepOutput={outputForStep(stepNum)}
              submitUserInput={submitUserInput}
              stepNum={stepNum}
              getStepInputs={getStepInputs}
              abortExecution={abortExecution}
              execution={execution}
            />
          </Collapse>
        </div>
      </div>
    </div>
  );
}

/**
 * @param {{
 *  icon: string,
 *  title: string,
 *  subtitle: string
 *  stepStatus: StatusOfStep,
 *  onClick: Function,
 * }} props
 */
function StepDetails({ actor, title, subtitle, stepStatus, onClick }) {
  return (
    <div
      className={clsx(styles.stepDetails, {
        [styles.futureStatus]: stepStatus?.status === STEP_STATUS.FUTURE,
      })}
      onClick={onClick}
    >
      <div className={styles.description}>
        <div className={styles.title} data-testid="dtid_orchestration_flow_title">
          <div className={styles.stepIcon}>
            <ActorIcon actor={actor} />
          </div>
          {title}
        </div>
        <div className={styles.subtitle}>{subtitle}</div>
      </div>
    </div>
  );
}
