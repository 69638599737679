import React, { useEffect, useState } from "react";
import clsx from "clsx";
import { DeleteIcon } from "../../../icons";

import Popover from "@dlx/atoms/Popover";
import Button, { BUTTON_VARIANTS } from "@dlx/atoms/Button";
import Tooltip from "@dlx/atoms/Tooltip";

import { AstRow, ExecuteElement } from "../Helpers";
import { SchemaTitle } from "../Wrappers";
import {
  getAstElementFromValue,
  getAstElementFromValueType,
  schemaToAst,
} from "../util";
import { TYPE_OBJECT, TYPE_REFERENCE } from "../constants";

function ObjectEditor({ schema, element, setElement }) {
  const {
    draft,
    setDraft,
    draftFields,
    elementFields,
    schemaProperties,
    schemaRequired,
    addField,
    editField,
    deleteField,
    updateFieldName,
    isApplyDisabled,
  } = useObjectEditHelpers({ schema, element });

  let popoverCloseHandler = null;
  return (
    <Popover
      onInit={({ close }) => {
        popoverCloseHandler = close;
      }}
      dataTestId="dtid_object_edit"
    >
      <div className="ml1 pv2 pointer">
        {elementFields.length === 0 ? (
          <span className="text-primary-light">Click to edit the object</span>
        ) : (
          <span>
            {` { `}
            {elementFields.join(", ")}
            {` } `}
          </span>
        )}
      </div>
      <div className="font-medium" style={{ minWidth: "500px" }}>
        <div className="flex items-center bb border-secondary ph3 pv3">
          <SchemaTitle title="Object Editor" subtitle={schema?.title} />
          <Button
            variant={BUTTON_VARIANTS.TEXT}
            onClick={() => {
              setDraft(element.value);
              popoverCloseHandler();
            }}
            dataTestId="dtid_cancel_changes"
          >
            Cancel
          </Button>
          <Button
            variant={BUTTON_VARIANTS.PRIMARY}
            className="pv2 ph2 ml2"
            disabled={isApplyDisabled}
            onClick={() => {
              setElement(getAstElementFromValue(draft));
              popoverCloseHandler();
            }}
            dataTestId="dtid_apply_changes"
          >
            Apply
          </Button>
        </div>
        {draftFields.map((fieldKey) => {
          const fieldValue = draft[fieldKey];
          const fieldSchema = schemaProperties[fieldKey];
          const isRequired = schemaRequired?.includes(fieldKey);
          const canDelete = !isRequired || !fieldSchema;
          return (
            <div key={fieldKey} className="flex items-center bb border-secondary">
              {fieldSchema ? (
                (() => {
                  const { title = fieldKey, description, type } = fieldSchema;
                  return (
                    <>
                      <div className="pl3" style={{ width: "150px" }}>
                        <Tooltip text={description}>
                          <div className="capitalize">
                            {title} {isRequired && "*"}
                          </div>
                        </Tooltip>
                        <div className="text-secondary font-small">
                          {Array.isArray(type) ? type.join(", ") : type}
                        </div>
                      </div>
                      <div className="flex-auto">
                        <AstRow
                          schema={fieldSchema}
                          element={fieldValue}
                          setElement={(value) => editField(fieldKey, value)}
                          className={`dtid_object_field_${fieldKey}`}
                          isBlank={fieldValue.isBlank}
                        />
                      </div>
                    </>
                  );
                })()
              ) : (
                <>
                  <FieldNameEditor
                    field={fieldKey}
                    onChange={(update) => updateFieldName(fieldKey, update)}
                  />
                  <AstRow
                    element={fieldValue}
                    setElement={(value) => editField(fieldKey, value)}
                    className={clsx("flex-auto", `dtid_object_field_${fieldKey}`)}
                  />
                </>
              )}
              <Tooltip text="Remove Field">
                <div
                  className={clsx(
                    "flex items-center ma2",
                    {
                      "text-primary pointer": canDelete,
                      "text-primary-light": !canDelete,
                    },
                    `dtid_delete_object_field_${fieldKey}`
                  )}
                  onClick={() => canDelete && deleteField(fieldKey)}
                >
                  <DeleteIcon name={"Clear"} size={24} color="inherit" />
                </div>
              </Tooltip>
            </div>
          );
        })}
        <div className="tl pa3 sticky bottom0 bg-default flex items-center justify-between">
          <Button
            className="pa2 text-primary"
            variant={BUTTON_VARIANTS.BORDER}
            onClick={addField}
          >
            + Add Field
          </Button>
          <ExecuteElement
            element={getAstElementFromValue(draft)}
            tooltip="Test object"
          />
        </div>
      </div>
    </Popover>
  );
}

const useObjectEditHelpers = ({ schema, element }) => {
  const [draft, setDraft] = useState(TYPE_OBJECT.DEFAULT_VALUE);
  const { properties: schemaProperties = {}, required: schemaRequired = [] } =
    schema;
  const draftFields = Object.keys(draft || {});
  const elementFields = Object.keys(element?.value || {});

  const emptyKey = [];
  draftFields.forEach((elem, i) => {
    if (!elem.trim().length) {
      emptyKey.push(i);
    }
  });
  const isApplyDisabled = Boolean(emptyKey.length);

  useEffect(() => {
    if (elementFields.length) {
      setDraft(element.value);
      return;
    }
    const defaultDraft = {};
    const properties = Object.keys(schemaProperties);
    if (draftFields.length === 0) {
      if (properties.length > 0) {
        properties.forEach((key) => {
          defaultDraft[key] = {
            ...schemaToAst(schemaProperties[key]),
            isBlank: true,
          };
        });
      } else {
        defaultDraft["field1"] = getAstElementFromValueType(null);
      }
    }
    setDraft(defaultDraft);
  }, [element.value]);

  const addField = () => {
    let length = draftFields.length;
    let uniqueFieldName = `field${length + 1}`;
    while (draft[uniqueFieldName]) {
      ++length;
      uniqueFieldName = `field${length}`;
    }
    setDraft({
      ...draft,
      [uniqueFieldName]: {
        type: TYPE_REFERENCE.TYPE,
        value: TYPE_REFERENCE.DEFAULT_VALUE,
      },
    });
  };

  const editField = (field, value) => {
    const newDraft = { ...draft, [field]: value };
    setDraft(newDraft);
  };

  const deleteField = (field) => {
    const newDraft = { ...draft };
    delete newDraft[field];
    setDraft(newDraft);
  };

  const updateFieldName = (field, fieldDraft) => {
    if (field === fieldDraft) {
      return;
    }
    const newObject = {};
    Object.keys(draft).forEach((key) => {
      //Deleting edited key name and adding new one will shift the key-value pair to the last of the list.
      //This logic preserves the order of the edited key.
      newObject[key === field ? fieldDraft : key] = draft[key];
    });
    setDraft({ ...newObject });
  };

  return {
    draft,
    setDraft,
    draftFields,
    elementFields,
    schemaProperties,
    schemaRequired,
    addField,
    editField,
    deleteField,
    updateFieldName,
    isApplyDisabled,
  };
};

function FieldNameEditor({ field, onChange }) {
  const [draft, setDraft] = useState(field);
  const [isEditing, setEditing] = useState(false);

  const handleChange = (e) => {
    setDraft(e.target.value);
  };

  const handleSave = () => {
    onChange(draft);
    setEditing(false);
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      onChange(draft);
    }
  };

  return (
    <div style={{ width: "150px" }}>
      {isEditing ? (
        <div className="pv1 ml1 rounded-pill" onClick={() => setEditing(field)}>
          {field}
        </div>
      ) : (
        <input
          autoFocus
          type="text"
          className="p0 bn rounded-pill ph2 pv1 ml1 bgDefault"
          placeholder="Enter field"
          value={draft}
          onChange={handleChange}
          onBlur={handleSave}
          onKeyDown={handleKeyDown}
        />
      )}
    </div>
  );
}

export default ObjectEditor;
