import React from "react";
import clsx from "clsx";
import styles from "./StepChip.module.scss";
/**
 * @param {{
 * confirmLabel?: string,
 * cancelLabel?: string,
 * onConfirm: Function,
 * onCancel: Function,
 * children: Object
 * }} props
 */
const ConfirmBox = ({
  confirmLabel = "Confirm",
  cancelLabel = "Cancel",
  onConfirm,
  onCancel,
  children,
}) => {
  return (
    <div className={styles.confirmStepHolder}>
      <div className={styles.confirmText} data-testid="dtid_step_chip_confirm_text">
        {children}
      </div>
      <div className={styles.buttonHolder}>
        <div className={styles.confirmButton} onClick={(e) => onCancel(e)}>
          {cancelLabel}
        </div>
        <div
          className={clsx(styles.confirmButton, styles.confirm)}
          onClick={(e) => onConfirm(e)}
          data-testid="dtid_step_chip_confirm"
        >
          {confirmLabel}
        </div>
      </div>
    </div>
  );
};

export default ConfirmBox;
