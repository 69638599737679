import React, { useMemo, useEffect, useState } from "react";
import styles from "../OrchestrationFlow.module.scss";
import OptionsHolder from "../options/OptionsHolder";
import StepOrchestrationSelector from "../selectors/StepOrchestrationSelector";
import FunctionLibrarySelector from "../selectors/FunctionLibrarySelector";
import CodeInput from "../options/OptionEditors/CodeInput";
import SchemaInput from "../options/OptionEditors/SchemaInput";
import { useDispatch, useSelector } from "react-redux";
import useGetTransform from "../../../state/transforms/useGetTransform";
import selectors from "../../../state/selectors";
import { getFullConnection } from "../../../state/connections/selectors";
import { getTriggerEvents } from "../state/actions";
import { Step, StepLabel, Typography } from "@material-ui/core";
import ConditionsEditor from "../ConditionsEditor/ConditionsEditor";
import UserReading from "../options/OptionEditors/UserReading";
import ConnectionConfigurationSelector from "../selectors/ConnectionConfigurationSelector";
import { useGetConnector } from "../../../state/hooks";
import StepOptions from "./StepOptions";
import StepErrorHandlingOptions from "./StepErrorHandlingOptions";
import { getStepState } from "../OrchestrationEditorStepSelector";
import validationErrorMessageGenerator from "../../StepChip/validationErrorMessageGenerator";
import ContentComponent from "../ContentComponent";
import {
  expressionToAst,
  AST_ELEMENT_TYPES,
} from "@dlx/components/ExpressionEditor";
import { useOrchestrationEditorContext } from "../BaseEditor";

const connectionSelector = "connection-selector";
const codeEditor = "code-editor";
const SchemaEditor = "schema-editor";
const readingActionSelector = "reading-action-selector";
const functionLibrarySelector = "functionlibrary-selector";
const orchestrationSelector = "orchestration-selector";
const userReading = "user-reading";

const getConnectorActions = (connector = {}) => {
  const actions = {};
  Object.entries(connector.actions || {}).forEach(([k, v]) => {
    if (connector.readings?.initiatingActions.includes(k)) {
      actions[k] = v;
    }
  });
  return actions;
};

/**
 * @param {{
 *  step: Step,
 *  stepNum: number,
 *  onUpdate: Function,
 * }} props
 */
const ActorContentContainer = ({ step, stepNum, onUpdate }) => {
  const astExpression = expressionToAst(step.connection);
  const dispatch = useDispatch();
  const actor = useSelector((state) => selectors.getActorSelector(state, step.kind));
  const { transform } = useGetTransform(step.libraryTransformId);
  const selectedConnector = useGetConnector(step?.connector)["connector"];
  const connection = useSelector(getFullConnection(astExpression?.value));
  const connector = selectedConnector || connection.connector;

  const [userSelection, setUserSelection] = useState(
    astExpression?.type === AST_ELEMENT_TYPES.IDENTIFIER
  );

  useEffect(() => {
    if (step.connection && !userSelection) {
      dispatch(getTriggerEvents({ connectionId: astExpression?.value }));
    }
  }, [dispatch, step, userSelection]);

  const actions = useMemo(() => {
    return actor?.inputs.includes(readingActionSelector)
      ? getConnectorActions(connector)
      : connector?.actions;
  }, [actor, connector]);

  const optionsSchema = useMemo(() => {
    return (
      connector?.actions?.[step.command]?.optionsSchema ||
      transform?.optionsSchema ||
      connector?.orchestrations?.[step.orchestration]?.invokeSchema ||
      actor?.optionsSchema
    );
  }, [actor, connector, step, transform]);

  const credentialsSchema = useMemo(() => {
    return (
      connector?.actions?.[step.command]?.credentialsSchema ||
      transform?.credentialsSchema ||
      connector?.orchestrations?.[step.orchestration]?.invokeSchema ||
      actor?.credentialsSchema
    );
  }, [actor, connector, step, transform]);

  return (
    <ActorContent
      actions={actions}
      actor={actor}
      connection={connection}
      connector={connector}
      credentialsSchema={credentialsSchema}
      onUpdate={onUpdate}
      optionsSchema={optionsSchema}
      setUserSelection={setUserSelection}
      step={step}
      stepNum={stepNum}
      userSelection={userSelection}
      stepState={getStepState(step)}
    />
  );
};

export default ActorContentContainer;

export function ActorContent(props) {
  const { actor, setUserSelection, step, stepNum } = props;
  const [activePanel, setActivePanel] = useState(0);
  const [currentStep, setCurrentStep] = useState(stepNum);
  const astExpression = expressionToAst(step.connection);
  const { orchestration = {} } = useOrchestrationEditorContext() || {};
  useEffect(() => {
    if (currentStep !== stepNum) {
      setActivePanel(0);
      setCurrentStep(stepNum);
    }
  }, [stepNum]);

  useEffect(() => {
    if (astExpression?.type === AST_ELEMENT_TYPES.IDENTIFIER) {
      setUserSelection(true);
    }
  }, [setUserSelection, step]);

  if (!actor?.inputs) {
    return <div className={styles.selectorContainer}>No Inputs Found</div>;
  }

  const availablePanels = createAvailablePanels({
    ...props,
    setActivePanel,
    orchestration,
  });

  return (
    <ContentComponent activePanel={activePanel} availablePanels={availablePanels} />
  );
}

/**
 *
 * @param {{
 * actor: Object,
 * step: Step,
 * onUpdate: Function,
 * userSelection: Boolean,
 * setUserSelection: Function,
 * stepNum: number,
 * actions: Array,
 * connector: Object,
 * connection: Object,
 *}} props
 */

function ActorConfiguration({
  actor,
  step,
  onUpdate,
  userSelection,
  setUserSelection,
  stepNum,
  actions,
  connector,
  connection,
}) {
  return (
    <>
      {actor.inputs.includes(orchestrationSelector) && (
        <StepOrchestrationSelector
          setOrchestration={onUpdate}
          stepNum={stepNum}
          step={step}
        />
      )}
      {actor.inputs.includes(connectionSelector) && (
        <ConnectionConfigurationSelector
          step={step}
          onUpdate={onUpdate}
          userSelection={userSelection}
          setUserSelection={setUserSelection}
          stepNum={stepNum}
          actions={actions}
          connector={connector}
          connection={connection}
          actor={actor}
        />
      )}
      {actor.inputs.includes(codeEditor) && (
        <CodeInput step={step} stepNum={stepNum - 1} onUpdate={onUpdate} />
      )}
      {actor?.inputs.includes(SchemaEditor) && (
        <SchemaInput
          name="inputsSchema"
          onChange={onUpdate}
          value={step.inputsSchema}
          // The key ensures that the editor is re-rendered with new data when the step changes
          key={stepNum}
          stepNum={stepNum - 1}
          step={step}
        />
      )}
      {actor.inputs.includes(functionLibrarySelector) && (
        <FunctionLibrarySelector step={step} updateStep={onUpdate} />
      )}
    </>
  );
}

function createAvailablePanels(props) {
  const { credentialsSchema, optionsSchema, step, orchestration } = props;
  const showErrorHandlingTab = orchestration?.orchestrationVersion !== 1;

  props.stepStates = {
    fullyConfigured: "Configured, check options",
    unconfigured: "Complete configuration",
    noConfiguration: "Check options",
  };

  props.stepConfigError =
    step.validationError && step.validationError.kind !== "MissingRequiredOption";

  const availablePanels = [];
  availablePanels.push(stepDetailsPanel({ ...props, availablePanels }));

  if (optionsSchema?.schema || optionsSchema?.properties) {
    availablePanels.push(stepOptionsPanel({ ...props, availablePanels }));
  }

  if (credentialsSchema) {
    availablePanels.push(stepCredentialsPanel({ ...props, availablePanels }));
  }

  availablePanels.push(stepConditionsPanel({ ...props, availablePanels }));

  if (showErrorHandlingTab) {
    availablePanels.push(stepErrorHandlingPanel({ ...props, availablePanels }));
  }

  return availablePanels;
}

function stepDetailsPanel(props) {
  const {
    actor,
    setActivePanel,
    stepConfigError,
    stepStates,
    stepState,
    step,
    availablePanels,
  } = props;

  return {
    title: actor.name,
    buttonTitle: "Step details",
    stepSelector: (
      <Step
        key="details"
        onClick={() =>
          setActivePanel(availablePanels.findIndex((p) => p.title === actor.name))
        }
        style={{ padding: "0px" }}
      >
        <StepLabel
          optional={
            <Typography
              variant="subtitle1"
              color={stepConfigError ? "error" : "primary"}
            >
              {stepConfigError
                ? validationErrorMessageGenerator(step)
                : stepStates[stepState]}
            </Typography>
          }
          error={stepConfigError}
        >
          {actor.name}
        </StepLabel>
      </Step>
    ),
    stepDisplay: <ActorConfiguration {...props} />,
  };
}

function stepOptionsPanel(props) {
  const { optionsSchema, step, availablePanels, stepNum, setActivePanel, onUpdate } =
    props;

  const error =
    step.validationError && step.validationError.kind === "MissingRequiredOption";

  const notFilledOptions = optionsSchema.required?.length
    ? (optionsSchema.required || []).filter(
        (o) =>
          !Object.entries(step.options || {})
            .filter(([_k, v]) => v)
            .map(([k]) => k)
            .includes(o)
      )
    : [];

  let caption = notFilledOptions.length
    ? `${notFilledOptions.length} required options not set`
    : "Required options set";

  if (error) {
    caption = validationErrorMessageGenerator(step);
  }

  return {
    title: "Options",
    buttonTitle: "Enter options",
    stepSelector: (
      <Step
        key="options"
        onClick={() =>
          setActivePanel(availablePanels.findIndex((p) => p.title === "Options"))
        }
      >
        <StepLabel
          optional={
            <Typography
              variant="subtitle1"
              color={error || notFilledOptions.length ? "error" : "primary"}
            >
              {caption}
            </Typography>
          }
          error={error}
          data-testid="dtid_options_label"
        >
          Options
        </StepLabel>
      </Step>
    ),
    stepDisplay: (
      <div className={styles.stepOptionsContainer}>
        {step.kind === userReading && (
          <UserReading
            step={step}
            // The key ensures that the editor is re-rendered with new data when the step changes
            key={stepNum}
            stepNum={stepNum - 1}
          />
        )}
        {optionsSchema && (
          <StepOptions
            stepNum={stepNum}
            onUpdate={onUpdate}
            step={step}
            optionsSchema={optionsSchema}
          />
        )}
      </div>
    ),
  };
}

function stepCredentialsPanel(props) {
  const {
    credentialsSchema,
    setActivePanel,
    availablePanels,
    onUpdate,
    stepNum,
    step,
  } = props;
  return {
    title: "Credentials",
    buttonTitle: "Enter credentials",
    stepSelector: (
      <Step
        key="credentials"
        onClick={() =>
          setActivePanel(availablePanels.findIndex((p) => p.title === "Credentials"))
        }
      >
        <StepLabel
          optional={
            credentialsSchema?.required && (
              <Typography variant="subtitle1" color="error">
                {`${credentialsSchema.required.length} required credential${
                  credentialsSchema.required.length > 1 ? "s" : ""
                }`}
              </Typography>
            )
          }
        >
          Credentials
        </StepLabel>
      </Step>
    ),
    stepDisplay: (
      <div className={styles.stepOptionsContainer}>
        <OptionsHolder
          onUpdate={onUpdate}
          optionsSchema={credentialsSchema}
          step={step}
          name={"credentials"}
          stepNum={stepNum}
        />
      </div>
    ),
  };
}
function stepConditionsPanel(props) {
  const { step, setActivePanel, stepNum, availablePanels, onUpdate } = props;

  return {
    title: "Conditions",
    buttonTitle: "Add conditions",
    stepSelector: (
      <Step
        key="conditions"
        onClick={() =>
          setActivePanel(availablePanels.findIndex((p) => p.title === "Conditions"))
        }
      >
        <StepLabel
          optional={
            step.conditions?.length ? (
              <Typography variant="subtitle1" color="primary">
                {`${step.conditions.length} condition${
                  step.conditions.length > 1 ? "s" : ""
                } in effect`}
              </Typography>
            ) : (
              <Typography variant="subtitle1" color="primary">
                No conditions in effect
              </Typography>
            )
          }
        >
          Conditions
        </StepLabel>
      </Step>
    ),
    stepDisplay: (
      <ConditionsEditor
        key={`${stepNum}-conditions`}
        stepNum={stepNum}
        conditions={step.conditions}
        onUpdate={onUpdate}
      />
    ),
  };
}

function stepErrorHandlingPanel(props) {
  const { step, setActivePanel, stepNum, availablePanels, onUpdate } = props;

  return {
    title: "Error Handling",
    buttonTitle: "Enter options",
    stepSelector: (
      <Step
        key="Error Handling"
        onClick={() =>
          setActivePanel(
            availablePanels.findIndex((p) => p.title === "Error Handling")
          )
        }
      >
        <StepLabel
          optional={
            <Typography variant="subtitle1" color="primary">
              Configured
            </Typography>
          }
          data-testid="dtid_options_label"
        >
          Error Handling
        </StepLabel>
      </Step>
    ),
    stepDisplay: (
      <div className={styles.stepOptionsContainer}>
        <StepErrorHandlingOptions
          name="errorHandling"
          stepNum={stepNum}
          onUpdate={onUpdate}
          step={step}
        />
      </div>
    ),
  };
}
