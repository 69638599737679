import React from "react";
import { useDispatch, useSelector } from "react-redux";
import * as selectors from "./live-readings.selectors";
import * as actions from "./live-readings.actions";

/**
 * A React hook for the live readings data that is collected when a user
 * holds a lease on a connection.
 * @memberof module:React
 */

export default function useLiveReadings(connectionId) {
  const dispatch = useDispatch();
  const transientReading = useSelector(
    selectors.selectTransientReading(connectionId)
  );
  const permanentReadings = useSelector(
    selectors.selectPermanentReadings(connectionId)
  );

  const clearReadings = React.useCallback(
    () => dispatch(actions.clearReadings()),
    [dispatch]
  );

  return {
    transientReading,
    permanentReadings,
    clearReadings,
  };
}
