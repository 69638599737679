import { SIGN_ON } from "@dlx/views/routes";
import { getAuthConfig, client } from "./sso";
import { logger } from "@dlx/utils";
import { clearStorage } from "@dlx/utils/index";
const log = logger("sso");

export async function logoutWithIdentityProvider(token) {
  try {
    log("logging out...");
    const authConfig = await getAuthConfig();
    const logoutUrl = buildLogoutUrl(authConfig, token);
    clearStorage();
    redirectUserToLogoutUrl(logoutUrl);
  } catch (error) {
    log(error.message);
    redirectUserToSignOn();
  }
}

function buildLogoutUrl(authConfig, token) {
  const redirectUrl = new URL(SIGN_ON, window.location.href);
  const logoutUrl = new URL(authConfig.end_session_endpoint);

  logoutUrl.searchParams.set("id_token_hint", token.id_token);
  logoutUrl.searchParams.set("post_logout_redirect_uri", redirectUrl.href);
  logoutUrl.searchParams.set("client_id", client.client_id);
  logoutUrl.searchParams.set("state", JSON.stringify({ action: "logout" }));

  return logoutUrl.href;
}

function redirectUserToLogoutUrl(logoutUrl) {
  window.location.replace(logoutUrl);
}

function redirectUserToSignOn() {
  window.location.replace(SIGN_ON);
}
