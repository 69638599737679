import React from "react";
import clsx from "clsx";

function ExpressionNode({ children, className }) {
  return (
    <section
      className={clsx(
        "inline-flex mt2 pr2 items-center bg-primary text-contrast bg-hover-primary-dark rounded4",
        className
      )}
    >
      {children}
    </section>
  );
}

export default ExpressionNode;
