import React, { Fragment, useState, useEffect } from "react";
import variables from "../../../assets/styles/variables.scss";

import { withStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import NativeSelect from "@material-ui/core/NativeSelect";
import InputBase from "@material-ui/core/InputBase";

const BootstrapInput = withStyles(() => ({
  input: {
    borderRadius: 4,
    backgroundColor: variables.lightgreenbackground,
    position: "relative",
    border: `1px solid ${variables.lightgrayborder}`,
    fontSize: 16,
    padding: "10px 26px 10px 12px",

    "&:focus": {
      borderRadius: 4,
      borderColor: "#80bdff",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
    },
  },
}))(InputBase);

/**
 * @param {TriggerTimerSelectorProps} { interval, units, setTriggerEvent }
 */
function TriggerTimerSelector({ interval, units, setTriggerEvent }) {
  const [timeInterval, setTimeInterval] = useState(interval);
  const [timeUnits, setTimeUnits] = useState(units);

  useEffect(() => {
    setTimeInterval(interval);
    setTimeUnits(units);
  }, [interval, units]);

  const handleChange = (event) => {
    const update = {};
    update[event.target.id] = event.target.value;
    setTriggerEvent(update);
  };

  return (
    //You can use connector.commands to build a menu of commands available
    <Fragment>
      <FormControl id="interval_controll_1">
        <NativeSelect
          id="interval"
          value={timeInterval}
          onChange={handleChange}
          input={<BootstrapInput />}
        >
          <option value={1}>1</option>
          <option value={2}>2</option>
          <option value={3}>3</option>
          <option value={4}>4</option>
          <option value={5}>5</option>
          <option value={10}>10</option>
          <option value={20}>20</option>
          <option value={30}>30</option>
          <option value={40}>40</option>
          <option value={50}>50</option>
          <option value={60}>60</option>
        </NativeSelect>
      </FormControl>
      <FormControl id="interval_controll_1">
        <NativeSelect
          id="units"
          value={timeUnits}
          onChange={handleChange}
          input={<BootstrapInput />}
        >
          <option value={"minute"}>{`minute${timeInterval > 1 ? "s" : ""}`}</option>
          <option value={"hour"}>{`hour${timeInterval > 1 ? "s" : ""}`}</option>
          <option value={"day"}>{`day${timeInterval > 1 ? "s" : ""}`}</option>
        </NativeSelect>
      </FormControl>
    </Fragment>
  );
}

export default TriggerTimerSelector;
