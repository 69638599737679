import React, { useEffect, useState } from "react";
import clsx from "clsx";
import LookupValueSelector from "../Selectors/LookupValueSelector";
import { getAstElementFromValue } from "../util";

function DefaultEditor({ schema, element, setElement, inputType, isBlank }) {
  const [draft, setDraft] = useState(null);
  const [isEditing, setEditing] = useState(false);
  const usesLookup = Boolean(schema?.enum || schema?.lookup);
  const isNumber = inputType === "number";

  useEffect(() => {
    if (!isBlank && element.value !== draft) {
      setDraft(element.value);
    }
  }, [element.value, isBlank]);

  const handleChange = (e) => {
    const value = e.target.value;
    setDraft(value);
  };

  const handleSave = () => {
    const val = isNumber ? Number(draft) : draft ?? "";
    setElement(getAstElementFromValue(val));
    setEditing(false);
  };

  const handleSelectLookUpValue = (v) => {
    setElement(getAstElementFromValue(v ?? ""));
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      handleSave();
    }
  };

  return (
    <div>
      {usesLookup ? (
        <LookupValueSelector
          value={element.value}
          setValue={handleSelectLookUpValue}
          schema={schema}
        />
      ) : (
        <>
          {!isEditing ? (
            <div
              className={clsx(
                "pv2 ml1 rounded-pill",
                {
                  "text-primary-light": isBlank,
                },
                "dtid_literal_edit"
              )}
              onClick={() => setEditing(true)}
              style={{ minWidth: "50px" }}
            >
              {draft ?? `Enter the ${inputType}`}
            </div>
          ) : (
            <input
              autoFocus
              type={inputType}
              className={clsx(
                "p0 bn rounded-pill ph2 pv1  ml1 bgDefault",
                "dtid_literal_edit_input"
              )}
              placeholder={`Enter the ${inputType}`}
              value={draft ?? ""}
              onChange={handleChange}
              onBlur={() => handleSave()}
              onKeyDown={handleKeyDown}
            />
          )}
        </>
      )}
    </div>
  );
}

export default DefaultEditor;
