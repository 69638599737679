import React from "react";
import { Menu } from "../Menu";
import { MenuItem } from "../MenuItem";
import { triggers } from "../../../state/triggers/triggers";
import TriggerTypeRow from "../selectors/TriggerTypeRow";
import { useSystemEnvContext } from "../../../contexts/system/Provider";

/**
 * @param {{
 *  selected?: string,
 *  onClose: Function,
 *  onSelect: Function,
 *  anchorEl: any,
 *  setAnchorEl: Function
 * }} props
 */
const TriggerMenu = ({ selected, onClose, onSelect, anchorEl, setAnchorEl }) => {
  const {
    config: { enableDeprecatedFeature },
  } = useSystemEnvContext();
  const options = Object.entries(triggers).filter(
    ([key, trigger]) =>
      (enableDeprecatedFeature || !trigger.deprecatedFeature) && key !== selected
  );

  return (
    <Menu open={Boolean(anchorEl)} anchorEl={anchorEl} onClose={onClose}>
      {options.map(([key, option]) => (
        <MenuItem
          key={key}
          onClick={() => {
            onSelect(key);
            setAnchorEl(null);
          }}
        >
          <TriggerTypeRow type={key} label={option.name} />
        </MenuItem>
      ))}
    </Menu>
  );
};

export default TriggerMenu;
