import { makeStyles } from "@material-ui/core/styles";

const drawerWidth = 72;

export default makeStyles((theme) => ({
  // dashboard
  root: {
    display: "flex",
    zIndex: 0,
    height: "100%",
    position: "relative",
  },
  main: {
    flexGrow: 1,
    padding: theme.spacing(2),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
    zIndex: 0,
  },
  // sidebar
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
    zIndex: 1,
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    margin: "-2px",
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
    marginRight: "30px",
    minHeight: "auto",
  },
}));
