import React, { useEffect, useMemo, useRef, useState } from "react";
import styles from "./StepChip.module.scss";
import { OutputDataSelector } from "../SourceDataSelector/SourceDataSelector";
import { useDispatch, useSelector } from "react-redux";
import selectors from "../../state/selectors";
import { TimesIcon } from "../../icons";
import DLXTooltip from "../DLXTooltip/DLXTooltip";
import renderValidationErrorMessage from "./validationErrorMessageGenerator";
import clsx from "clsx";
import StepMenuSelector from "./StepMenuSelector";
import SkeletonChip from "./SkeletonChip";
import ConfirmBox from "./ConfirmBox";
import ChipIcon from "./ChipIcon";
import StepIndex from "./StepIndex";
import StepName from "./StepName";
import useGetTransform from "../../state/transforms/useGetTransform";
import { actions } from "../../state/hooks/useApiResource";
import { ERROR_HANDLING_SCHEMA } from "../orchestrationeditor/state/constants";

const isStepConfigured = (step, actors, connector, transform) => {
  let optionsSchema = actors[step.kind]?.optionsSchema;

  if (transform) {
    optionsSchema = transform.optionsSchema;
  }
  if (connector && step?.command) {
    optionsSchema = connector.actions[step.command]?.optionsSchema;
  }

  const setOptions = Object.keys(step?.options || {});

  return (optionsSchema?.required || []).every((o) => setOptions.includes(o));
};

const stepStateClasses = (stepState, active, stepConfigured) => {
  return {
    [styles.unconfigured]: stepState === "unconfigured" || !stepConfigured,
    [styles.fullyConfigured]:
      ["fullyConfigured", "noConfiguration"].includes(stepState) && stepConfigured,
    [styles.validationError]: stepState === "validationError",
    [styles.active]: active,
  };
};

const getIconTitle = (triggers, stepKind, titles) =>
  triggers[stepKind]?.name || titles[stepKind] || null;

const getStepName = (step, stepNum) => step?.name || "Step " + stepNum;

const getTitle = (connector, actor) => connector?.name || actor.name;
const getText = (validationErrorMessage) =>
  validationErrorMessage ? `Error: ${validationErrorMessage}` : "";

const getActiveStep = (activeStep, stepNum) => activeStep === stepNum + 1;
const getConnectorId = (step) => step.connector?.id || step.connector;

/**
 * @param {{
 * active: boolean,
 * deleteStep: Function,
 * onClick: function,
 * index: number,
 * setActiveStep: Function,
 * activeStep: number,
 * step: Step,
 * stepNum: number,
 * stepId: string,
 * stepState: "validationError" | "fullyConfigured" | "unconfigured",
 * updateStep: Function,
 * updateTrigger: Function
 * }} props
 */
const StepChip = ({
  active,
  deleteStep,
  onClick,
  index,
  setActiveStep,
  activeStep,
  step,
  stepNum,
  stepId,
  stepState,
  updateStep,
  updateTrigger,
}) => {
  const [deleteFlag, setDeleteFlag] = useState(false);
  const [kindChangeFlag, setKindChangeFlag] = useState(false);
  const selectedKind = useRef("");
  const actors = useSelector(selectors.getActors);
  const triggers = useSelector(selectors.getTriggers);
  const stepKind = step?.kind;
  const dispatch = useDispatch();
  const [connector, setConnector] = useState();
  const connectorId = getConnectorId(step);

  useEffect(() => {
    let mounted = true;
    if (mounted && step.connector && connectorId) {
      const fetchConnector = async () => {
        const response = await dispatch(
          actions.apiTransaction({
            route: `/connectors?id=${connectorId}`,
          })
        );
        response.items && setConnector(response.items[0]);
      };
      fetchConnector();
    }
    return () => {
      mounted = false;
    };
  }, [connectorId, dispatch, step.connector]);

  const { transform } = useGetTransform(step?.libraryTransformId);
  const isTrigger = stepNum === -1;
  const activeStepChip = getActiveStep(activeStep, stepNum);

  const { titles } = useMemo(() => {
    const results = { titles: {}, stepIcons: {} };
    Object.entries({ ...actors }).forEach(([key, actor]) => {
      results.stepIcons[key] = actor.icon;
      results.titles[key] = getTitle(connector, actor);
    });
    return results;
  }, [actors, connector]);

  const iconTitle =
    getIconTitle(triggers, stepKind, titles) ||
    `Unknown ${isTrigger ? "trigger" : "step"}`;
  const stepName = getStepName(step, stepNum);

  const icon = (
    <ChipIcon
      isTrigger={isTrigger}
      actors={actors}
      stepKind={stepKind}
      active={activeStepChip}
    />
  );
  const stepConfigured = isStepConfigured(step, actors, connector, transform);
  const validationErrorMessage = renderValidationErrorMessage(step);

  const handleDelete = (e) => {
    e.stopPropagation();
    deleteStep(stepNum);
    setDeleteFlag(false);
    setActiveStep(stepNum);
  };

  const handleEditStep = (name) => {
    updateStep({ num: stepNum, ...step, name: name });
  };

  const confirmStepKindChange = (kind) => {
    selectedKind.current = kind;
    setKindChangeFlag(true);
  };

  const handleStepKindChange = () => {
    const kind = selectedKind.current;
    if (isTrigger) {
      updateTrigger({ kind });
    } else {
      const stepObj = {
        kind,
        num: stepNum,
      };
      if (step.errorHandling) {
        stepObj.errorHandling = {
          errorResponse: ERROR_HANDLING_SCHEMA.properties.errorResponse.default,
        };
      }
      updateStep({ ...stepObj });
    }
    setConnector(null);
    setKindChangeFlag(false);
  };

  const content = (
    <DLXTooltip text={getText(validationErrorMessage)}>
      <div
        onClick={() => onClick(index)}
        className={clsx(
          styles.stepBox,
          stepStateClasses(stepState, active, stepConfigured)
        )}
      >
        <div
          className={clsx(styles.stepContent, {
            [styles.hideStepContent]: kindChangeFlag || deleteFlag,
          })}
        >
          {isTrigger && <div className={styles.stepIconHolder}>{icon}</div>}
          <div className={styles.stepText} data-testid="dtid_step_chip_step_text">
            {!isTrigger && (
              <StepName
                name={stepName}
                active={activeStepChip}
                editStep={handleEditStep}
              />
            )}
            <div className={styles.stepSubTitle}>
              <StepMenuSelector
                step={step}
                stepLabel={iconTitle}
                stepNum={stepNum}
                icon={icon}
                onSelect={confirmStepKindChange}
                active={activeStepChip}
              />
            </div>
          </div>
          <div className={styles.stepData}>
            <OutputDataSelector
              stepNum={stepNum}
              stepId={stepId}
              title="Output"
              copyAll={true}
            />
          </div>
          <StepIndex index={stepNum} trigger={isTrigger} />
          <div
            className={clsx(
              { [styles.deleteButton]: !isTrigger && active },
              styles.hideDelete
            )}
            data-testid="dtid_step_chip_delete_toggle_button"
          >
            <DLXTooltip text="Remove step">
              <div onClick={() => setDeleteFlag(true)}>
                <TimesIcon className={styles.popupIcon} />
              </div>
            </DLXTooltip>
          </div>
        </div>
        {deleteFlag && (
          <ConfirmBox
            confirmLabel="Delete"
            onConfirm={handleDelete}
            onCancel={() => setDeleteFlag(false)}
          >
            Are you sure you want to delete this step?
          </ConfirmBox>
        )}
        {kindChangeFlag && (
          <ConfirmBox
            confirmLabel="Change"
            onConfirm={handleStepKindChange}
            onCancel={() => setKindChangeFlag(false)}
          >
            Are you sure you want to change the type?
          </ConfirmBox>
        )}
      </div>
    </DLXTooltip>
  );
  return iconTitle ? content : <SkeletonChip />;
};

export default StepChip;
