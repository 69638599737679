import React, { useEffect, useState } from "react";

import Grid from "@material-ui/core/Grid";
import { KeyboardTimePicker, KeyboardDatePicker } from "@material-ui/pickers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const TimerTriggerStepContent = ({ trigger, setTriggerEvent }) => {
  const [triggerStartDate, setTriggerStartDate] = useState();
  const [triggerUntilDate, setTriggerUntilDate] = useState();

  useEffect(() => {
    setTriggerStartDate(trigger.start);
    setTriggerUntilDate(trigger.until);
  }, [trigger]);

  const handleTriggerStartChange = (date) => {
    setTriggerEvent({ kind: "timer", start: date, until: triggerUntilDate });
    setTriggerStartDate(date);
  };

  const handleTriggerUntilChange = (date) => {
    setTriggerEvent({ kind: "timer", start: triggerStartDate, until: date });
    setTriggerUntilDate(date);
  };

  return (
    <Grid container style={{ justifyContent: "space-around" }}>
      <div id="trigger-start-date-time">
        <KeyboardDatePicker
          format="MM/dd/yyyy"
          margin="normal"
          id="trigger-start-date"
          label="Starting on"
          value={triggerStartDate}
          onChange={handleTriggerStartChange}
          KeyboardButtonProps={{
            "aria-label": "change date",
          }}
        />
        <KeyboardTimePicker
          margin="normal"
          id="trigger-start-time"
          label="at"
          keyboardIcon={<FontAwesomeIcon icon="clock" />}
          value={triggerStartDate}
          onChange={handleTriggerStartChange}
          KeyboardButtonProps={{
            "aria-label": "change time",
          }}
        />
      </div>
      <div id="trigger-until-date-time">
        <KeyboardDatePicker
          format="MM/dd/yyyy"
          margin="normal"
          id="trigger-until-date"
          label="Until"
          value={triggerUntilDate}
          onChange={handleTriggerUntilChange}
          KeyboardButtonProps={{
            "aria-label": "change date",
          }}
        />
        <KeyboardTimePicker
          margin="normal"
          id="trigger-until-time"
          label="at"
          keyboardIcon={<FontAwesomeIcon icon="clock" />}
          value={triggerUntilDate}
          onChange={handleTriggerUntilChange}
          KeyboardButtonProps={{
            "aria-label": "change time",
          }}
        />
      </div>
    </Grid>
  );
};
export default TimerTriggerStepContent;
