import React from "react";
import OptionsHolder from "../options/OptionsHolder";
import { ERROR_HANDLING_SCHEMA } from "../state/constants";

function StepErrorHandlingOptions({ name, stepNum, onUpdate, step }) {
  const { errorResponse, message, ...notification } =
    ERROR_HANDLING_SCHEMA.properties;
  const messageSchema = {
    properties: { message },
  };
  const notificationSchema = {
    properties: notification,
  };

  const handleOnUpdate = (updates, schema = null) => {
    const currentObject = { ...step[name] };
    schema &&
      Object.keys(currentObject).forEach((key) => {
        if (schema.properties[key] && !updates[name][key]) {
          //On clear expression, if a key required by schema is not present in updates
          //Then delete the key from error handeling object as well because updates are missing it on clear expression.
          delete currentObject[key];
        }
      });
    onUpdate({ [name]: { ...currentObject, ...updates[name] } });
  };

  return (
    <div>
      <div className="font-large pb3 bb border-primary text-default">
        Error Handling Settings
      </div>
      <div className="flex items-center pv5">
        <div style={{ width: "120px" }}>{errorResponse.title}</div>
        {errorResponse.enum.map((val) => {
          return (
            <div key={`response_type_${val}`}>
              <input
                type="radio"
                name="error_response"
                value={val}
                checked={
                  val ===
                  (step?.errorHandling?.errorResponse || errorResponse?.default)
                }
                id={`response_type_${val}`}
                onChange={() =>
                  handleOnUpdate({ errorHandling: { errorResponse: val } })
                }
              />
              <label htmlFor={`response_type_${val}`} className="mr5 ml2 pointer">
                {val}
              </label>
            </div>
          );
        })}
      </div>
      <OptionsHolder
        key={`0-${stepNum}-options`}
        onUpdate={(updates) => handleOnUpdate(updates, messageSchema)}
        optionsSchema={messageSchema}
        step={step}
        name={name}
        stepNum={stepNum}
      />

      <div className="font-large pb3 bb border-primary text-default mv5">
        Notifications
      </div>
      <OptionsHolder
        key={`1-${stepNum}-options`}
        onUpdate={(updates) => handleOnUpdate(updates, notificationSchema)}
        optionsSchema={notificationSchema}
        step={step}
        name={name}
        stepNum={stepNum}
      />
    </div>
  );
}

export default StepErrorHandlingOptions;
