import React from "react";
import styles from "./StepChip.module.scss";

const defaultLength = 3;

const SkeletonChip = ({ lines = defaultLength }) => {
  const count = Array.from({ length: lines }, (_v, i) => i);

  return (
    <div className={styles.skeletonHolder}>
      {count.map((j) => (
        <div className={styles.skeletonLoader} key={j} />
      ))}
    </div>
  );
};

export default SkeletonChip;
