import useGetCollection from "../hooks/useGetCollection";
import useGetRecord from "../hooks/useGetRecord";

const slice = "userRoles";

export default function useGetUserRoles(id) {
  const [fetched, userRole] = useGetRecord(slice, id);
  return { fetched, userRole };
}
export function useGetUserRolesCollection() {
  const [loaded, userRoles] = useGetCollection(slice);
  return { loaded, userRoles };
}
