import React, { memo, useState } from "react";

import { AstRow } from "./Helpers";
import { Context, getContextValue } from "./context";
import { useTransforms } from "./hooks";

function AstEditor({
  context = {},
  schema = {},
  element,
  setElement,
  onClear = null,
  executeLookup = null,
  dataTestId = "",
  className = "",
}) {
  const { transforms } = useTransforms();
  const [output, setOutput] = useState({});
  return (
    <Context.Provider
      value={getContextValue({
        schema,
        context,
        executeLookup,
        transforms,
        output,
        setOutput,
      })}
    >
      <AstRow
        schema={schema}
        element={element}
        setElement={setElement}
        onClear={onClear}
        className={className}
        dataTestId={dataTestId}
      />
    </Context.Provider>
  );
}

export default memo(AstEditor);
