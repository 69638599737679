import React from "react";
import ConditionsEditor from "../ConditionsEditor/ConditionsEditor";

/**
 * @param {{
 * conditions: String[],
 * onUpdate: Function,
 * }} props
 */
const TriggerConditions = ({ conditions = [], onUpdate }) => {
  return (
    <ConditionsEditor
      key="trigger-conditions"
      stepNum={0}
      conditions={conditions}
      onUpdate={onUpdate}
    />
  );
};

export default TriggerConditions;
