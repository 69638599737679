import React, { useState } from "react";
import { areEqual, withJsonFormsControlProps } from "@jsonforms/react";
import {
  rankWith,
  scopeEndsWith,
  or,
  scopeEndIs,
  optionIs,
  schemaMatches,
} from "@jsonforms/core";
import { useTheme } from "@material-ui/core/styles";
import Close from "@material-ui/icons/Close";
import FormControl from "@material-ui/core/FormControl";
import IconButton from "@material-ui/core/IconButton";
import InputLabel from "@material-ui/core/InputLabel";
import Input from "@material-ui/core/Input";
import InputAdornment from "@material-ui/core/InputAdornment";
import merge from "lodash/merge";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";

const MuiInputText = React.memo(
  ({
    className,
    config,
    data = "",
    enabled,
    handleChange,
    id = "password",
    label,
    muiInputProps,
    path,
    schema,
    errors,
    uischema,
    visible,
  }) => {
    const [showAdornment, setShowAdornment] = useState(true);

    const [showPassword, setShowPassword] = useState(false);
    const [editing, setEditing] = useState(false);

    const maxLength = schema.maxLength;
    const appliedUiSchemaOptions = merge({}, config, uischema.options);
    let inputProps = null;

    if (appliedUiSchemaOptions.restrict) {
      inputProps = { maxLength: maxLength };
    } else {
      inputProps = {};
    }

    inputProps = merge(inputProps, muiInputProps);

    if (appliedUiSchemaOptions.trim && maxLength !== undefined) {
      inputProps.size = maxLength;
    }
    const onChange = (ev) => handleChange(path, ev.target.value);

    const handleHidePassword = React.useCallback((e) => {
      e.stopPropagation();
      setEditing(false);
      setShowPassword(false);
    }, []);

    const handleShowPassword = React.useCallback((e) => {
      e.stopPropagation();
      setEditing(true);
      setShowPassword(true);
    }, []);

    const dummyPassword = data?.length ? "********" : "";

    if (!visible) {
      return null;
    }

    return (
      <FormControl fullWidth={!appliedUiSchemaOptions.trim}>
        <InputLabel htmlFor={id} error={Boolean(errors)}>
          {label}
        </InputLabel>
        <Input
          autoComplete="off"
          autoFocus={appliedUiSchemaOptions.focus}
          className={className}
          disabled={!enabled}
          error={Boolean(errors)}
          fullWidth={!appliedUiSchemaOptions.trim || maxLength === undefined}
          id={id}
          inputProps={inputProps}
          multiline={appliedUiSchemaOptions.multi}
          onBlur={handleHidePassword}
          onChange={onChange}
          onClick={() => setEditing(true)}
          onFocus={() => setEditing(true)}
          type={showPassword ? "text" : "password"}
          value={editing ? data : dummyPassword}
          onPointerEnter={() => setShowAdornment(true)}
          onPointerLeave={() => setShowAdornment(false)}
          endAdornment={
            <InputAdornment
              position="end"
              style={{
                display:
                  !showAdornment || !enabled || data === undefined ? "none" : "flex",
                position: "absolute",
                right: 0,
              }}
            >
              <IconButton
                aria-label="toggle password visibility"
                onClick={showPassword ? handleHidePassword : handleShowPassword}
              >
                {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
              </IconButton>
              <ClearInputFieldButton onClick={() => handleChange(path, undefined)} />
            </InputAdornment>
          }
        />
      </FormControl>
    );
  },
  areEqual
);

function ClearInputFieldButton({ onClick }) {
  const theme = useTheme();

  const inputDeleteBackgroundColor =
    theme.jsonforms?.input?.delete?.background || theme.palette.background.default;

  return (
    <InputAdornment position="end">
      <IconButton aria-label="Clear input field" onClick={onClick}>
        <Close
          style={{
            background: inputDeleteBackgroundColor,
            borderRadius: "50%",
          }}
        />
      </IconButton>
    </InputAdornment>
  );
}

const PasswordTester = rankWith(
  3, //increase rank as needed
  or(
    scopeEndsWith("password"),
    schemaMatches((schema) => "secret" in schema),
    scopeEndIs("secret"),
    optionIs("secret", true),
    optionIs("password", true)
  )
);

const MuiPassword = {
  renderer: withJsonFormsControlProps(MuiInputText),
  tester: PasswordTester,
};
export default MuiPassword;
