import React, { useState, useEffect, useCallback } from "react";
import get from "lodash/get";
import Button from "@material-ui/core/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import styles from "./StepContent.module.scss";
import JsonForm from "../JsonForm/JsonForm";
import { validSchema } from "../JsonForm/helpers";
import { getExpressionValue } from "../ExpressionEditor";
import { ORCHESTRATION_VERSION_ONE } from "./constants";

const templateKey = /"{{.*?}}"/g;

const hasReferences = (schema) =>
  schema && JSON.stringify(schema).match(templateKey);

const mapReferences = (schema, stepData) => {
  if (stepData) {
    let schemaStr = JSON.stringify(schema);
    const references = [...schemaStr.matchAll(templateKey)].map(([ref]) => ref);
    references.forEach((ref) => {
      const dataRef = get(stepData, ref.slice(3, -3), "");
      schemaStr = schemaStr.replace(ref, JSON.stringify(dataRef));
    });
    return JSON.parse(schemaStr);
  }
  return schema;
};

/**
 * @param {{
 * executeLookupCommand: Function,
 * getStepInputs: Function,
 * inputsSchema: Object,
 * stepNum: Number,
 * submitted: Boolean,
 * submitUserInput: Function,
 * working: Boolean,
 * step: Object,
 * execution: Object,
 * }} props
 */
function UserInputInterface({
  executeLookupCommand,
  getStepInputs,
  inputsSchema,
  stepNum,
  submitted = false,
  submitUserInput,
  working = false,
  step,
  execution,
}) {
  const [valid, setValid] = useState(false);
  const [userData, setUserData] = useState({});
  const [reset, setReset] = useState(false);
  const [loadingStepData, setLoadingStepData] = useState(false);
  const [formDirty, setFormDirty] = useState(false);
  const [mappedInputsSchema, setMappedInputsSchema] = useState(
    hasReferences(inputsSchema) ? null : JSON.parse(JSON.stringify(inputsSchema))
  );
  const [stepInput, setStepInput] = useState({});
  const [submitLabel, setSubmitLabel] = useState("Submit");
  const [resetLabel, setResetLabel] = useState("Reset");
  const [hideReset, setHideReset] = useState(false);

  useEffect(() => {
    async function getStepInput() {
      const inputData = await getStepInputs(stepNum);
      setStepInput(inputData);
    }
    getStepInput();
  }, [getStepInputs, stepNum]);

  useEffect(() => {
    getExpressionValue(step?.options?.submitButtonLabel, stepInput).then((data) => {
      setSubmitLabel(data);
    });
    getExpressionValue(step?.options?.resetButtonLabel, stepInput).then((data) => {
      setResetLabel(data);
    });
    getExpressionValue(step?.options?.hideResetButton, stepInput).then((data) => {
      setHideReset(data);
    });
  }, [stepInput]);

  const handleOnChange = useCallback(({ data, isDirty, isValid }) => {
    setValid(isValid);
    setFormDirty(isDirty);
    setUserData(data);
  }, []);

  useEffect(() => {
    if (hasReferences(inputsSchema)) {
      setLoadingStepData(true);
      const isStepInputs = getStepInputs(stepNum);
      isStepInputs?.then(() => setLoadingStepData(false));
      !isStepInputs && setLoadingStepData(false);
    }
  }, [getStepInputs, inputsSchema, stepNum]);

  useEffect(() => {
    getStepInputs(stepNum)?.then((stepData) => {
      if (stepData) {
        if (!stepData.steps) {
          stepData.steps = [];
        }
        if (
          execution?.orchestration.orchestrationVersion > ORCHESTRATION_VERSION_ONE
        ) {
          stepData.steps[stepNum] = { result: userData };
        } else {
          stepData.steps[stepNum] = userData;
        }
        if (stepData.triggeredAt) {
          setMappedInputsSchema(mapReferences(inputsSchema, stepData));
        }
      }
    });
  }, [inputsSchema, setMappedInputsSchema, getStepInputs, stepNum, userData]);

  const handleOnClick = useCallback(
    () => submitUserInput(userData),
    [submitUserInput, userData]
  );

  const isValidSchema = mappedInputsSchema && validSchema(mappedInputsSchema.schema);

  if (loadingStepData) {
    return (
      <div className={styles.loader}>
        <FontAwesomeIcon icon="spinner" spin />
      </div>
    );
  }

  const noExecutionForReference =
    hasReferences(inputsSchema) &&
    !isValidSchema &&
    !mappedInputsSchema &&
    !Object.keys(stepInput || {}).length;

  return (
    <div className={styles.jsonFormContainer}>
      {isValidSchema && (
        <>
          <JsonForm
            data={userData}
            schema={mappedInputsSchema.schema}
            uischema={mappedInputsSchema.uiSchema}
            onChange={handleOnChange}
            reset={reset}
            setReset={setReset}
            executeLookupCommand={executeLookupCommand}
          />
          <div className={styles.buttonContainer}>
            <div className={styles.buttonHolder}>
              <Button
                variant="contained"
                color="primary"
                className={styles.submitBtn}
                onClick={handleOnClick}
                disabled={submitted || !valid || working}
                startIcon={
                  working ? (
                    <FontAwesomeIcon icon="spinner" spin />
                  ) : (
                    <FontAwesomeIcon icon="upload" />
                  )
                }
              >
                {submitted ? "Submitted" : submitLabel || "Submit"}
              </Button>
              {!hideReset && (
                <Button
                  variant="contained"
                  className={styles.resetBtn}
                  onClick={() => setReset(true)}
                  disabled={!formDirty}
                  startIcon={<FontAwesomeIcon icon="sync" />}
                >
                  {resetLabel || `Reset`}
                </Button>
              )}
            </div>
          </div>
        </>
      )}
      {!isValidSchema && isValidSchema !== null && (
        <p className={styles.invalidSchema}>Invalid schema provided!</p>
      )}
      {noExecutionForReference && (
        <div className={styles.loader}>
          <p
            className="font-large"
            style={{ textAlign: "center", lineHeight: "25px" }}
          >
            Because step has references, the orchestration must be executed at least
            once before this step can be tested
          </p>
        </div>
      )}
    </div>
  );
}

export default UserInputInterface;
