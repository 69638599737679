import React, { useState } from "react";
import styles from "./ConnectionInterfaceTable.module.scss";
import { useDispatch } from "react-redux";
import { sendConnectionActionRequest } from "../../../state/sagas/connectionAction.saga";
import { alertError } from "../../../state/alerts/actions";
import DLXButton from "../../DLXButton/DLXButton";
import ActionOptionsDialog from "./ActionOptionsDialog/ActionOptionsDialog";
import useLeaseConnection from "../../../state/connections/lease/useLeaseConnection";

const mapActions = (actions, type, connector) =>
  actions
    ? actions
        .filter((action) => connector.actions[action])
        .map((action) => ({
          ...connector.actions[action],
          actionType: type,
          id: action,
        }))
    : [];

const getIcon = (actionType) => (actionType === "initiating" ? "dot-circle" : "");
const getColor = (actionType) =>
  actionType === "initiating" ? "secondary" : "primary";

const ActionButtons = ({
  connection,
  allowReadings,
  attributes = {},
  setWaitingForReading,
  waitingForReading,
  waitingForSeries,
}) => {
  const dispatch = useDispatch();
  const { isLeaseHolder } = useLeaseConnection();
  const [working, setWorking] = useState(false);
  const [currentAction, setCurrentAction] = useState({});
  const [showOptionsDialog, setShowOptionsDialog] = useState(false);
  const connectionOnline = connection?.status?.online?.valid;

  const associatedActions = mapActions(
    connection?.connector?.readings?.associatedActions,
    "associated",
    connection.connector
  );

  const initiatingActions = mapActions(
    connection?.connector?.readings?.initiatingActions,
    "initiating",
    connection.connector
  );

  const actions = allowReadings
    ? [...initiatingActions, ...associatedActions]
    : associatedActions;

  const executeCommand = async (action, options = {}) => {
    setWorking(action);
    setWaitingForReading(
      connection?.connector?.readings?.initiatingActions.includes(action)
    );
    try {
      const { success, message } = await dispatch(
        sendConnectionActionRequest({
          connectionId: connection.id,
          action,
          actionName: action,
          options,
          timeout: 60000,
          attributes: attributes,
        })
      );
      if (!success) {
        throw new Error(message);
      }
    } catch (error) {
      setWaitingForReading(false);
      dispatch(alertError(`Action did not execute: ${error.message}`));
    }
    setWorking(false);
  };

  const handleClick = async (action) => {
    setCurrentAction(action);
    if (
      action?.optionsSchema?.properties &&
      Object.keys(action.optionsSchema.properties).length
    ) {
      setShowOptionsDialog(true);
      return;
    }
    await executeCommand(action.id);
  };

  return !actions.length ? (
    <p>No actions available</p>
  ) : (
    <div className={styles.actionButtonContainer}>
      {showOptionsDialog && (
        <ActionOptionsDialog
          onSubmit={(options) => executeCommand(currentAction.id, options)}
          onCancel={() => setShowOptionsDialog(false)}
          action={currentAction}
          executeCommand={executeCommand}
        />
      )}
      {actions.map((action) => (
        <DLXButton
          key={`${action.title}-button`}
          disabled={Boolean(
            working ||
              !isLeaseHolder ||
              !connectionOnline ||
              waitingForReading ||
              waitingForSeries
          )}
          icon={getIcon(action.actionType)}
          size="small-xs"
          label={action.title}
          variant="contained"
          color={getColor(action.actionType)}
          handleClick={() => handleClick(action)}
          tooltip={action.description}
        >
          {action.title}
        </DLXButton>
      ))}
    </div>
  );
};

export default ActionButtons;
