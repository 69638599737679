export function generateStepId(num, usedIds) {
  if (!usedIds || !(usedIds instanceof Set)) {
    throw TypeError(`used ids must be a set, recieved type ${typeof key}`);
  }

  if (typeof num === "number") {
    return generateStepId(num.toString(), usedIds);
  }

  const id = hash(num);

  if (usedIds.has(id)) {
    return generateStepId(`0${num}`, usedIds);
  }

  return id;
}

function hash(key) {
  if (typeof key !== "string") {
    throw TypeError(`key must be string, recieved type ${typeof key}`);
  }

  let h = 0;
  for (let i = key.length; i >= 0; i--) {
    h += key.charCodeAt(i);
    h += h << 10;
    h ^= h >> 6;
  }

  h += h << 3;
  h ^= h >> 11;

  return (((h + (h << 15)) & 4294967295) >>> 0).toString(36).padStart(7, "0");
}
