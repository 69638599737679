import { withStyles } from "@material-ui/styles";
import MuiMenuItem from "@material-ui/core/MenuItem";

export const MenuItem = withStyles({
  root: {
    borderTop: "1px solid #dadce0",
    textAlign: "center",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
})(MuiMenuItem);
