import { useEffect } from "react";
import { getStoredAccessToken } from "@dlx/services/tokens";

export const AuthHeaderInterceptor = ({ client }) => {
  useEffect(() => {
    const interceptor = client.interceptors.request.use(async (req) => {
      if (req?.skipAuthHeaders) {
        return req;
      }
      return navigator.locks.request("token", { mode: "shared" }, () => {
        const accessToken = getStoredAccessToken();
        req.headers["Authorization"] = `Bearer ${accessToken}`;
        return req;
      });
    });
    return () => client.interceptors.request.eject(interceptor);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
};
